<template>
	<div style="width: 100%; display: flex;flex-direction: column; background-color: #fff;">
		<div style="display: flex; align-items: flex-end;">
			<div style="font-size: 17px; margin-right: 15px;">学习足迹</div>
		</div>
		<div class="center_record_list" v-infinite-scroll="nextPage" infinite-scroll-delay="300">
			<div class="record_item" v-for="(item,index) of productData.list" @mouseover="mouseoverHandler(item)"
				@mouseout="mouseoutHandler()" @click="toDetail(item)">
				<div class="record_item_lf">
					<div class="record_item_lf_dot" :class="{'gray':item.created_at!='今天'}"></div>
					<div class="record_item_lf_line" v-if="index!=productData.list.length-1"></div>
				</div>
				<div class="record_item_cr">{{item.created_at}}</div>
				<div class="record_item_rt" :class="{'hover':hover_index == item}">
					{{item.title}}
					<i class="el-icon-arrow-right record_item_rt_icon" v-if="hover_index == item"></i>
				</div>
			</div>
		</div>

		<el-empty v-if="!productData.list"></el-empty>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				type: null,
				hover_index: 0,
				search: {
					page_index: 1,
					page_size: 10,
					order_by: 'created_at:desc'
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				},
				loading: false
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.nextPage();
		},
		methods: {
			mouseoverHandler(item) {
				this.hover_index = item;
			},
			mouseoutHandler() {
				this.hover_index = null;
			},
			toDetail(item) {
				this.GoToDetail(item.product_type, item.id);
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_history_list_get;
				this.Ajax(url, this.search, (data, code, msg) => {
					if (code == 0) {
						if (data) {
							if (that.productData.total == 0) {
								that.productData.total = data.total;
							}
							if (that.productData.count == 0) {
								that.productData.count = data.count;
							}
							that.productData.list = that.productData.list.concat(data.list);
						}
					} else if (code == 40004) {
						this.loading = false;
						if (that.search.page_index == 1) {
							that.productData.list = null;
						}
					}
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.getProductList();
			},
			nextPage() {
				if (this.search.page_index >= this.productData.count && this.search.page_index != 0) {
					this.search.page_index = this.productData.count;
					return;
				}
				this.loading = true;
				this.currentPage(this.search.page_index + 1);
			}


		},
	};
</script>


<style lang="scss" scoped>
	.border-no {
		border-bottom: none !important;
	}

	.center_record_list {
		display: flex;
		flex-direction: column;
		margin-top: 5px;

		.record_item {
			display: flex;
			align-items: center;
			font-size: 14px;

			.record_item_lf {
				display: flex;
				width: 14px;
				height: 14px;
				position: relative;
				justify-content: center;
				align-items: center;
				margin-right: 18px;

				.record_item_lf_dot {
					z-index: 33;
					display: flex;
					width: 14px;
					height: 14px;
					background-color: #FCA35A;
					border-radius: 50%;

					&.gray {
						background-color: #999 !important;
					}
				}

				.record_item_lf_line {
					z-index: 1;
					width: 1px;
					height: 36px;
					background-color: #ccc;
					position: absolute;
					bottom: -42px;
				}
			}

			.record_item_cr {
				width: 130px;
				margin-right: 75px;
				color: #666;
			}

			.record_item_rt {
				flex: 1;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 14px;
				color: #333;
				font-weight: 600;
				padding: 20px 30px;
				box-sizing: border-box;
				border-radius: 4px;
				cursor: pointer;

				&.hover {
					background-color: #F1F1F1 !important;
				}

				.record_item_rt_icon {
					color: #FCA35A;
					font-size: 20px;
				}
			}
		}

	}
</style>