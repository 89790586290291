<template>
	<div class="page-main">
		<div style="width: 100%;display: flex; flex-direction: column; align-items: center;background: #fff;">
			<div style="display: flex; flex-direction: column;width: 1200px; max-width: 1200px; box-sizing: border-box;">
				<div class="mianb">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/' }">注册</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				
				<div style=" margin-top: 50px;display: flex; align-items: center;justify-content: center;">
					<div style="width: 784px;position: relative; ">
						<img  src="../../assets/images/pic_login.png"  style="width: 550px;z-index: 1;margin-top: 50px;"/>
						<div style="width: 784px;display: flex;z-index: 2;position: absolute;top: 0; left: 0;padding: 0 60px;">
							<div style="flex: 1;">
								
							</div>
							<div style="display: flex;flex-direction: column; width: 252px; line-height: 1;">
								<div style="display: flex;flex-direction: column;">
									<div style="font-size: 32px; margin-bottom: -10px; z-index: 2; padding-left: 5px; font-weight: bold;">注册</div>
									<div style="height: 15px; background-color: #3CD0BA;display: inline-block; width: 77px;z-index: 1;"></div>
								</div>
								<div style="font-size: 15px;color: #333;margin-top: 12px;">
									机工教师特训营
								</div>
								<div style=" width: 100%; display: flex;flex-direction: column;font-size: 15px;margin-top: 25px;color: #999;">
									<div style="display: flex;">手机号码</div>
									<div style="display: flex;margin-top: 5px;width: 100%; ">
										<input type="text" placeholder="请输入手机号码" style=" width: 100%; background: transparent;border-bottom: 1px solid #999; line-height: 2; color: #333;"/>
									</div>
									<div style="display: flex;margin-top: 20px;">安全验证</div>
									<div style="display: flex;margin-top: 12px;width: 100%; ">
										  <drag-verify
										     ref="dragVerify"
										     :isPassing.sync="isPassing"
										     text="请按住滑块"
										     successText="验证成功"
										     handlerIcon="el-icon-d-arrow-right"
										     successIcon="el-icon-circle-check"
										   >
										   </drag-verify>
									</div>
									<div style="display: flex;margin-top: 20px;">
										验证码
									</div>
									<div style="display: flex;margin-top: 5px;width: 100%;border-bottom: 1px solid #999;  align-items: center;">
										<input type="password" name="" id="" placeholder="请输入验证码" style="flex: 1; background: transparent;line-height: 2;color: #333;"/>
										<div style="display: inline-block;color:#3CD0BA ; font-size: 12px; margin-left: 5px;">获取验证码</div>
										
									</div>
									<div style="display: flex; flex-direction: row; justify-content: space-between;margin-top: 10px;font-size: 12px;">
										
									</div>
								</div>
								<div style="display: flex;margin-top: 20px; width: 100%;">
									  <el-button type="primary" style="width: 100%;">登录</el-button>
								</div>
								<div style="display: flex; align-items: center; justify-content: center; width: 100%; font-size: 12px; margin-top: 10px;">
									已有账号？<div style="color: #3CD0BA; line-height: 1.5; border-bottom: 1px solid #3CD0BA; display: inline-block;cursor: pointer;">立即登录</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
	
				
	
			</div>
		</div>
	
		<foo-ter />
	</div>
</template>

<script>
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";

	export default {
		data() {
			return {
				isShow: false,
				iframeSrc: '',
				tabList: [],
				isPassing:false,
			};
		},
		created() {
			var option = {
				code: this.$route.query.code || "",
			};
			if (option && option.code) {
				this.wxlogin();
			}
		},
		methods: {
			loginClick() {
				this.isShow = true;
				this.wxlogin();
			},
			wxlogin() {
				var that = this;
				var option = {
					code: this.$route.query.code || "",
				};
				var param = {};
				if (option && option.code) {
					param = { code: option.code }
				} else {
					param = { page_url: window.location.href }
				}
				this.Ajax(ApiUrl.user_weixin_login, param, function(data, code) {
					if (code == 0) {
						localStorage.setItem("userLogInInfo", JSON.stringify(data));
						that.GoToChangeTab(1);
					} else if (code == 40002) {
						that.iframeSrc = data.href;
						console.log(JSON.stringify(data))
					} else {
						that.isShow = false;
						that.$message({
							message: '抱歉，您不是企业用户，暂无法登录',
							type: 'warning',
							offset: 330,
							duration: 2000
						});
						that.$router.go(0)
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	html,
	body {
		width: 100%;
		height: 100vh;
		background: #f7f7f7;
	}

	.main {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
	
	/deep/.el-button--primary{
		background-color: #3CD0BA;
		border-color: #3CD0BA;
	}
	/deep/.el-button--primary:hover,.el-button--primary:focus{
		background-color: #3CD0BA;
		border-color:#3CD0BA;
	}
</style>
