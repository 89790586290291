<template>
	<div class="page-main" style="position: relative;" :id="backtop_id">
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;"
			v-if="page_index == 1">
			<!-- 报名 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						报名
					</div>
				</div>
			</div>
			
			
			
			<div v-if="detailData"
				style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 35px; margin-bottom: 60px; ;line-height: 1;">
				<div
					style="display: flex; justify-content: space-between;padding-bottom: 22px; border-bottom: 1px solid #eee;">
					<div style="display: flex; flex-direction: column;font-size: 18px; color: #333;">
						<div style="font-weight: 600;">项目</div>
						<div style="margin-top: 40px;">{{detailData.title}}</div>
					</div>
					<div style="display: flex; flex-direction: column; justify-content: flex-end;" v-if="price">
						<div style="font-size: 12px; color: #ff9000;">总计</div>
						<div style="font-size: 18px; color: #333; margin-top: 24px;">{{price}} x {{gift_count}} =
							￥{{total_fee}}</div>
					</div>
				</div>

				<div style=" margin-top: 15px;"
					v-if="detailData && detailData.is_open_pay==1 && detailData.case_type == 1">
					<el-alert title="支付方式说明" type="warning"
						description="本项目支持微信在线支付、对公转账及现场缴费，提交完报名信息后，系统引导您选择支付方式，并提供对公支付、转账信息，无论您选择哪种支付方式，均可开具发票。"
						show-icon>
					</el-alert>
				</div>


				<div style="display: flex;flex-direction: column;">
					<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0;">
						报名信息
					</div>



					<div style="display: inline-block; width: 640px;">
						<el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="260px">

							<div v-for="(domain, index) in dynamicValidateForm.domains" :key="domain.key">
								<el-divider content-position="left" v-if="index>0">参学人员 +{{index}}</el-divider>

								<el-form-item v-if="detailData.is_package==1"
									:prop="'domains.' + index + '.package_items'" label="参会"
									:rules="[{ required: true, message: '请选择您想要参加的会议', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.package_items" style="margin-top:5px"
										@change="changePackageItems" :disabled="domain.package_items_disabled">
										<el-radio v-for="package_item in detailData.package_items"
											:label="package_item.id">{{package_item.title}}
											{{package_item.price}}</el-radio>
									</el-radio-group>
								</el-form-item>

								<el-form-item :prop="'domains.' + index + '.name'" label="姓名"
									:rules="[{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.name" placeholder="请输入姓名"></el-input>
								</el-form-item>
								<el-form-item :prop="'domains.' + index + '.mobile'" label="手机"
									:rules="[{ required: true, message: '请输入手机号码', trigger: ['blur', 'change'] },{ pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }]">
									<el-input @blur="inputMobileBlurEvent" v-model="domain.mobile"
										placeholder="请输入手机号码"></el-input>
								</el-form-item>
								<el-form-item :prop="'domains.' + index + '.sex'" label="性别"
									:rules="[{ required: true, message: '请选择性别', trigger: 'change' }]">
									<el-radio-group v-model="domain.sex">
										<el-radio label="男"></el-radio>
										<el-radio label="女"></el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item v-if="detailData.case_type == 1" :prop="'domains.' + index + '.diet_type'"
									label="餐饮" :rules="[{ required: true, message: '请选择餐饮类型', trigger: 'change' }]">
									<el-radio-group v-model="domain.diet_type">
										<el-radio label="清真"></el-radio>
										<el-radio label="非清真"></el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item :prop="'domains.' + index + '.job_type'" label="身份"
									:rules="[{ required: true, message: '请选择身份类型', trigger: 'change' }]">
									<el-radio-group v-model="domain.job_type" @input="jobTypeChangeEvent"
										:disabled="domain.job_type_disabled">
										<el-radio label="教师"></el-radio>
										<el-radio label="学生"></el-radio>
										<el-radio label="其他"></el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师' || domain.job_type == '学生'"
									:prop="'domains.' + index + '.school'" label="所在学校"
									:rules="[ { required: true, message: '请输入学校', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.school" placeholder="请输入学校"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师' || domain.job_type == '学生'"
									:prop="'domains.' + index + '.college'" label="学院"
									:rules="[ { required: true, message: '请输入学院', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.college" placeholder="请输入学院"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'" :prop="'domains.' + index + '.title'"
									label="职称"
									:rules="[ { required: true, message: '请输入职称', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.title" placeholder="请输入职称"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'" :prop="'domains.' + index + '.duty'"
									label="职务"
									:rules="[ { required: true, message: '请输入职务', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.duty" placeholder="请输入职务"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'"
									:prop="'domains.' + index + '.teaching_length'" label="教龄"
									:rules="[{ required: true, message: '请输入教龄', trigger: ['blur', 'change'] },{ type: 'number', message: '教龄必须为数字类型', trigger: ['blur', 'change']}]">
									<el-input v-model.number="domain.teaching_length" placeholder="请输入教龄"></el-input>
								</el-form-item>

								<el-form-item v-if="domain.job_type == '其他'" :prop="'domains.' + index + '.company'"
									label="公司(单位)名称"
									:rules="[ { required: true, message: '请输入公司或单位名称', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.company" placeholder="请输入公司(单位)名称"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '学生' || domain.job_type == '其他'"
									:prop="'domains.' + index + '.education'" label="学历"
									:rules="[{ required: true, message: '请选择学历', trigger: 'change' }]">
									<el-radio-group v-model="domain.education">
										<el-radio label="专科"></el-radio>
										<el-radio label="本科"></el-radio>
										<el-radio label="研究生"></el-radio>
									</el-radio-group>
								</el-form-item>


								<!-- 网安 -->
								<div v-if="id == 't_86331296ae324124bf1072a0d3a018fe'">

									<el-form-item :prop="'domains.' + index + '.check_in_date'" label="报到日期"
										:rules="[{ required: true, message: '请选择报道日期', trigger: ['blur', 'change'] }]">
										<div style=" line-height: 1.4; margin: 12px 0;color: #999">
											报到地址：合肥乐富强铂悦酒店（酒店地址：安徽省合肥市蜀山区创新大道229号，联系电话：1832660374724）
										</div>
										<el-radio-group v-model="domain.check_in_date">
											<el-radio label="4月12日"></el-radio>
											<el-radio label="4月13日9:00前（报到地址：合肥乐富强铂悦酒店）"></el-radio>
											<el-radio label="4月13日9:00前（报到地址：中国科学技术大学高新园区）"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.check_in_date == '其他'"
										:prop="'domains.' + index + '.check_in_date_other'" label="其他报到日期"
										:rules="[ { required: true, message: '请输入其他报道日期', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.check_in_date_other"
											placeholder="请输入其他报到日期"></el-input>
									</el-form-item>


									<el-form-item :prop="'domains.' + index + '.room_type'" label="您对入住房型的需求"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											合肥乐富强铂悦酒店<br>
											协议价：大床房（含单早）350元/天，标准间（含单早）350元/天、标准间（含双早）410元/天
										</div>
										<el-radio-group v-model="domain.room_type">
											<el-radio label="大床房"></el-radio>
											<el-radio label="双床房(单住)"></el-radio>
											<el-radio label="双床房(合住)"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>


									<el-form-item v-if="domain.room_type == '双床房(合住)'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>


									<el-form-item :prop="'domains.' + index + '.is_visit'" label="您是否参加14日下午的参观考察？"
										:rules="[{ required: true, message: '请选择您是否参加参观考察', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0; color: #999;">
											本源量子、科大先研院（龙芯中科）、新华三等合肥市高新技术企业</div>

										<el-radio-group v-model="domain.is_visit">
											<el-radio label="是"></el-radio>
											<el-radio label="否"></el-radio>
										</el-radio-group>
									</el-form-item>

								</div>



								<!-- SOLIDWORKS -->
								<div v-if="id == 't_8c168c7c2ab741ec87ae4b324793f394'">
									<el-form-item label="住宿">
										<div style=" line-height: 1.4; margin: 12px 0;color: #999">
											会议地点提供住宿服务：大连栖悦酒店（辽宁省大连市甘井子区大连门广场1号）标准间、大床房均为350元/间，均含早餐。请自行联系酒店预定房间，联系人:林经理，订房电话：155
											6688 8849。
										</div>
									</el-form-item>
								</div>

								<!-- <div v-if="id == 't_8c168c7c2ab741ec87ae4b324793f394'">
									
									<el-form-item  :prop="'domains.' + index + '.check_in_date'" label="报到日期" :rules="[{ required: true, message: '请选择报道日期', trigger: ['blur', 'change'] }]">
										<div style=" line-height: 1.4; margin: 12px 0;color: #999">
											报到地址：中铁国资北戴河分公司（河北省秦皇岛市北戴河区滨海大道2号），报到、住宿联系人齐老师 15133550003、王老师 18432266111
										</div>
										<el-radio-group v-model="domain.check_in_date">
										    <el-radio label="5月16日"></el-radio>
										    <el-radio label="5月17日"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>
									
									<el-form-item v-if="domain.check_in_date == '其他'" :prop="'domains.' + index + '.check_in_date_other'" label="其他报到日期" :rules="[ { required: true, message: '请输入其他报道日期', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.check_in_date_other" placeholder="请输入其他报到日期"></el-input>
									</el-form-item>
									
									
									<el-form-item :prop="'domains.' + index + '.room_type'" label="住宿选择" :rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											会务组统一为您预定房间<br>
											标准间：280 元/间，大床房：350 元/间，均含早餐
										</div>
										<el-radio-group v-model="domain.room_type">
										    <el-radio label="大床房"></el-radio>
										    <el-radio label="标准间(单住)"></el-radio>
											<el-radio label="标准间(合住)"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>
									
									<el-form-item v-if="domain.room_type == '其他'" :prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求" :rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other" placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>
									
									
									<el-form-item v-if="domain.room_type == '标准间(合住)'" :prop="'domains.' + index + '.room_share_with'" label="合住人姓名" :rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>

								</div> -->


								<!-- 新能源汽车 -->
								<div v-if="id == 't_8d955ddd69204696b061b5de87f35161'">

									<el-form-item :prop="'domains.' + index + '.room_type'" label="住宿选择"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											福建厦门佰翔软件园酒店（厦门市湖里区观日路1号）<br>
											协议价：400元/天（标准房、大床房），会务组视当天房间情况随机安排房型，酒店联系人 何仕杰18050059902
										</div>
										<el-radio-group v-model="domain.room_type">
											<el-radio label="单人入住"></el-radio>
											<el-radio label="与指定人员拼房"></el-radio>
											<el-radio label="会务组随机拼房"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>


									<el-form-item v-if="domain.room_type == '与指定人员拼房'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>

								</div>


								<!-- 扎根 -->
								<div v-if="id == 't_8e4a37fb05364034980726c88b53d529'">

									<el-form-item :prop="'domains.' + index + '.room_type_before'" label="房型需求"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											会议报到及推荐入住酒店：<br>
											青岛华澜大酒店（青岛市李沧区金水路171号）。<br>
											40平米客房协议价：380元/天（标准房、大床房），30平米客房协议价：330元/天（标准房、大床房 ，已订满），会务组视当天房间情况随机安排单人房型。
										</div>
										<el-radio-group v-model="domain.room_type_before">
											<el-radio label="40平米客房"></el-radio>
											<el-radio label="30平米客房（已订满）" disabled></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.room_type_before == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>

									<el-form-item
										v-if="domain.room_type_before == '40平米客房' || domain.room_type_before == '30平米客房'"
										:prop="'domains.' + index + '.room_type'" label="入住方式"
										:rules="[{ required: true, message: '请选择入住方式', trigger: ['blur', 'change'] }]">
										<el-radio-group v-model="domain.room_type">
											<el-radio label="单人入住"></el-radio>
											<el-radio label="与指定人员拼房"></el-radio>
											<el-radio label="会务组随机拼房"></el-radio>
										</el-radio-group>
									</el-form-item>


									<el-form-item v-if="domain.room_type == '与指定人员拼房'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>

								</div>


								<!-- 新能源汽车 -->
								<div v-if="id == 't_8f44faaea70849d49f5c7ca486b9b453'">

									<el-form-item :prop="'domains.' + index + '.room_type'" label="住宿选择"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											广州芳华美宿（广东省广州市番禺区沙湾街市良路1342号广州番禺职业技术学院院内）<br>
											协议价：328元/天（含双早）
										</div>
										<el-radio-group v-model="domain.room_type">
											<el-radio label="单人入住"></el-radio>
											<el-radio label="与指定人员拼房"></el-radio>
											<el-radio label="会务组随机拼房"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>


									<el-form-item v-if="domain.room_type == '与指定人员拼房'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>

								</div>


								<!-- 无人机 -->
								<div v-if="id == 't_8h28e4cf4ef6574730a16bc569232e0e'">


									<el-form-item :prop="'domains.' + index + '.room_type'" label="住宿选择"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											内蒙古维力斯大酒店（呼和浩特市新城区新华东街89号）<br>
											协议价：350元/天（大床房/标间，含双早）
										</div>
										<el-radio-group v-model="domain.room_type">
											<el-radio label="单人入住"></el-radio>
											<el-radio label="与指定人员拼房"></el-radio>
											<el-radio label="会务组随机拼房"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>


									<el-form-item v-if="domain.room_type == '与指定人员拼房'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>


									<el-form-item :prop="'domains.' + index + '.is_join'" label="是否申请加入无人机资源建设委员会？"
										:rules="[{ required: true, message: '请选择您是否申请加入', trigger: ['blur', 'change'] }]">
										<el-radio-group v-model="domain.is_join">
											<el-radio label="是"></el-radio>
											<el-radio label="否"></el-radio>
										</el-radio-group>
										<div v-if="domain.is_join == '是'"
											style="line-height: 1.4;margin: 12px 0; color: #999;">
											<div>请下载申请表，填写后发送至邮箱303431623@qq.com</div>
											<div @click="downloadClick"
												style="display: inline-block; color: rgb(41, 121, 255);border-bottom: 1px solid rgb(41, 121, 255);margin-top: 10px; cursor: pointer;">
												点击下载申请表</div>
										</div>
									</el-form-item>

									<el-form-item :prop="'domains.' + index + '.is_will'" label="是否有意愿考取CAAC民用无人机操控员执照？"
										:rules="[{ required: true, message: '请选择您是否申请加入', trigger: ['blur', 'change'] }]">
										<el-radio-group v-model="domain.is_will">
											<el-radio label="是"></el-radio>
											<el-radio label="否"></el-radio>
										</el-radio-group>
									</el-form-item>


									<el-form-item :prop="'domains.' + index + '.id_card'" label="身份证号码"
										:rules="[{ required: true, message: '请输入身份证号码', trigger: ['blur', 'change'] },{ len:18,message: '请输入正确的身份证号码', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											7月26日外出航拍，主办方为参与人员统一购买意外险，请各位老师填写身份证号
										</div>
										<el-input v-model="domain.id_card" placeholder="请输入身份证号码"></el-input>
									</el-form-item>

								</div>


								<!-- 数控 -->
								<div v-if="id == 't_8i4d1cf92190324a0a9ab655ced72f9f'">

									<el-form-item :prop="'domains.' + index + '.check_in_date'" label="报到日期"
										:rules="[{ required: true, message: '请选择报道日期', trigger: ['blur', 'change'] }]">
										<div style=" line-height: 1.4; margin: 12px 0;color: #999">
											报到地点：吉林省长春市绿园区新红旗大街1777号长春汽车职业技术大学（原长春汽车工业高等专科学校）
										</div>
										<el-radio-group v-model="domain.check_in_date">
											<el-radio label="7月3日"></el-radio>
											<el-radio label="7月4日"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.check_in_date == '其他'"
										:prop="'domains.' + index + '.check_in_date_other'" label="其他报到日期"
										:rules="[ { required: true, message: '请输入其他报道日期', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.check_in_date_other"
											placeholder="请输入其他报到日期"></el-input>
									</el-form-item>


									<el-form-item :prop="'domains.' + index + '.room_type'" label="住宿选择"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											校内培训宾馆提供住宿服务，268.00元/标间/天
										</div>
										<el-radio-group v-model="domain.room_type">
											<el-radio label="住宿"></el-radio>
											<el-radio label="不住宿"></el-radio>
										</el-radio-group>
									</el-form-item>

								</div>


								<!-- AIGC-->
								<div v-if="id == 't_8jaa9f66f964fe41b69886ef9cebbf3f' ||  id == 't_8t714f7bafc07f490e9ba8dcf00df17d4f' || id == 't_8yb6d3f1c67a0a4d3bade212aed273c1'">

									<el-form-item :prop="'domains.' + index + '.id_card'" label="身份证号码"
										:rules="[{ required: true, message: '请输入身份证号码', trigger: ['blur', 'change'] },{ len:18,message: '请输入正确的身份证号码', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											为登记信息，方便后续大家考试，请各位老师填写身份证号
										</div>
										<el-input v-model="domain.id_card" placeholder="请输入身份证号码"></el-input>
									</el-form-item>
									
									
									<!-- 邮寄地址待处理 -->
									<!-- 
									<el-form-item label="证书邮件地址" required>
										<el-col :span="24">
										  <el-form-item :prop="'domains.' + index + '.pca'" :rules="[{ required: true, message: '请选择省市区', trigger: ['blur', 'change']}]">
											<el-cascader placeholder="请选择省市区" :options="options" v-model="domain.pca"  :props="{label:'name',value:'name',children:'children' }" filterable ></el-cascader>
										  </el-form-item>
										</el-col>
										<el-col :span="24">
										  <el-form-item :prop="'domains.' + index + '.address'" :rules="[{ required: true, message: '请输入详情地址', trigger: ['blur', 'change']}]">
											<el-input v-model="domain.address" placeholder="请输入详细地址"></el-input>
										  </el-form-item>
										</el-col>
									</el-form-item> 
									-->
									
			
								</div>



								<!-- PLC-->
								<div v-if="id == 't_8kc6154d6cff8b47c29f537e1eae471e'">


									<el-form-item :prop="'domains.' + index + '.room_type'" label="住宿选择"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											会议住宿酒店：云南昆明·中维天恒大酒店（云南省昆明市五华区青年路432号），协议价：400元/天（单早），430元/天（双早）
										</div>
										<el-radio-group v-model="domain.room_type">
											<el-radio label="单人入住"></el-radio>
											<el-radio label="与指定人员拼房"></el-radio>
											<el-radio label="会务组随机拼房"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>


									<el-form-item v-if="domain.room_type == '与指定人员拼房'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>


									<el-form-item :prop="'domains.' + index + '.id_card'" label="身份证号码"
										:rules="[{ required: true, message: '请输入身份证号码', trigger: ['blur', 'change'] },{ len:18,message: '请输入正确的身份证号码', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											为登记信息，方便大家进出参观院校，请各位老师填写身份证号
										</div>
										<el-input v-model="domain.id_card" placeholder="请输入身份证号码"></el-input>
									</el-form-item>

								</div>


								<!-- 国际贸易-->
								<div v-if="id == 't_8mbfe254cbfd2e42df842fc051080e54'">

									<el-form-item :prop="'domains.' + index + '.room_type'" label="住宿选择"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											请参会者自行联系酒店预订房间，报名信息我平台会及时同步酒店方。
											暑期房源紧张，请尽早联系酒店预订。<br><br>

											蒙·世家酒店（集宁区昌宁街乌兰美文旅大厦）<br>
											协议价：400元/人（商务大床/标间、不含早餐）<br>
											酒店联系人：连经理，155 4099 2983
										</div>
										<el-radio-group v-model="domain.room_type">
											<el-radio label="单人入住"></el-radio>
											<el-radio label="与指定人员拼房"></el-radio>
											<el-radio label="会务组随机拼房"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '与指定人员拼房'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>

								</div>



								<!-- 工业互联网-->
								<div v-if="id == 't_8n29da89c6205b478d9987f083f5594f'">

									<el-form-item :prop="'domains.' + index + '.room_type'" label="住宿选择"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											会议住宿酒店：重庆桃乐源酒店(江北机场桃源公园店)(重庆市渝北区双凤桥街道长凯路2号)，协议价：320元(含双早)
										</div>
										<el-radio-group v-model="domain.room_type">
											<el-radio label="单人入住"></el-radio>
											<el-radio label="与指定人员拼房"></el-radio>
											<el-radio label="会务组随机拼房"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>

									<el-form-item v-if="domain.room_type == '与指定人员拼房'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>

								</div>


								<!-- QCA-->
								<div v-if="id == 't_8oc16aebed25b34f489e8f66e12acc7c'">

									<el-form-item label="住宿">
										<div style=" line-height: 1.4; margin: 12px 0;color: #999">
											会议酒店：盛世民航国际酒店（河南省郑州市金水路2号）<br>
											协议价：360元/天（含双早）<br>
											酒店联系人：徐经理 180 3767 3239，董经理 157 3818 1136<br>
											温馨提示：敬请与会人员自行联系酒店预订房间，订房时向酒店方表明参加“机工社培训”活动，以便享受协议优惠房价。
										</div>
									</el-form-item>

								</div>


								<!-- “五金”新基建 -->
								<div v-if="id == 't_8v669a06c7030047d0858d2c8538f135'">

									<el-form-item :prop="'domains.' + index + '.is_cert'" label="是否考取 数字化技能创新应用技术（高级）证书"
										:rules="[{ required: true, message: '请选择是否考证', trigger: ['blur', 'change'] }]">
										<el-radio-group v-model="domain.is_cert" @input="isCertChangeEvent"
											:disabled="domain.is_cert_disabled">
											<el-radio label="是"></el-radio>
											<el-radio label="否"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item v-if="domain.is_cert == '是'" :prop="'domains.' + index + '.id_card'"
										label="身份证号码"
										:rules="[{ required: true, message: '请输入身份证号码', trigger: ['blur', 'change'] },{ len:18,message: '请输入正确的身份证号码', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											为登记信息，方便后续大家考试，请各位老师填写身份证号
										</div>
										<el-input v-model="domain.id_card" placeholder="请输入身份证号码"></el-input>
									</el-form-item>

								</div>
								
								
								<!-- 电气工程-->
								<div v-if="id == 't_8zb5f0693bbdf645b2943aa23c84e551'">
								
									<el-form-item :prop="'domains.' + index + '.room_type_before'" label="房型需求"
										:rules="[{ required: true, message: '请选择您对入住房型的需求', trigger: ['blur', 'change'] }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999">
											会议报到及推荐入住酒店：<br>
											无锡山明水秀大饭店（无锡市蠡溪路999号）。<br>
											普通标准间340元/晚（含双早）；高级标准间380元/晚（单早），420元/晚（双早）；高级大床房380元/晚（单早）
										</div>
										<el-radio-group v-model="domain.room_type_before">
											<el-radio label="普通标准间"></el-radio>
											<el-radio label="高级标准间"></el-radio>
											<el-radio label="高级大床房"></el-radio>
											<el-radio label="不住宿"></el-radio>
											<el-radio label="其他"></el-radio>
										</el-radio-group>
									</el-form-item>
								
									<el-form-item v-if="domain.room_type_before == '其他'"
										:prop="'domains.' + index + '.room_type_other'" label="其他入住房型的需求"
										:rules="[ { required: true, message: '请输入其他入住房型的需求', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_type_other"
											placeholder="请输入其他入住房型的需求"></el-input>
									</el-form-item>
								
									<el-form-item
										v-if="domain.room_type_before == '普通标准间' || domain.room_type_before == '高级标准间'"
										:prop="'domains.' + index + '.room_type'" label="入住方式"
										:rules="[{ required: true, message: '请选择入住方式', trigger: ['blur', 'change'] }]">
										<el-radio-group v-model="domain.room_type">
											<el-radio label="单人入住"></el-radio>
											<el-radio label="与指定人员拼房"></el-radio>
											<el-radio label="会务组随机拼房"></el-radio>
										</el-radio-group>
									</el-form-item>
								
								
									<el-form-item v-if="domain.room_type == '与指定人员拼房'"
										:prop="'domains.' + index + '.room_share_with'" label="合住人姓名"
										:rules="[ { required: true, message: '请输入合住人姓名', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.room_share_with" placeholder="请输入合住人姓名"></el-input>
									</el-form-item>
								
								</div>


								<el-button @click.prevent="removeDomain(domain)" v-if="index>0">移除参学人员
									+{{index}}</el-button>
							</div>

							<div style="margin-top: 30px;" v-if="is_gift_add">
								<el-form-item>
									<!-- <el-button type="primary" @click="submitForm('dynamicValidateForm')">验证填写信息</el-button> -->
									<el-button @click="addDomain">新增参学人员</el-button>
									<!-- <el-button @click="resetForm('dynamicValidateForm')">重置参学人员信息</el-button> -->
								</el-form-item>
								<el-alert title="报名信息填写验证通过" type="success" v-if="alert_show1">
								</el-alert>
							</div>
							<div style=" width: 100%; margin-top: 30px; font-size: 15px; color: #515a6e; padding-left: 160px; box-sizing: border-box;"
								v-if="!is_gift_add">
								<i class="el-icon-warning-outline" style="margin-right: 5px;"></i>如需添加更多参学人员请联系工作人员
							</div>

						</el-form>

						<div v-if="detailData.student_price"
							style="display: flex; flex-direction: column; width: 640px;font-size: 14px; margin-top: 12px; line-height: 1.5;text-align: justify;">
							暂不支持同时添加多种身份的参学者，如有需要请分别使用不同账号提交报名信息或联系工作人员<br>
							温馨提示：学生身份的参学者，参会时请务必携带学生证，会务组会在报到时核验报名信息
						</div>
					</div>


					<div v-if="total_fee > 0" style="display: flex; font-size: 24px; margin-top: 58px;">
						<el-checkbox v-model="checked" @change="checkedChangeEvent">我要发票</el-checkbox>
					</div>



					<div v-if="checked">

						<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0 10px 0;">
							发票信息
						</div>

						<div style="display: flex; flex-direction: column; width: 640px;">
							<div style="font-size: 14px; margin: 12px 0 30px 0; line-height: 1.5; text-align: justify;">
								我社统一开具电子普票，会后发送至您预留的邮箱。如需专票，请联系工作人员。<br>
								为避免后续开具的发票无法用于报销，填写发票信息前务必找贵校/贵司财务专员确认最新开票信息及可开发票类目。
							</div>


							<el-form :model="dynamicValidateForm2" ref="dynamicValidateForm2" label-width="260px">
								<div v-for="(domain, index) in dynamicValidateForm2.domains" :key="domain.key">

									<el-form-item v-if="gift_count>1" :prop="'domains.' + index + '.invoice_type'"
										label="开具方式"
										:rules="[{ required: true, message: '请选择发票类目', trigger: 'change' }]">
										<el-radio-group v-model="domain.invoice_type" @input="bindInvoiceData">
											<el-radio label="统一开具"></el-radio>
											<el-radio label="单独开具"></el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item :prop="'domains.' + index + '.invoice_count'" :border="false"
										label="发票张数"
										:rules="[{ required: true, message: '请输入发票张数', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.invoice_count" readonly
											placeholder="请输入发票张数"></el-input>
									</el-form-item>

									<el-form-item :prop="'domains.' + index + '.total_fee'" label="开票金额"
										:rules="[{ required: true, message: '请输入开票金额', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.total_fee" readonly placeholder="请输入开票金额"></el-input>
									</el-form-item>
									<el-form-item :prop="'domains.' + index + '.title'" label="发票抬头"
										:rules="[{ required: true, message: '请输入发票抬头', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.title" placeholder="请输入发票抬头"></el-input>
									</el-form-item>
									<el-form-item :prop="'domains.' + index + '.tax_id'" label="发票税号"
										:rules="[{ required: true, message: '请输入发票税号', trigger: ['blur', 'change'] },{ min: 15, max: 20, message: '请输入正确的发票税号', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.tax_id" placeholder="请输入发票税号(三证合一后税号为18位)"></el-input>
									</el-form-item>


									<el-collapse accordion>
										<el-collapse-item>
											<template slot="title">
												地址/电话/开户行/账号(非必填，点击可展开填写) <i class="header-icon el-icon-info"></i>
											</template>

											<div>
												<el-form-item :prop="'domains.' + index + '.registered_address'"
													label="地址(非必填)">
													<el-input v-model="domain.registered_address"
														placeholder="请输入开票信息中的单位地址"></el-input>
												</el-form-item>
												<el-form-item :prop="'domains.' + index + '.registered_contact'"
													label="电话(非必填)">
													<el-input v-model="domain.registered_contact"
														placeholder="请输入开票信息中的单位电话"></el-input>
												</el-form-item>
												<el-form-item :prop="'domains.' + index + '.bank'" label="开户行(非必填)">
													<el-input v-model="domain.bank"
														placeholder="请输入开票信息中的开户行名称"></el-input>
												</el-form-item>
												<el-form-item :prop="'domains.' + index + '.bank_account'"
													label="账号(非必填)">
													<el-input v-model="domain.bank_account"
														placeholder="请输入开票信息中的银行账号"></el-input>
												</el-form-item>
											</div>
										</el-collapse-item>
									</el-collapse>




									<el-form-item :prop="'domains.' + index + '.category'" label="发票类目"
										:rules="[{ required: true, message: '请选择发票类目', trigger: 'change' }]">
										<div style="line-height: 1.4;margin: 12px 0;color: #999"
											v-if="detailData.case_type == 1">
											开具的发票中会议费显示为『*会展服务*会议费』，培训费显示为『*非学历教育服务*{{detailData.title}}培训费』，请根据可开类目选择
										</div>
										<el-radio-group v-model="domain.category">
											<el-radio label="会议费" v-if="detailData.case_type == 1"></el-radio>
											<el-radio label="培训费"></el-radio>
											<!--  
											  <el-radio label="信息服务费"></el-radio>
											  <el-radio label="技术服务费"></el-radio>
											  <el-radio label="其他"></el-radio> 
											  -->
										</el-radio-group>
									</el-form-item>


									<el-form-item v-if="domain.category == '其他'"
										:prop="'domains.' + index + '.category_other'" label="其他发票类目"
										:rules="[{ required: true, message: '请输入发票类目', trigger: 'change' }]">
										<el-input v-model="domain.category_other" placeholder="请输入其他发票类目"></el-input>
									</el-form-item>
									<el-form-item :prop="'domains.' + index + '.email'" label="发票接收邮箱"
										:rules="[{ required: true, message: '请输入邮箱地址', trigger: ['blur', 'change'] },{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.email" placeholder="请输入邮箱地址"></el-input>
									</el-form-item>
									<el-form-item :prop="'domains.' + index + '.mobile'" label="发票接收人手机"
										:rules="[{ required: true, message: '请输入手机号码', trigger: ['blur', 'change'] },{ pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }]">
										<el-input v-model="domain.mobile" placeholder="请输入手机号码"></el-input>
									</el-form-item>


									<div style="margin-top: 30px;">
										<el-alert title="开票信息填写验证通过" type="success" v-if="alert_show2">
										</el-alert>
									</div>

								</div>
							</el-form>
						</div>
					</div>
				</div>

				<div @click="infoConfirm"
					style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div
						style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
						提交报名信息</div>
				</div>

			</div>
		</div>
		
		
	
		
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="page_index == 2">
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);"></div>
					<div v-if="stateData.result == 1"
						style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						报名成功
					</div>
					<div v-if="stateData.result == 0"
						style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						提交成功
					</div>
				</div>
			</div>
		
			<div style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 50px; margin-bottom: 50px; ;line-height: 1;">
		
				<div style="display: flex;flex-direction: column;justify-content: center; align-items: center; padding: 48px 0 40px 0; box-sizing: border-box; border-bottom: 1px solid #eee;">
					<img src="../../assets/images/icon_success.png" style="width: 120px; height: auto;" />
					<div v-if="stateData.result == 0" style=" font-size: 24px; margin-top: 30px; color: #333;">
						报名信息已提交成功
					</div>
					<div v-if="stateData.result == 1" style=" font-size: 24px; margin-top: 30px; color: #333;">
						报名成功
					</div>
					<div style="width: 640px;display: flex; flex-direction:column;margin-top: 40px; font-size: 17px; line-height: 1.75;color: #333;">
						<div>报名信息如下：</div>
						<div>会议名称：{{detailData.title}}</div>
						<div>会议日期：{{detailData.start_at}} 至 {{detailData.stop_at}}</div>
						<div>参会人数：{{stateData.applyer_count}} 人</div>
						<div>人员名单：{{stateData.applyer}}</div>
						<div>报名费用：{{orderData.total_fee}} 元</div>
						<div v-if="stateData.result == 1">缴费状态：已缴费</div>
						<div v-if="stateData.result == 0">缴费状态：未缴费</div>
						<div style="display: flex; justify-content: space-between;" v-if="detailData.is_open_pay == 1 && stateData.pay_method == 0">
							<div>缴费方式确认：未确认</div>
							<div style="display: inline-flex; color:#ca3f1e; border-bottom: 1px solid #ca3f1e; cursor: pointer;" @click="toPay">确认缴费方式</div>
						</div>
						<div style="display: flex; flex-direction: column;" v-if="stateData.pay_method !=0">
							<div style="display: flex; justify-content: space-between;" v-if="stateData.result == 0">
								<div style="display: inline-flex;" v-if="stateData.pay_method == 1">缴费方式确认：在线支付</div>
								<div style="display: inline-flex;" v-if="stateData.pay_method == 2">缴费方式确认：对公转账</div>
								<div style="display: inline-flex;" v-if="stateData.pay_method == 3">缴费方式确认：现场缴费</div>
								<div style="display: inline-flex;" v-if="stateData.pay_method == 4">缴费方式确认：扫码支付</div>
								<div style="display: inline-flex; color:#ca3f1e; border-bottom: 1px solid #ca3f1e; cursor: pointer;" @click="toPay">更换缴费方式</div>
							</div>
							<div style="display: flex; flex-direction: column;" v-if="stateData.result == 1">
								<div style="display: inline-flex;" v-if="stateData.pay_method == 1">缴费方式：在线支付</div>
								<div style="display: inline-flex;" v-if="stateData.pay_method == 2">缴费方式：对公转账</div>
								<div style="display: inline-flex;" v-if="stateData.pay_method == 3">缴费方式：现场缴费</div>
								<div style="display: inline-flex;" v-if="stateData.pay_method == 4">缴费方式：扫码支付</div>
							</div>
						</div>
						
					
						
						<div v-if="stateData.result == 0" style="display: flex; flex-direction: column;font-size: 24upx; color: #a0a0a0;margin-top: 24px; line-height: 1.5; text-align: justify;">
							<div v-if="stateData.pay_method == 2">您选择的支付方式为对公转账，支持公对公转账，也支持个人对公转账</div>
							<div v-if="stateData.pay_method == 3">您选择的支付方式为现场缴费，会议报到当天现场缴费，现场缴费支持刷卡、支付宝、微信支付，现场缴费均不享受早鸟价</div>
							<div v-if="detailData.is_open_pay == 0">本次会议采用预报名形式，{{detailData.is_open_pay_message}}，后续缴费功能会自动开放，缴费方式支持微信在线支付、对公转账、现场缴费。</div>
						</div>
					</div>
					<div v-if="detailData.is_open_pay == 1 && stateData.result == 0" style=" width: 640px; display: flex;flex-direction: row; justify-content:  space-between; margin-top: 32px;">
						<div @click="toPay" style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:240px; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
							<div v-if="stateData.pay_method !=2 && stateData.pay_method !=4">立即缴费</div>
							<div v-if="stateData.pay_method == 2">查看转账信息</div>
							<div v-if="stateData.pay_method == 4">查看扫码信息</div>
						</div>
						
						<div @click="toDetail" style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:240px; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
							稍后缴费
						</div>
					</div>
					
					<div v-if="detailData.is_open_study == 1 && stateData.result == 1" style=" width: 640px; display: flex;flex-direction: column; margin-top: 60px;">
						<div @click="toStudyClick" style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:100%; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
							开启特训营的学习
						</div>
					</div>
					
					
					<div style=" width: 640px; font-size: 17px;color: #999; line-height: 1.3; margin-top: 50px; display: flex; flex-direction: column; ;justify-content: center;align-items: center; ">
						<div>报名后，请各位老师扫描下方二维码添加客服微信号，添加好友时请备注：会议名称+姓名+学校，邀请您进本次会议专属群，我们将在群内发布本次会议更多学习信息。</div>
						<div style="margin-top: 30px;" v-if="detailData">
							<img v-if="detailData.kf_qrcode_img_url" :src="detailData.kf_qrcode_img_url" style="width: 320px;" />
							<img v-if="!detailData.kf_qrcode_img_url" src="../../assets/images/qrcode_kf.jpg" style="width: 320px;" />
						</div>
					</div>
		
				</div>
				<div style="display: flex; flex-direction: column; padding:40px 0; box-sizing: border-box;">
					<div style="display: flex;flex-direction: column;">
						<div style="font-size: 18px; color: #666;line-height: 1.4; margin-top: 40px;">
							推荐您关注我们的微信公众号，<br>
							获取教师系统能力特训营及数字教研室的最新活动信息，<br>
							结识更多优秀教师好友。
						</div>
						<div style="display: flex;  justify-content: space-between; margin-top: 40px;">
							<div style="display: flex;align-items: flex-end; ">
								<div style="width: 132px;height: 132px;border: 1px solid #eee; background-color: #eee; margin-right: 20px;">
									<img src="../../assets/images/qrcode_for_cmptt.jpg" style="width: 130px;" />
								</div>
								<div style="font-size: 18px;">
									<i class="el-icon-caret-left"></i>
									扫码关注公众号
								</div>
							</div>
							<div style="display: flex;align-items: flex-end;">
								<img src="../../assets/images/logo.png" style="width: 180px; height: auto;" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		

		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="page_index == 3">
			<!-- 支付 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						支付
					</div>
				</div>
			</div>

			<div style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 35px; margin-bottom: 60px;line-height: 1;">
				<div style="font-size: 18px;color: #333; font-weight: 600;">请选择支付方式</div>
				<div class="pay_tabs">
					<div class="pay_tab" :class="{'actived':pay_tab_index == 1}" @click="changePayTab(1)">
						<img src="../../assets/images/icon_weixin.png" style="width: 50px;margin-right: 10px;" />
						微信支付
						<div v-if="pay_tab_index == 1" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
					<div class="pay_tab" :class="{'actived':pay_tab_index == 2}" @click="changePayTab(2)">
						<img src="../../assets/images/icon_transfer.png" style="width: 50px; margin-right: 10px;" />
						对公转账
						<div v-if="pay_tab_index == 2" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
					<div v-if="detailData.case_type == 1" class="pay_tab" :class="{'actived':pay_tab_index == 3}" @click="changePayTab(3)">
						<img src="../../assets/images/icon_face_to_face.png" style="width: 50px; margin-right: 10px;" />
						现场缴费
						<div v-if="pay_tab_index == 3" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
				</div>


				<div v-if="pay_tab_index == 1" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 65px;">
					<div style="border: 1px solid #eee; background-color: #eee; width: 232px; height: 232px;">
						<div ref="qrcode" style="width: 230px; height: 230px;"></div>
					</div>
					<div style="font-size: 24px; margin-top: 40px;" v-if="orderData && orderData.total_fee">
						总金额：¥{{orderData.total_fee}}
					</div>
					<div style="font-size: 22px;color: #999; margin-top:15px;">
						请使用微信扫码支付
					</div>
				</div>
				<div v-if="pay_tab_index == 2" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 100px; line-height: 1;">
					<div style="font-size: 18px; line-height: 2;" ref="textDiv">
						<span style="font-weight: 600;" v-if="orderData && orderData.total_fee">打款金额：</span>¥ {{orderData.total_fee}}<br>
						<span style="font-weight: 600;">收款账号：</span>0200 0014 0920 0070 931<br>
						<span style="font-weight: 600;">收款公司名称：</span>机械工业出版社有限公司<br>
						<span style="font-weight: 600;">开户行：</span>中国工商银行北京百万庄支行<br>
						<span style="font-weight: 600;" v-if="orderData && orderData.remark">备注信息：</span>{{orderData.remark}}<br>
					</div>
					<div @click="copyText" style="width: 160px; height: 36px; border-radius: 10px;  background-color: #FCA459;color: #fff; font-size: 18px; display: flex;justify-content: center; align-items: center; margin-top: 35px; margin-bottom: 40px; cursor: pointer;">
						一键复制转账信息
					</div>
				</div>
				<div v-if="pay_tab_index == 3" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 100px; line-height: 1;">
					<div style="font-size: 24px; line-height: 2; width: 500px;">
						温馨提示：<br>如果您选择参加线下课程时现场缴费，需要按原始定价<span v-if="detailData.is_early_bird">{{detailData.line_price}}元</span>缴费，不再享受早鸟价，缴费成功后由工作人员手动更新订单状态，订单状态更新后即可解锁线上课程或线上直播回放，请各位老师根据需要选择支付方式。
					</div>
				</div>
				<div v-if="pay_tab_index == 1" @click="finishConfirm" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">已完成支付</div>
				</div>
				<div v-if="pay_tab_index != 1" @click="updateOrderInfo()" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">确认支付方式</div>
				</div>
				<div style=" font-size: 22px; margin-top: 50px;color: #999; display: flex; flex-direction: column; ;justify-content: center;align-items: center; line-height: 1.3; ">
					<div>报名后，请各位老师扫描下方二维码添加客服微信号，添加好友时请备注：会议名称+姓名+学校，邀请您进本次会议专属群，我们将在群内发布本次会议更多学习信息。</div>
					<div style="margin-top: 30px;" v-if="detailData">
						<img v-if="detailData.kf_qrcode_img_url" :src="detailData.kf_qrcode_img_url" style="width: 320px;" />
						<img v-if="!detailData.kf_qrcode_img_url" src="../../assets/images/qrcode_kf.jpg" style="width: 320px;" />
					</div>
				</div>
			</div>
		</div>


	
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import download from 'downloadjs';
	
	let pca = require("../../js/pca-code.json");

	export default {
		data() {
			return {
				dynamicValidateForm: {
					domains: [{
						package_items: '',
						package_items_disabled: false,
						name: '',
						mobile: '',
						sex: '',
						job_type: '',
						job_type_disabled: false,
						school: '',
						college: '',
						title: '',
						duty: '',
						teaching_length: '',
						company: '',
						education: '',
						diet_type: '',
						check_in_date: '',
						check_in_date_other: '',
						stay_type: '',
						hotel: '',
						room_type_before: '',
						room_type: '',
						room_type_other: '',
						room_share_with: '',
						stay_days: '',
						is_visit: '',
						is_join: '',
						is_will: '',
						id_card: '',
						is_cert: '',
						is_cert_disabled: false,
						pca: '',
						address: '',
					}]
				},
				dynamicValidateForm2: {
					domains: [{
						invoice_type: '统一开具',
						invoice_count: '',
						title: '',
						tax_id: '',
						category: '会议费',
						category_other: '',
						bank: '',
						bank_account: '',
						registered_address: '',
						registered_contact: '',
						email: '',
						mobile: '',
						gift_buy_count: '',
						price: '',
						total_fee: '',
					}]
				},
				checked: true, //是否开具发票
				page_index: 0,
				pay_tab_index: 1,
				id: null,
				path: null,
				detailData: null,
				gift_count: 1,
				is_gift_add: false,
				total_fee: 0,
				price: 0,
				alert_show1: false,
				alert_show2: false,
				search: {
					id: null,
					case_type: 2,
					gift_buy_count: 1,
				},
				/*
				orderData: {
					total_fee:'1280',
					out_trade_no:'17110695997V5M5W',
					remark:'',
					qrcode_url:'',
				},*/
				orderData: null,
				stateData: null,
				qrcode: null,
				isLogin: true,
				backtop_id: Date.now().toString(10),
				options: pca,
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			if (!this.isLogin) {
				window.top.location.href = "/login";
				return;
			}
			var option = {
				id: this.$route.query.id || "",
				path: this.$route.query.path || "",
			};
			if (option.id) {
				this.id = option.id;
				if (option.path) {
					this.path = option.path;
				}
				this.search.id = this.id;
				this.getDetail();
			}
		},
		components: {
			qrcode: QRCode,
		},
		methods: {
			toDetail(){
				this.GoToDetail(this.detailData.product_type,this.detailData.id);
			},
			toPay(){
				this.page_index = 3;
				
				if(this.stateData.pay_method!=0){
					this.changePayTab(this.stateData.pay_method);
				}else{
					if(this.detailData.is_online_pay==1){
						this.changePayTab(1);
					}else if(this.detailData.is_scan_pay==1){
						this.changePayTab(4);
					}else{
						this.changePayTab(2);
					}
				}
				
			},
			copyText() {
				var text = '';
				text += '打款金额：¥ ' + this.orderData.total_fee + '\r\n';
				text += '收款账号：0200 0014 0920 0070 931\r\n';
				text += '收款公司名称：机械工业出版社有限公司\r\n';
				text += '开户行：中国工商银行北京百万庄支行\r\n';
				text += '备注信息：' + this.orderData.remark + '\r\n';

				this.$copyText(text).then((res) => {
					//console.log("内容已复制到剪切板")
					this.$alert('内容已复制到剪切板', '提示');
				}).catch((err) => {
					//console.log("抱歉，复制失败")
					this.$alert('抱歉，复制失败', '提示');
				})
			},
			downloadClick() {
				download("https://images.cmptt.com/h5/cmptt/file/t_8h28e4cf4ef6574730a16bc569232e0e/202405301625.docx",
					"第三届无人机职业教育教学资源建设委员会委员申报表.docx");
			},
			changePackageItems(e) {
				this.dynamicValidateForm.domains.forEach((item, index) => {
					item.package_items = e;
					if (index != 0) {
						item.package_items_disabled = true;
					}
				})
				this.price = parseFloat(this.detailData.package_items.filter(i => i.id == e.toString())[0].price);
				this.total_fee = this.price * this.gift_count * 1.00;
				this.bindInvoiceData();
				this.isGiftAdd();
			},
			inputMobileBlurEvent() {
				var mobile = this.dynamicValidateForm2.domains[0].mobile;
				if (!mobile) {
					this.dynamicValidateForm2.domains[0].mobile = this.dynamicValidateForm.domains[0].mobile;
				}
			},
			bindInvoiceData() {
				if (this.total_fee && this.total_fee > 0) {
					this.checked = true;
				} else {
					this.checked = false;
				}

				var type = this.dynamicValidateForm2.domains[0].invoice_type;
				if (type) {
					if (type == "单独开具") {
						this.dynamicValidateForm2.domains[0].invoice_count = this.gift_count;
						this.dynamicValidateForm2.domains[0].total_fee = this.price.toString();
						this.dynamicValidateForm2.domains[0].gift_buy_count = 1;
						this.dynamicValidateForm2.domains[0].price = this.price.toString();
					} else {
						this.dynamicValidateForm2.domains[0].invoice_count = 1;
						this.dynamicValidateForm2.domains[0].total_fee = this.total_fee.toString();
						this.dynamicValidateForm2.domains[0].gift_buy_count = this.gift_count;
						this.dynamicValidateForm2.domains[0].price = this.price.toString();
					}
				}
			},
			isGiftAdd() {
				var next_total_fee = this.price * (this.gift_count + 1) * 1.00;
				if (next_total_fee > 6000.00) {
					this.is_gift_add = false;
				} else {
					this.is_gift_add = true;
				}

			},
			scrollTop() {
				this.backtop_id = Date.now().toString();
				this.$nextTick(() => {
					//window.scrollTop(0,0);
					window.location.hash = this.backtop_id;
				});
			},
			toStudyClick() {
				var url = "/trainingCamp/study";
				var query = {
					id: this.id
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			checkedChangeEvent(e) {
				if (!e) {
					this.checked = true;
					this.$confirm('您确认不需要开具发票么，确认后会务组将不再为您开具发票?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$message({
							type: 'success',
							message: '设置成功!'
						});
						this.checked = false;
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
				}
			},
			jobTypeChangeEvent() {
				//有学生价时，身份变更时，定价更新
				if (this.detailData.student_price) {

					if (!this.dynamicValidateForm.domains[0].job_type) {
						var _job_type = '';
						this.dynamicValidateForm.domains.forEach((item, index) => {
							if (item.job_type) {
								_job_type = item.job_type;
							}
						})
						this.dynamicValidateForm.domains[0].job_type = _job_type;
					}

					this.dynamicValidateForm.domains.forEach((item, index) => {
						item.job_type = this.dynamicValidateForm.domains[0].job_type;
						if (index != 0) {
							item.job_type_disabled = true;
						}
					})

					if (this.dynamicValidateForm.domains[0].job_type == '学生') {
						this.price = parseFloat(this.detailData.student_price);
					} else {
						this.price = parseFloat(this.detailData.price);
					}
					this.total_fee = this.price * this.gift_count * 1.00;
					this.bindInvoiceData();

				}
			},
			isCertChangeEvent() {
				if (this.detailData.cert_fee) {

					if (!this.dynamicValidateForm.domains[0].is_cert) {
						var _is_cert = '';
						this.dynamicValidateForm.domains.forEach((item, index) => {
							if (item.is_cert) {
								_is_cert = item.is_cert;
							}
						})
						this.dynamicValidateForm.domains[0].is_cert = _is_cert;
					}

					this.dynamicValidateForm.domains.forEach((item, index) => {
						item.is_cert = this.dynamicValidateForm.domains[0].is_cert;
						if (index != 0) {
							item.is_cert_disabled = true;
						}
					})


					if (this.dynamicValidateForm.domains[0].is_cert == '是') {
						this.price = parseFloat(this.detailData.price) + parseFloat(this.detailData.cert_fee);
					} else {
						this.price = parseFloat(this.detailData.price);
					}
					this.total_fee = this.price * this.gift_count * 1.00;
					this.bindInvoiceData();
				}
			},
			addDomain() {
				var _package_items = '';
				var _package_items_disabled = false;
				var _job_type = '';
				var _job_type_disabled = false;
				var _school = '';
				var _college = '';
				var _company = '';
				var _is_cert = '';
				var _is_cert_disabled = false;
				if (this.detailData.package_items && this.detailData.package_items.length > 0 && this.dynamicValidateForm
					.domains[0].package_items) {
					_package_items = this.dynamicValidateForm.domains[0].package_items;
					_package_items_disabled = true;
				}
				if (this.detailData.student_price && this.dynamicValidateForm.domains[0].job_type) {
					_job_type = this.dynamicValidateForm.domains[0].job_type;
					_job_type_disabled = true;
				}
				if (this.dynamicValidateForm.domains[0].school) {
					_school = this.dynamicValidateForm.domains[0].school;
				}
				if (this.dynamicValidateForm.domains[0].college) {
					_college = this.dynamicValidateForm.domains[0].college;
				}
				if (this.dynamicValidateForm.domains[0].company) {
					_company = this.dynamicValidateForm.domains[0].company;
				}
				if (this.dynamicValidateForm.domains[0].is_cert) {
					_is_cert = this.dynamicValidateForm.domains[0].is_cert;
					_is_cert_disabled = true;
				}

				this.dynamicValidateForm.domains.push({
					package_items: _package_items,
					package_items_disabled: _package_items_disabled,
					name: '',
					mobile: '',
					sex: '',
					job_type: _job_type,
					job_type_disabled: _job_type_disabled,
					school: _school,
					college: _college,
					title: '',
					duty: '',
					teaching_length: '',
					company: _company,
					education: '',
					diet_type: '',
					check_in_date: '',
					check_in_date_other: '',
					stay_type: '',
					hotel: '',
					room_type_before: '',
					room_type: '',
					room_type_other: '',
					room_share_with: '',
					stay_days: '',
					is_visit: '',
					is_join: '',
					is_will: '',
					id_card: '',
					is_cert: _is_cert,
					is_cert_disabled: _is_cert_disabled,
					pca: '',
					address: '',
					key: Date.now()
				});
				this.gift_count++;
				this.total_fee = this.price * this.gift_count * 1.00;
				this.bindInvoiceData();
				this.isGiftAdd();
			},
			removeDomain(item) {
				var index = this.dynamicValidateForm.domains.indexOf(item)
				if (index !== -1) {
					this.dynamicValidateForm.domains.splice(index, 1)
					this.dynamicValidateForm2.domains.splice(index, 1)
					this.gift_count--;
					this.total_fee = this.price * this.gift_count * 1.00;
					this.bindInvoiceData();
					this.isGiftAdd();
				}
			},
			infoConfirm() {
				var that = this;
				that.$refs['dynamicValidateForm'].validate((valid) => {
					if (valid) {
						if(this.checked){
							//包含开票信息
							that.$refs['dynamicValidateForm2'].validate((_valid) => {
								if (_valid) {
									console.log('可以提交')
									console.log(this.dynamicValidateForm.domains)
							
									console.log('dynamicValidateForm' + JSON.stringify(that.dynamicValidateForm
										.domains))
									console.log('dynamicValidateForm2' + JSON.stringify(that
										.dynamicValidateForm2.domains))
							
									that.search.id = this.id;
									that.search.gift_buy_count = this.gift_count;
									that.search.user_list_json = JSON.stringify(that.dynamicValidateForm
										.domains);
									that.search.invoice_list_json = JSON.stringify(that.dynamicValidateForm2
										.domains);
									that.goToOrder();
								}
							});
							
						}else{ 
							//不包含开票信息
							console.log('可以提交')
							console.log(this.dynamicValidateForm.domains)
														
							console.log('dynamicValidateForm' + JSON.stringify(that.dynamicValidateForm
								.domains))
					
							that.search.id = this.id;
							that.search.gift_buy_count = this.gift_count;
							that.search.user_list_json = JSON.stringify(that.dynamicValidateForm
								.domains);
							that.goToOrder();
						}

					}
				});
			},
			changePayMethod(index) {
				var that = this;
				that.page_index = 2;
				that.detailData.tt_out_trade_no = that.orderData.out_trade_no;
				that.changePayTab(index);
				that.scrollTop();
			},
			goToOrder() {
				var that = this;
				var url = apiUrl.tt_purchase_confirm;
				this.Ajax(url, this.search, (data) => {
					that.orderData = data;
					that.page_index = 2;
					that.stateData = {
						pay_method: that.orderData.pay_method,
						result: that.orderData.result,
						applyer: that.orderData.applyer,
						applyer_count:that.orderData.applyer_count,
					};
					that.scrollTop();
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (formName == 'dynamicValidateForm') {
							this.alert_show1 = true;
						} else if (formName == 'dynamicValidateForm2') {
							this.alert_show2 = true;
						}
					} else {
						console.log('error submit!!');
						if (formName == 'dynamicValidateForm') {
							this.alert_show1 = false;
						} else if (formName == 'dynamicValidateForm2') {
							this.alert_show2 = false;
						}
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			changePayTab(index) {
				var that = this;
				this.pay_tab_index = index;
				that.$nextTick(() => {
					that.updateOrderInfoAjax(index, (data) => {
						console.log(data);
						that.orderData = data;
						if (that.orderData && that.orderData.qrcode_url && index == 1) {
							that.setupQrCode(that.$refs.qrcode, that.orderData.qrcode_url, 230, (
								code_img) => {
									that.qrcode = code_img;
								});
						}
						that.scrollTop();
					})
				});
			},
			updateOrderInfoAjax(index, callback) {
				var that = this;
				var url = apiUrl.tt_order_info_update;
				this.Ajax(url, {
					out_trade_no: that.orderData.out_trade_no,
					product_type: that.orderData.product_type,
					pay_method: index
				}, (data) => {
					if (callback) callback(data);
				});
			},
			updateOrderInfo() {
				this.page_index = 2;
				this.checkOrderState();
			},
			finishConfirm() {
				this.page_index = 2;
				this.checkOrderState();
			},
			checkOrderState() {
				var that = this;
				var url = apiUrl.tt_order_state_check;
				this.Ajax(url, {
					out_trade_no: this.orderData.out_trade_no
				}, (data) => {
					that.stateData = data;
					that.scrollTop();
				});
			},
			getDetail() {
				var that = this;
				var url = apiUrl.tt_camp_detail_get;
				var parms = {
					id: this.id,
					is_sign_up: 1
				};
				if (this.path) {
					parms = {
						id: this.id,
						is_sign_up: 1,
						path: this.path
					};
				}
				this.Ajax(url, parms, (data) => {
					that.detailData = data;
					if (that.detailData.is_package != 1) {
						that.price = parseFloat(that.detailData.price);
					} else {
						/*
						var item = that.detailData.package_items[that.detailData.package_items.length-1];
						that.dynamicValidateForm.domains[0].package_items = item.id;
						that.price = parseFloat(item.price);
						*/
					}

					if (that.detailData.is_reg == 0) {
						that.page_index = 1;
						that.gift_count = 1;
						that.total_fee = that.price * that.gift_count * 1.00;
						that.bindInvoiceData();
						that.isGiftAdd();
					} else if (that.detailData.order_info) {
						that.page_index = 2;

						that.stateData = {
							pay_method: that.detailData.order_info.pay_method,
							result: that.detailData.order_info.result,
							applyer: that.detailData.order_info.applyer,
							applyer_count:that.detailData.order_info.applyer_count,
						};

						that.orderData = {
							total_fee: that.detailData.order_info.total_fee,
							out_trade_no: that.detailData.order_info.out_trade_no,
							product_id: that.detailData.order_info.product_id,
							product_type: that.detailData.order_info.product_type,
							remark: that.detailData.order_info.remark,
							qrcode_url: that.detailData.order_info.qrcode_url,
						};
					}
				});
			},
			//QrCode
			setupQrCode(el, url, width, callback) {
				let code_img = new QRCode(el, {
					width: width,
					height: width, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
				if (callback) callback(code_img);
			},

		},
	};
</script>
<style>
	/*单选按钮*/
	/* .el-radio__input.is-checked + .el-radio__label {
	  color: #606266 !important;
	}

	.el-radio__input.is-checked .el-radio__inner {
	  background: #606266 !important;
	  border-color: #606266 !important;
	}
	.el-radio__input:hover{
		border-color: #DCDFE6 !important;
	} */
</style>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.pay_tabs {
			display: flex;
			margin-top: 20px;

			.pay_tab {
				font-size: 22px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 315px;
				height: 90px;
				background-color: #F1F1F1;
				border-radius: 10px;
				position: relative;
				box-sizing: border-box;
				cursor: pointer;
				margin-right: 30px;

				&.actived {
					background-color: #fff;
					border: 2px solid #ff9000;


				}

				.tag {
					background-color: #ff9000;
					width: 30px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 10px 0 10px 0;
					position: absolute;
					right: -2px;
					bottom: -2px;
					box-sizing: border-box;
				}
			}
		}

		/deep/.sign_up_container {
			/*复选按钮*/
			.el-checkbox__input.is-checked .el-checkbox__inner,
			.el-checkbox__input.is-indeterminate .el-checkbox__inner {
				background-color: #FCA459 !important;
				border-color: #FCA459 !important;
			}

			.el-checkbox__input.is-focus .el-checkbox__inner {
				border-color: #FCA459 !important;
			}

			.el-checkbox__label {
				font-size: 15px;
				color: #333 !important;
			}

			.el-checkbox__input.is-checked+.el-checkbox__label {
				color: #FCA459 !important;
			}

			.el-radio {
				line-height: 2 !important;
			}

			.el-collapse {
				border: none !important;
			}

			.el-collapse-item__wrap {
				border: none !important;
			}

			.el-collapse-item__header {
				padding-left: 156px;
				box-sizing: border-box;
				border: none !important;
			}

			.el-alert {
				padding: 16px;
			}

			.el-alert__title {
				font-size: 15px;
			}

			.el-alert .el-alert__description {
				font-size: 14px;
			}

			.el-form-item__label {
				line-height: 1.4;
				display: inline-flex;
				justify-content: flex-end;
				align-items: center;
				height: 40px;
			}

		}

		/*
		.sign_up_container {
			/deep/ .el-radio__label {
				font-size: 18px !important;
			}

			/deep/ .el-radio__inner {
				border-radius: 4px !important;
				width: 20px !important;
				height: 20px !important;
				background-color: #fff !important;
			}

			/deep/ .el-radio__inner::after {
				width: 12px !important;
				height: 12px !important;
				border-radius: 4px !important;
				background-color: #999 !important;
			}

			/deep/ .el-radio__input.is-checked+.el-radio__label {
				color: rgb(153, 153, 153) !important;
			}

			/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
				color: #666 !important;
			}

			/deep/ .el-checkbox__label {
				font-size: 14px;
			}

			/deep/ .el-checkbox__inner {
				border-radius: 4px !important;
				width: 20px !important;
				height: 20px !important;
				background-color: #fff !important;
			}

			/deep/ .el-checkbox__inner::after {
				width: 12px !important;
				height: 12px !important;
				border-radius: 4px !important;
				background-color: #999 !important;
				box-sizing: content-box;
			}
		}
		*/
	}
</style>