<template>
	<div class="page-main">
		<div style="width: 1200px; display: flex; flex-direction: column;">
			
			<!-- 直播回放 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 40px 0 20px 0;">
					<div class="" style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;">
						<div style="font-size: 28px; color: #333;">直播回放</div>
					</div>
				</div>
			</div>
			
			<div style="display: flex;flex-direction: column;" v-if="detailData">
				<div style="font-size: 24px;">
					{{detailData.title}}
				</div>
				<div style="font-size: 18px;">
					<p v-if="detailData.teachers" >
						主讲人：{{getAnchor()}}
					</p>
				</div>
				<div style="width:100%;margin:15px auto; display: flex;justify-content: center; box-sizing: border-box;">
				    <iframe :src="detailData.webcast_url"   width="1280px"  height="724px"  frameborder="0"  allowfullscreen="true" align="center"></iframe>
				</div>
			</div>
			
			<div style="display: flex;flex-direction: column; justify-content: center;align-items: center; width: 100%; height: 480px; " v-if="!detailData">
				<img src="../../assets/images/empty.png" />
				<p style="font-size: 24px;">抱歉，您暂无权限访问本页内容</p>
			</div>

		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				id: null,
				tt_camp_id:null,
				detailData:{},
			};
		},
		created() {
			document.title = "名师团 - 机工教师特训营";

			var option = {
				id: this.$route.query.id || "",
				tt_camp_id: this.$route.query.tt_camp_id || "",
			};
			if (option.id) {
				this.id = option.id;
				if(option.tt_camp_id){
					this.tt_camp_id = option.tt_camp_id;
				}
				this.getDetail();
			}
		},
		methods: {
			getDetail() {
				var that = this;
				var url = apiUrl.tt_webcast_detail_get;
				this.Ajax(url, {
					id: that.id,
					tt_camp_id: that.tt_camp_id,
				}, (data) => {
					that.detailData = data;
					document.title = data.title + " - 直播 - 机工教师特训营";
				},(data,code)=>{
					that.detailData = null;
					console.log('');
				});
			},
			getAnchor(){
				if(this.detailData.teachers){
					var arr =new Array();
					this.detailData.teachers.forEach((item,index)=>{
						arr.push(item.name);
					})
					return arr.join('、');
				}else{
					return "";
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
	}
</style>