<template>
	<div id="app">
		<transition
			enter-active-class="animate__animated animate__fadeIn"
			leave-active-class="animate__animated animate__fadeOut"
			mode="out-in"
			duration="500"
			appear>
			<router-view />
		</transition>
	</div>
</template>

<script>
	import "animate.css";

	export default {
		beforeCreate() {
			document.title = "机工教师特训营";
		},

		methods: {},
	};
</script>
<style lang="scss">
	* {
		margin: 0;
		padding: 0;
		border: 0;
		outline: none;
	}

	#app {
		font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		background: #fff;

	}

	.appPC {
		min-width: 1157px;
		overflow: auto;
	}

	.van-image__img {
		object-fit: cover;
		overflow: auto;
	}

	.el-image {
		width: 100%;
		height: 100%;

		img {
			object-fit: cover;
		}
	}
</style>