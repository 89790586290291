import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'babel-polyfill'
import comm from './js/comm.js'
//import common from './js/common.js'
import rem from './js/rem.js'
import VueResource from "vue-resource"
//import VideoPlayer from 'vue-video-player'
//import 'vue-video-player/src/custom-theme.css'
//import 'video.js/dist/video-js.css'
import dragVerify from 'vue-drag-verify2'
import VueLazyload from 'vue-lazyload'
import globalVariable from './js/globalVariable';
Vue.config.productionTip = false

// import VueWechatTitle from 'vue-wechat-title'
// Vue.use(VueWechatTitle)

// 引入elemeui
import ElementUI from 'element-ui';
import infiniteScroll from 'vue-infinite-scroll'

import 'element-ui/lib/theme-chalk/index.css';
Vue.use(infiniteScroll)
Vue.use(ElementUI);
Vue.use(VueResource);
Vue.use(rem);
//Vue.use(VideoPlayer);
Vue.use(dragVerify);//滑块安全验证
Vue.use(VueLazyload)//图片懒加载



// 引入iviewui
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

//瀑布流插件
// import {VueMasonryPlugin} from 'vue-masonry';
// Vue.use(VueMasonryPlugin)

//一键复制插件
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

//设备检测
// import DeviceDetector from 'vue-device-detector'
// Vue.use(DeviceDetector)

//图表
import echarts from 'echarts'
Vue.prototype.$echarts = echarts 

//字体图标
import './assets/fonts/iconfont.css'

//公共AJAX方法
Vue.prototype.Ajax = comm.commAjax;
Vue.prototype.AjaxTeacher = comm.commAjaxTeacher;
Vue.prototype.commonVariable = globalVariable;

//全局变量（目前无效）
//Vue.prototype.common = common;

router.afterEach((to, from, next) => {
	//百度统计代码
	setTimeout(() => {
	    let _hmt = _hmt || [];
	    (() => {
	      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
	      let hm = document.createElement('script');
	      hm.src = 'https://hm.baidu.com/hm.js?a6b0dd91923e2a9660ad38322332bc63';
	      hm.id = 'baidu_tj';
	      let s = document.getElementsByTagName('script')[0];
	      s.parentNode.insertBefore(hm, s);
	    })();
	}, 50);
});

Vue.prototype.GoToDetail = function (product_type, id) {
	if (!id || id =='') return;
	var url = null;
	switch (product_type) {
		case 0: //不做任何处理
			return;
			break;
		case 4: //课程
			url = '/course/detail';
			break;
		case 26: //数字教研室
			url = "/teachRoom/detail";
			break;
		case 27: //训练营
			url = "/trainingCamp/detail";
			break;
		case 28: //直播
			url = ""
			window.open(id,"_blank");
			break;
		case 29: //线下课程
			url = "";
			window.open(id,"_blank");
			break;
		case 30: //讲师
			url = "/teacher/detail"
			break;
		case 31: //会议
			url = "/conference/detail"
			break;

		default:
			return;
	}
	if (url) {
		var toPath = url.toLowerCase();
		localStorage.setItem("toPagePath", toPath);
		var query = { id: id };
		let routeUrl = this.$router.resolve({
			path: url,
			query: query,
		});
		window.open(routeUrl.href, '_blank');
		// this.$router.push({
		// 	path: url,
		// 	query: {
		// 		id: id,
		// 	},
		// });
	}
}



Vue.prototype.GoToAllBook = function (query) {
	// this.$router.push({
	// 	path: "Filtrate",
	// 	query: query,
	// });

	let routeUrl = this.$router.resolve({
		path: "Filtrate",
		query: query,
	});
	window.open(routeUrl.href, '_blank');
}

Vue.prototype.GoToChangeTab = function (product_type) {
	if (!product_type) product_type = 0;
	var path = "";
	switch(product_type)
	{
		case 0: //不做任何处理
			path = "/homepage";
			break;
		case 4: //课程
			path = '/course/list';
			break;
		case 26: //数字教研室
			path = "/teachRoom/list";
			break;
		case 27: //训练营
			path = "/trainingCamp/list";
			break;
		case 28: //直播
		case 29: //线下课程
			path = "";
			break;
		case 30: //讲师
			path = "/teacher/list"
			break;
		
		default:
			return;
	}
	var toPath = path.toLowerCase();
	localStorage.setItem("toPagePath", toPath);
	this.$router.push({
		path: path,
	});	
}

// Vue.use (VueAxios,axios)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')