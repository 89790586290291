<template>
	<div class="page-main ">
		<div class="conference_list_container">
			<!-- 机工会议 -->
			<div style=" width: 100%; display: inline-flex; flex-direction: column; z-index: 3; ">
				<div style="display: flex; margin: 54px 0 70px 0;">
					<div class="" style="margin-right: 17px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);"></div>
					<div style="display: flex;flex-direction: column; line-height: 1;">
						<div style="font-size: 50px; color: #333;">机工会议</div>
						<div style="font-size: 30px; color: #999; margin-top: 15px;">凝聚智慧、深化合作、促进交流、互学互鉴</div>
					</div>
				</div>
			</div>


			<div style="width: 100%;display: flex; flex-direction: row;justify-content:space-between; ">
				<div style="width: 332px; height: auto; display: inline-flex;flex-direction: column;">
					<div style="width: 100%; height: 54px; border-radius: 12px; font-size: 23px;">
						<el-input v-model="input1" placeholder="搜索" confirm-type="search" @keyup.enter.native="handleEnter">
							<i slot="prefix" class="el-input__icon el-icon-search" style="font-size: 22px !important;"></i>
						</el-input>
					</div>
					<div class="" style="width: 100%;display: flex; flex-direction: column;line-height: 1; ">
						<div style="width: 100%;display: flex;font-size: 23px; color: #333; align-items: center;margin: 25px 0 15px 0;">
							<img src="../../assets/images/icon_c_type_list.png" style=" width: 25px; height: 24px; margin-right: 9px;" />
							类型
						</div>
						<div v-if="categoryData"
							style=" width: 100%; display: inline-flex; flex-wrap: wrap; background-color: #fff;padding: 23px 0px 23px 20px; box-sizing: border-box;border-radius: 12px;">

							<el-checkbox-group v-model="checkType1List">
								<el-checkbox @change="checkboxChange(item)"  v-for="(item, index) in findCategoryList(4)" :label="item.title" :disabled="item.disabled"></el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
					<div class="" style="width: 100%;display: flex; flex-direction: column; line-height: 1;">
						<div style="width: 100%;display: flex;font-size: 23px; color: #333;align-items: center;margin: 25px 0 15px 0; ">
							<img src="../../assets/images/icon_c_area_list.png" style=" width: 25px; height: 24px; margin-right: 9px;" />
							领域
						</div>
						<div v-if="categoryData" style=" width: 100%; display: inline-flex; flex-wrap: wrap; background-color: #fff; padding: 23px 0px 23px 20px; box-sizing: border-box;border-radius: 12px;">
							<el-checkbox-group v-model="checkType2List">
								<el-checkbox @change="checkboxChange(item)"  v-for="(item, index) in findCategoryList(3)" :label="item.title" :disabled="item.disabled"></el-checkbox>
							</el-checkbox-group>
						</div>
					</div>
				</div>

				<div style="width: 846px;">
					<div class="order_by_tabs">
						<div class="order_by_tab" :class="{'actived':order_change_index==1}" @click="changeOrderBy(1)">
							按会议开始时间
							<i class="el-icon-caret-bottom" style="font-size: 20px !important;" v-if="this.search.order_by != 'start_at:asc'"></i>
							<i class="el-icon-caret-top" style="font-size: 20px !important;" v-if="this.search.order_by == 'start_at:asc'"></i>
						</div>
						<div class="order_by_tab" :class="{'actived':order_change_index==2}" @click="changeOrderBy(2)">
							按信息发布时间
							<i class="el-icon-caret-bottom" style="font-size: 20px !important;" v-if="this.search.order_by != 'created_at:asc'"></i>
							<i class="el-icon-caret-top" style="font-size: 20px !important;" v-if="this.search.order_by == 'created_at:asc'"></i>
						</div>
					</div>
					
					<div class="" style="width: 100%;border-radius: 12px;background-color: #fff;display: flex;flex-direction: column;margin-top: 20px; padding: 30px;box-sizing: border-box; margin-bottom: 45px;">
						<div style="display: flex; width: 100%; margin-bottom: 45px; cursor: pointer;" v-for="(item,index) in productData.list" @click="toDetail(item)">
							<div style="width: 232px; height: 163px; background-color: #999; margin-right: 24px; cursor: pointer; position: relative; " >
								<!-- <img v-if="item.img_url_compressed" :src="item.img_url_compressed" style="width: 232px; height: 163px;"/>
								<img v-if="!item.img_url_compressed" src="../../assets/images/conference.jpg" style="width: 232px; height: 163px;"/> -->
								
								<img src="../../assets/images/conference.jpg" style="width: 232px; height: 163px;"/>
								<div style="position: absolute; top: 0; right: 15px; display: inline-flex; flex-direction: column; justify-content: space-around; align-items: center; background: linear-gradient( 92deg, #CB3B2D 0%, #FF8103 100%);font-size: 12px; color: #fff; padding: 2px 8px; box-sizing: border-box;">
									<div>{{item.start_at.split('.')[0]}}</div>
									<div style="width: 100%; height: 1px; background-color: #fff; "></div>
									<div>{{item.start_at.split('.')[1]}}.{{item.start_at.split('.')[2]}}</div>
								</div>
							</div>
							<div style="flex: 1; display: flex;flex-direction: column; justify-content: space-between;">
								<div style="display: flex; flex-direction: column;">
									<div style="color: #333;font-size: 18px; line-height: 2; font-weight: 600;cursor: pointer;">{{item.title}}</div>
									<div style="color: #999;font-size: 12px; line-height: 1;">发布时间：{{item.created_at}}</div>
									<div style="color: #666;font-size: 14px; line-height: 1.5;margin-top: 10px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;word-break: break-all;line-height: normal" v-html="item.content"></div>
									<div style="display: flex;margin-top: 10px;" v-if="item.keywords">
										<div v-for="(tem,dex) in item.keywords.split(';')" style="font-size: 14px;color: #FFA350; padding: 4px 8px; border: 1px solid #FFA350; line-height: 1; border-radius: 4px; margin-right: 10px;">
											{{tem}}
										</div>
									</div>
								</div>
								<div style="display: flex;font-size: 14px; color: #333; font-weight: 400; line-height: 1; justify-content: space-between;">
									<div>
										<i class="el-icon-location-outline"></i>
										会议地点：{{item.province}}·{{item.city}}
									</div>
									<div>
										<i class="el-icon-time"></i>
										会议时间：{{item.start_at}} – {{item.stop_at.split('.')[2]}}
									</div>
									<div style=" width: 103px; cursor: pointer; color: #FFA350;">
										<div v-if="item.file_name" @click.stop="downloadClick(item)">
											<span style="border-bottom: 1px solid #FFA350; padding-bottom: 2px;">下载会议通知</span>
											<i class="el-icon-download" style="margin-left: 5px;"></i>
										</div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import download from 'downloadjs';


	export default {
		data() {
			return {
				input1: null,
				tab_change_index: 1,
				order_change_index: null,
				checkType1List: [],
				checkType2List: [],
				categoryData: null,
				type1All: false,
				type2All: false,
				search: {
					page_index: 1,
					page_size: 10,
					type1: '',
					type2: '',
					value: '',
					order_by: ''
				},
				productData: {
					total: 0,
					count: 0,
					list: []
				}
			};
		},
		created() {
			document.title = "机工会议 - 机工教师特训营";
			this.getCategoryList();
			this.productDataGet(1);
		},
		methods: {
			alert(){
				this.$alert('111');
			},
			handleEnter() {
				console.log('搜索')
				this.search.value = this.input1;
				this.productDataGet(1);
				this.$forceUpdate();
				this.input1 = "";
			},
			downloadClick(item){
				download(item.file_path,item.file_name);
			},
			checkboxChange(e){
				console.log(JSON.stringify(e))
				var arr1=new Array();
				var arr2=new Array();
				this.categoryData.map(val =>{
					if(val.title == e.title){
						val.checked = !e.checked;
					}
				});
				
				this.categoryData.map(val =>{
					if(val.checked || (val.title == e.title && e.checked)){
						if(val.type == 4){
							arr1.push(val.id);
						}else if(val.type == 3){
							arr2.push(val.id);
						}
					}
				});
				
				console.log('arr1 '+arr1.join())
				console.log('arr2 '+arr2.join())
				this.search.type1 = arr1.join();
				this.search.type2 = arr2.join();
				this.productDataGet(1);
				this.$forceUpdate();
			},
			toDetail(item) {
				this.GoToDetail(item.product_type, item.id);
			},
			checkAllClick(index){
				if(index == 1){
					var arr1=new Array();
					this.categoryData.filter(el => el.type == 4).map(val =>{
						if(!val.disabled){
							if(!this.type1All){
								val.checked = true;
								arr1.push(val.id);
							}else{
								val.checked = false;
							}
						}
					})
					this.type1All = !this.type1All;
					this.search.type1 = arr1;
					this.productDataGet(1);
				}else if(index == 2){
					var arr2=new Array();
					this.categoryData.filter(el => el.type == 3).map(val =>{
						if(!val.disabled){
							if(!this.type2All){
								val.checked = true;
								arr2.push(val.id);
							}else{
								val.checked = false;
							}
						}
					})
					this.type2All = !this.type2All;
					this.search.type2 = arr2;
					this.productDataGet(1);
				}
				this.$forceUpdate();
			},
			changeOrderBy(index){
				if(index == 1){
					this.order_change_index = 1;
					this.search.order_by = this.search.order_by != "start_at:desc" ? "start_at:desc" : "start_at:asc";
				}else{
					this.order_change_index = 2;
					this.search.order_by = this.search.order_by != "created_at:desc" ? "created_at:desc" : "created_at:asc";
				}
				this.productDataGet(1);
				this.$forceUpdate();
			},
			changTabClick(index) {
				this.tab_change_index = index;
			},
			findCategoryList(type) {
				var list = this.categoryData.filter(el => el.type == type);
				/*
				list.unshift({
					id: 0,
					title: '全部',
					type: 0,
					product_count: 1000
				})
				*/
				return list;
			},
			getCategoryList() {
				var that = this;
				var url = apiUrl.tt_category_list_get;
				this.Ajax(url, {
					product_type: 31
				}, (data) => {
					that.categoryData = data;
					that.categoryData.map(val =>{
						val.checked = false;
						val.disabled = val.product_count == 0 ? true:false;
					})
					console.log(JSON.stringify(that.categoryData))
				});
			},
			productDataGet(index) {
				var that = this;
				if (index <= 1) {
					that.search.page_index = 1;
					that.totalPage = 1;
				}
				if (that.search.page_index > that.totalPage) {
					// uni.showToast({
					// 	title: "没有更多内容了",
					// 	icon: 'none',
					// 	duration: 2000
					// });
					return;
				}
				that.search.page_index = index;
				var url = apiUrl.tt_conference_list_get;
				this.Ajax(url, this.search, (data) => {
					that.productData.list = data.list;
					that.productData.total = data.total;
					that.productData.count = data.count;
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.productDataGet();
			}
		},
	};
</script>
<style>

</style>


<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;


	}

	/deep/.conference_list_container {

		display: inline-flex;
		flex-direction: column;
		width: 1200px;

		.el-input__inner {
			border: none !important;
			background-color: #e3e3e3 !important;
			color: #666 !important;
			border-radius: 10px !important;
			font-size: 23px !important;
			height: 54px !important;
			line-height: 54px !important;
			padding: 0 15px 0 35px !important;
		}

		input[class="el-input__inner"]::placeholder {
			color: #666 !important;
			font-size: 23px !important;
		}

		.el-checkbox {
			margin-right: 0 !important;
			min-width: 103px;
		}

		.el-checkbox__label {
			display: inline-block;
			padding-left: 5px !important;
			line-height: 36px !important;
			font-size: 16px !important;
		}

		.order_by_tabs {
			width: 100%;
			height: 54px;
			border-radius: 12px;
			font-size: 17px;
			background-color: #fff;
			display: flex;
			align-items: center;
		
			
			
			.order_by_tab{
				margin-left: 35px;
				cursor: pointer;
				color: #808080;
				
				&:hover,
				&.actived{
					color: #C93A2E;
				}
			}
		}
	}
</style>