<template>
	<div class="page-main" style="position: relative;" v-if="detailData">
		

		<!-- 手机端扫码访问 -->
		<div  style="width: 150px; height: 40px; position: fixed; bottom: 40px; right: 20px; z-index: 999;">
			<el-popover
			  placement="bottom"
			  width="200"
			  trigger="click"
			>
			
			<div style="display: flex;  flex-direction: column;justify-content: center; align-items: center;width: 100%; height: 200px;">
				<div ref="qrcode" style="width: 120px; height: 120px;"></div>
				<div style="font-size: 13px; width: 200; margin-top: 15px;display: flex;flex-direction: column; align-items: center;">
					扫码访问手机版详情页
				</div>
			</div>
		
			 <el-button slot="reference">手机版二维码</el-button>
			</el-popover>
		</div>
		
		
		<div style=" position: sticky; left: 0; top: 0; width:100%;height: 70px;background:linear-gradient(to right,#F1F9FB,#F6FAFD); display: flex; justify-content: center;border-bottom: 1px solid #eaeaea;">
			<div style="width: 1200px;display: flex; justify-content: space-between; position: relative;">
				
				<div style="display: inline-flex;font-size: 20px;align-items: center; ">
					<div style="color: #c93a2e; font-weight: 600;">CMPTT</div>
					<div style="color: #999;margin: 0 10px;">|</div>
					<div style="color: #999;">{{detailData.title}}</div>
				</div>
				
				<div style="display: inline-flex;align-items: center;">
					
					<div style="color: #333; margin-right: 15px; display: flex; " v-if="detailData.state != 2 && ( !detailData.status || (detailData.status && detailData.status.status_code == 2) || id!='t_8c168c7c2ab741ec87ae4b324793f394')">
						<div style="display: inline-flex; align-items: center;font-weight: 400;" v-if="detailData.is_early_bird==1">
							<div style="color: #c93a2e; font-size: 16px; width: 16px; line-height: 1.1;  " >早鸟价</div>
							<div style="display: flex;flex-direction: column; align-items: center; margin-left: 10px; line-height: 1;">
								<div style="color: #c93a2e; font-size: 30px;" v-if="detailData.price.indexOf('~') ==-1">¥{{detailData.price}}</div>
								<div style="color: #c93a2e; font-size: 26px;" v-if="detailData.price.indexOf('~') !=-1">¥{{detailData.price}}</div>
								<div style="color: #999; font-size: 17px; text-decoration: line-through; margin-top: 2px;">原价：¥{{detailData.line_price}}</div>
							</div>
						</div>
						<div v-if="detailData.is_early_bird==0">
							<div v-if="!detailData.student_price && detailData.price!=0" style="color: #c93a2e; font-size: 30px;">¥{{detailData.price}}</div>
							<div v-if="detailData.student_price" style="display: flex;flex-direction: column; align-items: center; margin-left: 10px; line-height: 1;">
								<div style="color: #c93a2e; font-size: 30px;">¥{{detailData.price}}</div>
								<div style="color: #999; font-size: 17px; margin-top: 2px;">学生价：¥{{detailData.student_price}}</div>
							</div>
						</div>
					</div>
					
					<div style="border: 1px solid #c93a2e;color: #c93a2e; border-radius: 30px; width: 130px; height: 30px; display: flex;justify-content: center; align-items: center; cursor: pointer;margin-right:30px; ">
						<el-popover
						  placement="bottom"
						  width="240"
						  trigger="click"
						  >
						 <div style="z-index: 999; display: flex; flex-direction: column;justify-content: center; align-items: center;">
							<div style="display: flex;  flex-direction: column;justify-content: center; align-items: center;width: 180px;">
								<img src="../../assets/images/qrcode_for_cmptt.jpg"  style="width: 180px; height: auto; " />
								<div style="font-size: 13px; width: 180px; margin-top: 5px;display: flex;flex-direction: column; align-items: center;">
									<div>扫码关注机工教师微信公众号</div>
									<div>公号对话框留言即可在线咨询</div>
								</div>
							</div>
							<div style=" width: 180px; display: flex;  flex-direction: column;justify-content:  flex-start; margin-top: 8px; line-height: 1.75;  font-size: 13px;">								
								<div style="display: flex;">
									<div>电话咨询：</div>
									<div style="display: flex;flex-direction: column;">
										<div>010-88373033</div>
										<div>010-88378723</div>
									</div>
								</div>
								<div style="margin-top: 5px;">
									邮件咨询：service@cmptt.com
								</div>
								<div style="margin-top: 5px;">人工在线时间：</div>
								<div>工作日，9:00~17:30</div>
							</div>
						 </div>
						 <div style=" display: flex; justify-content: center; align-items: center;" slot="reference">
							立即咨询
						 </div>
						</el-popover>
					</div>
					
					
					
					<div v-if="detailData.res && detailData.state != 2" @click="downloadClick(detailData.res)" style="border: 1px solid #c93a2e;color: #c93a2e; border-radius: 30px; width: 130px; height: 30px; display: flex;justify-content: center; align-items: center;cursor: pointer;margin-right:30px;">下载会议通知</div>
					
					<!-- 工业互联网 -->
				
					<div v-if="id == 't_8n29da89c6205b478d9987f083f5594f' || id == 't_8i4d1cf92190324a0a9ab655ced72f9f' || id == 't_8lfcccee3702384f5f9a7336bcf87281'"  style="background: #ccc; color: #fff; border-radius: 30px;width: 130px; height: 30px; display: flex;justify-content: center; align-items: center;">暂停报名</div>	
					
					<div v-if="id != 't_8n29da89c6205b478d9987f083f5594f' && id != 't_8i4d1cf92190324a0a9ab655ced72f9f' && id != 't_8lfcccee3702384f5f9a7336bcf87281'"> 

					<!-- <div> -->
						<div v-if="(!detailData.status || (detailData.status && detailData.status.status_code == 2)) && detailData.state!=2">
							<div @click="signUpClick" v-if="detailData.is_reg == 0" style="background: linear-gradient(to right,#c93a2e,#ff9000); color: #fff; border-radius: 30px;width: 130px; height: 30px; display: flex;justify-content: center; align-items: center;cursor: pointer; position: relative;">
								<span v-if="detailData.is_open_pay == 0">预报名</span>
								<div v-if="detailData.is_open_pay == 0 && detailData.is_open_pay_message" style="  width: 200px;position: absolute; bottom: -20px; font-size: 12px; color: #666; display: flex; justify-content: center;">{{detailData.is_open_pay_message}}</div>
								<span v-if="detailData.is_open_pay == 1">立即报名</span>
							</div>
							<div @click="signUpClick" v-if="detailData.is_reg == 1" style="background: linear-gradient(to right,#c93a2e,#ff9000); color: #fff; border-radius: 30px;width: 130px; height: 30px; display: flex;justify-content: center; align-items: center;cursor: pointer;">
								<span v-if="detailData.is_open_pay == 0">预报名成功</span>
								<span v-if="detailData.is_open_pay == 1">报名成功</span>
							</div>
						</div>
						<div v-if="detailData.status && detailData.status.status_code == 1" @click="studyClick" style="background: linear-gradient(to right,#c93a2e,#ff9000); color: #fff; border-radius: 30px;width: 130px; height: 30px; display: flex;justify-content: center; align-items: center;cursor: pointer;">立即学习</div>
						<div v-if="detailData.state == 2 && ((detailData.status && detailData.status.status_code != 1) ||  !detailData.status || out_trade_no)" style="background: #ccc; color: #fff; border-radius: 30px;width: 130px; height: 30px; display: flex;justify-content: center; align-items: center;position: relative;">
							<span>已经结束</span>
							<!-- <span v-if="id=='t_8jaa9f66f964fe41b69886ef9cebbf3f'" style="width: 200px;position: absolute; bottom: -20px; font-size: 12px; color: #666; display: flex; justify-content: center;">第1期报名已结束，第2期请期待</span> -->
						</div>
					</div>
					
				</div>
				
			</div>
				
		</div>
		
		<!-- 手机端扫码访问 -->
		<!-- <div  style="width: 120px; height: 40px; position: fixed; bottom: 20px; right: 20px;">
			<el-popover
			  placement="left"
			  width="240"
			  trigger="click"
			>
			 <div style="z-index: 999; display: flex; flex-direction: column;justify-content: center; align-items: center;">
				<div style="display: flex;  flex-direction: column;justify-content: center; align-items: center;width: 180px;">
					<div ref="qrcode" style="width: 230px; height: 230px;"></div>
					<div style="font-size: 13px; width: 180px; margin-top: 5px;display: flex;flex-direction: column; align-items: center;">
						扫码访问手机版详情页
					</div>
				</div>
			 </div>
			 <div style=" display: flex; justify-content: center; align-items: center;" slot="reference">
				访问手机版详情
			 </div>
			</el-popover>
		</div> -->
		
		<div style="display: flex;flex-direction: column; width: 100%;" v-html="detailData.description"></div>
		
		
		
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import download from 'downloadjs';
	import QRCode from "qrcodejs2";


	export default {
		data() {
			return {
				id:null,
				path:null,
				detailData:null,
				isLogin: false,
			};
		},
		created() {
			document.title = "教师特训营 - 机工教师特训营";
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			console.log(this.isLogin);
			
			var option = {
				id: this.$route.query.id || "",
				path: this.$route.query.path || "",
			};
			if (option.id) {
				this.id = option.id;
				if(option.path){
					this.path = option.path;
				}
				this.getDetail();
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				if(vm.isMobilePhone()){
					if(vm.$route.query.path){
						window.location.href ='https://m.cmptt.com/pages/camp/campDetails?id='  +vm.$route.query.id+ '&path='+vm.$route.query.path;
					}else{
						window.location.href ='https://m.cmptt.com/pages/camp/campDetails?id='  +vm.$route.query.id
					}
				}
			});
		},
		components: {
			qrcode: QRCode,
		},
		methods: {
			isMobilePhone(){
				const ua = navigator.userAgent.toLowerCase();
				const t1 = /android|webos|iphone|ipod|blackberry|iemobile|opera mini|opera mobile|applewebkit.*mobile|mobile/i.test(ua);
				return t1;
			},
			downloadClick(item){
				download(item.file_path,item.file_name);
			},
			getDetail() {
				var that = this;
				var url = apiUrl.tt_camp_detail_get;
				var parms = {id:this.id};
				if(this.path){
					parms = {id:this.id , path:this.path};
				}
				this.Ajax(url, parms, (data) => {
					that.detailData = data;
					document.title = data.title + " - 教师特训营 - 机工教师特训营";
					
					that.$nextTick(()=>{
						that.setupQrCode(that.$refs.qrcode, 'https://m.cmptt.com/pages/camp/campDetails?id='+that.id, 120, (code_img) => {
							that.qrcode = code_img;
						});
					})
					
				});
			},
			signUpClick(){
				if(this.id == 't_8qa585b25d743d4df3add040fc39317f'){
					window.open('https://meeting.cmes.org/?sid=1185&mid=296&v=100', "_blank");
				}else if(this.id == 't_8u23b69ca04989462a942cac80ffbfef'){
					this.$alert('会议正在进行中，线上内容暂未开放，敬请期待······', '温馨提示', {
					          confirmButtonText: '确定'
					  });
				}else if(!this.isLogin){
					window.top.location.href = "/Login";
					return;
				}else{
					var url = "/trainingCamp/signup";
					var query = { id: this.id };
					if(this.path){
						query = { id: this.id , path: this.path};
					}
					let routeUrl = this.$router.resolve({
						path: url,
						query: query,
					});
					window.open(routeUrl.href, "_blank");
				}
			},
			studyClick(){
				if(!this.isLogin){
					window.top.location.href = "/Login";
					return;
				}else{
					if(this.detailData.is_open_study!=1){
						this.$alert('您已报名成功，线上内容暂未开放，敬请期待······', '温馨提示', {
						          confirmButtonText: '确定'
						  });
					}else{
						var url = "/trainingCamp/study";
						var query = { id: this.id };
						let routeUrl = this.$router.resolve({
							path: url,
							query: query,
						});
						window.open(routeUrl.href, "_blank");
					}
				}
			},
			//QrCode
			setupQrCode(el, url, width, callback) {
				let code_img = new QRCode(el, {
					width: width,
					height: width, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
				if (callback) callback(code_img);
			},
		},
	};
</script>


<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		
		
	}
</style>