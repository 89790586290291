<template>
	<div class="">
		<div class="teach_design-items" v-if="!pptShow">
			<div class="teach_design-item" :class="{'actived': pptSelected && pptSelected.id == item.id}" v-for="(item,index) of fileListData.list" @click="pptShowClick(item)">
				<i class="iconfont icon-ppt" style="font-size: 70px;"></i>
				<div class="teach_design-title" style="">{{item.title}}</div>
				<div class="triangle_white"></div>
				<div class="triangle_black"></div>
			</div>
		</div>
		<div style="display: flex;flex-direction: column; position: relative;" v-if="pptShow">
			<div style="position: absolute; right: 0; display: flex; align-items: center; cursor: pointer; " @click="pptHide">
				<i class="iconfont icon-back" style="margin-right: 5px; margin-bottom: -3px;font-size: 32px;"></i>
				<div style="font-size: 22px;">返回</div>
			</div>
			
			<div class="" style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px;">
				PPT展示区
			</div>
		
			<div class="" style="width: 100%; margin: 25px 0; display: flex;flex-direction: column;">
				<div class="" style=" width: 100%; height: 449px; border: 15px solid #fff; background-color: #fff; position: relative; ">
		
					<div style="display: inline-block;z-index: 2;">
						<img id="img" style="width: 100%; height: auto; " />
					</div>
				</div>
				<div class="" style="width: 100%; height: 50px; background-color: #333;display: flex; justify-content: center; align-items: center;">
						<i @click="lastPPT" class="iconfont icon-bg-left" style="margin-left: 15px;  margin-right: 20px; ;font-size: 24px;color: #fff; cursor:  pointer; "></i>
						<div style="font-size:20px ; color: #fff;">{{listData.current.index}} / {{listData.count}}</div>
						<i @click="nextPPT" class="iconfont icon-bg-right" style="margin-left: 20px;font-size: 24px;color: #fff;cursor:  pointer; "></i>
				</div>
			</div>
		
			<div class="" style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px; margin-bottom: 25px;">
				PPT文稿区
			</div>
		
			<div class="" style="font-size: 20px;color: #666; text-align: justify; line-height: 1.75;">
				<div class="" v-if="listData.current.ppt_text" v-html="listData.current.ppt_text"></div>
				<div class="" v-if="!listData.current.ppt_text">暂无</div>
			</div>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import watermark from "../../js/watermark.js";

	export default {
		props:{
			myid:String
		},
		data() {
			return {
				fileSearch: {
					id: this.myid,
					page_index: 1,
					page_size: 3000,
				},
				fileListData: {
					total: 0,
					count: 0,
					current: {},
					list: [],
				},
				search: {
					design_id: '',
					page_index: 1,
					page_size: 3000,
				},
				listData: {
					total: 0,
					count: 0,
					current: {},
					list: [],
				},
				chapterList: [],
				show: false,
				selected: null,
				pptShow:false,
				pptSelected:null
			};
		},
		created() {
			//document.title = "教学设计 - 数字教研室 - 机工教师特训营";
			this.getfileList();
		},
		// watch: {
		// 	myid(newval) {
		// 		this.fileSearch.id = newval;
		// 	},
		// },
		methods: {
			pptHide(){
				this.pptShow = false;
			},
			pptShowClick(item){
				this.pptShow = true;
				this.pptSelected = item;
				this.search.design_id = item.id;
				this.getList();
			},
			getfileList() {
				var that = this;
				var url = apiUrl.digital_office_design_list_get;
				this.Ajax(url, this.fileSearch, (data) => {
					if (that.fileListData.total == 0) {
						that.fileListData.total = data.page_count;
					}
					if (that.fileListData.count == 0) {
						that.fileListData.count = data.total_count;
					}
					that.fileListData.list = data.list;
				});
			},
			getList() {
				var that = this;
				var url = apiUrl.digital_office_design_detail_list_get;
				this.Ajax(url, this.search, (data) => {
					if (that.listData.total == 0) {
						that.listData.total = data.page_count;
					}
					if (that.listData.count == 0) {
						that.listData.count = data.total_count;
					}
					that.listData.list = data.list;
					that.getPPT(data.list[0]);
				});
			},
			getPPT(id) {
				var that = this;
				var url = apiUrl.digital_office_design_detail_get;
				this.Ajax(url, { design_detail_id: id }, (data) => {
					that.listData.current = data;
					that.listData.current.ppt_text = that.listData.current.ppt_text.replace(/\n/g, '<br>')
					that.listData.list.forEach((item, index) => {
						if (item == data.id) {
							that.listData.current.index = index + 1;
						}
					})
					
					var mark_text="游客 888****8888";
					if(localStorage.getItem("userLogInInfo")){
						var userInfo = JSON.parse(localStorage.getItem("userLogInInfo"));
						if (userInfo && userInfo.mobile != '') {
							mark_text="用户 "+userInfo.mobile;
						}
					}

					watermark.getImgSrc(this.listData.current.img_url, mark_text, (img) => {
						document.getElementById('img').src = img.src;
					});
				});
			},
			nextPPT() {
				if (this.listData.current.index >= this.listData.count) {
					this.$message({
						message: '已经是最后一页',
						type: 'warning',
						offset: 300,
						duration: 1500
					});
					return;
				}
				this.getPPT(this.listData.list[this.listData.current.index]);
			},
			lastPPT() {
				if (this.listData.current.index <= 1) {
					this.$message({
						message: '已经是第一页',
						type: 'warning',
						offset: 300,
						duration: 1500
					});
					return;
				}
				this.getPPT(this.listData.list[this.listData.current.index - 2])
			}



		},
	};
</script>


<style lang="scss" scoped>
	
	.ppt_chapters_scroll_y {
		padding-bottom: 100px !important;
	}
	
	.ppt_chapters_scroll_y::-webkit-scrollbar {
		width: 3px;
	}
	
	.ppt_chapters_scroll_y::-webkit-scrollbar-thumb {
		height: 50px;
		background-color: #3DD2BC;
		border-radius: 6px;
	}
	
	.chapter-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		font-size: 15px;
		padding: 10px 0;
		border-bottom: 1px solid #ccc;
		box-sizing: border-box;
		cursor: pointer;
	
		.title {
			font-weight: 600;
			color: #00786c;
		}
	
		.sub_title {
			margin-top: 5px;
			color: #999;
		}
	
		&:hover,
		&.actived {
			background-color: #E4E4E4;
	
			.title {
				color: #333;
			}
	
			.sub_title {
				color: #5d5d5d;
			}
		}
	
		&:last-child {
			border-bottom: 0;
		}
	}
	
	.teach_design-items {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	
		.teach_design-item {
			display: flex;
			flex-direction: column;
			width: 170px;
			height: 180px;
			padding: 0 25px;
			justify-content: center;
			align-items: center;
			background-color: #E4E4E4;
			color: #999;
			position: relative;
			margin: 0 30px 30px 0;
			cursor: pointer;
	
			&:nth-child(4n) {
				margin-right: 0;
			}
	
			.icon-ppt {
				font-size: 70px;
			}
	
			.teach_design-title {
				font-size: 18px;
				margin-top: 15px;
				text-align: center;
				line-height: 1.2;
			}
	
			.triangle_white {
				position: absolute;
				width: 0;
				right: 0;
				bottom: 0;
				border-top: 13px solid transparent;
				border-right: 13px solid #eee;
				border-bottom: 13px solid #eee;
				border-left: 13px solid transparent;
			}
	
			.triangle_black {
				position: absolute;
				width: 0;
				right: 0;
				bottom: 0;
				border-top: 10px solid transparent;
				border-right: 10px solid #999;
				border-bottom: 10px solid #999;
				border-left: 10px solid transparent;
			}
	
			&:hover,&.actived {
				//background: linear-gradient(to bottom right, #B6DED5, #36DEC1);
				background: #fff;
				color: #CA6927;
				box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.3);
	
				.teach_design-title {
					color: #333;
				}
	
				.triangle_black {
					border-right: 10px solid #CA6927;
					border-bottom: 10px solid #CA6927;
				}
			}
		}
	}
</style>