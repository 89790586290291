<template>
	<div class="page-main">
		<div style="width: 100%; height: 450px; overflow: hidden;">
			<img src="../../assets/images/bg_room_02.png" style="width: 100%;" />
		</div>

		<div
			style="width: 100%;height: 450px; box-sizing: border-box; margin-top: 30px ;display: flex; flex-direction: column; align-items: center; margin-top: -450px; ">

			<div v-if="detailData" id="top" style="flex: 1 ;display: flex; flex-direction: column;width: 1024px; ">
				<div style="width: 100%; height: 100%; display: flex;flex-direction: row; padding: 0 75px 0 40px;">
					<div style="flex: 1;display:flex;flex-direction: column; margin-top: 50px; position: relative;">
						<div style="font-size: 50px; font-weight: 600;">
							<div>{{detailData.title.replace('数字教研室','')}}</div>
							<div style="margin-top: 20px;">数字教研室</div>
						</div>
						<div style="font-size: 23px; color: #666; margin-top: 23px;">
							<span v-if="detailData.activity_start">更新时间：{{detailData.activity_start}} ~
								{{detailData.activity_end}}</span>
							<span style="margin-left: 12px;color: #F56C6C;"
								v-if="detailData.is_try == 1 && expires_in > 0">试用倒计时：{{formatStr(expires_in)}}</span>
							<span style="margin-left: 12px;color: #F56C6C;"
								v-if="detailData.is_try == 1 && expires_in == 0">试用即将失效</span>
							<span v-if="!detailData.activity_start"><br></span>
						</div>

						<div
							style="display: flex;flex-direction: row;   z-index: 10;  margin-top: 30px;  margin-bottom: -78px;">
							<div style="display: flex;flex-direction: column; margin-right: 35px;">
								<el-avatar :size="190" :src="detailData.teacher_img"
									v-if="detailData.teacher_img"></el-avatar>
								<el-avatar :size="190"
									src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
									v-if="!detailData.teacher_img"></el-avatar>
								<div style="font-size: 24px;color: #333; text-align: center; margin-top: 12px;">教研室负责人
								</div>
							</div>
							<div style="display: flex; flex-direction: column;">
								<div style="font-size: 36px;font-weight: 600;color: #333; margin-top: 24px;">
									{{detailData.teacher_name}}
								</div>
								<div style="font-size: 18px; color: #666; margin-top: 18px; height: 45px;">
									<div v-if='detailData.teacher_title && detailData.teacher_title.split("；")[0]'>
										{{detailData.teacher_title.split("；")[0]}}
									</div>
									<div style="margin-top: 9px;"
										v-if='detailData.teacher_title && detailData.teacher_title.split("；")[1]'>
										{{detailData.teacher_title.split("；")[1]}}
									</div>
								</div>
								<div class="exts">
									<div class="ext">
										<div class="ext_hd">资源</div>
										<div class="ext_bd">
											<div style="color: #CD671B;">{{detailData.resourse_count}}</div>
											<div class="unit">个</div>
										</div>
									</div>
									<div class="ext ext2" @click="toJoiner()">
										<div class="ext_hd">成员</div>
										<div class="ext_bd">
											<div style="color: #CD671B;">{{detailData.student_count}}</div>
											<div class="unit">人</div>
										</div>
									</div>
									<div class="ext">
										<div class="ext_hd">教龄</div>
										<div class="ext_bd">
											<div style="color: #CD671B;">{{detailData.teaching_length}}</div>
											<div class="unit">年</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<img style="width: 230px; height: 325px; box-shadow: 3px 5px 3px rgba(0,0,0,0.3);border: 1px solid #eee; margin-top: 40px;"
						:src="detailData.book_img" />
				</div>


				<div
					style="width: 100%; height: 96px; display: flex; flex-direction: row; margin-bottom: -40px; z-index: 9;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); line-height: 1; ">
					<div style="flex: 1; height: 96px;background:#fff; "></div>
					<div @click="showJoinClick"
						v-if="detailData && (detailData.have_auth == 0 || detailData.is_try == 1)"
						style=" line-height: 1; width: 218px;height: 96px;display:flex; flex-direction: column; background:  linear-gradient(to right,#C63E31,#FB9226); color:#fff ; font-weight: 600; justify-content: center;align-items: center; cursor: pointer">
						<!-- 
						<div style="display: flex; align-items: baseline;">
							<div class="" style="font-size: 20px;">
								￥
							</div>
							<div class="" style="font-size: 30px;">
								{{detailData.price}}
							</div>
						</div>
						<div class="" style="font-size: 20px; margin-top: 8px;">立即加入</div> 
						-->
						<div class="" style="font-size: 24px; margin-top: 8px;">立即加入</div>
					</div>
				</div>


			</div>

		</div>

		<div style="margin-top: 90px; width: 100%; display: flex; flex-direction: row; justify-content: center;">
			<div style="display: flex; flex-direction: column;width: 1024px; ">
				<img src="../../assets/images/banner_20231221.jpg"
					style="width: 100%; margin-top: -30px; margin-bottom: -20px; "
					v-if="id == '9ea73161ee334b79a2ef0575316a088b'" />
				<img src="../../assets/images/banner_20240125174140.jpg"
					style="width: 100%; margin-top: -30px; margin-bottom: -20px; "
					v-if="id == '56bd65acb72f4e48963307e70d6bbba1'" />
				<img src="../../assets/images/banner_20240322111659.jpg"
					style="width: 100%; margin-top: -30px; margin-bottom: -20px; "
					v-if="id == 'f15b3eb3b76840c8bbb8d8672347eaaf'" />
				<div style="display: flex;flex-direction: row;" v-if="newData && newData.current">
					<div
						style="width: 180px; height: 70px;color:#fff;display: flex; justify-content: center;align-items: center; background:  linear-gradient(to right,#C63E31,#FB9226); ">
						<img src="" />
						<i class="el-icon-s-promotion"></i>
						<div style="font-size:20px;font-weight: 600; margin-left: 5px;">最新消息</div>
					</div>
					<div style="flex: 1; height: 70px;background-color: #eee; ">
						<div style=" width: 100%; padding-top: 15px; display: flex;font-size: 18px;">
							<div
								style=" width: 70px; color: #333; padding-left: 32px; box-sizing: border-box; font-size: 22px;">
								<i class="el-icon-s-promotion"></i>
							</div>
							<div style="flex: 1;display: flex;flex-direction: column;">
								<div style="color: #333;font-weight: 600;">{{newData.current.title}}</div>
								<div style="margin-top: 8px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;
}
">{{newData.current.content}}</div>
							</div>
							<div class="new_btns"
								style="width:50px;display: flex;flex-direction: column; font-size: 20px;color: #cccccc; align-items: center; justify-content: center;">
								<i class="el-icon-caret-top btn" @click="lastNew()"></i>
								<i class="el-icon-caret-bottom btn" @click="nextNew()"></i>
							</div>
						</div>
					</div>
				</div>

				<div style="display: flex;flex-direction: row;margin: 40px 0;">
					<div class="sidebars">
						<div class="sidebars_ul">
							<div class="sidebars_li" v-for="(item,index) in sideList"
								:class="{'active_side':selected_side && selected_side.id == item.id,'ques_bar':selected_side && selected_side.id == item.id && (item.id ==6) , 'noshow':!item.show}"
								@click="changeSide(item)">
								<div class="sidebar_box">
									<div class="sidebar_main">
										<img v-if="selected && selected.id == item.id" class="hover_img"
											src="../../assets/images/icon_book_zk_white.png" />
										<img v-if="!selected || selected.id != item.id" class="default_img"
											src="../../assets/images/icon_book.png" />
										<div v-if="item.id!=3">{{item.text}}</div>
										<div v-if="item.id==3" style="line-height: 1.2;">课程思政<br>实施方案</div>
									</div>
									<div class="sidebar_exts"
										v-if="(item.id ==6 ) && selected_side && selected_side.id == item.id">
										<div class="sidebar_ext" v-if="item.id ==6" v-for="(tem,ndex) in qTypeList"
											@click="changQuesType(tem.type)"><span>{{tem.title}}</span></div>
									</div>
								</div>

							</div>

						</div>
					</div>

					<div v-if="detailData" :class="{'noauth':detailData.have_auth == 0 }"
						style="flex: 1; max-width: 844px; background-color: #eee;min-height: 1200px;box-sizing: border-box; position: relative;">

						<div v-if="detailData.have_auth == 0" @click="showJoinClick"
							style="position:absolute;width: 100%;height:100%; left: 0; top: 0;  z-index: 999; display: flex;flex-direction: column; justify-content: flex-end; ; background: linear-gradient(to bottom,rgba(238,238,238,0.1),rgba(238,238,238,1)); box-sizing: border-box; line-height: 1; cursor: pointer;">
							<div
								style="display: inline-flex; flex-direction: column; justify-content: center;align-items: center; padding-bottom: 50px;">
								<div style="font-size: 21px; color:#333; font-weight: 600;">联系管理员获取激活码即可解锁当前内容</div>
								<!-- <div
									style="display: flex; align-items: baseline; font-weight: 600; margin-top: 10px;color:#333;">
									<div class="" style="font-size: 20px;">
										￥
									</div>
									<div class="" style="font-size: 30px;">
										{{detailData.price}}
									</div>
								</div> -->
								<i class="el-icon-d-arrow-left"
									style="transform: rotate(-90deg); font-size: 24px; color: #333; margin: 8px 0 12px 0;"></i>
								<div
									style=" display: inline-block;font-size: 20px; color:#fff;padding: 12px 24px; background:  linear-gradient(to right,#C63E31,#FB9226); border-radius: 24px;">
									立即获取</div>
							</div>
						</div>


						<!-- 负责人介绍 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==1">
							<div class="" style="display: flex; justify-content: flex-start;margin-bottom: 35px; ">
								<div class=""
									style="width: 265px;  height: 265px; position: relative; margin-right: 35px;">
									<img :src="detailData.teacher_img" mode=''
										style="width: 265px;height: 265px;z-index: 99; position: absolute; left: 0; top: 0;" />
									<div class=""
										style="width:265px;height:265px; background: linear-gradient(to bottom,#C63E31,#FB9226);position:absolute; right: -10px; bottom:-10px;z-index: 1;">
									</div>
								</div>
								<div class=""
									style="flex:1; display: flex; flex-direction: column; justify-content: flex-end;">
									<div style="font-size:32px; font-weight:600; color:#333;">
										{{detailData.teacher_name}}
									</div>
									<div class="" style="margin-top:15px; font-size: 17px;">
										<div v-if='detailData.teacher_title && detailData.teacher_title.split("；")[0]'>
											{{detailData.teacher_title.split("；")[0]}}
										</div>
										<div style="margin-top: 9px;"
											v-if='detailData.teacher_title && detailData.teacher_title.split("；")[1]'>
											{{detailData.teacher_title.split("；")[1]}}
										</div>
									</div>
									<div class=""
										style="margin-top: 30px; font-size: 20px; padding-left: 6px; border-left: 3px solid #333; color: #333; font-weight: 600;">
										教学领域
									</div>
									<div style="display:block; margin-top: 12px;">
										<div style="font-size:12px; padding:5px 10px; box-sizing: border-box; color: #fff; display: inline-block; background: linear-gradient(to right,#C63E31,#FB9226); margin-right: 15px; border-radius: 4px;"
											v-if="detailData.teacher_field && detailData.teacher_field.split('；')"
											v-for="tag in  detailData.teacher_field.split('；')">{{tag}}</div>
									</div>
								</div>

							</div>

							<div style="text-align: justify; font-size: 20px; line-height: 1.5;"
								v-html="detailData.teacher_desc"></div>





							<div v-if="detailData.id=='56bd65acb72f4e48963307e70d6bbba1'">

								<div class=""
									style="display: flex; justify-content: flex-start;margin: 60px 0 35px 0; ">
									<div class=""
										style="width: 265px;  height: 265px; position: relative; margin-right: 35px;">
										<img src="../../assets/images/avatar_teacher2.png?v=1" mode=''
											style="width: 265px;height: 265px;z-index: 99; position: absolute; left: 0; top: 0; background-color:#8D92A5;" />
										<div class=""
											style="width:265px;height:265px; background: linear-gradient(to bottom,#C63E31,#FB9226);position:absolute; right: -10px; bottom:-10px;z-index: 1;">
										</div>
									</div>
									<div class=""
										style="flex:1; display: flex; flex-direction: column; justify-content: flex-end;">
										<div style="font-size:32px; font-weight:600; color:#333;">
											朱光辉
										</div>
										<div class="" style="margin-top:15px; font-size: 17px;">
											<div>
												南京大学计算机科学与技术系助理研究员
											</div>
											<div style="margin-top: 9px;">
												江苏省计算机学会优博
											</div>
										</div>
										<div class=""
											style="margin-top: 30px; font-size: 20px; padding-left: 6px; border-left: 3px solid #333; color: #333; font-weight: 600;">
											教学领域
										</div>
										<div style="display:block; margin-top: 12px;">
											<div
												style="font-size:12px; padding:5px 10px; box-sizing: border-box; color: #fff; display: inline-block; background: linear-gradient(to right,#C63E31,#FB9226); margin-right: 15px; border-radius: 4px;">
												计算机系统基础</div>
										</div>

									</div>


								</div>
								<div style="text-align: justify; font-size: 20px; line-height: 1.5;">
									南京大学计算机科学与技术系助理研究员，江苏省计算机学会优博，江苏省“双创博士”，华为“难题揭榜火花奖”获得者，教育部-华为“智能基座”先锋教师，教育部-华为“智能基座”产教融合协同育人基地优秀课件奖励计划获得者，南京大学“师德师风”先进团队核心成员，长期从事“计算机系统基础”“大数据处理综合实验”以及“智能计算系统”等系统类课程的教学工作。研究方向为大数据与智能计算，已在国内外著名会议/期刊发表高水平论文20余篇，主持国家自然科学基金青年项目、江苏省自然科学基金项目、江苏省科技厅重点研发计划课题、教育部产学合作协同育人项目以及企业横向合作项目多项，研究成果在国际人工智能自动化AI建模大赛中，9次荣获国际大奖，6次获得前三名，并荣获第五届中国“互联网+”大学生创新创业大赛全国金奖，相关技术已落地应用于华为、奇虎360等IT企业。
								</div>


								<div class=""
									style="display: flex; justify-content: flex-start;margin: 60px 0 35px 0; ">
									<div class=""
										style="width: 265px;  height: 265px; position: relative; margin-right: 35px;">
										<img src="../../assets/images/avatar_teacher3.png?v=2" mode=''
											style="width: 265px;height: 265px;z-index: 99; position: absolute; left: 0; top: 0; background-color:#8D92A5;" />
										<div class=""
											style="width:265px;height:265px; background: linear-gradient(to bottom,#C63E31,#FB9226);position:absolute; right: -10px; bottom:-10px;z-index: 1;">
										</div>
									</div>
									<div class=""
										style="flex:1; display: flex; flex-direction: column; justify-content: flex-end;">
										<div style="font-size:32px; font-weight:600; color:#333;">
											余子濠
										</div>
										<div class="" style="margin-top:15px; font-size: 17px;">
											<div>
												中国科学院计算技术研究所博士
											</div>
										</div>
										<div class=""
											style="margin-top: 30px; font-size: 20px; padding-left: 6px; border-left: 3px solid #333; color: #333; font-weight: 600;">
											教学领域
										</div>
										<div style="display:block; margin-top: 12px;">
											<div
												style="font-size:12px; padding:5px 10px; box-sizing: border-box; color: #fff; display: inline-block; background: linear-gradient(to right,#C63E31,#FB9226); margin-right: 15px; border-radius: 4px;">
												计算机系统基础</div>
										</div>

									</div>


								</div>
								<div style="text-align: justify; font-size: 20px; line-height: 1.5;">
									中国科学院计算技术研究所博士，师从孙凝晖院士和包云岗研究员。研究方向包括开源处理器芯片敏捷设计和计算机系统教育。“一生一芯”计划的培养方案设计者和首席讲师，参与建设大规模人才培养流程。教学版模拟器NEMU和南京大学“计算机系统基础”课程实验PA的设计者。南京大学计算机全系统教学实验系列Project-N的联合设计者之一。研发的工具被10余所高校、组织和企业团队采用，支撑RISC-V开源高性能处理器“香山”的敏捷开发流程，相关工作被体系结构顶会MICRO录用，并获IEEE
									Micro Top-Picks论文奖。参与编写《计算机系统基础 第2版》《计算机组成与设计（基于RISC-V架构）》《数字逻辑与计算机组成》等计算机专业核心课程教材。
								</div>

								<div style="margin-bottom: 100px;"></div>
							</div>



							<div v-if="detailData.id=='f15b3eb3b76840c8bbb8d8672347eaaf' && detailData.teacher_list">

								<div style="display: flex;flex-direction: column;"
									v-for="(item,index) in detailData.teacher_list" v-if="index!=0">
									<div class=""
										style="display: flex; justify-content: flex-start;margin: 60px 0 35px 0; ">
										<div class=""
											style="width: 265px;  height: 265px; position: relative; margin-right: 35px;">
											<img :src="item.img_url" mode=''
												style="width: 265px;height: 265px;z-index: 99; position: absolute; left: 0; top: 0; background-color:#8D92A5;" />
											<div class=""
												style="width:265px;height:265px; background: linear-gradient(to bottom,#C63E31,#FB9226);position:absolute; right: -10px; bottom:-10px;z-index: 1;">
											</div>
										</div>
										<div class=""
											style="flex:1; display: flex; flex-direction: column; justify-content: flex-end;">
											<div style="font-size:32px; font-weight:600; color:#333;">{{item.name}}
											</div>
											<div class="" style="margin-top:15px; font-size: 17px;">
												<div>{{item.school}}{{item.college}}{{item.title}}</div>
											</div>
											<div class=""
												style="margin-top: 30px; font-size: 20px; padding-left: 6px; border-left: 3px solid #333; color: #333; font-weight: 600;">
												教学领域
											</div>
											<div style="display:block; margin-top: 12px;">
												<div
													style="font-size:12px; padding:5px 10px; box-sizing: border-box; color: #fff; display: inline-block; background: linear-gradient(to right,#C63E31,#FB9226); margin-right: 15px; border-radius: 4px;">
													{{item.teaching_field}}</div>
											</div>

										</div>


									</div>
									<div style="text-align: justify; font-size: 20px; line-height: 1.5;"
										v-html="item.description"></div>

								</div>



								<div style="margin-bottom: 100px;"></div>
							</div>

						</div>

						<!-- 教学计划 -->
						<div class="teach_plan"
							style="padding:25px 35px;box-sizing: border-box; user-select: all !important; background-color: #f1f1f1;"
							v-html="detailData.teach_plan" v-if="selected_side && selected_side.id==2">
						</div>

						<!-- 实施思政 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==3">
							<sizheng id="sizheng" ref="sizheng"></sizheng>
						</div>


						<!-- PPT讲义 -->
						<div style="width: 100%; display: flex;flex-direction: column; padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==4">
							<ppt id="ppt" ref="ppt" :myid="id"></ppt>
						</div>

						<!-- 课堂实录 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==5">
							<live id="live" ref="live" :myid="id"></live>
						</div>


						<!-- 习题库 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==6">
							<quesob id="quesob" ref="quesob" v-if="ques_type == 1" :myid="id"
								:myauth="detailData.have_auth"></quesob>
							<quessub id="quessub" ref="quessub" v-if="ques_type == 2" :myid="id"
								:myauth="detailData.have_auth"></quessub>
							<paper id="paper" ref="paper" v-if="ques_type == 3" :myid="id"
								:myauth="detailData.have_auth"></paper>
						</div>



						<!-- 教学设计 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==7">
							<design id="design" ref="design" :myid="id"></design>
						</div>

						<!-- 教学研讨 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==8">
							<discuss id="discuss" ref="discuss" :myid="id"></discuss>
						</div>


						<!-- 教材电子书 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==9">
							<ebook id="ebook" ref="ebook" :myid="id"></ebook>
						</div>


						<!-- 参考文献 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==10">
							<reference id="reference" ref="reference" :myid="id"></reference>
						</div>
						
						<!-- 知识图谱 -->
						<div style="width: 100%; display: flex;flex-direction: column;padding:80px 35px;box-sizing: border-box;"
							v-if="selected_side && selected_side.id==11 && detailData.have_auth==1">
							<div @click="targetLink(detailData.knowledge_graph_url)" style="display:inline-flex; font-size: 24px;height: 80px; background:  linear-gradient(to right,#C63E31,#FB9226); color:#fff ;  justify-content: center;align-items: center; cursor: pointer">
								<i class="el-icon-link" style="margin-right: 10px;"></i> 打开新的页面查看知识图谱
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>

		<!-- 购买提示 -->
		<el-dialog title="扫码订阅" width="420px" top="28vh" :visible.sync="dialogQrVisible" :show-close="true">
			<div
				style=" width: 100%; height: 150px; display: flex; flex-direction: column;justify-content: center;align-items: center;">
				<div ref="qrcode2" style="width: 150px; height: 150px;"></div>
				<div style="margin-top: 10px;" v-if="detailData && detailData.group_list">
					{{detailData.group_list[0].title}}
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogQrVisible = false">取消支付</el-button>
				<el-button type="primary" @click="finishedClick">支付完成</el-button>
			</div>
		</el-dialog>


		<!-- 购买提示 -->
		<el-dialog title="加入数字教研室" width="768px" top="10vh" :visible.sync="dialogJoinVisible" :show-close="true">

			<!-- 2个分组权限 -->
			<!-- 
			<table class="desc_table" border="1" cellspacing="0" cellpadding="0" v-if="detailData && detailData.group_list">
				<tr>
					<th v-for="(item,index) in detailData.group_list">{{item.title}}</th>
				</tr>
				<tr>
					<td v-for="(tem,dex) in detailData.group_list">
						<p style="font-weight: 400;color: #333; margin-bottom: 6px;">对应开放本教研室以下勾选模块：</p>
						<p v-for="(item,index) in loadMenuList(tem.menu_list)"
							style="display: flex; align-items: center;">
							<span v-if="item.show"
								style="width: 14px; height: 14px; border-radius: 2px; background-color: #333;margin-right: 5px;display: flex; justify-content: center; ;align-items: center;">
								<i class="el-icon-check" style="color:#fff;font-size: 10px;"></i>
							</span>
							<span v-if="item.show" style="color:#333 ;">{{item.text}}</span>

							<span v-if="!item.show"
								style="width: 14px; height: 14px; border-radius: 2px; background-color: #edf2fc; border: 1px solid #DCDFE6; ;margin-right: 5px;display: flex; justify-content: center; ;align-items: center;">
							</span>
							<span v-if="!item.show" style="color:#C0C4CC ;">{{item.text}}</span>
						</p>
					</td>
				</tr>
				<tr>
					<th>
						<div @click="showQrClick"
							style=" background-color: #CE671B; color: #fff; border-radius: 20px;padding: 6px 26px; display: inline-block;font-size: 14px;cursor: pointer;">
							立即订阅
						</div>

					</th>
					<th>
						<div @click="showCodeClick"
							style="background-color: #CE671B; color: #fff;border-radius: 20px;padding: 6px 26px; display: inline-block;font-size: 14px; cursor: pointer;">
							立即激活
						</div>

					</th>
				</tr>
			</table> 
			-->
			
			<!-- 一个分组权限 -->
			<table class="desc_table" border="1" cellspacing="0" cellpadding="0" v-if="detailData && detailData.group_list">
				<tr>
					<th v-for="(item,index) in detailData.group_list">{{item.title}}</th>
				</tr>
				<tr>
					<td v-for="(tem,dex) in detailData.group_list">
						<p style="font-weight: 400;color: #333; margin-bottom: 6px;">对应开放本教研室以下勾选模块：</p>
						<p v-for="(item,index) in loadMenuList(tem.menu_list)"
							style="display: flex; align-items: center;">
							<span v-if="item.show"
								style="width: 14px; height: 14px; border-radius: 2px; background-color: #333;margin-right: 5px;display: flex; justify-content: center; ;align-items: center;">
								<i class="el-icon-check" style="color:#fff;font-size: 10px;"></i>
							</span>
							<span v-if="item.show" style="color:#333 ;">{{item.text}}</span>
			
							<span v-if="!item.show"
								style="width: 14px; height: 14px; border-radius: 2px; background-color: #edf2fc; border: 1px solid #DCDFE6; ;margin-right: 5px;display: flex; justify-content: center; ;align-items: center;">
							</span>
							<span v-if="!item.show" style="color:#C0C4CC ;">{{item.text}}</span>
						</p>
					</td>
				</tr>
				<tr>
					<th>
						<div @click="showCodeClick"
							style="background-color: #CE671B; color: #fff;border-radius: 20px;padding: 6px 26px; display: inline-block;font-size: 14px; cursor: pointer;">
							立即激活
						</div>
			
					</th>
				</tr>
			</table>


			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogJoinVisible = false">关闭</el-button>
			</div>
		</el-dialog>


		<!-- 数字教研室激活 -->
		<el-dialog title="激活数字教研室成员身份" width="640px" top="25vh" :visible.sync="dialogCodeVisible" :show-close="true">
			<div style="width: 60%; height: 60px; margin: 0 auto; ">
				<p><el-input v-model="redeem_code" placeholder="请输入激活码" clearable></el-input></p>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogCodeVisible = false">关闭</el-button>
				<el-button type="primary" @click="redeemClick">立即激活</el-button>
			</div>
		</el-dialog>




	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import sizheng from "../TeachRoom/TeachRoomSiZheng.vue"; //实施思政
	import ppt from "../TeachRoom/TeachRoomPPT.vue"; //ppt讲义
	import live from "../TeachRoom/TeachRoomLive.vue"; //课堂实录
	import quesob from "../TeachRoom/TeachRoomQues.vue"; //客观题
	import quessub from "../TeachRoom/TeachRoomQues2.vue"; //主观题
	import paper from "../TeachRoom/TeachRoomPaper.vue"; //组卷
	import design from "../TeachRoom/TeachRoomDesign.vue"; //教学设计
	import discuss from "../TeachRoom/TeachRoomDiscuss.vue"; //教学研讨
	import ebook from "../TeachRoom/TeachRoomEbook.vue"; //参考文献
	import reference from "../TeachRoom/TeachRoomReference.vue"; //参考文献

	export default {
		data() {
			return {
				id: null,
				auth_code:null,
				detailData: null,
				newData: {
					current: null,
					list: [],
				},
				search: {
					product_id: '',
					page_index: 1,
					page_size: 12,
					order_by: 'created_at:asc'
				},
				resData: {
					total: 1,
					count: 1,
					list: [],
				},
				selected: null,
				sideList: null,
				selected_side: null,
				ques_type: 1,
				qTypeList: null,
				ebook: {
					uuid: '',
					chapterid: ''
				},
				dialogMsgVisible: false,
				isLogin: false,
				dialogQrVisible: false,
				dialogJoinVisible: false,
				dialogCodeVisible: false,
				redeem_code: '',
				expires_in: null,
				
			};
		},
		components: {
			qrcode: QRCode,
			sizheng,
			ppt,
			live,
			quesob,
			quessub,
			paper,
			design,
			discuss,
			ebook,
			reference
		},
		created() {
			document.title = "数字教研室 - 机工教师特训营";
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			var option = {
				id: this.$route.query.id || "",
				uid: this.$route.query.uid || "",
				auth_code: this.$route.query.auth_code || "",
				token: this.$route.query.token || ""
			};
			if(option.token){
				this.checkToken(option.token);
			}
			if(option.auth_code){
				this.auth_code = option.auth_code;
			}
			if (option.id) {
				this.id = option.id;
				this.getDetail();
				this.getNewData();
				this.getSideList();

				if (option.uid) {
					var obj = new Object();
					obj.uid = option.uid;
					obj.office_id = option.id;
					localStorage.setItem('inviter', JSON.stringify(obj));

					// 先拷贝一个一模一样的对象
					let newQuery = JSON.parse(JSON.stringify(this.$route.query))
					//删除不需要的参数
					delete newQuery.uid
					//再把新的替换了
					this.$router.replace({
						query: newQuery
					})
				}
			}



			// let code_img = new QRCode(this.$refs.qrcode2, {
			// 	width: 110,
			// 	height: 110, // 高度
			// 	text: 'https://yd.cmpreading.com/pages/questionbank/index?id=1', // 二维码内容
			// 	colorDark: '#333333',
			// 	colorLight: '#ffffff',
			// 	correctLevel: QRCode.CorrectLevel.L,
			// });

		},
		methods: {
			targetLink(url){
				window.open(url,'_bank');
			},
			checkToken(_token) {
				var that = this;
				var url = apiUrl.tt_token_check;
				this.Ajax(url, {
					token: _token
				}, (data) => {
					localStorage.setItem("userLogInInfo", JSON.stringify(data));

					// 先拷贝一个一模一样的对象
					let newQuery = JSON.parse(JSON.stringify(this.$route.query))
					//删除不需要的参数
					delete newQuery.token
					//再把新的替换了
					this.$router.replace({
						query: newQuery
					})
					 location.reload();
				});
			},
			redeemClick() {

				var redeem_code = this.redeem_code.trim();
				if (!this.isLogin) {
					this.$message({
						type: 'warning',
						message: '您尚未登录'
					});
					return;
				}
				if (!redeem_code) {
					this.$message({
						type: 'warning',
						message: '激活码不能为空'
					});
					return;
				}

				var that = this;
				var url = apiUrl.digital_office_actived;
				this.Ajax(url, {
					office_id: this.id,
					redeem_code: redeem_code,
				}, (data) => {
					this.$message({
						type: 'success',
						message: '激活成功'
					});
					this.dialogCodeVisible = false;
					location.reload();
				}, (data, code, msg) => {
					this.$message({
						type: 'warning',
						message: msg
					});
				});
			},
			changeClick(e) {
				console.log(e)
			},
			finishedClick() {
				this.dialogQrVisible = false;
				window.location.reload();
			},
			showJoinClick() {
				if (this.isLogin) {

					this.dialogJoinVisible = true;

				} else {
					var msg_title = "您尚未登录，是否登录?";
					this.$confirm(msg_title, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						window.top.location.href = "/login";
					}).catch(() => {
						// this.$message({
						// 	type: 'info',
						// 	message: '已取消'
						// });
					});
				}
			},
			showCodeClick() {
				this.dialogCodeVisible = true;
				this.dialogJoinVisible = false;
			},
			showQrClick() {
				this.dialogJoinVisible = false;
				if (this.isLogin) {

					this.dialogQrVisible = true;

					var that = this;
					if (!that.$refs.qrcode2) {
						that.$nextTick(() => {
							that.setupQrCode(that.$refs.qrcode2, that.detailData.qrcode_url, 150, (code_img) => {
								that.$refs.qrcode2 = code_img;
							});
						});
					}

					// this.$alert('您暂无权限查看相关内容，请联系教材区域经理获取激活码', '提示', {
					// 	confirmButtonText: '确定'
					// });

				} else {
					var msg_title = "您尚未登录，是否登录?";
					this.$confirm(msg_title, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						window.top.location.href = "/login";
					}).catch(() => {
						// this.$message({
						// 	type: 'info',
						// 	message: '已取消'
						// });
					});
				}
			},
			createNode(callback) {
				if (callback) callback();
			},
			changQuesType(value) {
				this.ques_type = value;
			},
			changeChapterSelectItemsShow() {
				this.chapter_select_items_show = !this.chapter_select_items_show;
			},
			selectChapterClick(item) {
				this.selected_chapter_select = item;
				this.chapter_select_items_show = false;
			},
			changeSide(item) {
				if (!item.show) {
					return;
				}
				this.selected_side = item;
				if (item.id != 9 && document.getElementById('csslink')) {
					document.head.removeChild(document.getElementById('csslink'));
				}
			},
			toJoiner() {
				var url = "/teachRoom/joiner";
				var query = {
					id: this.id
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			toDetail(item) {
				var url = "/teachRes/detail";
				var query = {
					id: item.id
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			formatLength(length) {
				var result = '';
				var values = length.split(':');
				switch (values.length) {
					case 1:
						result = values[0] + '秒';
						break;
					case 2:
						result = values[0] + '分' + values[1] + '秒';
						break;
					case 3:
						result = values[0] + '时' + values[1] + '分' + values[2] + '秒';
						break;

					default:
						result = '';
						break;
				}
				return result;
			},
			playClick(item) {
				if (this.selected && this.selected.resource_id == item.resource_id) {
					return;
				}
				console.log(JSON.stringify(item))
				this.selected = item;
			},
			getDetail() {
				var that = this;
				var url = apiUrl.digital_office_detail_get;
				var parms = { id: this.id };
				if(this.auth_code){
					parms = { id: this.id , auth_code: this.auth_code };
				}
				this.Ajax(url, parms, (data) => {
					that.detailData = data;
					
					if(that.detailData.group_list.length == 2){
						var arr = new Array();
						arr.push(that.detailData.group_list[1]);
						that.detailData.group_list = arr;
					}
					
					
					if(that.detailData.have_auth == 1 && that.$route.query && that.$route.query.auth_code){
						// 先拷贝一个一模一样的对象
						let newQuery = JSON.parse(JSON.stringify(that.$route.query))
						//删除不需要的参数
						delete newQuery.auth_code
						//再把新的替换了
						that.$router.replace({
							query: newQuery
						})
						location.reload();
					}
					
					if(that.id == '9ea73161ee334b79a2ef0575316a088b'){
						that.detailData.student_count = 208;
					}
					document.title = data.title + " - 数字教研室 - 机工教师特训营";

					if (data.uuid && data.uuid != '') {
						that.ebook.uuid = data.uuid;
					}
					if (data.chapterid && data.chapterid != '') {
						that.ebook.chapterid = data.chapterid;
					}
					that.detailData.qrcode_url = 'http://m.cmptt.com/pages/TeachRoom/TeachRoomDetail?id=' + data
					.id;
					if (data.expires_in && data.expires_in > 0) {
						that.expires_in = data.expires_in;
						that.showTimer();
					}
				});
			},
			showTimer() {
				let timer = setInterval(() => {
					this.expires_in--;
					if (this.expires_in <= 0) {
						clearInterval(timer);
						this.expires_in = 0;
					}
				}, 1000);
			},
			formatStr(value) {
				var second = parseInt(value);
				var minute = 0;
				var hour = 0;
				if (second > 60) {
					minute = parseInt(second / 60);
					second = parseInt(second % 60);
				}
				if (minute > 60) {
					hour = parseInt(minute / 60);
					minute = parseInt(minute % 60);
				}

				var result = '';
				if (hour > 0) {
					hour = hour < 10 ? '0' + hour : hour;
					result += hour + '时';
				}
				if (minute > 0) {
					minute = minute < 10 ? '0' + minute : minute;
					result += minute + '分';
				}
				second = second < 10 ? '0' + second : second;
				result += second + '秒';
				return result;
			},
			getNewData() {
				var that = this;
				var url = apiUrl.digital_office_news_list_get;
				this.Ajax(url, {
					id: this.id
				}, (data) => {
					if (data) {
						that.newData.list = data;
						that.newData.current = data[0];
						if (that.newData.current && that.newData.current.length > 0) {
							that.newData.current.index = 0;
						}

					}
				});
			},
			nextNew() {
				if (this.newData.current.index >= this.newData.list.length - 1 || !this.newData.current) {
					return;
				}
				var index = this.newData.current.index + 1;
				this.newData.current = this.newData.list[index];
				this.newData.current.index = index;
				console.log(this.newData.current)
			},
			lastNew() {
				if (this.newData.current.index <= 0 || !this.newData.current) {
					return;
				}
				var index = this.newData.current.index - 1;
				this.newData.current = this.newData.list[index];
				this.newData.current.index = index;
			},
			getResList() {
				var that = this;
				var url = apiUrl.resource_list_get;
				this.Ajax(url, this.search, (data) => {
					if (that.resData.total == 0) {
						that.resData.total = data.total;
					}
					if (that.resData.count == 0) {
						that.resData.count = data.count;
					}
					that.resData.list = that.resData.list.concat(data.list);
				});
			},
			loadMenuList(list) {
				var arr = new Array();
				arr.push({
					id: '1',
					text: '负责人介绍',
					show: false
				})
				arr.push({
					id: '2',
					text: '教学计划',
					show: false
				});
				arr.push({
					id: '3',
					text: '课程思政实施方案',
					show: false
				});
				arr.push({
					id: '4',
					text: 'PPT讲义',
					show: false
				});
				arr.push({
					id: '5',
					text: '课堂实录',
					show: false
				});
				arr.push({
					id: '6',
					text: '习题库',
					show: false
				});
				
				if(this.id == '9ea73161ee334b79a2ef0575316a088b'){
					arr.push({
						id: '7',
						text: '教学重难点',
						show: false
					});
				}else{
					arr.push({
						id: '7',
						text: '教学设计',
						show: false
					});
				}
				
				arr.push({
					id: '8',
					text: '教学研讨',
					show: false
				});
				arr.push({
					id: '9',
					text: '教材电子书',
					show: false
				});
				arr.push({
					id: '10',
					text: '参考文献',
					show: false
				});
				arr.push({
					id: '11',
					text: '知识图谱',
					show: false
				});

				arr.forEach((item, index) => {
					list.forEach((tem, dex) => {
						if (item.id == tem) {
							item.show = true;
						}
					})
				})

				return arr;
			},
			getSideList() {
				var that = this;
				var url = apiUrl.digital_office_menu_state_get;
				this.Ajax(url, {
					id: this.id
				}, (data) => {
					var arr = new Array();
					arr.push({
						id: '1',
						text: '负责人介绍',
						show: false
					})
					arr.push({
						id: '2',
						text: '教学计划',
						show: false
					});
					arr.push({
						id: '3',
						text: '课程思政实施方案',
						show: false
					});
					arr.push({
						id: '4',
						text: 'PPT讲义',
						show: false
					});
					arr.push({
						id: '5',
						text: '课堂实录',
						show: false
					});
					arr.push({
						id: '6',
						text: '习题库',
						show: false
					});
					if(this.id == '9ea73161ee334b79a2ef0575316a088b'){
						arr.push({
							id: '7',
							text: '教学重难点',
							show: false
						});
					}else{
						arr.push({
							id: '7',
							text: '教学设计',
							show: false
						});
					}
					arr.push({
						id: '8',
						text: '教学研讨',
						show: false
					});
					arr.push({
						id: '9',
						text: '教材电子书',
						show: false
					});
					arr.push({
						id: '10',
						text: '参考文献',
						show: false
					});
					arr.push({
						id: '11',
						text: '知识图谱',
						show: false
					});

					that.sideList = [];

					arr.forEach((item, index) => {
						that.sideList.push({
							id: item.id,
							text: item.text,
							show: item.show
						})

					});

					that.sideList.forEach((item, index) => {
						data.menu_list.forEach((tem, dex) => {
							if (item.text == tem) {
								item.show = true;
							}
						})
					});
					if (that.sideList[3].show) {
						that.selected_side = that.sideList[3];
					} else {
						that.selected_side = that.sideList[0];
					}


					var arr2 = new Array();
					data.practise_menu_list.forEach((item, index) => {
						switch (item) {
							case '客观题':
								arr2.push({
									type: 1,
									title: '客观题'
								})
								break;
							case '主观题':
								arr2.push({
									type: 2,
									title: '主观题'
								});
								break;
							case '选题组卷':
								arr2.push({
									type: 3,
									title: '策略组卷'
								});
								break;
						}
					})
					that.qTypeList = arr2;
				});
			},
			//QrCode
			setupQrCode(el, url) {
				let code_img = new QRCode(el, {
					width: 110,
					height: 110, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
			},
		},
	};
</script>
<style>
	.noauth {
		min-height: 880px !important;
		height: 880px !important;
		overflow: hidden !important;
	}

	.teach_plan {
		width: 100%;
		display: flex;
		flex-direction: column;
		line-height: 2;
	}

	.teach_plan h2 {
		font-size: 24px;
		color: #333;
	}

	.teach_plan h3 {
		font-size: 18px;
		color: #333;
	}

	.teach_plan p {
		font-size: 17px !important;
		line-height: 1.75 !important;

	}

	.teach_plan p b {
		font-size: 17px !important;
		color: #333 !important;
		font-weight: 600;
	}



	.teach_plan a {
		text-decoration: none;
		color: #333 !important;
		border-bottom: 1px solid #333 !important;
		padding-bottom: 2px;
	}

	.teach_plan table {
		width: 100% !important;
	}

	.teach_plan table p b {
		color: #333 !important;
	}

	table.desc_table {
		width: 100%;
		line-height: 1.5;
		border-collapse: collapse;
	}

	table.desc_table tr th,
	table.desc_table tr td {
		border: 1px solid #E1E6F0;
	}

	table.desc_table tr th {
		background-color: #F5F7FA;
		color: #000;
		text-align: center;
		padding: 8px 13px;
	}

	table.desc_table tr td {
		color: #666;
		padding: 30px 40px;
	}

	.check_show {
		-webkit-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		user-select: none !important;
	}

	.el-message {
		top: 15vh !important;
	}
</style>
<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		line-height: 1;

		/deep/.el-avatar--circle {
			background: linear-gradient(to right, #CD9F6D, #FBE8BE);
			box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.3);
			padding: 6px;
			box-sizing: border-box;
		}

		/deep/.el-avatar>img {
			border-radius: 50%;
		}

		/deep/.el-checkbox__input.is-disabled+span.el-checkbox__label {
			color: #C0C4CC !important;
		}

		/deep/.el-checkbox__label {
			color: #333 !important;
		}

		.new_btns {
			.btn {
				cursor: pointer;

				&:hover {
					color: #333;
				}
			}
		}

		.exts {
			display: flex;
			flex-direction: row;
			align-items: baseline;
			margin-top: 45px;

			.ext {
				display: flex;
				flex-direction: column;
				font-weight: 600;
				color: #333;

				&.ext2 {
					padding: 0 25px;
					margin: 0 25px;
					border-left: 1px solid #ccc;
					border-right: 1px solid #ccc;
					cursor: pointer;
				}

				.ext_hd {
					font-size: 18px;
				}

				.ext_bd {
					font-size: 28px;
					margin-top: 10px;
					display: flex;
					align-items: baseline;
					line-height: 1;


					.unit {
						font-size: 18px;
						margin-left: 2px;
						color: #7d7d7d;
					}
				}
			}
		}


		.sidebars {
			width: 180px;
			display: flex;
			flex-direction: column;

			.sidebars_ul {
				width: 100%;
				list-style: none;
				display: flex;
				flex-direction: column;

				.sidebars_li {
					height: 80px;

					border-bottom: 1px solid #4c4c4c;
					display: flex;
					justify-content: start;
					align-items: center;
					color: #999;
					background-color: #333;
					cursor: pointer;

					&:last-child {
						border-bottom: none;
					}

					&:hover,
					&.active_side {
						background: linear-gradient(to right, #C63E31, #FB9226) !important;
						background-color: transparent !important;
						color: #fff !important;
					}

					&.ques_bar {
						height: 130px;
					}

					&.noshow {
						color: #666 !important;
						cursor: default !important;

						&:hover {
							background: none !important;
							background-color: #333 !important;
							color: #666 !important;
						}
					}

					.sidebar_box {
						display: flex;
						flex-direction: column;

						.sidebar_main {
							display: flex;
							flex-direction: row;
							align-items: center;
							padding-left: 30px;

							.default_img,
							.hover_img {
								width: 23px;
								height: 24px;
								margin-right: 10px;
							}
						}

						.sidebar_exts {
							padding-left: 60px;
							font-size: 15px;
							width: 100%;
							display: flex;
							flex-direction: column;

							.sidebar_ext {
								display: inline-flex;
								color: #666;
								margin-top: 10px;

								line-height: 1.5;

								span {
									border-bottom: 1px solid #666;
								}

								&:hover,
								&.actived {
									span {
										color: #fff;
										border-bottom: 1px solid #fff;
									}
								}
							}
						}
					}
				}
			}
		}

		.link {
			border-bottom: 1px dotted #00786c;
			color: #00786c;
			padding: 2px;
		}
	}
</style>