<template>
	<div class="page-main ">
		<div class="conference_detail_container" v-if="detailData">
			<!-- 机工会议 -->
			<div style=" width: 100%; display: inline-flex; flex-direction: column; z-index: 3;">
				<div style="display: flex; margin: 54px 0 70px 0;">
					<div class=""
						style="margin-right: 17px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;">
						<div style="font-size: 50px; color: #333;">机工会议</div>
						<div style="font-size: 30px; color: #999; margin-top: 15px;">凝聚智慧、深化合作、促进交流、互学互鉴</div>
					</div>
				</div>
			</div>


			<div style="width: 100%;display: flex; flex-direction: row;justify-content:space-between; line-height: 1; margin-bottom: 45px;">
				<div style="width: 332px; height: auto; display: flex;flex-direction: column; align-items: center; background-color: #fff;border-radius: 12px; padding: 25px; box-sizing: border-box;">
					
					<div v-if="detailData.is_reg == 1" @click="signUpClick" style="width: 268px; height: 57px; background: linear-gradient(to right,#c93a2e,#ff9000); border-radius: 12px; display: flex; justify-content: center; align-items: center; font-size: 23px; color: #fff;cursor: pointer;">
						已经报名
					</div>
					
					<div v-if="detailData.is_reg == 0">
						<div v-if="detailData.state != 2" @click="signUpClick" style="width: 268px; height: 57px; background: linear-gradient(to right,#c93a2e,#ff9000); border-radius: 12px; display: flex; justify-content: center; align-items: center; font-size: 23px; color: #fff;cursor: pointer;">
							我要报名
						</div>
						<div v-if="detailData.state == 2" style="width: 268px; height: 57px; background: #ccc; border-radius: 12px; display: flex; justify-content: center; align-items: center; font-size: 23px; color: #fff;cursor: pointer;">
							我要报名
						</div>
					</div>


					<div style=" width: 100%; display: flex; justify-content: space-between;  margin-top: 23px;">
						
						<div v-if="detailData.res" @click="downloadClick(detailData.res)" style="font-size: 17px;color:#FFA350 ; display: flex;align-items: center;cursor: pointer;">
							<i class="el-icon-download" style="font-size: 20px; margin-right: 5px;"></i>
							<span style="border-bottom: 1px solid #FFA350; padding-bottom: 3px;">下载会议通知</span>
						</div>
						
						<div v-if="!detailData.res"></div>
						
						<div v-if="detailData.state == 0" style="background-color: #FFA350;color: #fff; font-size: 12px; padding: 3px 5px; border-radius: 3px; display: flex; justify-content: center; align-items: center;">
							未开始
						</div>
						
						<div v-if="detailData.state == 1" style="background: linear-gradient(to right,#c93a2e,#ff9000);color: #fff; font-size: 12px; padding: 3px 5px; border-radius: 3px; display: flex; justify-content: center; align-items: center;">
							进行中
						</div>
						
						<div v-if="detailData.state == 2" style="background-color: #ccc;color: #fff; font-size: 12px; padding: 3px 5px; border-radius: 3px; display: flex; justify-content: center; align-items: center;">
							已结束
						</div>
						
					</div>
					 <el-divider class="line_left"></el-divider>
					 <div style="display: flex;flex-direction: column; clear: both; width: 100%;">
						 <div style="display: flex;flex-direction: column;font-size: 17px; line-height: 1.35; margin-bottom: 20px;" v-if="detailData.host">
							 <div style="color: #333; font-weight: 600;">主办单位：</div>
							 <div style="color: #666;" v-if="detailData.host.split(';').length>0" v-for="(item,index) in detailData.host.split(';')">{{item}}</div>
						 </div>
						 
						 <div style="display: flex;flex-direction: column;font-size: 17px; line-height: 1.35; margin-bottom: 20px;">
							 <div style="color: #333; font-weight: 600;">会议时间：</div>
							 <div style="color: #666;">{{detailData.arrival_at}}报到，{{detailData.start_at.split('-')[2]}}~{{detailData.stop_at.split('-')[2]}}日开会，{{detailData.stop_at.split('-')[2]}}日离会。</div>
						 </div>
						 
						 <div style="display: flex;flex-direction: column;font-size: 17px; line-height: 1.35; margin-bottom: 20px;">
							 <div style="color: #333; font-weight: 600;">报名截止日期：</div>
							 <div style="color: #666;">{{detailData.close_at}}</div>
						 </div>
						 
						 <div style="display: flex;flex-direction: column;font-size: 17px; line-height: 1.35; margin-bottom: 20px;">
							 <div style="color: #333; font-weight: 600;">举办地点：</div>
							 <div style="color: #666;">{{detailData.location_title}}</div>
						 </div>
						 
						 <div style="display: flex;flex-direction: column;font-size: 17px; line-height: 1.35; margin-bottom: 20px;">
							 <div style="color: #333; font-weight: 600;">联系人：</div>
							 <div v-for="(item,index) in detailData.contacts.split(';')" style="color: #666;">{{item}}</div>
						 </div>
						 
					 </div>
					 
					 <div id="map" style="width: 283px; height: 278px; background-color: #f1f1f1;  cursor: pointer;" @click="queryMapClick">
				
					 </div>
					 
					
				</div>

				<div style="width: 846px;height: auto; background-color: #fff;border-radius: 12px; display: flex; flex-direction: column; padding:50px 40px; box-sizing: border-box;">
					<div style="display: flex; ">
						<div class="" style="margin-right: 17px;width: 4px;background: linear-gradient(to bottom,#ff9000,#c93a2e);"></div>
						<div style="display: flex;flex-direction: column; line-height: 1.2; font-size: 34px; width: 90%;color: #333;">
							{{detailData.title}}
						</div>
					</div>
					
					
					<!-- 标准版本 -->
					<div style="display: flex; flex-direction: column;" v-if="detailData.template_type == 1">
						<div style="font-size: 17px;color: #333; line-height: 1.4; text-align: justify; margin: 44px 0 36px 0;" v-html="detailData.preface"></div>
						
						<div style="display: flex; flex-direction: column;">
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.1; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">会议时间</div>
								</div>
								<div style="color: #666; ">
									<div style="margin-top: 10px;">报到时间：{{detailData.arrival_at}}</div>
									<div style="margin-top: 10px;">参会时间：{{detailData.start_at}}</div>
									<div style="margin-top: 10px;">离会时间：{{detailData.stop_at}}</div>
								</div>
							</div>
							
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.5; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">会议组织</div>
								</div>
								<div style="color: #666;">
									<div style="margin-top: 5px; padding-left: 4.5em; display: flex;" v-if="detailData.host && detailData.host.split(';').length>0">
										<div style="margin-left: -4.5em;">主办单位：</div>
										<div style="display: flex;flex-direction: column;">
											<div v-for="(item,index) in detailData.host.split(';')">{{item}}</div>
										</div>
									</div>
									
									<div style="margin-top: 5px; padding-left: 4.5em; display: flex;" v-if="detailData.organizer && detailData.organizer.split(';').length>0">
										<div style="margin-left: -4.5em;">承办单位：</div>
										<div style="display: flex;flex-direction: column;">
											<div v-for="(item,index) in detailData.organizer.split(';')">{{item}}</div>
										</div>
									</div>
									
									<div style="margin-top: 5px; padding-left: 4.5em; display: flex;" v-if="detailData.co_organizer && detailData.co_organizer.split(';').length>0" >
										<div style="margin-left: -4.5em;">协办单位：</div>
										<div style="display: flex;flex-direction: column;">
											<div v-for="(item,index) in detailData.co_organizer.split(';')">{{item}}</div>
										</div>
									</div>
								</div>
							</div>
							
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.1; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">会议地点</div>
								</div>
								<div style="color: #666; ">
									<div style="margin-top: 10px;">
										{{detailData.location_title}} {{detailData.province}}{{detailData.city}}{{detailData.area}}{{detailData.place}}
									</div>
								</div>
							</div>
							
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.1; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">参会人员</div>
								</div>
								<div style="color: #666; line-height: 1.5; margin-top: 5px;text-align: justify;"  v-html="detailData.attender"></div>
							</div>
							
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.1; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">参会内容</div>
								</div>
								<div style="color: #666; line-height: 1.5; margin-top: 5px;text-align: justify;" v-html="detailData.content"></div>
							</div>
							
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.1; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">会务安排</div>
								</div>
								<div style="color: #666; line-height: 1.5; margin-top: 5px;text-align: justify;">
									<div>1、会议费: {{detailData.price.replace('.00','')}} 元/人</div>
									<div>2、餐食：{{detailData.meals}}</div>
									<div>3、交通及住宿：{{detailData.trans_and_hotel}}</div>
									<div v-if="detailData.contacts && detailData.contacts.split(';').length>0">
										<div>4、会务联系人</div>
										<div v-for="(item,index) in detailData.contacts.split(';')" >{{item}}</div>
									</div>
								</div>
							</div>
							
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.1; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">报名缴费</div>
								</div>
								<div style="color: #666; line-height: 1.5; margin-top: 5px; text-align: justify;">
									<div>1、{{detailData.sign_up_desc}}</div>
									<div>2、缴费方式：{{detailData.payment_desc}}</div>
									<div>3、发票：{{detailData.invoice_desc}}</div>
									<div>4、证书：{{detailData.cert_desc}}</div>
								</div>
							</div>
							
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.1; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">交通指南</div>
								</div>
								<div style="color: #666; line-height: 1.5; margin-top: 5px; text-align: justify;" v-html="detailData.trans_guide"></div>
							</div>
							
							
							<div style="display: flex; flex-direction: column; padding-left: 16px; box-sizing: border-box;font-size: 17px;line-height: 1.1; margin-bottom: 32px;">
								<div style="display: flex;align-items: center;  margin-left: -16px ;">
									<div style="width: 8px; height: 8px; background-color: #c93a2e; margin-right: 8px;"></div>
									<div style="color: #333;font-weight: 600;">其他事项</div>
								</div>
								<div style="color: #666; line-height: 1.5; margin-top: 5px; text-align: justify;" v-html="detailData.ext_desc"></div>
							</div>
							
						</div>
					</div>
					
					
					
					<!-- 简约版本 -->
					<div style="display: flex; flex-direction: column;" v-if="detailData.template_type == 2" v-html="detailData.description"></div>


					<div v-if="detailData.res"  style=" width: 100%; display: flex; justify-content: space-between;  margin-top: 23px;">
						<div @click="downloadClick(detailData.res)" style="font-size: 17px;color:#FFA350 ; display: flex;align-items: center; cursor: pointer;">
							<i class="el-icon-download" style="font-size: 20px; margin-right: 5px;"></i>
							<span style="border-bottom: 1px solid #FFA350; padding-bottom: 3px;">下载会议通知</span>
						</div>
					</div>
					
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import download from 'downloadjs';
	import AMapLoader from '@amap/amap-jsapi-loader';

	export default {
		data() {
			return {
				id: null,
				detailData: null,
				out_trade_no:null,
			};
		},
		created() {
			document.title = "机工会议 - 机工教师特训营";
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			console.log(this.isLogin);
		},
		mounted() {
			//this.loadScript();
			this.loadAmap();
		},
		methods: {
			downloadClick(item){
				download(item.file_path,item.file_name);
			},
			queryMapClick(){
				location.href='https://ditu.amap.com/search?query='+ encodeURI(this.detailData.city +''+ this.detailData.location_title) ;
				//location.href='https://map.qq.com/?type=poi&what='+ encodeURI(this.detailData.location_title) +'&c=1539';
			},
			loadAmap(){
				
				var option = {
					id: this.$route.query.id || ""
				};
				if (option.id) {
					this.id = option.id;
					this.detailGet(()=>{
						this.$nextTick(() => {
							var location_lat = parseFloat(this.detailData.location_lat);
							var location_lng = parseFloat(this.detailData.location_lng);
							
							window._AMapSecurityConfig = {
							 securityJsCode: "9f8709f9ed02029de034a8469b6527bb",
							};
							AMapLoader.load({
								key: "c8a1b064bf0952b6462cf07e4908cf0f", // 申请好的Web端开发者Key，首次调用 load 时必填
								version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
								plugins: ["AMap.Scale"], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
							})
							.then((AMap) => {
							  this.map = new AMap.Map("map", {
								zoom: 10, //地图级别
								center: [location_lng, location_lat], //地图中心点
								viewMode: "3D", //设置地图模式
								  
							  });
							  const marker = new AMap.Marker({
							    position: new AMap.LngLat(location_lng, location_lat), //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
							    title: "会议地点",
								offset:[0,0]
							  });
							  //将创建的点标记添加到已有的地图实例：
							  this.map.add(marker);
							  
							})
							.catch((e) => {
							  console.log(e);
							});
												
						})
					});
				}
				
				 
			},
			loadScript(){
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = 'https://map.qq.com/api/gljs?v=1.exp&key=YQ4BZ-XTW33-CCX34-YGM3U-SFALO-ARBGD';
				script.charset = 'utf-8';
				document.head.appendChild(script);
				script.onload = () => {
				    
					var option = {
						id: this.$route.query.id || ""
					};
					if (option.id) {
						this.id = option.id;
						this.detailGet(()=>{
							this.$nextTick(() => {
								var location_lat = parseFloat(this.detailData.location_lat);
								var location_lng = parseFloat(this.detailData.location_lng);
								var center = new TMap.LatLng(location_lat, location_lng)
								//定义map变量，调用 TMap.Map() 构造函数创建地图
								var map = new TMap.Map(document.getElementById('map'), {
									center: center,//设置地图中心点坐标
									zoom: 17.2,   //设置地图缩放级别
									pitch: 43.5,  //设置俯仰角
									rotation: 45    ,//设置地图旋转角度
									viewMode:'2d',
									showControl:false
								});
								
								var markerLayer = new TMap.MultiMarker({
								    map: map,  //指定地图容器
								    //样式定义
								    styles: {
								        //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
								        "myStyle": new TMap.MarkerStyle({ 
								            "width": 25,  // 点标记样式宽度（像素）
								            "height": 35, // 点标记样式高度（像素）
								            "src": '../img/marker.png',  //图片路径
								            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
								            "anchor": { x: 16, y: 32 }  
								        }) 
								    },
								    //点标记数据数组
								    geometries: [{
								        "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
								        "styleId": 'myStyle',  //指定样式id
								        "position": new TMap.LatLng(location_lat, location_lng),  //点标记坐标位置
								        "properties": {//自定义属性
								            "title": "marker1"
								        }
								    }
								    ]
								});
							})
						});
					}
					
				}
			},
			signUpClick(){
				if(!this.isLogin){
					window.top.location.href = "/Login";
					return;
				}else{
					var url = "/conference/signup";
					var query = { id: this.id };
					let routeUrl = this.$router.resolve({
						path: url,
						query: query,
					});
					window.open(routeUrl.href, "_blank");
				}
			},
			detailGet(callBack) {
				var that = this;
				var url = apiUrl.tt_conference_detail_get;
				this.Ajax(url,{ id: this.id }, (data) => {
					that.detailData = data;
					if (callBack) callBack();
				});
			},
		},
	};
</script>
<style>

</style>


<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		
		/deep/.conference_detail_container{
			display: inline-flex;
			flex-direction: column;
			width: 1200px;
		}

	}

</style>