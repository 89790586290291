<template>
	<div style="width: 100%; display: flex;flex-direction: column; background-color: #fff;">
		<div style="display: flex; align-items: flex-end;">
			<div style="font-size: 17px; margin-right: 15px;">已开发票</div>
		</div>

		<el-alert type="warning" show-icon :closable="false" style="margin-top: 10px;">
			我社统一开具电子普票，会后发送至您预留的邮箱。如需专票，请联系工作人员。
		</el-alert>
		<div style="display: flex;flex-direction: column; margin-top: 5px;">
			<div style="display: flex; padding:30px 0px; line-height: 1; border-bottom: 1px solid #eee;"
				:class="{'border-no':index == productData.list.length-1}" v-for="(item,index) of productData.list">
				<div style="font-size: 16px;color: #333; font-weight: 600; margin-right: 30px;">¥{{item.total_fee}}
				</div>
				<div style="flex: 1; display: flex;  flex-direction: column;">
					<div style="font-size: 16px;color: #333; font-weight: 600;">{{item.title}}</div>
					<div style="font-size: 13px;color: #666; margin-top: 12px; display: flex;">
						<div v-if="item.updated_at">开票日期：{{item.updated_at}}</div>
						<div v-if="!item.updated_at">提交日期：{{item.created_at}}</div>
						<div v-if="item.state ==0" style="margin-left: 30px;">状态：开具中</div>
						<div v-if="item.state==1" style="margin-left: 30px;">状态：已开具</div>
						<div style="margin-left: 30px;">形式：电子发票 </div>
						<div style="margin-left: 30px;">交付：{{item.email}}</div>
					</div>
				</div>
			</div>
		</div>

		<el-empty v-if="!productData.list"></el-empty>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				search: {
					page_index: 1,
					page_size: 10,
					order_by: 'created_at:desc'
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				},
				loading: false
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.getProductList();
		},
		methods: {
			getProductList() {
				var that = this;
				var url = apiUrl.tt_invoice_list_get;
				this.Ajax(url, this.search, (data, code, msg) => {
					if (code == 0) {
						if (data) {
							if (that.productData.total == 0) {
								that.productData.total = data.total;
							}
							if (that.productData.count == 0) {
								that.productData.count = data.count;
							}
							that.productData.list = that.productData.list.concat(data.list);
						}
					} else if (code == 40004) {
						this.loading = false;
						if (that.search.page_index == 1) {
							that.productData.list = null;
						}
					}
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.getProductList();
			},
			nextPage() {
				if (this.search.page_index >= this.productData.count && this.search.page_index != 0) {
					this.search.page_index = this.productData.count;
					return;
				}
				this.loading = true;
				this.currentPage(this.search.page_index + 1);
			}
		},
	};
</script>

<style lang="scss" scoped>
	.border-no {
		border-bottom: none !important;
	}
</style>