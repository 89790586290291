<template>
	<div class="page-main" style="position: relative;" :id="backtop_id">
		<div class="teacher_reg_container" style="width: 1200px; display: flex; flex-direction: column;" >

			<!-- 教师身份认证 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						教师身份认证
					</div>
				</div>
			</div>
				
				
			<div style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 35px 35px 100px 35px; margin-bottom: 60px;line-height: 1;">
				
				
				<div v-if="reg_state == 1" style="display: flex; flex-direction: column; box-sizing: border-box; ">
					
					<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0;">
						基本信息
					</div>
					
					<el-form ref="form" :rules="rules" :model="form" style="width: 800px;" :inline="true">
						<el-form-item label="真实姓名" prop="name" label-width="100px">
							<el-input v-model="form.name" placeholder="真实姓名(汉字)" style="width: 223px"></el-input>
						</el-form-item>
						<el-form-item label="性别" prop="sex" label-width="100px">
							<el-radio-group v-model="form.sex">
								<el-radio label="1">男</el-radio>
								<el-radio label="2">女</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="学校" prop="school" label-width="100px">
							<el-select v-model="form.school" filterable placeholder="请选择学校" @change="schoolChanged()" style="width:223px">
								<el-option v-for="item in school_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="学院" prop="college" label-width="100px">
							<el-select v-model="form.college" filterable placeholder="请选择学院" style="width:223px">
								<el-option v-for="item in college_list" :key="item.num" :label="item.name"
									:value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="职称" prop="job" label-width="100px">
							<el-select v-model="form.job" placeholder="请选择职称" style="width:223px">
								<el-option v-for="item in job_list" :key="item.num" :label="item.name"
									:value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="职务" prop="position" label-width="100px">
							<el-input v-model="form.position" placeholder="职务(汉字)" style="width:223px"></el-input>
						</el-form-item>
						<el-form-item label="电子邮箱" prop="email" label-width="100px">
							<el-input v-model="form.email" placeholder="电子邮箱" style="width:223px"></el-input>
						</el-form-item>
					</el-form>
					
					<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0;">
						主讲课程 ①
					</div>
					   
					<el-form ref="field1" :inline="true" :model="field1" :rules="rules" style="width: 800px;">
						<el-form-item label="授课专业" prop="major" label-width="100px">
							<el-select v-model="field1.major" filterable placeholder="请选择授课专业" @change="major1Changed()">
								<el-option v-for="item in major_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="课程名称" prop="course" label-width="100px">
							<el-select v-model="field1.course" filterable placeholder="请选择课程名称">
								<el-option v-for="item in course_list1" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="学生人数" prop="students_count" label-width="100px">
							<el-input v-model="field1.students_count" placeholder="学生人数" style="width: 223px"></el-input>
						</el-form-item>
						<el-form-item label="学生层次" prop="education_level" label-width="100px">
							<el-select v-model="field1.education_level" filterable placeholder="请选择学生层次">
								<el-option v-for="item in education_level_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="学期" prop="term" label-width="100px">
							<el-select v-model="field1.term" filterable placeholder="请选择学期">
								<el-option v-for="item in term_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="现用教材名及出版社" prop="current_prep" label-width="100px">
							<el-input v-model="field1.current_prep" placeholder="现用教材名及出版社" style="width: 223px"></el-input>
						</el-form-item>
						
						<el-form-item label="教辅满意度" prop="teach_resource_degree" label-width="100px">
							<el-select v-model="field1.teach_resource_degree" filterable placeholder="请选择教辅满意度">
								<el-option v-for="item in degree_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="教材满意度" prop="prep_degree" label-width="100px">
							<el-select v-model="field1.prep_degree" filterable placeholder="请选择教材满意度">
								<el-option v-for="item in degree_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
		
					
					<!--主讲课程2 -->
					<div v-show="field2DisplayFlag">
					
					<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0;">
						主讲课程 ②
					</div>
					
					<el-form ref="field2" :inline="true" :model="field2" :rules="rules" style="width: 800px;">
						<el-form-item label="授课专业" prop="major" label-width="100px">
							<el-select v-model="field2.major" filterable placeholder="请选择授课专业" @change="major2Changed()">
								<el-option v-for="item in major_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="课程名称" prop="course" label-width="100px">
							<el-select v-model="field2.course" filterable placeholder="请选择课程名称">
								<el-option v-for="item in course_list2" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="学生人数" prop="students_count" label-width="100px">
							<el-input v-model="field2.students_count" placeholder="学生人数" style="width: 223px"></el-input>
						</el-form-item>
						<el-form-item label="学生层次" prop="education_level" label-width="100px">
							<el-select v-model="field2.education_level" filterable placeholder="请选择学生层次">
								<el-option v-for="item in education_level_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="学期" prop="term" label-width="100px">
							<el-select v-model="field2.term" filterable placeholder="请选择学期">
								<el-option v-for="item in term_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="现用教材名及出版社" prop="current_prep" label-width="100px">
							<el-input v-model="field2.current_prep" placeholder="现用教材名及出版社" style="width: 223px"></el-input>
						</el-form-item>
						
						<el-form-item label="教辅满意度" prop="teach_resource_degree" label-width="100px">
							<el-select v-model="field2.teach_resource_degree" filterable placeholder="请选择教辅满意度">
								<el-option v-for="item in degree_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="教材满意度" prop="prep_degree" label-width="100px">
							<el-select v-model="field2.prep_degree" filterable placeholder="请选择教材满意度">
								<el-option v-for="item in degree_list" :key="item.num" :label="item.name" :value="item.num">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
					</div>
					
					<div style="padding: 40px 0;">
						<el-button v-show="!field2DisplayFlag" @click="fieldClick()" style="width: 150px;">新增主讲课程 ②</el-button>
						<el-button v-show="field2DisplayFlag" @click="fieldClick()" style="width: 150px;">移除主讲课程 ②</el-button>
					</div>
					<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0;">
						教师资质证明
					</div>
					<div style="width: 480px;">
					<div style="margin: -10px 0 10px 0;" v-if="showAlert">
						<el-alert title="请上传教师资质证明图片" type="error" @close="closeAlert"></el-alert>
					</div>
					
					<el-upload
						:file-list="fileList"
						list-type="picture"
						accept=".jpg,.jpeg,.bmp,.png"
						action="#"
						drag
						multiple
						:limit="3"
						:auto-upload=false
						:on-exceed="handleExceed"
						ref="upload"
						:on-change="handlChange"
						:on-remove="handleRemove"
					  >
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div slot="tip" class="el-upload__tip">请上传jpg/png格式的图片，教师证拍照或学校官网上教师介绍页截图</div>
					  </el-upload>
					</div>
	
					<div @click="onSubmit" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
						<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">提交认证信息</div>
					</div>
					
				</div>
				
				<div v-if="reg_state == 2" style="display: flex; padding: 100px 0;  flex-direction: column; justify-content: center;align-items: center;width: 1200px; max-width: 1200px; box-sizing: border-box;">
					<i class="el-icon-success" style="font-size: 72px; "></i> 
					<div style="font-size: 20px; line-height: 1.5; color: #333; text-align: center; margin-top: 25px;color:#333;">
						您的帐号已通过教师认证的审核。<br />请退出并重新登录后，既可体验完整内容。<br />
					</div>
					<el-button type="text" style="font-size: 17px;" @click="backClick">点击返回上一页面</el-button>
				</div>
				
				<div v-if="reg_state == 3" style="display: flex; padding: 100px 0;  flex-direction: column; justify-content: center;align-items: center;width: 1200px; max-width: 1200px; box-sizing: border-box;">
					<i class="el-icon-success" style="font-size: 72px;"></i> 
					<div style="font-size: 20px;line-height: 1.5; color: #333; text-align: center; margin-top: 25px;">
						您的信息已提交，请等待管理员审核后再登录。<br />
						我们将在1个工作日内完成审核并通过短信告知您审核结果。<br />
					</div>
					<el-button type="text" style="font-size: 17px;" @click="backClick">点击返回上一页面</el-button>
				</div>
				<div v-if="reg_state == 4" style="display: flex; padding: 100px 0;  flex-direction: column; justify-content: center;align-items: center;width: 1200px; max-width: 1200px; box-sizing: border-box;">
					<i class="el-icon-error" style="font-size: 72px;"></i> 
					<div style="font-size: 20px;line-height: 1.5; color: #333; text-align: center; margin-top: 25px;">
						教师认证审核失败，请联系管理员。<br />
					</div>
					<el-button type="text" style="font-size: 17px;" @click="backClick">点击返回上一页面</el-button>
				</div>
				<div v-if="reg_state == 0" style="display: flex; padding: 100px 0;  flex-direction: column; justify-content: center;align-items: center;width: 1200px; max-width: 1200px; box-sizing: border-box;">
					<i class="el-icon-error" style="font-size: 72px;"></i> 
					<div style="font-size: 20px;line-height: 1.5; color: #333; text-align: center; margin-top: 25px;">
						账号异常，请联系管理员。<br />
					</div>
					<el-button type="text" style="font-size: 17px;" @click="backClick">点击返回上一页面</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";

	export default {
		data() {
			var validateEmail = (rule, value, callback) => {
				const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
				if (!value) {
					return callback(new Error("请输入电子邮箱地址"));
				}
				setTimeout(() => {
					if (mailReg.test(value)) {
						callback();
					} else {
						callback(new Error("请输入正确的邮箱格式"));
					}
				}, 100);
			};
			return {
				reg_state:1,
				field2DisplayFlag:false,
				school_list: [],
				college_list: [],
				major_list: [],
				course_list1: [],
				course_list2: [],
				fileList : [],
				education_level_list: [
					{num : 1, name : '本科' },
					{num : 2, name : '专科' },
					{num : 3, name : '硕士研究生' },
					{num : 4, name : 'MBA' },
					{num : 5, name : '成教' },
				],
				term_list: [
					{num : 1, name : '春' },
					{num : 2, name : '秋' }
				],
				degree_list: [
					{num : 1, name : '满意' },
					{num : 2, name : '一般' },
					{num : 3, name : '不满意' }
				],
				job_list: [
					{ num: 1, name: '教授' },
					{ num: 2, name: '副教授' },
					{ num: 3, name: '讲师' },
					{ num: 4, name: '助教' },
					{ num: 5, name: '其他' }
				],
				form: {
					name: '',
					sex: '',
					school: '',
					college: '',
					job: '',
					position: '',
					email: ''
				},
				field1: {
					major: '',
					course: '',
					education_level: '',
					students_count: '',
					term: '',
					current_prep: '',
					teach_resource_degree: '',
					prep_degree: ''
				},
				field2: {
					major: '',
					course: '',
					education_level: '',
					students_count: '',
					term: '',
					current_prep: '',
					teach_resource_degree: '',
					prep_degree: ''
				},
				rules: {
					name: [
						{ required: true, message: '请输入真实姓名', trigger: 'blur' },
						{ min: 2, max: 6, message: '长度在 2 到 6 个字符', trigger: 'blur' }
					],
					sex: [
						{ required: true, message: '请选择性别', trigger: 'change' }
					],
					school: [
						{ required: true, message: '请选择学校', trigger: 'change' }
					],
					college: [
						{ required: true, message: '请选择学院', trigger: 'change' }
					],
					job: [
						{ required: true, message: '请选择职称', trigger: 'change' }
					],
					position: [
						{ required: true, message: '请输入职务', trigger: 'blur' },
						{ min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
					],
					email: [
						{ required: true, message: '请输入电子邮箱', trigger: 'blur' },
						{ validator: validateEmail, trigger: 'blur' }
					],
					students_count: [
						{ required: true, message: '请输入学生人数', trigger: 'blur' },
						{type: 'integer', message:'请输入正确的学生人数', trigger: ['blur', 'change'], transform: (value) => Number(value)}
					],
					major: [
						{ required: true, message: '请选择授课专业', trigger: 'change' }
					],
					course: [
						{ required: true, message: '请选择课程名称', trigger: 'change' }
					],
					education_level: [
						{ required: true, message: '请选择学生层次', trigger: 'change' }
					],
					term: [
						{ required: true, message: '请选择学期', trigger: 'change' }
					],
					current_prep: [
						{ required: true, message: '请输入现用教材名及出版社', trigger: 'blur' },
						{ min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
					],
					teach_resource_degree: [
						{ required: true, message: '请选择教辅满意度', trigger: 'change' }
					],
					prep_degree: [
						{ required: true, message: '请选择教材满意度', trigger: 'change' }
					]
				},
				showAlert:false
			};
		},

		created() {
			//this.getUserState();
			this.getSchoolList();
			this.getMajorList();
		},
		methods: {
			getUserState(){
				if(localStorage.getItem("userLogInInfo")){
					var that = this;
					var url = apiUrl.teacher_state_get;
					this.Ajax(url, {}, (data) => {
						//that.reg_state = data;
						that.reg_state = 1;
						//console.log(that.reg_state)
					});
				}else{
					window.top.location.href = "/login";
				}
			},
			backClick(){
				this.$router.go(-1);
			},
			getSchoolList() {
				var that = this;
				var url = apiUrl.school_list_get;
				this.Ajax(url, { level: 3 }, (data) => {
					that.school_list = data;
				});
			},
			getMajorList(){
				var that = this;
				var url = apiUrl.teaching_category_list_get_by_level;
				this.Ajax(url, { level: 2 }, (data) => {
					that.major_list = data;
				});
			},
			major1Changed(){
				var that = this;
				that.field1.course = "";
				that.course_list1 = [];
				var url = apiUrl.teaching_category_list_get;
				this.Ajax(url, { parent_num: that.field1.major }, (data) => {
					that.course_list1 = data;
				});
			},
			major2Changed(){
				var that = this;
				that.field2.course = "";
				that.course_list2 = [];
				var url = apiUrl.teaching_category_list_get;
				this.Ajax(url, { parent_num: that.field2.major }, (data) => {
					that.course_list2 = data;
				});
			},
			schoolChanged() {
				var that = this;
				that.form.college = "";
				that.college_list = [];
				var url = apiUrl.school_list_get;
				this.Ajax(url, { parent_num: that.form.school }, (data) => {
					that.college_list = data;
				});
			},
			fieldClick() {
				this.field2DisplayFlag = !this.field2DisplayFlag;
			},
			onSubmit() {

				var that = this;
				const form_list = [];
				const form = new Promise((resolve, reject) => {
					this.$refs.form.validate(valid => {
						if (valid) {
							resolve();
						}
					});
				});
				form_list.push(form);
				
				const field1 = new Promise((resolve, reject) => {
					this.$refs.field1.validate(valid => {
						if (valid) {
							resolve();
						}
					});
				});
				form_list.push(field1);
				
				if(this.field2DisplayFlag){
					const field2 = new Promise((resolve, reject) => {
						this.$refs.field2.validate(valid => {
							if (valid) {
								resolve();
							}
						});
					});
					form_list.push(field2);
				}
				
				if(this.fileList.length == 0){
					this.showAlert = true;
					return;
				}else{
					this.showAlert = false;
				}
				
				
				Promise.all(form_list).then(() => {
					var url = "";
					// 上传文件
					if (this.$refs.upload.uploadFiles.length > 0) {
						url = apiUrl.teaching_prove_upload;
						this.fileList.forEach((file) => {
							// 用FormData存放上传文件
							const formData = new FormData();
							formData.append("files", file.raw);
							var token = "";
							var userInfo = JSON.parse(localStorage.getItem("userLogInInfo"));
							if (userInfo && userInfo.token != '') {
							    token = userInfo.token;
							}
							formData.append("token", token);
							
							this.Ajax(url, formData, (data) => {
							});
						});
					}
					
					//验证成功
					// 提交主讲课程1
					url = apiUrl.teaching_field_add;
					var param1 = {
						major: that.field1.major,
						course: that.field1.course,
						education_level: that.field1.education_level,
						students_count: that.field1.students_count,
						term: that.field1.term,
						current_prep: that.field1.current_prep,
						teach_resource_degree: that.field1.teach_resource_degree,
						prep_degree: that.field1.prep_degree
					}
					this.Ajax(url, param1, (data) => {
					});
					
					// 提交主讲课程2
					if (that.field2DisplayFlag){
						var param2 = {
							major: that.field2.major,
							course: that.field2.course,
							education_level: that.field2.education_level,
							students_count: that.field2.students_count,
							term: that.field2.term,
							current_prep: that.field2.current_prep,
							teach_resource_degree: that.field2.teach_resource_degree,
							prep_degree: that.field2.prep_degree
						}
						this.Ajax(url, param2, (data) => {
						});
					}
					
					// 提交
					url = apiUrl.teacher_reg;
					var param = {
						name: that.form.name,
						sex: that.form.sex,
						school: that.form.school,
						college: that.form.college,
						job_title: that.form.job,
						position: that.form.position,
						email: that.form.email
					}
					this.Ajax(url, param, (data) => {
						that.reg_state = 3;
					});
				}).catch((error) => {
					//验证失败
					console.log('error submit!!');
					console.log(error);
					return false;
				})
			},
			handleRemove(file, fileList)  {
				this.fileList = fileList;
			   console.log(file, fileList)
			},
			
			handlePreview(uploadFile)  {
				console.log(uploadFile);
			},
			
			handleExceed(files, uploadFiles)  {
				this.$message.error("最多上传3个图片");
			},
			
			beforeRemove(uploadFile, uploadFiles)  {
			  
			},
			handlChange(file, fileList) {
				this.fileList = fileList;
				console.log(this.fileList);
				if(fileList.length!=0){
					this.showAlert = false;
				}else{
					this.showAlert = true;
				}
			},
			closeAlert(){
				this.showAlert = false;
			}
		}
	}
</script>

<style lang="scss" scoped>

	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		
		/deep/.teacher_reg_container{
			
			.el-button--primary {
				background-color: #3CD0BA;
				border-color: #3CD0BA;
			}
			
			.el-button--primary:hover,
			.el-button--primary:focus {
				background-color: #3CD0BA;
				border-color: #3CD0BA;
			}
			
			.el-form-item__label{
				 width: 100px !important;
				 height: 40px;
				 line-height: 1.2;
				 display:  inline-flex !important;
				 justify-content: flex-end;
				 align-items: center;
	
			}
		}
		
		
	}
</style>