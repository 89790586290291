<template>
	<div id="container" class="page-main">
		
		<div class="body-main">
			
			<!-- 数字教研室 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;z-index: 3;">
				<div style="display: flex; margin: 40px 0 20px 0;">
					<div class="" style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);"></div>
					<div style="display: flex;flex-direction: column; line-height: 1;">
						<div style="font-size: 28px; color: #333;">{{detail.title}}</div>
						<div style="font-size: 17px; color: #999; margin-top: 10px;">实体书配套数字资源</div>
					</div>
				</div>
			</div>
			
			<div style="width: 1200px; display: flex; flex-direction: column; margin: 30px auto;">
				<div style=" width: 100%; height: 479px; display: flex;background-color: #000; border: 1px solid #c0c5cc; overflow-y: hidden;">
					<div style="flex: 1; max-width: 850px;display: flex; height: 100%; ">
						<div id="mse" v-show="selected && selected.res_type && selected.res_type ==2">
							<!-- 幻灯片图片区 -->
						</div>
						
						<!-- pdf浏览器 -->
						<div style=" display: block; width: 100%; height: 479px; overflow: hidden; position: relative;" v-if="selected.res_type ==6 && selected.res_url">
							<iframe :src="selected.res_url" scrolling="auto" frameborder="0" width="100%" height="100%" align="center"></iframe>
							
							<div @click="fullscreenClick" style="position: absolute; width: 30px; height: 30px; border-radius: 50%; background-color: #2C2E30; bottom: 30px; right: 30px;display: flex; flex-direction: column;justify-content: center;align-items: center; cursor: pointer;">
								<i class="el-icon-full-screen" style="color: #ccc; font-weight: 600;"></i>
							</div>
						</div>
						
						<el-scrollbar v-show="selected && selected.res_type && selected.res_type !=2 && selected.res_type !=6" style="width: 100%;">
							<div class="ppt_container" v-show="selected.res_type ==1 && selected.img_list">
								<div style="width: 100%; padding: 15px; box-sizing: border-box; ">
									<el-image v-for="(item,index) in selected.img_list" :key="index" v-if="item.img_url" :src="item.img_url"
										:preview-src-list="selected.img_arr" lazy></el-image>
								</div>
								
							</div>
							
							<div class="teach_plan teach_plan_f" v-show="selected.res_type ==3" style="background-color: #f1f1f1; border: 1px solid #ccc;padding:15px;">
								<div style="padding:25px 35px; line-height: 2; box-sizing: border-box; user-select: all !important; background-color: #f1f1f1;" v-html="selected.html"></div>
								
							</div>
							
							<div id="question_container1" class="question_container" v-show="selected.res_type ==4 && selected.list">
								<div style=" width: 100%; padding:25px 35px; line-height: 2; box-sizing: border-box; user-select: all !important; background-color: #f1f1f1;">
									<div v-for="(item,index) in selected.list" style="margin-bottom: 35px;">
										<h3>{{item.type_title}}</h3>
										<div v-for="(tem,dex) in item.list" style="margin-bottom: 15px;">
											<div v-if="tem.title && item.list.length==1" v-html="tem.title" style="margin-bottom: 5px;"></div>
											<div v-if="tem.title && item.list.length>1" style="margin-bottom: 5px;display: flex;flex-direction: row; position: relative; justify-content: flex-start; align-items: flex-start;">
												<div style="position: absolute;">{{dex+1}}. </div>
												<div v-html="tem.title" style="text-indent: 1em;" v-if="dex<9 && (tem.type_id ==1 || tem.type_id ==2 || tem.type_id ==3)"></div>
												<div v-html="tem.title" style="text-indent: 1.5em;" v-if="dex>8 && (tem.type_id ==1 || tem.type_id ==2 || tem.type_id ==3)"></div>
												<div v-html="tem.title" class="text-indent_1" v-if="dex<9 && (tem.type_id !=1 && tem.type_id !=2 && tem.type_id !=3)"></div>
												<div v-html="tem.title" class="text-indent_15"  v-if="dex>8 && (tem.type_id !=1 && tem.type_id !=2 && tem.type_id !=3)"></div>
											</div>
											<div v-if="item.type_id ==1 || item.type_id ==2" style="display: flex;flex-direction: column;">
												<div v-if="tem.option_a" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">A.</span><span v-html="tem.option_a"></span></div>
												<div v-if="tem.option_b" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">B.</span><span v-html="tem.option_b"></span></div>
												<div v-if="tem.option_c" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">C.</span><span v-html="tem.option_c"></span></div>
												<div v-if="tem.option_d" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">D.</span><span v-html="tem.option_d"></span></div>
												<div v-if="tem.option_e" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">E.</span><span v-html="tem.option_e"></span></div>
												<div v-if="tem.option_f" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">F.</span><span v-html="tem.option_f"></span></div>
												<div v-if="tem.option_g" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">G.</span><span v-html="tem.option_g"></span></div>
												<div v-if="tem.option_h" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">H.</span><span v-html="tem.option_h"></span></div>
											</div>
											<div v-if="tem.answer && (tem.type_id ==1 || tem.type_id ==2 || tem.type_id ==3)">
												答案：{{tem.answer =="√"?"正确":tem.answer=="×"?"错误":tem.answer}}
											</div>
											<div v-if="tem.answer && (tem.type_id !=1 && tem.type_id !=2 && tem.type_id !=3)" style="display: flex;flex-direction: row; position: relative; justify-content: flex-start; align-items: flex-start;">
												<div style="position: absolute;">答案：</div>
												<div class="text-indent_3" v-html="tem.answer" ></div>
											</div>
											
											<div v-if="tem.answer_key" style="display: flex;flex-direction: row; position: relative;justify-content: flex-start; align-items: flex-start;">
												<div style="position: absolute;">答案解析：</div>
												<div v-html="tem.answer_key" style="text-indent: 5em;"></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							
							<div @click="fullscreenClick" style="position: absolute; width: 30px; height: 30px; border-radius: 50%; background-color: #2C2E30; bottom: 30px; right: 30px;display: flex; flex-direction: column;justify-content: center;align-items: center; cursor: pointer;">
								<i class="el-icon-full-screen" style="color: #ccc; font-weight: 600;"></i>
							</div>

						</el-scrollbar>
						
					
						
						
					</div>
					<div style="width: 350px; height: 100%;background-color: #1B2128;">
						<div style="width: 100%; height: 460px; line-height: 36px; padding: 15px; box-sizing: border-box; background-color: #1B2128; color: #a1a9b2; overflow-x: hidden; overflow-y: auto;">
							<div style="font-size: 16px;" >授课视频</div>
							<div style="font-size: 14px;padding-left: 1em; box-sizing: border-box;">
								<!-- <div v-if="videoList && videoList.length>0" @click="getVideo(item,false)" v-for="(item,index) in videoList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-video-play"></i> {{index+1}}.{{item.title}}
								</div> -->
								
								<div v-if="office_id=='ab52f9cd88084cbb81164a882e2d0e6e' && pptList && pptList.length>0" v-for="(item,index) in pptList" style="line-height: 36px; ">
									<div style="height: 35px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;" v-if="findVideoList(item.id) && findVideoList(item.id).length>0">{{item.title}}</div>
									<div @click="getVideo(tem,false)"  style=" cursor: pointer; height: 35px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;" v-for="(tem,dex) in findVideoList(item.id)">
										<i class="el-icon-video-play"></i> {{tem.title}}
									</div>
								</div>
								
								<div v-if="office_id!='ab52f9cd88084cbb81164a882e2d0e6e' && videoList && videoList.length>0" @click="getVideo(item,false)" v-for="(item,index) in videoList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-video-play"></i> {{index+1}}.{{item.title}}
								</div>
								
								<div v-if="videoList2 && videoList2.length>0" @click="getRes(item,false)" v-for="(item,index) in videoList2" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-video-play"></i> {{index+1}}.{{item.title}}
								</div>
							</div>
							
							<div style="font-size: 16px;">学习资源</div>
							<div @click="targetLink(kgs)" v-if="kgs" style="font-size: 14px;padding-left: 1em;cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
								<i class="el-icon-link"></i> 知识图谱
							</div>
							
							<div @click="getRes(mindmap2,false)" v-if="mindmap2" style="font-size: 14px;padding-left: 1em;cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
								<i class="el-icon-document"></i> 教学思维导图
							</div>
							
							<div @click="getPlan(false)" v-if="detail && detail.teach_plan"  style="font-size: 14px;padding-left: 1em;cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
								<i class="el-icon-document"></i> 教学大纲
							</div>
							<div @click="getRes(design2,false)" v-if="design2" style="font-size: 14px;padding-left: 1em;cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
								<i class="el-icon-document"></i> 教学大纲
							</div>
							
							<div style="font-size: 16px;padding-left: 1em;">课件展示</div>
							<div style="font-size: 14px;padding-left: 1.7em; box-sizing: border-box;">
								<div v-if="pptList && pptList.length>0" @click="getPPT(item,false)" v-for="(item,index) in pptList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-picture-outline"></i> {{index+1}}.{{item.title}}
								</div>
								<div v-if="pptList2 && pptList2.length>0" @click="getRes(item,false)" v-for="(item,index) in pptList2" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-document"></i> {{index+1}}.{{item.title}}
								</div>
							</div>
							
							<div style="font-size: 16px;padding-left: 1em;" v-if="designList  && designList.length>0">教学设计</div>
							<div style="font-size: 14px;padding-left: 1.7em; box-sizing: border-box;" v-if="designList && designList.length>0">
								<div @click="getDesign(item,false)" v-for="(item,index) in designList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-picture-outline"></i> {{index+1}}.{{item.title}}
								</div>
							</div>
							
							<div style="font-size: 16px;padding-left: 1em;" v-if="sizhengList2 && sizhengList2.length>0">思政</div>
							<div style="font-size: 14px;padding-left: 1.7em; box-sizing: border-box;" v-if="sizhengList2 && sizhengList2.length>0">
								<div @click="getRes(item,false)" v-for="(item,index) in sizhengList2" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-document"></i> {{index+1}}.{{item.title}}
								</div>
							</div>
							
							<div style="font-size: 16px;padding-left: 1em;">题库</div>
							<div style="font-size: 14px;padding-left: 1.7em; box-sizing: border-box;">
								<div v-if="questionsList && questionsList.length>0"  @click="getQuestions(item,false)" v-for="(item,index) in questionsList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-document"></i> {{index+1}}.{{item.title}}
								</div>
								<div v-if="questionsList2 && questionsList2.length>0" @click="getRes(item,false)" v-for="(item,index) in questionsList2" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-document"></i> {{index+1}}.{{item.title}}
								</div>
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="course_tab_main" style="margin-top: 50px;">
					<div class="course_tab_items">
						<div class="course_tab_item" @click="changeTab(1)" :class="{'actived':tab_index == 1}">
							简介
						</div>
						<div class="course_tab_item" @click="changeTab(2)" :class="{'actived':tab_index == 2}">
							目录
						</div>
					</div>
					<div class="course_tab_conts">
						<div v-if="tab_index == 1 && detail" class="course_tab_cont" >
							<div style="padding: 15px 0;display: flex; flex-direction: column;min-height: 500px;">
								<div v-html="detail.summary" style="margin-bottom: 15px;"></div>
								<div>【书名】：{{detail.title}}</div>
								<div>【作者】：{{detail.author}}</div>
								<div>【纸书定价】：{{detail.paperprice}}</div>
								<div>【ISBN】：{{detail.isbn}}</div>
								<div>【出版年月】：{{detail.publish_at}}</div>
							</div>
						</div>
						<div v-if="tab_index == 2" class="course_tab_cont">
							<div style="width: 100%; line-height: 36px; padding: 15px 0; overflow-x: hidden; overflow-y: auto;">
								<div>授课视频</div>
								<div style="padding-left: 1em; box-sizing: border-box;">
									
									
									<div v-if="office_id=='ab52f9cd88084cbb81164a882e2d0e6e' && pptList && pptList.length>0" v-for="(item,index) in pptList" style="line-height: 36px; ">
										<div style="height: 35px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;" v-if="findVideoList(item.id) && findVideoList(item.id).length>0">{{item.title}}</div>
										<div @click="getVideo(tem,true)"  style=" cursor: pointer; height: 35px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;" v-for="(tem,dex) in findVideoList(item.id)">
											<i class="el-icon-video-play"></i> {{tem.title}}
										</div>
									</div>
			
									<div v-if="office_id!='ab52f9cd88084cbb81164a882e2d0e6e' && videoList && videoList.length>0" @click="getVideo(item,true)" v-for="(item,index) in videoList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
										<i class="el-icon-video-play"></i> {{index+1}}.{{item.title}}
									</div>
									
									<div v-if="videoList2 && videoList2.length>0" @click="getRes(item,true)" v-for="(item,index) in videoList2" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
										<i class="el-icon-video-play"></i> {{index+1}}.{{item.title}}
									</div>
								</div>
								<div>学习资源</div>
								
								<div @click="targetLink(kgs)" v-if="kgs" style="padding-left: 1em;cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-link"></i> 知识图谱
								</div>
								
								<div @click="getRes(mindmap2,true)" v-if="mindmap2" style="padding-left: 1em;cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-document"></i> 教学思维导图
								</div>
								
								<div @click="getPlan(true)" v-if="detail && detail.teach_plan" style="padding-left: 1em;cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-document"></i> 教学大纲
								</div>
								<div @click="getRes(design2,true)" v-if="design2" style="padding-left: 1em;cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
									<i class="el-icon-document"></i> 教学大纲
								</div>
								
								<div style="padding-left: 1em;">课件展示</div>
								<div style="padding-left: 1.7em; box-sizing: border-box;">
									<div v-if="pptList  && pptList.length>0" @click="getPPT(item,true)" v-for="(item,index) in pptList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
										<i class="el-icon-picture-outline"></i> {{index+1}}.{{item.title}}
									</div>
									<div v-if="pptList2  && pptList2.length>0" @click="getRes(item,true)" v-for="(item,index) in pptList2" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
										<i class="el-icon-picture-outline"></i> {{index+1}}.{{item.title}}
									</div>
								</div>
								
								<div style="padding-left: 1em;" v-if="sizhengList2 && sizhengList2.length>0">思政</div>
								<div style="padding-left: 1.7em; box-sizing: border-box;" v-if="sizhengList2 && sizhengList2.length>0">
									<div @click="getRes(item,true)" v-for="(item,index) in sizhengList2" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
										<i class="el-icon-document"></i> {{index+1}}.{{item.title}}
									</div>
								</div>
								
								<div style="padding-left: 1em;" v-if="designList && designList.length>0">教学设计</div>
								<div style="padding-left: 1.7em; box-sizing: border-box;" v-if="designList && designList.length>0">
									<div @click="getDesign(item,true)" v-for="(item,index) in designList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
										<i class="el-icon-picture-outline"></i> {{index+1}}.{{item.title}}
									</div>
								</div>
								
								
								<div style="padding-left: 1em;">题库</div>
								<div style="padding-left: 1.7em; box-sizing: border-box;">
									<div v-if="questionsList && questionsList.length>0" @click="getQuestions(item,true)" v-for="(item,index) in questionsList" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
										<i class="el-icon-document"></i> {{index+1}}.{{item.title}}
									</div>
									<div v-if="questionsList2 && questionsList2.length>0" @click="getRes(item,true)" v-for="(item,index) in questionsList2" style=" cursor: pointer; height: 36px; line-height: 36px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">
										<i class="el-icon-document"></i> {{index+1}}.{{item.title}}
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			
			</div>
			
		</div>
		
		
		
		<!-- 全屏 -->
		<el-dialog v-if="show" :visible.sync="show" destroy-on-close :fullscreen="true">
	
			<!-- pdf浏览器 -->
			<div class="full-height-body" style=" display: block; width: 100%; height:100%;overflow: hidden; " v-if="selected.res_type ==6 && selected.res_url">
				<iframe :src="selected.res_url" scrolling="auto" frameborder="0" width="100%" height="100%" align="center"></iframe>
			</div>
		
			<el-scrollbar class="full-height-body" v-if="selected && selected.res_type && selected.res_type!=6">
				<div class="teach_plan" v-if="selected.res_type ==3">
					<div style="max-width: 1024px;margin:0 auto ; padding:25px 35px; line-height: 2; box-sizing: border-box; user-select: all !important; background-color: #f1f1f1;" v-html="selected.html"></div>
				</div>
				<div class="ppt_container" v-if=" selected.res_type ==1 && selected.img_list">
					<div style="max-width: 1024px;margin:0 auto ; padding: 15px; box-sizing: border-box; user-select: all !important; ">
						<el-image v-for="(item,index) in selected.img_list" :key="index" v-if="item.img_url" :src="item.img_url" :preview-src-list="selected.img_arr" lazy></el-image>
					</div>
				</div>
				<div id="question_container2"  class="question_container" v-show="selected.res_type ==4 && selected.list">
					<div style=" width: 1024px; margin:0 auto ;padding:25px 35px; line-height: 2; box-sizing: border-box; user-select: all !important; background-color: #fff;">
						<div v-for="(item,index) in selected.list" style="margin-bottom: 35px;">
							<h3>{{item.type_title}}</h3>
							<div v-for="(tem,dex) in item.list" style="margin-bottom: 15px;">
								<div v-if="tem.title && item.list.length==1" v-html="tem.title" style="margin-bottom: 5px;"></div>
								<div v-if="tem.title && item.list.length>1" style="margin-bottom: 5px;display: flex;flex-direction: row; position: relative; justify-content: flex-start; align-items: flex-start;">
									<div style="position: absolute;">{{dex+1}}. </div>
									<div v-html="tem.title" style="text-indent: 1em;" v-if="dex<9 && (tem.type_id ==1 || tem.type_id ==2 || tem.type_id ==3)"></div>
									<div v-html="tem.title" style="text-indent: 1.5em;" v-if="dex>8 && (tem.type_id ==1 || tem.type_id ==2 || tem.type_id ==3)"></div>
									<div v-html="tem.title" class="text-indent_1" v-if="dex<9 && (tem.type_id !=1 && tem.type_id !=2 && tem.type_id !=3)"></div>
									<div v-html="tem.title" class="text-indent_15"  v-if="dex>8 && (tem.type_id !=1 && tem.type_id !=2 && tem.type_id !=3)"></div>
								</div>
								<div v-if="item.type_id ==1 || item.type_id ==2" style="display: flex;flex-direction: column;">
									<div v-if="tem.option_a" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">A.</span><span v-html="tem.option_a"></span></div>
									<div v-if="tem.option_b" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">B.</span><span v-html="tem.option_b"></span></div>
									<div v-if="tem.option_c" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">C.</span><span v-html="tem.option_c"></span></div>
									<div v-if="tem.option_d" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">D.</span><span v-html="tem.option_d"></span></div>
									<div v-if="tem.option_e" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">E.</span><span v-html="tem.option_e"></span></div>
									<div v-if="tem.option_f" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">F.</span><span v-html="tem.option_f"></span></div>
									<div v-if="tem.option_g" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">G.</span><span v-html="tem.option_g"></span></div>
									<div v-if="tem.option_h" style="display: flex;align-items: center;"><span style="margin-right: 0.5em;">H.</span><span v-html="tem.option_h"></span></div>
								</div>
								<div v-if="tem.answer && (tem.type_id ==1 || tem.type_id ==2 || tem.type_id ==3)">
									答案：{{tem.answer =="√"?"正确":tem.answer=="×"?"错误":tem.answer}}
								</div>
								<div v-if="tem.answer && (tem.type_id !=1 && tem.type_id !=2 && tem.type_id !=3)" style="display: flex;flex-direction: row; position: relative; justify-content: flex-start; align-items: flex-start;">
									<div style="position: absolute;">答案：</div>
									<div class="text-indent_3" v-html="tem.answer" ></div>
								</div>
								
								<div v-if="tem.answer_key" style="display: flex;flex-direction: row; position: relative;justify-content: flex-start; align-items: flex-start;">
									<div style="position: absolute;">答案解析：</div>
									<div v-html="tem.answer_key" style="text-indent: 5em;"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-scrollbar>
		</el-dialog>
		
		

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import Player from 'xgplayer';
	import 'xgplayer/dist/index.min.css';
	import { Scrollbar } from 'element-ui';


	export default {
		data() {
			return {
				office_id: '',
				detail: { title:'数字资源'},
				search: {
					office_id: '',
					page_index: 1,
					page_size: 3000,
				},
				videoList: [],
				pptList: [],
				designList:[],
				questionsList:[],
				kgs:null,
				videoList2: [],
				pptList2: [],
				sizhengList2:[],
				questionsList2:[],
				design2:null,
				mindmap2:null,
				selected: { res_type: 0 },
				player: null,
				show: false,
				tab_index: 1,
			};
		},
		
		created() {
			
			document.title = "数字资源 - 机工教师特训营";
			var option = {
				id: this.$route.query.id || "",
			};
			if (option.id) {
				this.office_id = option.id;
				this.search.office_id = this.office_id;
				var that = this;
				this.getDetail(()=>{
					if(this.detail.template_type == 1){
						that.getQuestionsList();
						that.getVideoList();
						that.getPPTList();
						that.getDesignList();
					}else{
						that.getVideoList2();
						that.getMindMap2();
						that.getDesign2();
						that.getPPTList2();
						that.getSiZhengList2();
						that.getQuestionsList2();
					}
					this.getKnowledgeGraphList();
					
				});
				
				
			}
		},
		components: {
		    'el-scrollbar': Scrollbar
		},
		methods: {
			play() {
				var that = this;
				if (!that.player) {
					var player = new Player({
						"el": document.getElementById('mse'),
						"url": that.selected.video_url,
						'fluid': true,
						'volume': 0.3,
						'videoInit': true,
						'playsinline': true,
						'playbackRate': [0.5, 0.75, 1, 1.5, 2],
						'defaultPlaybackRate': 1,
						'x5-video-player-type': 'h5',
						'x5-video-orientation': 'portraint',
						"lang": 'zh-cn',
						'commonStyle': {
							'playedColor': '#C43D31',
							'volumeColor': '#C43D31'
						}
					});
					that.player = player;
				} else {
					that.player.src = that.selected.video_url;
					that.player.autoplay = false;
				}
			},
			destroyPlayer(){
				var that = this;
				that.$nextTick(() => {
					if(that.player){
						that.player.destroy();
						that.player = null;
					}
				})
			},
			scrollToTop(){
				const element = document.getElementById('container');
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			},
			getVideoList() {
				var that = this;
				var url = apiUrl.digital_office_record_list_get;
				this.Ajax(url, this.search, (data) => {
					that.videoList = data;
					that.getVideo(data[0]);
				});
			},
			findVideoList(id){
				return this.videoList.filter(item => item.chapter_id == id);
			},
			getVideo(item,isSrcoll) {
				var that = this;
				var url = apiUrl.digital_office_record_detail_get;
				this.Ajax(url, {
					id: item.id
				}, (data) => {
					that.selected = {
						title: data.title,
						video_url: data.video_url,
						res_type: 2
					};
					if(isSrcoll){
						that.scrollToTop();
					}
					that.$nextTick(() => {
						that.play();
					})
					
				});
			},
			getPPTList() {
				var that = this;
				var url = apiUrl.tt_digital_office_chapter_list_get;
				this.Ajax(url, {
					id: this.office_id
				}, (data) => {
					that.pptList = data;
				});
			},
			getPPT(item,isSrcoll) {
				var that = this;
				var url = apiUrl.tt_digital_office_ppt_list_get;
				this.Ajax(url, {
					id: this.office_id,
					chapter_id: item.id,
					next_chapter_id: item.next_id
				}, (data) => {
					var arr = [];
					data.forEach((item, index) => {
						arr.push(item.img_url)
					})
					that.selected = {
						title: '',
						img_list: data,
						img_arr: arr,
						res_type: 1
					};

					that.destroyPlayer();
					if(isSrcoll){
						that.scrollToTop();
					}
				});
			},
			getDetail(callback) {
				var that = this;
				var url = apiUrl.tt_digital_office_detail_get;
				this.Ajax(url, {
					id: this.office_id
				}, (data) => {
					that.detail = data;
					if(callback) callback();
				});
			},
			getPlan(isSrcoll){
				var that = this;
				that.destroyPlayer();
				that.selected = {
					title: '',
					html: that.detail.teach_plan,
					res_type: 3
				};
				if(isSrcoll){
					that.scrollToTop();
				}
			},
			getDesignList() {
				var that = this;
				var url = apiUrl.tt_digital_office_design_list_get;
				this.Ajax(url, {
					id: this.office_id
				}, (data) => {
					that.designList = data;
				});
			},
			getDesign(item,isSrcoll) {
				var that = this;
				var url = apiUrl.tt_digital_office_design_detail_list_get;
				this.Ajax(url, {
					design_id: item.id,
				}, (data) => {
					var arr = [];
					data.forEach((item, index) => {
						arr.push(item.img_url)
					})
					that.selected = {
						title: '',
						img_list: data,
						img_arr: arr,
						res_type: 1
					};
			
					that.destroyPlayer();
					if(isSrcoll){
						that.scrollToTop();
					}
				});
			},
			getQuestionsList(){
				var that = this;
				var url = apiUrl.tt_q_category_list_get;
				this.Ajax(url, {
					parent_category_id: this.detail.practise_category_id
				}, (data) => {
					that.questionsList = data;
					that.commonVariable.Header();
				});
			},
			getQuestions(item,isSrcoll){
				var that = this;
				var url = apiUrl.tt_q_practise_list_by_category;
				this.Ajax(url, {
					category_id: item.id,
				}, (data) => {
					that.selected = {
						title: '',
						list: data,
						res_type: 4
					};
					that.$nextTick(()=> {
						if (!that.commonVariable.isMathjaxConfig) {
							that.commonVariable.initMathjaxConfig();
						}
						that.commonVariable.MathQueue('question_container1');
					});
					that.destroyPlayer();
					if(isSrcoll){
						that.scrollToTop();
					}
				});
			},
			getKnowledgeGraphList(){
				var that = this;
				var url = apiUrl.tt_digital_office_ext_list_get;
				this.Ajax(url, {
					office_id: this.office_id,
					menu_id: 11
				}, (data) => {
					that.kgs = data[0];
				});
			},
			targetLink(item){
				var that = this;
				var url = apiUrl.digital_office_ext_detail_get;
				this.Ajax(url, {
					id: item.id
				}, (data) => {
					this.$confirm('将打开新的页面访问您查看的内容, 是否继续?', '提示', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
						
					  window.open(data.res_url,'_bank');
					  
					}).catch(() => {
						
					  this.$message({
						type: 'info',
						message: '已取消'
					  }); 
					  
					});
				});
			},
			getSiZhengList2(){
				var that = this;
				var url = apiUrl.tt_digital_office_ext_list_get;
				this.Ajax(url, {
					office_id: this.office_id,
					menu_id: 3
				}, (data) => {
					that.sizhengList2 = data;
				});
			},
			getPPTList2(){
				var that = this;
				var url = apiUrl.tt_digital_office_ext_list_get;
				this.Ajax(url, {
					office_id: this.office_id,
					menu_id: 4
				}, (data) => {
					that.pptList2 = data;
				});
			},
			getQuestionsList2(){
				var that = this;
				var url = apiUrl.tt_digital_office_ext_list_get;
				this.Ajax(url, {
					office_id: this.office_id,
					menu_id: 6
				}, (data) => {
					that.questionsList2 = data;
				});
			},
			getDesign2(){
				var that = this;
				var url = apiUrl.tt_digital_office_ext_list_get;
				this.Ajax(url, {
					office_id: this.office_id,
					menu_id: 2
				}, (data) => {
					that.design2 = data[0];
				});
			},
			getMindMap2(){
				var that = this;
				var url = apiUrl.tt_digital_office_ext_list_get;
				this.Ajax(url, {
					office_id: this.office_id,
					menu_id: 12
				}, (data) => {
					that.mindmap2 = data[0];
				});
			},
			getVideoList2() {
				var that = this;
				var url = apiUrl.tt_digital_office_ext_list_get;
				this.Ajax(url, {
					office_id: this.office_id,
					menu_id: 5
				}, (data) => {
					that.videoList2 = data;
					that.getRes(data[0]);
				});
			},
			getRes(item,isSrcoll){
				var that = this;
				var url = apiUrl.digital_office_ext_detail_get;
				this.Ajax(url, {
					id: item.id
				}, (data) => {
					if(data.res_type == 6){
						that.selected = {
							title: '',
							res_url: `/pdf.js/4.7.76/web/viewer.html?file=${ encodeURIComponent(data.res_url) }`,
							res_type: data.res_type
						};
					}else if(data.res_type == 2){
						that.selected = {
							title: '',
							video_url: data.res_url,
							res_type: data.res_type
						};
					}
					
					
					if(isSrcoll){
						that.scrollToTop();
					}
					that.destroyPlayer();
					if(that.selected.res_type == 2){
						that.$nextTick(() => {
							that.play();
						})
					}
				});
			},
			fullscreenClick(){
				var that = this;
				
				that.$nextTick(() => {
					that.show = true;
					if(that.selected.res_type ==4){
						setTimeout(()=>{
							if (!that.commonVariable.isMathjaxConfig) {
								that.commonVariable.initMathjaxConfig();
							}
							that.commonVariable.MathQueue('question_container2');
						},500)
						
					}
				})
			},
			changeTab(index){
				this.tab_index = index;
			}
		},
	};
</script>

<style>
	
	.question_container  {
		font-size: 17px;
	}
	
	.question_container h3 {
		font-size: 20px;
	}
	
	.question_container p{
		margin: 0 !important;
		font-size: 17px !important;
		color: #515a6e !important;
	}
	.question_container .text-indent_1 p:nth-of-type(1){
		text-indent: 1em !important;
	} 
	.question_container .text-indent_15 p:nth-of-type(1){
		text-indent: 1.5em !important;
	} 
	.question_container .text-indent_3 p:nth-of-type(1){
		text-indent: 3em !important;
	}
	
	.question_container img{
		max-width: 640px !important;
	}
	.question_container table{
		max-width: 100% !important;
		border: 1px solid #000;
		border-collapse: collapse;
	}
	.question_container tr th,
	.question_container tr td {
		border: 1px solid #000;
	}
	.teach_plan {

		background-color: #000;
		padding: 15px;
	}
	
	.teach_plan_f{
		width: 100%;
		overflow-y: auto;
	}

	.teach_plan h2 {
		font-size: 24px;
		color: #333;
	}

	.teach_plan h3 {
		font-size: 18px;
		color: #333;
	}

	.teach_plan p {
		font-size: 17px !important;
		line-height: 1.75 !important;

	}

	.teach_plan p b {
		font-size: 17px !important;
		color: #333 !important;
		font-weight: 600;
	}

	.teach_plan a {
		text-decoration: none;
		color: #333 !important;
		border-bottom: 1px solid #333 !important;
		padding-bottom: 2px;
	}

	.teach_plan table {
		width: 100% !important;
	}

	.teach_plan table p b {
		color: #333 !important;
	}

	table.desc_table {
		width: 100%;
		line-height: 1.5;
		border-collapse: collapse;
	}

	table.desc_table tr th,
	table.desc_table tr td {
		border: 1px solid #E1E6F0;
	}

	table.desc_table tr th {
		background-color: #F5F7FA;
		color: #000;
		text-align: center;
		padding: 8px 13px;
	}

	table.desc_table tr td {
		color: #666;
		padding: 30px 40px;
	}
	
	.is-fullscreen .el-scrollbar__view{
		 background-color: #000;
	}

	.is-fullscreen .el-dialog__body {
	  padding: 0;
	  height:calc(100vh - 55px) ;
	  background-color: #000;
	}
	 
	.full-height-dialog {
	  display: flex;
	  flex-direction: column;
	  justify-content: space-between; /* 如果你需要头部和尾部 */
	  height:calc(100vh - 55px) ; /* 全屏高度 */
	}
	


	.el-scrollbar__bar.is-vertical{
		opacity: 1 !important;
		width: 12px;
	}
	.el-scrollbar__bar{
		border-radius: 6px;
	}
	.el-scrollbar__thumb:hover{
		background-color: #bdbdbd;
	}
	
	.el-dialog__header{
		position: sticky !important;
		top: 0 !important;
		width: 100%;
		background-color: #fff;
		height: 55px;
		z-index: 666;
	}
	.mjx-chtml {
		font-size: 16px !important;
	}
	.MJXc-display{
		overflow-x: auto !important;
	}

	
</style>

<style lang="scss" scoped>
	
	
	
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		
		.body-main {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #fff;
			//padding: 10px 0;
			box-sizing: border-box;
			
			.course_tab_main {
				.course_tab_items {
					color: #666;
					border-bottom: 1px solid #666;
					height: 50px;
					line-height: 50px;
					font-size: 20px;
					font-weight: 600;
					display: flex;
			
					.course_tab_item {
						display: inline-flex;
						height: 50px;
						line-height: 50px;
						box-sizing: border-box;
						cursor: pointer;
			
						&:hover {
							color: #FF7A39;
						}
			
						&.actived {
							border-bottom: 3px solid #FF7A39;
						}
					}
			
					.course_tab_item+.course_tab_item {
						margin-left: 30px;
					}
			
					.course_tab_conts {
						display: flex;
						flex-direction: column;
			
						.course_tab_cont {
							width: 100%;
							display: flex;
							flex-direction: column;
							line-height: 1;
							padding: 10px 0;
						}
					}
			
			
				}
			}
		}
	

		// .ppt_container {
		// 	width: 100%;
		// 	overflow-y: auto;
			
			
		// }
		// .ppt_container,.teach_plan{
		// 	&::-webkit-scrollbar{
		// 		width: 7px;
		// 		height: 7px;
		// 	}
			
		// 	&::-webkit-scrollbar-track{
		// 		border-radius: 999px;
		// 		background-color: #a1a9b2;
		// 	}
			
		// 	&::-webkit-scrollbar-thumb{
		// 		background-clip: content-box;
		// 		border-radius: 999px;
		// 		background-color: #1B2128;
		// 	}
		// }
	}
</style>