<template>
	<div class="page-main">
		<div style="width: 100%;display: flex; flex-direction: column; align-items: center;background: #fff;">
			<div style="display: flex; flex-direction: column;width: 1200px; max-width: 1200px; box-sizing: border-box;">
				<div class="mianb">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/teachRoom/list' }">数字教研室</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/teachRoom/detail?id='+id }">{{title}}</el-breadcrumb-item>
						<el-breadcrumb-item >成员</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				
				
				<div class="amounts">
				  <div class="sums">
					共 {{ listData.count }} 人加入数字教研室
				  </div>
				  <div
					class="aothr"
					style="overflow: auto"
					v-infinite-scroll="loadMore"
					:infinite-scroll-disabled="busy"
					:infinite-scroll-immediate-check="true"
					infinite-scroll-distance="10"
				  >
					<div
					  class="tols"
					  v-for="(item, index) in listData.list"
					  :key="index"
					>
					  <div class="tolft">
						<img

						  v-lazy="item.avatar"
						  style="width: 100%; height: 100%; border-radius: 50%"
						  alt=""
						/>
					  </div>
					  <div class="tlrgt">
						<div class="tops">
						  {{ item.nickname }} 
						  <span v-if="item.type == 1">负责人</span>
						  <span v-if="item.type == 2">教材编辑</span>
						  <span v-if="item.type == 3">运营</span>
						</div>
						<div class="osds">
						  <span class="span1">{{ item.summary }}</span>
						  <span class="span2">{{ item.created_at }}</span>
						</div>
					  </div>
					</div>
				
					<div class="tols"></div>
				  </div>
				</div>
			
		
			</div>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";

	export default {
		data() {
			return {
				id: null,
				title:null,
				busy: false,
				search: {
					office_id: null,
					page_index: 1,
					page_size: 10
				},
				listData: {
					total: 0,
					count: 0,
					list: null,
				},
			};
		},

		created() {
			document.title = "成员 - 数字教研室 - 机工教师特训营";
			var option = {
				id: this.$route.query.id || "",
			};
			if (option.id) {
				this.id = option.id;
				this.search.office_id = option.id;
				this.getList();
			}
		},
		methods: {
			getList() {
				var that = this;
				var url = apiUrl.user_list;
				this.Ajax(url, this.search, (data) => {
					if (that.listData.total == 0) {
						that.listData.total = data.page_count;
						
					}
					if (that.listData.count == 0) {
						that.listData.count = data.total_count;
					}
					if(that.search.page_index == 1){
						that.listData.list =[];
						that.title = data.title;
					}
					that.listData.list = that.listData.list.concat(data.list);
				});
			},
			loadMore() {
			  var that = this;
			  if (this.busy){
				  return;
			  } 
			  if (that.search.page_index >= that.listData.total){
				  return;
				  this.busy = true;
			  } 
			  
			  that.search.page_index ++;
			  console.log(that.search.page_index)
			  this.getList();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		line-height: 1;

		.amounts {
		  width: 100%;
		  min-height: 800px;
		  border: 1px solid #fff;
		  background: #fff;
		  .sums {
		    font-size: 24px;
		    font-weight: 800;
		    color: #2b2b2b;
		    letter-spacing: 1px;
		    margin: 55px 0 0 47px;
		  }
		  .aothr {
		    width: 1047px;
		    // height: 800px;
		
		    margin: 0 auto;
		
		    margin-top: 41px;
		    display: flex;
		    justify-content: space-between;
		    flex-wrap: wrap;
		    .tols {
		      height: 86px;
		      width: 496px;
		      margin-bottom: 21px;
		      display: flex;
		      justify-content: space-between;
		      .tolft {
		        width: 68px;
		        height: 68px;
		        border-radius: 50%;
		        margin-top: 5px;
		      }
		      .tlrgt {
		        height: 100%;
		        width: 401px;
		
		        border-bottom: 1px solid #eeeeee;
		        .tops {
		          font-size: 20px;
		          font-weight: 500;
		          color: #333333;
		          display: -webkit-box;
		          overflow: hidden;
		          -webkit-box-orient: vertical;
		          text-overflow: ellipsis;
		          -webkit-line-clamp: 1;
		          // margin-bottom: 6px;
				  display: flex;
				  align-items: flex-end;
				  
				  span{
					  display: inline-block;
					  padding: 4px 8px;
					  background-color: #eeeeee;
					  background: linear-gradient(to right,#63E2D2,#35A798);
					  color:#fff;
					  border-radius: 5px;
					  font-size: 12px;
					  margin-left: 6px;
					  font-weight: 600;
				  }
		        }
		        .osds {
		          height: 27px;
		          width: 100%;
		          display: flex;
		          justify-content: space-between;
		          .span1 {
		            font-size: 16px;
		            font-weight: 500;
		            color: #999999;
		            line-height: 27px;
		          }
		          .span2 {
		            font-size: 16px;
		            font-weight: 500;
		            color: #999999;
		            line-height: 27px;
		          }
		        }
		      }
		    }
		  }
		}
	}
</style>