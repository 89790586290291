<template>
	<div class="page-main">
		<div style="width: 100%;display: flex; flex-direction: column; align-items: center;background: #fff;">
			<div style="display: flex; flex-direction: column;width: 1200px; max-width: 1200px; box-sizing: border-box;">
				<div class="mianb">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">教材教辅</el-breadcrumb-item>
						<el-breadcrumb-item>{{search.name}}</el-breadcrumb-item>
						
					</el-breadcrumb>
				</div>
				<div style="text-align: center;padding: 50px;" class="noContentBg" v-if="!productData.total || productData.total == 0">
				  <img class="placeImg" src="../../assets/image/search_place.png" alt="" />
				</div>
				<div style=" width: 100%; display: flex; flex-direction: column; min-height: 80vh;  "  v-if="productData.total > 0">
					<div style="display: flex;flex-direction: row; flex-wrap: wrap;">
						<div class="book_item" style="cursor: pointer;
							width: 580px;
						height: 300px;
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						position: relative;
						margin-bottom: 40px;
						margin-right: 40px;min-height: fit-content;" v-for="(item,index) in productData.list" @click="toDetail(item)">
								<img style="position: absolute;  left: 25px; bottom: 20px; width: 183px; height: 259px;box-shadow: 3px 5px 3px rgba(0,0,0,0.3);border: 1px solid #eee;" v-lazy="item.image" />
								<div style="width: 100%; height: 200px; background-color: #eee; display: flex;justify-content: flex-end;">
									<div style="width: 370px;display: flex;flex-direction: column; padding: 20px 35px 0 35px; box-sizing: border-box; line-height: 1;">
										<div style="font-size: 24px;font-weight: 600; line-height: 1.2;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;" v-html="item.title"></div>
										<div style="font-size: 18px;margin-top: 15px; color: #00786c; ">
											<p style="font-size: 18px; margin-bottom: 8px;line-height: 1.2;  overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">作者：<span v-html="item.writer_name"></span></p>
											<p style="font-size: 18px; margin-bottom: 8px;">出版日期：{{item.publishing_time}}</p>
											<p style="font-size: 18px;margin-bottom: 8px;">ISBN：{{item.isbn}}</p>
										</div>
									</div>
								</div>
								<div style=" width: 100%;height: 56px; display: flex; flex-direction: row;">
									<div style="flex: 1;font-size: 18px; color: #00786c; height: 56px;background-color:#3BDABC ; padding-left: 35px; box-sizing: border-box; display: flex;justify-content: flex-end;">
										<div style="width: 250px; display: flex; padding-left:35px; align-items: center;box-sizing: border-box;">
											定价：{{item.subscriber_price}}元
										</div>
									</div>
									<div style="width:120px;height: 56px; background-color: #333; font-size: 20px; font-weight: 600; color: #fff; display: flex;justify-content: center;align-items: center;">
										详情
									</div>
								</div>
							</div>
					</div>
				</div>
				<div class="book_pager"  v-if="productData.total > 0">
					<el-pagination
						@current-change="currentPage"
						:current-page.sync="search.page_index"
						:page-size="search.page_size"
						:pager-count="5"
						layout="prev, pager, next"
						:total="productData.total">
					</el-pagination>
					<div class="page_count">
						共 {{productData.count}} 页
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";

	export default {
		data() {
			return {
				search: {
					series_id: null,
					name: null,
					page_index: 1,
					page_size: 10
				},
				productData: {
					total: 0,
					count: 0,
					list: null
				},
			};
		},
		created() {
			document.title = "机工教师特训营";
			
			var option = {
				series_id: this.$route.query.id || "",
				name: this.$route.query.name || ""
			};
			if(option.series_id){
				this.search.series_id = decodeURIComponent(option.series_id) ;
				this.search.name = decodeURIComponent(option.name) ;
				this.getProductList();
			}
		
		},
		methods: {
			toDetail(item) {
				var url = "/teachRes/detail";
				var query = { id: item.id };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			getProductList() {
				var that = this;
				var url = apiUrl.product_series_list_get;
				
				this.Ajax(url, this.search, (data) => {
					if(that.productData.total == 0){
						that.productData.total = data.total_count;
					}
					if(that.productData.count == 0){
						that.productData.count = data.page_count;
					}
					that.productData.list = data.list;
				});
			},
			// nextPage(){
			// 	this.search.page_index = this.search.page_index+1;
			// 	this.getProductList();
			// },
			// prevPage(){
			// 	this.search.page_index = this.search.page_index-1;
			// 	this.getProductList();
			// },
			currentPage(index){
				this.search.page_index = parseInt(index) ;
				this.getProductList();
			},
		},
	};
</script>

<style lang="scss">
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;

		.book_item:nth-child(2n) {
			margin-right: 0 !important;
		}
		
		.book_pager {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom:40px;
			
			.page_count{
				display: inline-flex;
				height: 40px;
				justify-content: center;
				align-items: center;
				font-size: 18px;
				color: #00786c;
			}
			.el-pagination{
				display:flex;
				justify-content: center;
				align-items: center;
			}
			.el-pagination .el-icon {
				font-size: 22px !important;
				cursor: pointer;
			}
			.el-pager li{
				font-size: 16px !important;
				display: inline-flex;
				width: 40px !important;
				min-width: 40px !important;
				height: 40px !important;
				align-items: center;
				justify-content: center;
				background-color: #fff;
				border-radius: 50%;
				font-weight: 400;
				color: #959595;
				margin: 5px;
				cursor: pointer;
			}
			.el-pager li.active{
				background-color: #F3641E !important;
				color: #fff !important;
			}
		}
		
	

		.mianb {
			width: 100%;
			height: 75px;
			margin: 0 auto;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 18px;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #363636;


			.el-breadcrumb {
				font-size: 16px;
			}
			.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link ,
			.el-breadcrumb__separator {
			    color: #00786c !important;
				
			}
			
			.el-breadcrumb__inner.is-link:hover{
				border-bottom: 1px solid #00786c;
			}
			
			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #00786c !important;
			}

			
		}
	}


	
</style>