<template>
	<div class="page-main">
		<div style="width: 100%; position: relative; margin-bottom: -10px;">
		<img src="../../assets/images/bg_login.png" style="width: 100%;opacity: 0.7"/>
			<div style="width: 100%;display: flex; flex-direction: column; align-items: center;height: 600px; position: absolute;top: 120px; ">
				<div style="display: flex; flex-direction: column;width: 1200px; max-width: 1200px; box-sizing: border-box; position: relative;">
					<!-- <div class="mianb">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
							<el-breadcrumb-item :to="{ path: '/' }">登录</el-breadcrumb-item>
						</el-breadcrumb>
					</div> -->

					<div style="width: 360px; min-height: 450px; background-color: #fff;  border-radius: 30px; padding: 35px 35px 65px 35px; box-sizing: border-box; position: absolute; top: 30px; right: 0; ">
						<div v-if="!mobileShow" style="display: flex;flex-direction: column; line-height: 1;">
							<div style=" width: 6em; display: inline-block; padding-bottom: 8px; font-size: 28px; z-index: 2; margin-bottom: 5px;font-weight: 600; border-bottom: 2px solid #f49845;">
								微信安全登录
							</div>
							<div style="font-size: 16px;color: #333;margin-top: 10px;">
								机工教师特训营CMPTT
							</div>
							<div v-if="qrcodeData" style=" width: 100%; display: flex;flex-direction: column;font-size: 15px;margin-top: 30px;color: #999;  align-items: center;">
								<div style="width: 200px;  height: 200px; background-color: #fff;justify-content: center;align-items: center; position: relative;">
									<img :src='qrcodeData.qr_img' style="width: 200px; height: 200px;border: 1px solid #eee;" />
									<div v-if="show" style="background-color: rgba(255, 255, 255, 0.9); border: 1px solid #eee; width: 200px;  height: 200px;display:flex;flex-direction: column; justify-content: center;align-items: center; position: absolute; left: 0; top: 0;">
										<i class="el-icon-refresh-right" style="font-size: 36px;"></i>
										<div style="font-size: 20px;color: #333; margin: 12px;">二维码已失效</div>
										<el-button type="primary" style="width: 60%;" @click="getLoginQrcode()">点击刷新</el-button>
									</div>
								</div>
							</div>
							<div v-if="!show" style="display: flex; align-items: center; justify-content: center; width: 100%; font-size: 12px; margin: 10px 0 -5px 0;">
								<div style="color: #999; line-height: 1.5;  display: inline-block;cursor: pointer;">二维码失效倒计时 {{time}} s</div>
							</div>
									
							<div style="display: flex; align-items: center; justify-content: center; width: 100%; font-size: 12px; margin-top: 10px;">
								<div style="color: #333; line-height: 1.5;  display: inline-block;cursor: pointer;">扫码登录中请关注公众号</div>
							</div>
						</div>
									
						<div v-if="mobileShow" style="display: flex;flex-direction: column; width: 252px; line-height: 1;">
							
							<div style=" width: 6em; display: inline-block; padding-bottom: 8px; font-size: 28px; z-index: 2; margin-bottom: 5px;font-weight: 600; border-bottom: 2px solid #f49845;">
								绑定手机号
							</div>
							<div style="font-size: 16px;color: #333;margin-top: 10px;">
								机工教师特训营CMPTT
							</div>
							<div style=" width: 100%; display: flex;flex-direction: column;font-size: 15px;margin-top: 25px;color: #999;">
								<div style="display: flex;">手机号码</div>
								<div style="display: flex; flex-direction: column;margin-top: 5px;width: 100%; position: relative; ">
									<input v-model="mobile_num" @blur="checkMobile()" @focus="mobileFocus()" type="text" placeholder="请输入手机号码" style=" width: 100%; background: transparent;border-bottom: 1px solid #999; line-height: 2; color: #333;" />
									<div style="color: #f56c6c;font-size: 12px; line-height: 1; position: absolute; left: 0; bottom: -15px;" v-if="mobile_msg">{{mobile_msg}}</div>
								</div>
								<div style="display: flex;margin-top: 20px; align-items: flex-end;">安全验证<span style="font-size: 12px;">（验证成功即发送手机验证码）</span></div>
								<div style="display: flex;margin-top: 12px;width: 100%; ">
									<drag-verify
										ref="dragVerify"
										:isPassing.sync="isPassing"
										text="按住滑块拖动以完成验证"
										:successText="successText"
										handlerIcon="el-icon-d-arrow-right"
										successIcon="el-icon-circle-check"
										@passcallback="passcallback">
									</drag-verify>
								</div>
								<div style="display: flex;margin-top: 20px;">
									验证码
								</div>
								<div style="display: flex; flex-direction: column; margin-top: 5px;width: 100%;border-bottom: 1px solid #999;  position: relative; ">
									<div style="display: flex;align-items: center;">
										<input v-model="vcode" @blur="checkVcode()" @focus="vcodeFocus()" type="text" name="" id="" placeholder="请输入验证码" style=" width: 100%;background: transparent;line-height: 2;color: #333;" />
									</div>
									<div style="color: #f56c6c;font-size: 12px; line-height: 1; position: absolute; left: 0; bottom: -15px;" v-if="vcode_msg">{{vcode_msg}}</div>
									
								</div>
								<div style="display: flex; flex-direction: row; justify-content: space-between;margin-top: 10px;font-size: 12px;">
									
								</div>
							</div>
							<div style="display: flex;margin-top: 20px; width: 100%;">
								<el-button type="primary" style="width: 100%;" @click="bindMobile()">绑定</el-button>
							</div>
						</div>
					</div>
			
								
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js"
	import comm from "../../js/comm.js"

	export default {
		data() {
			return {
				qrcodeData: null,
				userData: null,
				time: 30,
				show: false,
				timer: null,
				isPassing: false,
				mobileShow: false,
				mobile_num: "",
				vcode: "",
				mobile_msg: '',
				vcode_msg: '',
				successText:'验证成功',
			};
		},
		created() {
			if (!this.mobileShow) {
				this.getLoginQrcode();
			}
		},
		//页面退出前清除定时轮询
		beforeDestroy() {
			this.stopTimer();
		},
		methods: {
			passcallback(){
				console.log(this.isPassing)
				this.getVerificationCode();
			},
			getLoginQrcode() {
				var that = this;
				var url = apiUrl.login_qr_get;
				this.Ajax(url, {}, (data) => {
					that.qrcodeData = data;
					that.show = false;
					that.time = data.expire_seconds;
					that.showTimer();
				}, null,null,true);
			},
			stopTimer(){
				if (this.timer) {
					window.clearTimeout(this.timer);
					this.timer = null;
				}
			},
			showTimer() {
				this.time = this.time - 1;
				this.checkLogin();
				console.log(this.time);
				if (this.time <= 0) {
					this.time = 30;
					this.show = true;
					return;
				}
				this.timer = window.setTimeout(() => {
					this.showTimer();
				}, 1000);
			},
			checkLogin() {
				var that = this;
				var url = apiUrl.login;
				var inviter = localStorage.getItem('inviter');
				var param = { event_key: that.qrcodeData.event_key }
				if(inviter){
					var _inviter = JSON.parse(inviter);
					param = { event_key: that.qrcodeData.event_key , inviter_uid: _inviter.uid, office_id:_inviter.office_id}
				}
				this.Ajax(url, param, (data) => {
					that.userData = data;
					localStorage.removeItem('inviter');
					// 判断是否绑定了手机号
					console.log("!!!:" + data.mobile);
					if (data.mobile && data.mobile != "") {
						localStorage.setItem("userLogInInfo", JSON.stringify(that.userData));
						that.stopTimer();
						//window.open('/', "_self");
						if(window.history.length <=1){
							that.GoToChangeTab(0);
						}else{
							that.$router.go(-1);
						}
					} else {
						//window.open('/bind', "_self");
						that.mobileShow = true;
						that.stopTimer();
					}
				}, null,null,true);
			},
			checkMobile() {
				const phoneReg = /^[1][3-9][0-9]{9}$/;
				if (!this.mobile_num) {
					this.mobile_msg = "手机号不可以为空";
					return;
				}

				if (!phoneReg.test(this.mobile_num)) {
					this.mobile_msg = "请输入正确手机号";
					return;
				}

				this.mobile_msg = "";
			},
			mobileFocus() {
				this.mobile_msg = "";
			},
			checkVcode() {
				if (!this.vcode) {
					this.vcode_msg = "验证码不可以为空";
				}
			},
			vcodeFocus() {
				this.vcode_msg = "";
			},
			getVerificationCode() {
				this.checkMobile();
				var that = this;
				if (!this.mobile_msg) {
					var url = apiUrl.user_sms_send;
					this.Ajax(url, { mobile: this.mobile_num }, (data) => {
						that.vcode_msg = "验证码已发送，请稍候";
						that.successText = "验证码已发送";
					}, null,null,true);
				}
			},
			bindMobile() {
				this.checkMobile();
				this.checkVcode();
				var that = this;
				if (!this.mobile_msg && !this.mobile_msg) {
					var url = apiUrl.login_mobile_bind;
					this.Ajax(url, { mobile: this.mobile_num, vcode: this.vcode, token: this.userData.token }, (data) => {
						/*
						that.userData.mobile = data;
						localStorage.setItem("userLogInInfo", JSON.stringify(that.userData));
						*/

						var obj = JSON.parse(JSON.stringify(that.userData));
						obj.mobile = data.mobile;
						obj.is_reg_finished = data.is_reg_finished;
						localStorage.setItem("userLogInInfo", JSON.stringify(obj));

						//window.open('/', "_self");
						if(window.history.length <=1){
							that.GoToChangeTab(0);
						}else{
							that.$router.go(-1);
						}
					}, null,null,true);
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	html,
	body {
		width: 100%;
		height: 100vh;
		background: #f7f7f7;
	}

	.main {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}

	/deep/.el-button--primary {
		background-color: transparent !important;
		border-color: #FB9226 !important;
		background: linear-gradient(to bottom,#C63E31,#FB9226) !important
	}

	/deep/.el-button--primary:hover,
	.el-button--primary:focus {
		background-color: transparent !important;
		border-color: #FB9226 !important;
		background: linear-gradient(to bottom,#C63E31,#FB9226) !important;
	}
</style>