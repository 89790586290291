<template>
	<div class="page-main">
		<div style="width: 1200px; display: flex; flex-direction: column;">
			<!-- 个人中心 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin:40px 0;">
					<div class=""
						style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">

					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						个人中心
					</div>
				</div>
			</div>

			<div v-if="detailData" style="background-color: #fff; border-radius: 8px; width: 100%; height: 200px; display: flex; align-items: center; justify-content: space-between; padding: 0 35px 0 40px; box-sizing: border-box;">
				<div style="display: flex;">
					<img v-if="detailData.avatar" :src="detailData.avatar"
						style=" width: 90px; height: 90px; border: 3px solid #FCA54A; border-radius: 50%;" />
					<div style="display: flex; flex-direction: column; margin-left: 24px;  justify-content: center; line-height: 1;">
						<div style="font-size: 22px;">{{detailData.nickname}}</div>
						<div style="display: flex;font-size: 14px; margin-top: 14px; color: #666;">
							<div style="display: flex;width: 20px; height: 14px; align-items: center;">
								<img src="../../assets/images/icon_phone.png" style=" width: 14px; height: auto;  margin-right: 8px;" />
							</div>
							{{detailData.mobile}}
						</div>
						<div style="display: flex;font-size: 14px;margin-top: 14px;color: #666; height: 14px; align-items: center;">
							<div style="display: flex; width: 20px; height: 14px; align-items: center;">
								<img src="../../assets/images/icon_email.png" style=" width: 14px; height: 10px;  margin-right: 6px;" />
							</div>
							{{detailData.email}}
						</div>
					</div>
				</div>
				<div>
					<img src="../../assets/images/logo.png" style=" width: 175px; height: auto;" />
				</div>
				<!-- <div style="width: 360px; height: 136px; border-radius: 18px; background-color: #FFF5ED; box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2); display: flex; justify-content: space-between; align-items: center; padding: 20px 32px 20px 22px;">
					<div style="display: flex; height: 100%; flex-direction: column; justify-content: space-between;">
						<div style="display: flex;align-items: flex-end;">
							<div style="font-size: 22px; font-weight: 600; ">刘磊</div>
							<div style="font-size: 14px;color: #ED472D; font-weight: 600; margin-left: 12px;">教授</div>
							<div style="font-size: 14px; margin-left: 16px; color: #666; ">教龄：12年</div>
						</div>

						<div style="display: flex;flex-direction: column;font-size: 14px;">
							<div>西南财经大学</div>
							<div>MBA学院 博士生导师</div>
						</div>

					</div>
					<div style="display: flex; flex-direction: column;">
						<img src="../../assets/images/icon_auth.png" style=" width: 50px; height: auto; " />
						<div style="font-size: 14px;color: #ED472D; font-weight: 600; margin-top: 10px; line-height: 1;">教师认证</div>
					</div>
				</div> -->
			</div>

			<div class="center_tabs" style="width: 100%; display: flex;justify-content: space-between; margin: 25px 0;">
				<div class="center_tabs_navs"
					style="width: 208px; height: 825px; background-color: #fff; border-radius: 6px; display: flex; flex-direction: column;  color: #333; line-height: 1;">
					<div style="display: flex; height: 40px;align-items: center; font-size: 14px; margin-top: 14px; padding-left: 60px;box-sizing: border-box;">
						<img src="../../assets/images/icon_buy.png" style="width: 22px; margin-right: 10px; margin-left: -38px;" />
						我的已购
					</div>
					<div @click="selectedClick(1)" :class="{'sider_actived':selected == 1}"
						style="display: flex; height: 40px; align-items: center; font-size: 14px; padding-left: 60px; cursor: pointer;box-sizing: border-box;">
						特训营
					</div>
					<div @click="selectedClick(2)" :class="{'sider_actived':selected == 2}"
						style="display: flex; height: 40px; align-items: center; font-size: 14px; padding-left: 60px;cursor: pointer;box-sizing: border-box;">
						课程
					</div>
					<div @click="selectedClick(3)" :class="{'sider_actived':selected == 3}"
						style="display: flex; height: 40px; align-items: center; font-size: 14px; padding-left: 60px;cursor: pointer;box-sizing: border-box;">
						数字教研室
					</div>

					<div 
						style="display: flex; height: 40px;align-items: center; font-size: 14px; margin-top: 14px; padding-left: 60px;box-sizing: border-box;">
						<img src="../../assets/images/icon_ticket.png"
							style="width: 22px; margin-right: 10px; margin-left: -38px;" />
						我的发票
					</div>
					<div @click="selectedClick(4)" :class="{'sider_actived':selected == 4}"
						style="display: flex; height: 40px;align-items: center; font-size: 14px; padding-left: 60px;cursor: pointer;box-sizing: border-box;">
						已开发票
					</div>
					<div @click="selectedClick(5)" :class="{'sider_actived':selected == 5}"
						style="display: flex; height: 40px;align-items: center; font-size: 14px; padding-left: 60px;cursor: pointer;box-sizing: border-box;">
						未开发票
					</div>

					<div @click="selectedClick(6)" :class="{'sider_actived':selected == 6}"
						style="display: flex;height: 40px; align-items: center; font-size: 14px;margin-top: 14px; padding-left: 60px; cursor: pointer;box-sizing: border-box;">
						<img src="../../assets/images/icon_ticket.png"
							style="width: 22px; margin-right: 10px; margin-left: -38px;" />
						学习足迹
					</div>
				</div>
				<div style="flex: 1; margin-left: 30px;">

					<!-- 特训营 -->
					<div v-if="selected == 1"
						style="width: 100%; min-height: 825px; background-color: #fff; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box; border-radius: 6px;">
						<trainingCamp id="trainingCamp" ref="trainingCamp"></trainingCamp>
					</div>

					<!-- 课程 -->
					<div v-if="selected == 2"
						style="width: 100%; min-height: 825px; background-color: #fff; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box; border-radius: 6px;">
						<course id="course" ref="course"></course>
					</div>
					
					<!-- 教研室 -->
					<div v-if="selected == 3"
						style="width: 100%; min-height: 825px; background-color: #fff; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box; border-radius: 6px;">
						<teachRoom id="teachRoom" ref="teachRoom"></teachRoom>
					</div>
					
					
					<!-- 已开发票 -->
					<div v-if="selected == 4"
						style="width: 100%; min-height: 825px; background-color: #fff; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box; border-radius: 6px;">
						<invoice id="invoice" ref="invoice"></invoice>
					</div>
					
					
					<!-- 未开发票 -->
					<div v-if="selected == 5"
						style="width: 100%; min-height: 825px; background-color: #fff; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box; border-radius: 6px;">
						<invoice2 id="invoice2" ref="invoice2"></invoice2>
					</div>
					
					
					<!-- 学习足迹 -->
					<div v-if="selected == 6"
						style="width: 100%; min-height: 825px; background-color: #fff; display: flex;flex-direction: column;padding:25px 35px;box-sizing: border-box; border-radius: 6px;">
						<history id="history" ref="history"></history>
					</div>
					
					
				</div>
			</div>






		</div>
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import trainingCamp from "../Center/TrainingCampList.vue"; //特训营
	import course from "../Center/CourseList.vue"; //课程
	import teachRoom from "../Center/TeachRoomList.vue"; //教研室
	import invoice from "../Center/InvoiceList.vue"; //已开发票
	import invoice2 from "../Center/InvoiceList2.vue"; //未开发票
	import history from "../Center/HistoryList.vue"; //学习足迹
	

	export default {
		data() {
			return {
				id: null,
				detailData: null,
				newData: {
					current: null,
					list: [],
				},
				search: {
					product_id: '',
					page_index: 1,
					page_size: 12,
					order_by: 'created_at:asc'
				},
				resData: {
					total: 1,
					count: 1,
					list: [],
				},
				selected: 1,
				sideList: null,
				selected_side: null,
				isLogin: true,
			};
		},
		components: {
			qrcode: QRCode,
			trainingCamp: trainingCamp,
			course: course,
			teachRoom: teachRoom,
			invoice: invoice,
			invoice2: invoice2,
			history: history,
		},
		created() {
			document.title = "个人中心 - 机工教师特训营";
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			if(!this.isLogin){
				window.top.location.href = "/login";
				return;
			}
			this.getDetail();
			// if (option.id) {
			// 	this.id = option.id;
			// 	//this.getDetail();
			// 	//this.getNewData();
			// 	this.getSideList();

			// 	// if (option.uid) {
			// 	// 	var obj = new Object();
			// 	// 	obj.uid = option.uid;
			// 	// 	obj.office_id = option.id;
			// 	// 	localStorage.setItem('inviter', JSON.stringify(obj));

			// 	// 	// 先拷贝一个一模一样的对象
			// 	// 	let newQuery = JSON.parse(JSON.stringify(this.$route.query)) 
			// 	// 	//删除不需要的参数
			// 	// 	delete newQuery.uid 
			// 	// 	//再把新的替换了
			// 	// 	this.$router.replace({ query: newQuery }) 
			// 	// }
			// }



			// let code_img = new QRCode(this.$refs.qrcode2, {
			// 	width: 110,
			// 	height: 110, // 高度
			// 	text: 'https://yd.cmpreading.com/pages/questionbank/index?id=1', // 二维码内容
			// 	colorDark: '#333333',
			// 	colorLight: '#ffffff',
			// 	correctLevel: QRCode.CorrectLevel.L,
			// });

		},
		methods: {
			
			//QrCode
			setupQrCode(el, url) {
				let code_img = new QRCode(el, {
					width: 110,
					height: 110, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
			},
			getDetail() {
				var that = this;
				var url = apiUrl.tt_center_detail_get;
				this.Ajax(url, {}, (data) => {
					that.detailData = data;
				});
			},
			selectedClick(index){
				this.selected = index;
			}
		},
	};
</script>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.sider_actived {
			background-color: #FFF5ED;
			border-left: 5px solid #FAA55A;
			box-sizing: border-box;
			padding-left: 55px !important;
		}
	}
</style>