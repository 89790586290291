<template>
  <div
    class="maxBox"
    ref="main"
    style="height: 100vh; overflow: auto"
    v-infinite-scroll="loadMore"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-immediate-check="true"
    infinite-scroll-distance="0"
    @scroll="handleScroll"
    @click="removeInputState()"
  >
    <BackTop
      :bottom="300"
      :right="backTopRight"
      class="boska"
      :class="
        isShowScrollBtn && isShowScrollBtn2
          ? 'showScrollBtn'
          : 'unShowScrollBtn'
      "
      on-click="scrollToTop()"
    >
      <div class="top" @click="scrollToTop">
        <div class="divg">
          <img
            style="width: 100%; height: 100%"
            src="../assets/image/shang.png"
            alt=""
          />
        </div>
      </div>
    </BackTop>
   
   <div style=" width: 100%; display: flex; flex-direction: column;align-items: center; border-bottom: 1px solid #eaeaea;">
   	<div style=" width: 100%; height: 65px; background-color: #fff; display: flex; justify-content: space-between; ">
   		<div style="display: inline-flex;display: flex;">
   			<div style="display: inline-flex; width: 271px; height: 65px; justify-content: center; align-items: center; cursor: pointer;" @click="GoToIndex(0)">
   				<img src="../assets/images/logo.png" style=" width: 175px; height: auto;" />
   			</div>
   			<div class="navs">
   				<div class="nav" :class="{'actived':nav_index == 0}" @click="GoToIndex(0)">首页</div>
   				<div class="nav" :class="{'actived':nav_index == 27}" @click="GoToIndex(27)">教师特训营</div>
   				<div class="nav" :class="{'actived':nav_index == 4}" @click="GoToIndex(4)">课程</div>
   				<div class="nav" :class="{'actived':nav_index == 26}" @click="GoToIndex(26)">数字教研室</div>
   				<div class="nav" :class="{'actived':nav_index == 30}" @click="GoToIndex(30)">名师团</div>
   			</div>
   		</div>
   		
   		
   		<div style="display: flex; height: 100%; align-items: center;padding-right: 32px; box-sizing: border-box;">
   			<div style="display: flex; align-items: center;">
   				<div class="inputs"  style="width: 278px !important; background: #f7f7f7ff;display: flex; align-items: center;">
   				    <img src="../assets/image/search.png" alt="" />
   				    <input ref="inputRef" class="ipus"
   				      style="color: #999999;outline: none;background: rgb(247, 247, 247);border: none;width: 170px;height: 34px;font-size: 16px;"
   				      confirm-type="search"
   				      placeholder-class="text"
   				      placeholder="关键字"
   				      @focus="input_focusIpt"
   				      v-on:keyup.enter="input_search(input_content)"
   				      v-model="input_content"/>
   				    <div style="width: 88px;height: 34px;
   				        line-height: 34px;
   				        font-size: 16px;
   				        background: #e6564eff;
   				        color: white;
   				        border-radius: 19px;
   				        cursor: pointer;"
   				      @click="input_search()"
   					  >搜索</div>
   				</div>


				
   			</div>
   			<div style="display: flex;flex-direction: row;align-items: center;color: #c83a2e; font-weight: 500; margin-left: 20px;">
   				<div v-if="isUserLogin" style="display: flex;flex-direction: row; align-items: center; ">
   					<img src="../assets/images/icon_user.png" style="width: 24px; height: auto; margin-right: 8px; " />
   					<div style="font-size: 16px;">{{loginUser}}</div>
   					<div @click="toLogout()" style="margin-left: 8px; cursor: pointer;font-size: 16px;color: #666666; border-bottom: 1px solid #666;">退出</div>
   				</div>
   				<div v-if="!isUserLogin" @click="toLogin()"
   					style="display: flex;flex-direction: row; align-items: center; cursor: pointer;">
   					<img src="../assets/images/icon_user.png"
   						style=" width: 25px; height: auto; margin-right: 8px; " />
   					<div style="font-size: 16px;cursor: pointer;">登录</div>
   				</div>
   			</div>
   			
   			<div v-if="isUserLogin" style="display: inline-flex; align-items: center;">
   				<div @click="toCenter()" style="background: linear-gradient(to right,#c93a2e,#ff9000); color: #fff; font-size: 14px; padding: 2px 10px; margin: 0 20px 0 15px; border-radius:12px; cursor: pointer;">个人中心</div>
   				<div style="line-height: 1; padding:3px 18px 3px 15px;background-color: #E5E5E5;border-radius: 30px;font-size: 14px;display: inline-flex; align-items: center;">
   					<i class="iconfont icon-rili" style="margin-right: 5px; font-size: 22px !important;"></i> {{myDate.date}}
   				</div>
   				<div style="display: flex;"></div>
   			</div>
   	
   			
   		</div>
   
   	
   	</div>
   </div>
   
    <div class="searchpg" style="padding-bottom: 20px;">
      <div class="serchmax">

        <div class="tabLst" v-show="tabLists && tabLists.length > 0">
          <div class="tabFot">
            <div
              class="tabfonts"
              :class="TabCur == ina ? 'active' : ''"
              v-for="(ite, ina) in tabLists"
              :key="ina"
              @click="changeSelectTab(ina)"
            >
              {{ ite.name }}
            </div>
          </div>
        </div>
        <div
          class="batil"
          v-for="(typeItem, inedex2) in tabLists"
          v-show="
            tabLists[TabCur] &&
            typeItem.product_type == tabLists[TabCur].product_type
          "
          :key="inedex2"
        >
          <h2 v-if="typeItem.product_type == 4">共 {{ typeItem.product_count }} 门</h2>
		  <h2 v-if="typeItem.product_type == 26 || typeItem.product_type == 27">共 {{ typeItem.product_count }} 个</h2>
		  <h2 v-if="typeItem.product_type == 30 ">共 {{ typeItem.product_count }} 位</h2>
          <div class="conten"s>
            <div
              class="condtl"
              v-for="(item, index3) in result"
              :key="index3"
              @click="goToDetailPage(item.product_type, item.id, item)"
            >
              <div class="dtlft">
				  
				<img
				   v-if="item.product_type == 4"
				  :src="item.teacher_list[0].img_url"
				  style="
				    width: 100%;
				    height: 100%;
				    object-fit: cover;
				    border-radius: 5px;
				  "
				  alt=""
				/>
				
                <img
				   v-if="item.product_type == 27 || item.product_type == 30"
                  :src="item.img_url"
                  style="
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                  "
                  alt=""
                />

				<img
				  v-if="item.product_type == 26"
				  :src="item.teacher_img"
				  style="
				    width: 100%;
				    height: 100%;
				    object-fit: cover;
				    border-radius: 5px;
				  "
				  alt=""
				/>

                
              </div>
              <div class="dltrgt" v-if="item.product_type !=30" >
                <h3>{{ item.title }}</h3>
				<div class="thors" v-if="item.product_type ==4">{{ item.author }}</div>
                <div class="thors" v-if="item.product_type ==26">{{ item.teacher_name }}</div>
				<div class="thors" v-if="item.product_type ==27">{{ item.start_at.split('-')[0]+'年'+item.start_at.split('-')[1]+'月' }}</div>
              </div>
			  
			  <div class="dltrgt" v-if="item.product_type == 30" >
			    <h3>{{ item.name }}</h3>
				<div class="thors" v-if="item.school || item.college"><span v-if="item.school">{{item.school}}</span><span v-if="item.college">/{{item.college}}</span></div>
				<div class="thors" v-if="item.title">{{item.title}}</div>
				<div class="thors" v-if="!item.title && item.job">{{item.job}}</div>
				<div class="thors" v-if="!item.title && !item.job && item.job2">{{item.job2}}</div>
				<div class="thors" v-if="!item.school && !item.college && !item.title && !item.job && item.job3">{{item.job3}}</div>
			  </div>

			  

            </div>
          </div>
        </div>
        <div class="noContentBg" v-show="!(result && result.length > 0)">
          <img class="placeImg" src="../assets/image/search_place.png" alt="" />
        </div>
      </div>
	  </div>
      <foo-ter class="footer" />
    </div>
  </div>
</template>

<script>
import fooTer from "../components/footer";
import apiUrl from "../js/apiUrl.js";
import comm from "../js/comm.js";

export default {
  data() {
    return {
      num: 0,
      tabList: [],
      productTypeData: {},
      busy: false,
      input_content: "",
      acitvnum: 0,
      hideDel: false,
      TabCur: -1,
      isContent: true,
      isContents: false,
      NoContent: false,
      tabLists: [],

      hotKeywords: [], //热门搜索
      historyKeywords: [], //历史搜索
      searchSearch: {
        value: null,
        product_type: 1,
        page_index: 1,
        page_size: 10,
        order_by: "start_at:desc",
		client_type: 10,
      },
      result: [], //搜索结果
      total: "", //搜索总数
      pageCount: 1,
      hideDel: false,
      isShowNav: false,
      input_content: "",
      hotKeywords: [], //热门搜索
      isInputing2: this.isInputing,
      isShowScrollBtn: false,
      backTopRight: 200,
      isShowScrollBtn2: false,
	  myDate: {
	  	date: null,
	  	week: null,
	  },
	  corpTitle:'',
	  isGoIndex: false,
	  // isShowNav: false,
	  loginUser: '137****0188',
	  isUserLogin: true,
	  isRegDisplay: true,
	  nav_index :0,
	  show_wechat: false
    };
  },
  components: {
    fooTer,
  },
  created() {
    this.GetMyDate();
    
    var that = this;
    that.isUserLogin = localStorage.getItem("userLogInInfo") ? true : false;
    // that.isRegDisplay = that.isUserLogin && JSON.parse(localStorage.getItem("userLogInInfo")).is_reg_finished ==
    // 	0 ? true : false;
    if (that.isUserLogin) {
    	var userInfo = JSON.parse(localStorage.getItem("userLogInInfo"));
    	if (userInfo && userInfo.title != '') {
    		that.loginUser = userInfo.mobile;
    	}
    }
	
    var input_Str = this.$route.query.inputStr || "";
    this.input_content = input_Str;
    document.title = "搜索 - 机工教师";
    // this.hotKeywordsGet();
    // this.historyKeywordsGet();
    this.searchClick();
    this.setupList();
    //this.hotKeywordsGet();

    window.addEventListener("resize", function () {
      that.css_caculateBackTopRight();
    });
    this.css_caculateBackTopRight();
  },
  methods: {
	  GetMyDate() {
	  	let mydate = new Date();
	  	let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
	  	this.myDate.date = (mydate.getMonth() + 1) + '月' + mydate.getDate() + '日'
	  	this.myDate.week = weeks[mydate.getDay()];
	  },
	  GoToIndex(index) {
	  	this.GoToChangeTab(index)
	  	this.nav_index = index;
	  },
    css_caculateBackTopRight() {
      var screenWidth = document.body.offsetWidth;
      if (screenWidth < 1200) {
        this.isShowScrollBtn2 = false;
      } else {
        this.isShowScrollBtn2 = true;
      }
      var right = (screenWidth - 1157) / 2 - 30;
      this.backTopRight = right || 200;
    },

    scrollToTop() {
      this.$refs.main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    removeInputState() {
      this.isInputing = false;
      this.input_blurIpt();
      this.$forceUpdate();
    },
    hotKeywordsGet() {
      var that = this;
      this.Ajax(apiUrl.search_keywords_get, {}, function (data) {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    input_search(title) {
      if (title && title != "") this.input_content = title;
      this.searchClick();
      //this.setupList();
    },
    input_focusIpt() {
      setTimeout(() => {
        this.isInputing2 = true;
        this.$refs.inputRef.focus();
        this.$forceUpdate();
      }, 200);
    },
    input_blurIpt() {
      this.isInputing2 = false;
      this.$refs.inputRef.blur();
      // setTimeout(() => {
      // this.isInputing2 = true;
      //   this.$forceUpdate();
      // }, 500);
    },
    handleScroll(e, item) {
      let scrollY = e.target.scrollTop;
      console.log(e.target.scrollTop);
      var height = 90;
      if (scrollY >= height) {
        this.isShowNav = true;
        this.isInputing = false;
        this.input_blurIpt();
      } else {
        this.isShowNav = false;
      }
      this.isShowScrollBtn = scrollY > 200;
      this.$forceUpdate();
    },
    loadMore() {},
    setupList() {
      var that = this;
      this.tabList = [];
      var url = apiUrl.shuyuan_navbar_get;
      var param = { client_type: 2 };
      this.Ajax(url, param, (data) => {
        data.forEach((item) => {
          if (item.product_type == 11) {
            item.title = "直播";
          }else if (item.product_type == 13) {
		    item.title = "师资";
		  }
		  if(item.product_type != 11){
			  that.tabList.push(item);
		  }
        });
		that.tabList.push({
			title:'院校服务',
			img_url:'',
			product_type:15,
			product_count:0
		})
        that.productTypeData = data;
      });
    },
    handellet() {
      if (this.$refs.hoves.style.display == "none") {
        this.$refs.hoves.style.display = "block";
      } else if ((this.$refs.hoves.style.display = "block")) {
        this.$refs.hoves.style.display = "none";
      }
    },
    //Next PageList
    loadMore() {
      if (this.busy) return;
      this.busy = true;
      this.setupListData(this.searchSearch.page_index + 1);
    },
    //Click: change Tab
    changeSelectTab(index) {
      this.TabCur = index;
      if (!this.tabLists[this.TabCur]) return;
      this.setupListData(1, this.tabLists[this.TabCur].product_type);
    },
    //Click: Search
    searchClick() {
      this.isContents = true;
      this.isContent = false;
      this.setupTabarList();
    },
    //TabbarList
    setupTabarList() {
      var that = this;
      var url = apiUrl.tt_search_count_get;
      var param = { value: that.input_content || "" , client_type: 10 };
      this.Ajax(url,param,(data) => {
          if (data) {
            that.tabLists = [];

            for (let i = 0; i < data.length; i++) {
				
				that.tabLists.push({
				  name: data[i].title,
				  product_type: data[i].product_type,
				  product_count: data[i].product_count,
				});  
			
             
              //第一个有数据的
              if (that.TabCur < 0 && data[i].product_count > 0) {
                that.TabCur = i;
              }
            }
            if (!that.tabLists[that.TabCur]) return;
            that.setupListData(1, that.tabLists[that.TabCur].product_type);
          } else {
            //
          }
        },
        function (data) {
          that.result = [];
          that.tabLists = [];
          // uni.showToast({
          // 	title: "搜索内容不存在",
          // 	icon: 'none',
          // 	duration: 2000
          // });
          return;
        }
      );
    },
    //ListData
    setupListData(index, product_type) {
      var that = this;
      that.searchSearch.value = that.input_content || "";
      if (!index || index <= 0) {
        index = 1;
      }

      that.searchSearch.page_index = index;

      if (that.searchSearch.page_index == 1) {
        that.pageCount = 1;
        that.result = [];
      }

      if (that.searchSearch.page_index > that.pageCount) {
        // uni.showToast({
        // 	title: "没有更多内容了",
        // 	icon: 'none',
        // 	duration: 2000
        // });
        that.busy = false;
        return;
      }

      if (product_type) {
        that.searchSearch.product_type = product_type;
      }
      var url = apiUrl.tt_search_list_get;
      var param = that.searchSearch;
      this.Ajax(url, param, (data) => {
        if (data && data.list.length > 0) {
          if (that.searchSearch.page_index == 1) {
            that.result = data.list;
          } else {
            that.result.push.apply(that.result, data.list);
          }

          that.total = data.total;
          that.pageCount = data.count;
        } else if (data == null) {
          that.isContent = false;
          that.isContents = false;
          that.NoContent = true;
        }
        that.busy = false;
      });
    },
    //Page: Detail
    goToDetailPage(product_type, id, item) {
        this.GoToDetail(product_type, id);
    },
    // //Input forcous
    // input_focusIpt() {
    //   this.hideDel = true;
    // },
    // //Input blur
    // input_blurIpt() {
    //   if (this.input_content) {
    //     this.hideDel = true;
    //   } else {
    //     this.hideDel = false;
    //   }
    // },
    //Other: Remove History Keywords
    search_history_del(value) {
      var that = this;
      this.Ajax(
        apiUrl.search_history_del,
        {
          value: value,
        },
        function (data) {}
      );
    },
    //Other: Recomend keywords
    hotKeywordsGet() {
      var that = this;
      var url = apiUrl.search_keywords_get;
      var param = {};
      this.Ajax(url, param, (data) => {
        if (data && data.length > 0) {
          that.hotKeywords = data;
        }
      });
    },
    //Other: History keywords
    historyKeywordsGet() {
      var that = this;
      var url = apiUrl.search_history_get;
      var param = {};
      this.Ajax(url, param, (data) => {
        if (data && data.length > 0) {
          that.historyKeywords = data;
        }
      });
    },
    //Other Clear History Keywors
    search_history_clear() {
      var that = this;
      var url = apiUrl.search_history_clear;
      var param = {};
      this.Ajax(url, param, (data) => {});
    },
  },
};
</script>

<style lang="scss">
.maxBox {
  width: 100%;
  min-width: 1157px;
  cursor: default;
  // background: #d8e4f8;
  .boska {
    // background: yellow;
    position: fixed;
    // top: 660px;
    right: calc(100px + 50px);
    // right: calc(100%-20px);
    // right: cal;
  }
 
 
 .navs {
 	display: inline-flex;
 	flex-direction: row;
 	flex-wrap: nowrap;
 	box-sizing: border-box;
 }
 
 .navs>.nav {
 	height: 65px;
 	background-color: #fff;
 	display: inline-flex;
 	align-items: center;
 	color: #333;
 	font-size: 16px;
 	padding: 0 20px;
 	cursor: pointer;
 	box-sizing: border-box;
 	
 	&.actived{
 		color: #c83a2e !important;
 	}
 }
 
 
 .nav:hover {
 	background: linear-gradient(to bottom, transparent, #fff, #FEDADE) !important;
 }
 
 .inputs {
   width: 140px;
   height: 34px;
   background: #f7f7f7;
   border-radius: 25px;
   margin-left: 30px;
   text-align: center;
   font-size: 20px;
   line-height: 50px;
   display: flex;
   align-items: center;
   img {
     width: 17px;
     height: 17px;
     margin-left: 12px;
     margin-right: 12px;
   }
   span {
     font-size: 16px;
     font-family: PingFang-SC-Medium, PingFang-SC;
     font-weight: 500;
     color: #c9c9c9;
   }
 }

  .searchpg {
    width: 100%;
    background: #f7f7f7;
    min-width: 1157px;
    border: 1px solid #fff;
    .serchmax {
      width: 1157px;
      margin: 0 auto;
      min-height: 800px;
      background: #fff;
      margin-top: 20px;
      border: 1px solid #fff;
      .navOll {
        width: 726px;
        height: 67px;
        background: #f7f7f7;
        border-radius: 34px;
        margin: 0 auto;
        margin-top: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .navInp {
          width: 83%;
          height: 100%;
          border-radius: 34px;

          display: flex;
          // justify-content: space-between;
          align-items: center;

          .seekImg {
            width: 35px;
            height: 35px;
            z-index: 999;
            //   background: chartreuse;
            // border: 1px solid red;
            margin-left: 38px;
            margin-right: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .ipus {
            height: 100%;
            width: 79%;
            // border-radius: 34px;
            font-size: 25px;
            background: #f7f7f7;
            border: 1px solid #f7f7f7;
          }
        }
        .btnBox {
          width: 118px;
          height: 48px;
          background: #e6564e;
          border-radius: 34px;
          text-align: center;
          cursor: pointer;
          line-height: 48px;
          font-size: 22px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .tabLst {
        width: 1088px;
        margin: 0 auto;
        margin-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        .tabFot {
          height: 56px;
          width: 538px;

          margin: 0 auto;
          display: flex;
          justify-content: space-evenly;
          .tabfonts {
            font-size: 19px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
			//color: #CCCCCC;
            color: #999999;
            line-height: 56px;
          }
          .tabfonts:hover {
            cursor: pointer;
          }
          .active {
            display: flex;
            justify-content: center;
            position: relative;
            color: #e45754;
          }

          .active::before {
            content: "";
            width: 26px;
            border-radius: 5px;
            height: 3px;
            position: absolute;
            background-color: #e45754;
            display: block;
            bottom: 0;
          }
        }
      }
      .noContentBg {
        width: 100%;
        height: 700px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        .placeImg {
          width: 342px;
          height: 260px;
        }
      }
      .batil {
        width: 1022px;
        // height: 1000px;
        // border: 1px solid red;
        margin: 0 auto;
        margin-top: 22px;
        h2 {
          font-size: 29px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #2b2b2b;
          letter-spacing: 1px;
        }
        .conten {
          width: 100%;

          // border: 1px solid red;
          margin-top: 7px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .condtl {
            height: 192px;
            width: 455px;
            cursor: pointer;
            border-bottom: 1px solid #eeeeee;
            display: flex;
            justify-content: space-between;
            margin-top: 27px;

            .dtlft {
              width: 121px;
              height: 160px;

              box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
              object-fit: cover;

              position: relative;
              .erjia {
                width: 31px;
                height: 31px;
                position: absolute;
                top: 9px;
                right: 9px;
              }
            }
            .lftactiveL {
              width: 121px;
              height: 121px;
              border-radius: 50%;
            }

            .dltrgt {
              height: 160px;
              width: 315px;

              h3 {
                font-size: 20px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2b2b2b;
                letter-spacing: 1px;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                // margin-bottom: 9px;
              }
              .thors {
                font-size: 16px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: #666666;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                // margin-bottom: 12px;
              }
              p {
                font-size: 16px;
                font-family: PingFang-SC-Medium, PingFang-SC;
                font-weight: 500;
                color: #cccccc;
                margin-bottom: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
              .risk {
                display: flex;
                align-items: center;
                margin-top: 70px;
                img {
                  width: 27px;
                  height: 27px;
                  margin-left: 120px;
                }
                .flils {
                  width: 120px;
                  height: 36px;
                  background: #f4f5f6;
                  border-radius: 18px;
                  font-size: 22px;
                  font-family: PingFang-SC-Medium, PingFang-SC;
                  font-weight: 500;
                  color: #e6564e;
                  line-height: 36px;
                  letter-spacing: 1px;
                  text-align: center;
                  margin-left: 22px;
                }
              }
			  
			  .personMesoll {
			    display: flex;
			    color: #cccccc;
			    font-size: 15px;
			    justify-content: space-between;
			    max-height: 65px;
			    overflow: hidden;
			    margin: 5px 0;
			  
			    .personMesollP {
			      width: 90%;
			      height: 100%;
			  
			      p {
			        width: 100%;
			        white-space: nowrap;
			        text-overflow: ellipsis;
			        overflow: hidden;
			        font-size: 14px;
					margin-bottom: 0;
			      }
			    }
			  }
			  
			  
			  .sort {
			    margin-top: 5px;
			    display: flex;
			    flex-wrap: wrap;
			    max-height: 25px;
			    overflow: hidden;
			  }
			  
			  .sort span {
			    display: inline-block;
			    padding: 2px 10px;
			    background-color: #e0f3f7;
			    color: #52c4c6;
			    font-size: 15px;
			    // margin-bottom: 20px;
			  }
			  
			  .sort .labelBox {
			    display: inline-block;
			    //background-color: #e0f3f7;
			    //color: #52c4c6;
			    background-color: #fff4e7;
			    color: #ef8608;
			    padding: 2px 8px;
			    margin-right: 5px;
			    border-radius: 2px;
			    font-size: 14px;
			  }
			  
            }
          }
        }
      }
    }
  }
  .searchpg::-webkit-scrollbar {
    display: none;
  }
}
</style>
