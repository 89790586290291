<template>
	<div style="width: 100%; display: flex;flex-direction: column; background-color: #fff;">
		<div style="display: flex; align-items: flex-end;">
			<div style="font-size: 17px; margin-right: 15px;">未开发票</div>
		</div>
		<el-alert type="warning" show-icon :closable="false" style="margin-top: 10px;">
			本社统一开具电子发票，线下会议结束后发送至您报名时预留的邮箱中。
		</el-alert>
		<div style="display: flex;flex-direction: column; margin-top: 5px;">


			<div class="class1" style="display: flex; padding:30px 0px; line-height: 1; border-bottom: 1px solid #eee;"
				v-for="(item,index) in productData.list">
				<div style="font-size: 16px;color: #333; font-weight: 600; margin-right: 20px;cursor: pointer;">
					<div style="width: 16px; height: 16px; border: 1px solid #333; display: flex;justify-content: center;align-items: center; "
						@click="handleChecked(item)">
						<div style="width: 10px; height: 10px; background-color: #FB9026;"
							v-if="checked && checked.id == item.id">

						</div>
					</div>
				</div>
				<div style="flex: 1; display: flex;  flex-direction: column;">
					<div style="font-size: 16px;color: #333; font-weight: 600;">¥{{item.total_fee_span}} {{item.title}}
					</div>
					<div style="font-size: 13px;color: #666; margin-top: 15px; display: flex; ">
						<div>日期：{{item.created_at}}</div>
						<div style="margin-left: 30px;">
							订单总金额：¥{{item.total_fee}}
						</div>
					</div>

				</div>
			</div>

			<div style="display: flex; justify-content: center; align-items: center; margin-top: 40px;"
				v-if="productData.list && productData.list.length>0">
				<div @click="showDialog"
					style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:122px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">
					开具发票</div>
			</div>
		</div>

		<el-empty v-if="!productData.list"></el-empty>

		<el-dialog title="开票信息" :visible.sync="dialogFormVisible" width="750px">
			<div style="width: 100%; height: 58vh;overflow-y: scroll;">
				<div style=" width: 600px; margin: 0 auto; ">
					<el-form :model="dynamicValidateForm2" ref="dynamicValidateForm2" label-width="120px">
						<div v-for="(domain, index) in dynamicValidateForm2.domains" :key="domain.key">
							<div style="margin: 30px 0;">
								<el-divider content-position="left" v-if="index>0">开票信息 +{{index}}</el-divider>
							</div>
							
							<el-form-item :prop="'domains.' + index + '.total_fee'"  label="开票金额" :rules="[{ required: true, message: '请输入开票金额', trigger: ['blur', 'change'] }]">
								<el-input v-model="domain.total_fee" readonly placeholder="请输入开票金额"></el-input>
							</el-form-item>
							<el-form-item :prop="'domains.' + index + '.title'"  label="发票抬头" :rules="[{ required: true, message: '请输入发票抬头', trigger: ['blur', 'change'] }]">
								<el-input v-model="domain.title" placeholder="请输入发票抬头"></el-input>
							</el-form-item>
							<el-form-item :prop="'domains.' + index + '.tax_id'" label="发票税号" :rules="[{ required: true, message: '请输入发票税号', trigger: ['blur', 'change'] }]">
								<el-input v-model="domain.tax_id" placeholder="请输入发票税号"></el-input>
							</el-form-item>
							<el-form-item :prop="'domains.' + index + '.category'" label="发票类目" :rules="[{ required: true, message: '请选择发票类目', trigger: 'change' }]">
								<el-radio-group v-model="domain.category">
								      <el-radio label="会议费"></el-radio>
									  <el-radio label="信息服务费"></el-radio>
								      <el-radio label="技术服务费"></el-radio>
									  <el-radio label="其他"></el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item v-if="domain.category == '其他'" :prop="'domains.' + index + '.category_other'" label="其他发票类目" :rules="[{ required: true, message: '请输入发票类目', trigger: 'change' }]">
								<el-input v-model="domain.category_other" placeholder="请输入其他发票类目"></el-input>
							</el-form-item>
							<el-form-item :prop="'domains.' + index + '.email'" label="发票接收邮箱" :rules="[{ required: true, message: '请输入邮箱地址', trigger: ['blur', 'change'] },{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
								<el-input v-model="domain.email" placeholder="请输入邮箱地址" ></el-input>
							</el-form-item>
							<el-form-item :prop="'domains.' + index + '.mobile'" label="发票接收人手机" :rules="[{ required: true, message: '请输入手机号码', trigger: ['blur', 'change'] },{ pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }]">
								<el-input v-model="domain.mobile" placeholder="请输入手机号码"></el-input>
							</el-form-item>

							<el-button @click.prevent="removeDomain2(domain)" v-if="index>0">移除开票信息
								+{{index}}</el-button>
						</div>
						<div style="margin-top: 30px;">
							<el-form-item>
								<el-button @click="addDomain2"
									v-if="checked && checked.gift_buy_count_span>1 && dynamicValidateForm2.domains.length!=checked.gift_buy_count_span">新增开票信息</el-button>
							</el-form-item>
						</div>
					</el-form>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="infoConfirm">提 交</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				search: {
					page_index: 1,
					page_size: 10,
					order_by: 'created_at:desc'
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				},
				checked: null,
				dialogFormVisible: false,
				search2: {
					out_trade_no: null,
				},
				dynamicValidateForm2: {
					domains: [{
						title: '',
						tax_id: '',
						category: '会议费',
						category_other: '',
						email: '',
						mobile: '',
						total_fee: '',
					}]
				},
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.getProductList();
		},
		methods: {
			toInvoiceList(){
				this.$parent.selectedClick(4);
			},
			showDialog() {
				if (!this.checked) {
					this.$alert('您还未选择开票订单', '提示', {
						confirmButtonText: '确定',
					});
				} else {
					this.dialogFormVisible = true;
					if (this.dynamicValidateForm2.domains.length == 1) {
						this.dynamicValidateForm2.domains[0].total_fee = this.checked.total_fee_span.toString();
					} else {
						this.dynamicValidateForm2.domains[0].total_fee = this.checked.price.toString();
					}
				}
			},
			infoConfirm() {
				var that = this;
				that.$refs['dynamicValidateForm2'].validate((_valid) => {
					if (_valid) {
						console.log('可以提交')
						console.log('dynamicValidateForm2' + JSON.stringify(that.dynamicValidateForm2.domains))

						that.search2.out_trade_no = that.checked.out_trade_no;
						that.search2.invoice_list_json = JSON.stringify(that.dynamicValidateForm2.domains);
						that.postInvoiceInfo();
					}
				});
			},
			postInvoiceInfo() {
				var that = this;
				var url = apiUrl.tt_invoice_add;
				this.Ajax(url, this.search2, (data) => {
					that.dialogFormVisible = false;
					that.getProductList();
					that.dynamicValidateForm2.domains = [];
					that.dynamicValidateForm2.domains.push({
						title: '',
						tax_id: '',
						category: '会议费',
						category_other: '',
						email: '',
						mobile: '',
						total_fee: '',
						key: Date.now()
					});
					that.$message({
						showClose: true,
						message: '提交成功！',
						type: 'success'
					});
					that.toInvoiceList();
				});
			},
			handleChecked(item) {
				if (this.checked && this.checked.id == item.id) {
					this.checked = null;
					return;
				}
				this.checked = item;
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_invoice_list2_get;
				this.Ajax(url, this.search, (data, code, msg) => {
					if (code == 0) {
						if (data) {
							if (that.productData.total == 0) {
								that.productData.total = data.total;
							}
							if (that.productData.count == 0) {
								that.productData.count = data.count;
							}
							//that.productData.list = that.productData.list.concat(data.list);
							that.productData.list = data.list;
						}
					} else if (code == 40004) {
						this.loading = false;
						if (that.search.page_index == 1) {
							that.productData.list = null;
						}
					}
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.getProductList();
			},
			nextPage() {
				if (this.search.page_index >= this.productData.count && this.search.page_index != 0) {
					this.search.page_index = this.productData.count;
					return;
				}
				this.loading = true;
				this.currentPage(this.search.page_index + 1);
			},
			removeDomain2(item) {
				var index = this.dynamicValidateForm2.domains.indexOf(item)
				if (index !== -1) {
					this.dynamicValidateForm2.domains.splice(index, 1)
					if (this.dynamicValidateForm2.domains.length == 1) {
						this.dynamicValidateForm2.domains[0].total_fee = this.checked.total_fee_span.toString();
					} else {
						this.dynamicValidateForm2.domains[0].total_fee = this.checked.price.toString();
					}
				}
			},
			addDomain2() {
				if (this.dynamicValidateForm2.domains.length == this.checked.gift_buy_count_span) {
					return;
				}
				this.dynamicValidateForm2.domains.push({
					title: '',
					tax_id: '',
					category: '会议费',
					email: '',
					mobile: '',
					total_fee: this.checked.price.toString(),
					key: Date.now()
				});
				if (this.dynamicValidateForm2.domains.length == 1) {
					this.dynamicValidateForm2.domains[0].total_fee = this.checked.total_fee_span.toString();
				} else {
					this.dynamicValidateForm2.domains[0].total_fee = this.checked.price.toString();
				}
			},

		},
	};
</script>


<style lang="scss" scoped>
	.border-no {
		border-bottom: none !important;
	}

	/deep/.class1 .el-checkbox__label {
		display: none !important;
	}
</style>