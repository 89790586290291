<template>
	<div class="page-main" style="position: relative;">
		<div style=" width: 100%; border-top: 1px solid #ddd;"></div>
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="detailData">

			<!-- 特训营学习-->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin-top: 36px;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						特训营学习
					</div>
					<!-- <div style="flex: 1;margin-left: 15px;">
						<div @click="show_more = true" style="display: inline-flex; justify-content: center; align-items: center; width: 100px; height: 20px; border-radius: 20px; font-size: 12px; background: linear-gradient(to right,#c93a2e,#ff9000); color: #fff; cursor: pointer; position: relative;">
							已购特训营
							<i class="el-icon-caret-bottom" style="margin-left: 2px;"></i>
							<div v-if="show_more"  class="more_camp_items" >
								<div class="more_camp_item" @click.stop="changeTrainingCamp(item)" :class="{'last_item': item == 5}" v-for="item of 5">QCA方法论教师特训营</div>
							</div>
						</div>
					</div> -->
				</div>
			</div>

			<!-- 特训营标题 -->
			<div style="display: flex;font-size: 36px; color: #333;line-height: 1; margin: 36px 0;">
				{{detailData.camp_title}}
			</div>

			<!-- 特训营学习页切换卡 -->
			<div class="study_tabs">
				<div class="study_tab" :class="{'actived': study_tab_index == 1}" @click="changeStudyTab(1)">
					课程学习清单
					<div class="underline"></div>
				</div>
				<div class="study_tab" :class="{'actived': study_tab_index == 2}" @click="changeStudyTab(2)">
					学习资源清单
					<div class="underline"></div>
				</div>
				<div class="study_tab" @click="toCampDetail">
					了解本期特训营
					<div class="underline"></div>
				</div>
				<!-- <div class="study_tab" @click="toRoomDetail">
					<img src="../../assets/images/img_text_room.png" style="height: 17px; width: auto;" />
					<div class="underline"></div>
				</div> -->
			</div>


			<!-- 课程学习清单 -->
			<div style="display: flex; flex-direction: column;" v-if="study_tab_index == 1">
				<!-- 学习进度 -->
				<div
					style="margin-top: 25px;  display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 36px; margin-bottom: 50px; ;line-height: 1;">
					<!-- <div style="font-size: 18px;line-height: 1.85;">
						欢迎，<span style="font-size: 21px; color: #333;">{{detailData.user_name}}</span><br>
						开启{{detailData.camp_title}}的学习！<br>
						{{detailData.camp_title}}完整学习由以下模块组成:
					</div> -->
					
					<div style="font-size: 18px;line-height: 1.85;">
						欢迎您，<br>
						开启{{detailData.camp_title}}特训营的学习！<br>
						{{detailData.camp_title}}完整学习由以下模块组成:
					</div>
					<div style="display: flex; margin-top: 30px;">
						<div v-if="detailData.course_data"
							style="width: 200px; height: 100px; background-color: #FEF5EB; margin-right: 30px; border-radius: 15px; color: #333; display: flex;flex-direction: column;justify-content: center;  align-items: center;">
							<div style="display: inline-flex;align-items: flex-end; ">
								<div style="font-size: 48px;">{{detailData.course_data.total_num}}</div>
								<div style="font-size: 17px;margin-bottom: 8px; margin-left: 3px;">门</div>
							</div>
							<div style="display: inline-flex;">视频课程</div>
						</div>
						<div v-if="detailData.webcast_data"
							style="width: 200px; height: 100px; background-color: #E6EAFE;margin-right: 30px; border-radius: 15px;display: flex;flex-direction: column;justify-content: center;  align-items: center;">
							<div style="display: inline-flex;align-items: flex-end; ">
								<div style="font-size: 48px;">{{detailData.webcast_data.total_num}}</div>
								<div style="font-size: 17px;margin-bottom: 8px; margin-left: 3px;">门</div>
							</div>
							<div style="display: inline-flex;">直播课程</div>
						</div>
						<div v-if="detailData.offline_data"
							style="width: 200px; height: 100px; background-color: #D9EFD0;margin-right: 30px; border-radius: 15px;display: flex;flex-direction: column;justify-content: center;  align-items: center;">
							<div style="display: inline-flex;align-items: flex-end; ">
								<div style="font-size: 48px;">{{detailData.offline_data.total_num}}</div>
								<div style="font-size: 17px;margin-bottom: 8px; margin-left: 3px;">门（{{detailData.offline_data.total_day}}天）</div>
							</div>
							<div style="display: inline-flex;">线下培训课程</div>
						</div>
					</div>
					<div style="display: flex;  margin-top: 30px;">
						<div
							style="display: flex;flex-direction: column; width: 200px; height: 150px; border-right: 1px solid #eee;">
							<div style="font-size: 14px;color:#808080;">
								累计学习时长
							</div>
							<div
								style="flex: 1; display: flex;justify-content: center; align-items: center;color: #333;">
								<div style="font-size: 48px;">{{detailData.total_study_length}}</div>
								<div style="font-size: 17px;height: 36px; display: flex;align-items: flex-end;">小时</div>
							</div>
						</div>
						<div style="margin-left: 30px;flex: 1; display: flex; flex-direction: column;">
							<div style="font-size: 14px;color:#808080;">
								课程学习进度：
							</div>
							<div style="flex: 1; display: flex; align-items: flex-end;">
								<div v-if="findProductType(4).length > 0" class="class1"
									style="display: flex;  width: 150px; flex-direction: column; justify-content: center; align-items: center;">
									<el-progress type="circle" :percentage="detailData.course_percentage" :width="72"
										color="#FEF5EB"></el-progress>
									<div style="font-size: 14px; color: #333;margin-top: 18px;">视频课程</div>
								</div>
								<div v-if="findProductType(28).length > 0" class="class2"
									style="display: flex; width: 150px;flex-direction: column; justify-content: center; align-items: center;">
									<el-progress type="circle" :percentage="detailData.webcast_percentage" :width="72" color="#E6EAFE"
										define-back-color='#F1F1F1'></el-progress>
									<div style="font-size: 14px; color: #333;margin-top: 18px;">直播课程</div>
								</div>
								<div v-if="findProductType(29).length > 0"  class="class3"
									style="display: flex; width: 150px;flex-direction: column; justify-content: center; align-items: center;">
									<el-progress type="circle" :percentage="detailData.offline_percentage" :width="72" color="#D9EFD0"
										:format="format" define-back-color='#F1F1F1'></el-progress>
									<div style="font-size: 14px; color: #333; margin-top: 18px;">线下培训课程</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div class="study_tabs study_tabs2">
					<div class="study_tab" :class="{'actived': study_tab_index2 == 1}" @click="changeStudyTab2(1)">
						按课程类型
						<div class="underline"></div>
					</div>
					<div class="study_tab" :class="{'actived': study_tab_index2 == 2}" @click="changeStudyTab2(2)">
						按内容模块
						<div class="underline"></div>
					</div>
				</div>

				<!-- 按课程类型 -->
				<div v-if="study_tab_index2 == 1"
					style="margin-top: 25px;  display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 36px; margin-bottom: 50px; ;line-height: 1;">

					<!-- 视频课程 -->
					
					<div style="display: flex;flex-direction: column;" v-for="(product,product_index) of detailData.product_types">
						<div style="display: flex; flex-direction: column;" v-if="product.product_type == 4">
							
							<div style="font-size: 17px; color: #333; display: flex; align-items: center;">
								<div style="background: #FDD6B5; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
								{{product.product_type_value}}（共{{product.total_num}}门）
							</div>
							
							<div class="course_list" style="margin-top: 35px; border-bottom: 2px solid #eee;">
								<div class="item" style="display: flex; flex-direction: column;border-radius: 0 0 20px 20px;" v-for="(item,index) in detailData.course_list" @click="toCourseDetail(item)">
									<div style="display: flex; flex-direction: column;border-radius: 0 0 20px 20px;">
										<img style="width: 100%;" src="../../assets/images/cover.png" v-if="item.img_url_tt==''" />
										<img style="width: 100%;" :src="item.img_url_tt" v-if="item.img_url_tt!=''" />
										<div class="" style="flex: 1; background-color: #F1F1F1; display: flex;flex-direction: column;">
											<div style="height: 100px;  display: flex;flex-direction: column; padding: 15px 10px 10px 15px;">
												<div style="font-size: 20px; font-weight: 600; line-height: 1.2;display: -webkit-box;overflow: hidden;text-overflow: ellipsis;line-clamp: 2; -webkit-line-clamp: 2; -webkit-box-orient: vertical;max-height: calc(1.5em * 2);  ">
													{{item.title}}
												</div>
												<div style="font-size: 18px;line-height: 1; margin-top: 8px;">主讲人：{{item.author}}
												</div>
											</div>
											<div style="height: 40px;  border-radius: 0 0 20px 20px; border-top: 1px solid #E8E8E8; display: flex; justify-content: space-between; align-items: center; padding:0 17px 0 20px;">
												<div style="display: flex;font-size: 13px; align-items: center;">
													<div style="background-color: #c93a2e;color: #fff; padding:2px 8px; border-radius:20px;">
														{{item.res_count}}小节
													</div>
													<!-- <div style=" margin-left: 8px;"><i class="el-icon-pie-chart" style="margin-right: 2px;"></i>{{item.res_length}}</div> -->
												</div>
												
												<div style=" margin-left: 8px;font-size: 13px;"><i class="el-icon-pie-chart" style="margin-right: 2px;"></i>{{item.res_length}}</div>
												
												<!-- <div style="font-weight: 600; font-size: 17px; color: #c93a2e;"
													v-if="item.price!='0.00'">¥{{item.price}}</div>
												<div style="font-weight: 600; font-size: 17px; color: #c93a2e;"
													v-if="item.price=='0.00'">免费</div> -->
											</div>
										</div>
									</div>
									<div style="display: flex;justify-content: center;align-items: flex-end; margin-top: 15px;">
										<div style="font-size: 14px; margin-bottom: 2px; margin-right: 5px;" v-if="item.study_percentage ==100">已学习</div>
										<div style="font-size: 24px;" v-if="item.study_percentage !=100">{{item.study_percentage}}%</div>
									</div>
								</div>
							</div>
						</div>
						
						
						<div style="display: flex; flex-direction: column;" v-if="product.product_type == 28">
							
							<!-- 直播课程 -->
							<div style="font-size: 17px; color: #333; display: flex; align-items: center; margin: 30px 0 5px 0;">
								<div style="background: #B4C1FC; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
								{{product.product_type_value}}（共{{product.total_num}}门）
							</div>
							
							<div style="display: flex;flex-direction: column;line-height: 1; border-bottom: 2px solid #eee;">
								
								<div v-for="(item,index) in detailData.webcast_list" @click="toWebcastDetail(item)" style="display: flex; justify-content:space-between;  padding: 25px 0; border-bottom: 1px solid #eee; cursor: pointer;" :class="{'border-bottom-none': index == detailData.webcast_list.length -1 }">
									<div style="display: flex;" v-if="item.teacher_list && item.teacher_list.length == 1">
										<div style="display: inline-flex; font-size: 14px; display: flex;align-items: center; margin-right: 15px;">{{item.start_at}}</div>
										<div style="width: 64px; height: 64px; margin-right: 15px; display: flex; justify-content: center;  align-items: center; border-radius: 50%;background: linear-gradient(to bottom,#ff9000,#c93a2e); box-sizing: border-box;">
											<img :src="item.teacher_list[0].img_url" style="width: 58px; height: 58px;  border-radius: 50%;background-color:#CAD6E9 ;" />
										</div>
										<div style="flex: 1; display: flex;flex-direction: column;justify-content: space-around; padding: 5px 0;">
											<div style="display: flex;">
												<div style="font-size: 18px;">{{item.title}}</div>
												<div v-if="item.is_view == 1" style="background: linear-gradient(to bottom,#c93a2e,#ff9000);color:#fff;font-size: 12px; padding:3px 6px; border-radius: 16px; margin-left: 5px;">
													已学习
												</div>
											</div>
											<div style="display: flex;font-size: 14px;">
												<div style="font-weight: 600; margin-right: 5px;">{{item.teacher_list[0].name}}</div>
												<div v-if="item.teacher_list[0].title">{{item.teacher_list[0].title}}</div>
												<div v-if="!item.teacher_list[0].title && item.teacher_list[0].job">{{item.teacher_list[0].job}}</div>
											</div>
										</div>
									</div>
									<div style="display: flex; flex-direction: column;" v-if="item.teacher_list && item.teacher_list.length > 1">
										
										<div style="display: flex; ">
											<div style="font-size: 18px;">{{item.title}}</div>
											<div v-if="item.is_view == 1" style="background: linear-gradient(to bottom,#c93a2e,#ff9000);color:#fff;font-size: 12px; padding:3px 6px; border-radius: 16px; margin-left: 5px;">
												已学习
											</div>
										</div>
										<div style="display: flex; ">
											<div style="width: 86px; font-size: 14px; display: flex;align-items: center;">{{item.start_at}}</div>
											<div style="display: flex; flex-direction: column; margin-top: 15px;">
												<div style="display: flex;padding: 5px 0;" v-for="(tem,dex) in item.teacher_list">
													<div style="width: 40px; height: 40px; margin-right: 15px; display: flex; justify-content: center;  align-items: center; border-radius: 50%;background: linear-gradient(to bottom,#ff9000,#c93a2e); box-sizing: border-box;">
														<img :src="tem.img_url" style="width: 36px; height: 36px;  border-radius: 50%;background-color:#CAD6E9 ;" />
													</div>
													<div style="flex: 1;display: flex; align-items: center; font-size: 14px;padding: 5px 0;">
														<div style="font-weight: 600;  width: 4em;">{{tem.name}}</div>
														<div v-if="tem.title">{{tem.title}}</div>
														<div v-if="!tem.title && tem.job">{{tem.job}}</div>
													</div>
												</div>
											</div>
											
										</div>
	
									</div>
									<div style="display: flex; border-bottom: 1px; justify-content: center; align-items: center; font-size: 15px;color: #555;">
										<div style="border-bottom: 1px solid #555; padding-bottom: 5px;">
											<span v-if="item.is_stop == 0">观看直播</span>
											<span v-if="item.is_stop == 1">观看直播回放</span>
										</div>
									</div>
								</div>
								
							</div>
						</div>
						
						
						<div style="display: flex; flex-direction: column;" v-if="product.product_type == 29 && detailData.offline_list">
								
								<!-- 线下培训课程 -->
								<div style="font-size: 17px; color: #333; display: flex; align-items: center; margin: 30px 0 5px 0;">
									<div style="background: #69A452; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
									{{product.product_type_value}}（共{{product.total_day}}天）
									<div v-if="detailData.offline_list[0].is_join == 1" style="background: linear-gradient(to bottom,#c93a2e,#ff9000);color:#fff;font-size: 12px; padding:3px 6px; border-radius: 16px; margin-left: 5px;">
										已参加
									</div>
								</div>
								
								<div style="display: flex; flex-direction: column; padding: 20px 0;  border-bottom: 1px solid #eee;" :class="{'border-bottom-none':index == detailData.offline_list.length-1}" v-for="(item,index) in detailData.offline_list">
									<div style="font-size: 17px; font-weight: 600;">{{item.title}}</div>
									<div style="display: flex;line-height: 1;">
										<div style="display: flex;padding: 20px 35px 20px 10px;" v-for="(tem,dex) in item.teacher_list">
											<div style="width: 64px; height: 64px; margin-right: 15px; display: flex; justify-content: center;  align-items: center; border-radius: 50%;background: linear-gradient(to bottom,#ff9000,#c93a2e); box-sizing: border-box;">
												<img :src="tem.img_url" style="width: 58px; height: 58px;  border-radius: 50%;background-color:#CAD6E9 ;" />
											</div>
											<div style="display: flex;flex-direction: column; justify-content: center;">
												<div style="font-size: 14px;font-weight: 600;">{{tem.name}}</div>
												<div v-if="tem.title" style="font-size: 12px; margin-top: 10px; line-height: 1.5;">
													{{tem.title}}
												</div>
												<div v-if="!tem.title && tem.job" style="font-size: 12px; margin-top: 10px; line-height: 1.5;">
													{{tem.job}}
												</div>
												<div v-if="!tem.title && !tem.job && tem.school" style="font-size: 12px; margin-top: 10px; line-height: 1.5;">
													{{tem.school}}
												</div>
									
											</div>
										</div>
									</div>
									<div style="display: flex; flex-direction: column; line-height: 1;font-size: 14px; line-height: 2;">
										<div style="display: flex; ">
											<div style="font-weight: 600;">地点：</div>
											<div>{{item.place}} </div>
										</div>
										<div style="display: flex; ">
											<div style="font-weight: 600;">时间：</div>
											<div>{{item.start_at}}-{{item.stop_at}}</div>
										</div>
										<div style="display: flex; ">
											<div style="font-weight: 600;">日程：</div>
											<div style="flex: 1;" v-html="item.description"></div>
										</div>
										
									</div>					
								</div>
						</div>
						
						
					</div>
					

				</div>


				<!-- 按内容模块 -->
				<div v-if="study_tab_index2 == 2" style="display: flex; flex-direction: column;">

					<!-- 模块1 -->
					<div v-for="(pmodule,pindex) in detailData.product_modules" style="margin-top: 25px;  display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 36px; margin-bottom: 50px; ;line-height: 1;">

						<div  style="display: flex; align-items: center; margin-bottom: 36px;">
							<div style="background: linear-gradient(to right, #ff9000, #c93a2e); width: 10px; height: 10px; margin-right: 8px;"></div>
							<div style="font-size:21px;">模块{{pindex+1}} {{pmodule.title}}</div>
						</div>

						<div style="display: flex;flex-direction: column; padding-left: 20px;">
							<div style="display: flex;flex-direction: column;" v-for="(product,product_index) of detailData.product_types">
								<div style="display: flex; flex-direction: column;border-bottom: 2px solid #eee;" :class="{'border-bottom-none':findMyListLength(detailData.course_list,pmodule.id,28) == 0 && findMyListLength(detailData.course_list,pmodule.id,29) == 0 }" v-if="product.product_type == 4 && findMyListLength(detailData.course_list,pmodule.id,product.product_type) > 0">
									
									<div style="font-size: 17px; color: #333; display: flex; align-items: center;">
										<div style="background: #FDD6B5; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
										{{product.product_type_value}}（共{{findMyListLength(detailData.course_list,pmodule.id,product.product_type)}}门）
									</div>
									
									<div class="course_list" style="margin-top: 35px; ">
										<div class="item" style="display: flex; flex-direction: column;border-radius: 0 0 20px 20px;" v-for="(item,index) in findMyList(detailData.course_list,pmodule.id,product.product_type)" @click="toCourseDetail(item)">
											<div style="display: flex; flex-direction: column;border-radius: 0 0 20px 20px;">
												<img style="width: 100%;" src="../../assets/images/cover.png" v-if="item.img_url_tt==''" />
												<img style="width: 100%;" :src="item.img_url_tt" v-if="item.img_url_tt!=''" />
												<div class="" style="flex: 1; background-color: #F1F1F1; display: flex;flex-direction: column;">
													<div style="height: 100px;  display: flex;flex-direction: column; padding: 15px 10px 10px 15px;">
														<div style="font-size: 20px; font-weight: 600; line-height: 1.2;display: -webkit-box;overflow: hidden;text-overflow: ellipsis;line-clamp: 2; -webkit-line-clamp: 2; -webkit-box-orient: vertical;max-height: calc(1.5em * 2); ">
															{{item.title}}
														</div>
														<div style="font-size: 18px;line-height: 1; margin-top: 8px;">主讲人：{{item.author}}
														</div>
													</div>
													<div style="height: 40px;  border-radius: 0 0 20px 20px; border-top: 1px solid #E8E8E8; display: flex; justify-content: space-between; align-items: center; padding:0 17px 0 20px;">
														<div style="display: flex;font-size: 13px; align-items: center;">
															<div style="background-color: #c93a2e;color: #fff; padding:2px 8px; border-radius:20px;">
																{{item.res_count}}小节
															</div>
															<!-- <div style=" margin-left: 8px;"><i class="el-icon-pie-chart" style="margin-right: 2px;"></i>{{item.res_length}}</div> -->
														</div>
														<div style=" margin-left: 8px;font-size: 13px;"><i class="el-icon-pie-chart" style="margin-right: 2px;"></i>{{item.res_length}}</div>
														<!-- <div style="font-weight: 600; font-size: 17px; color: #c93a2e;"
															v-if="item.price!='0.00'">¥{{item.price}}</div>
														<div style="font-weight: 600; font-size: 17px; color: #c93a2e;"
															v-if="item.price=='0.00'">免费</div> -->
													</div>
												</div>
											</div>
											<div style="display: flex;justify-content: center;align-items: flex-end; margin-top: 15px;">
												<div style="font-size: 14px; margin-bottom: 2px; margin-right: 5px;" v-if="item.study_percentage ==100">已学习</div>
												<div style="font-size: 24px;" v-if="item.study_percentage !=100">{{item.study_percentage}}%</div>
											</div>
										</div>
									</div>
								</div>
								
								
								<div style="display: flex; flex-direction: column;border-bottom: 2px solid #eee;" :class="{'border-bottom-none': findMyListLength(detailData.course_list,pmodule.id,29) == 0 }" v-if="product.product_type == 28 && findMyListLength(detailData.webcast_list,pmodule.id,product.product_type) > 0">
									
									<!-- 直播课程 -->
									<div style="font-size: 17px; color: #333; display: flex; align-items: center; margin: 30px 0 5px 0;">
										<div style="background: #B4C1FC; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
										{{product.product_type_value}}（共{{findMyListLength(detailData.webcast_list,pmodule.id,product.product_type)}}门）
									</div>
									
									<div style="display: flex;flex-direction: column;line-height: 1; ">
										
										<div v-for="(item,index) in findMyList(detailData.webcast_list,pmodule.id,product.product_type)" @click="toWebcastDetail(item)" style="display: flex; justify-content:space-between;  padding: 25px 0; border-bottom: 1px solid #eee; cursor: pointer;" :class="{'border-bottom-none': index == findMyListLength(detailData.webcast_list,pmodule.id,product.product_type) -1 }">
											<div style="display: flex;" v-if="item.teacher_list && item.teacher_list.length == 1">
												<div style="display: inline-flex; font-size: 14px; display: flex;align-items: center; margin-right: 15px;">{{item.start_at}}</div>
												<div style="width: 64px; height: 64px; margin-right: 15px; display: flex; justify-content: center;  align-items: center; border-radius: 50%;background: linear-gradient(to bottom,#ff9000,#c93a2e); box-sizing: border-box;">
													<img :src="item.teacher_list[0].img_url" style="width: 58px; height: 58px;  border-radius: 50%;background-color:#CAD6E9 ;" />
												</div>
												<div style="flex: 1; display: flex;flex-direction: column;justify-content: space-around; padding: 5px 0;">
													<div style="display: flex;">
														<div style="font-size: 18px;">{{item.title}}</div>
														<div v-if="item.is_view == 1" style="background: linear-gradient(to bottom,#c93a2e,#ff9000);color:#fff;font-size: 12px; padding:3px 6px; border-radius: 16px; margin-left: 5px;">
															已学习
														</div>
													</div>
													<div style="display: flex;font-size: 14px;">
														<div style="font-weight: 600; margin-right: 5px;">{{item.teacher_list[0].name}}</div>
														<div v-if="item.teacher_list[0].title">{{item.teacher_list[0].title}}</div>
														<div v-if="!item.teacher_list[0].title && item.teacher_list[0].job">{{item.teacher_list[0].job}}</div>
													</div>
												</div>
											</div>
											<div style="display: flex; flex-direction: column;" v-if="item.teacher_list && item.teacher_list.length > 1">
												
												<div style="display: flex; ">
													<div style="font-size: 18px;">{{item.title}}</div>
													<div v-if="item.is_view == 1" style="background: linear-gradient(to bottom,#c93a2e,#ff9000);color:#fff;font-size: 12px; padding:3px 6px; border-radius: 16px; margin-left: 5px;">
														已学习
													</div>
												</div>
												<div style="display: flex; ">
													<div style="width: 86px; font-size: 14px; display: flex;align-items: center;">{{item.start_at}}</div>
													<div style="display: flex; flex-direction: column; margin-top: 15px;">
														<div style="display: flex;padding: 5px 0;" v-for="(tem,dex) in item.teacher_list">
															<div style="width: 40px; height: 40px; margin-right: 15px; display: flex; justify-content: center;  align-items: center; border-radius: 50%;background: linear-gradient(to bottom,#ff9000,#c93a2e); box-sizing: border-box;">
																<img :src="tem.img_url" style="width: 36px; height: 36px;  border-radius: 50%;background-color:#CAD6E9 ;" />
															</div>
															<div style="flex: 1;display: flex; align-items: center; font-size: 14px;padding: 5px 0;">
																<div style="font-weight: 600;  width: 4em;">{{tem.name}}</div>
																<div v-if="tem.title">{{tem.title}}</div>
																<div v-if="!tem.title && tem.job">{{tem.job}}</div>
															</div>
														</div>
													</div>
													
												</div>
												
											</div>
											<div style="display: flex; border-bottom: 1px; justify-content: center; align-items: center; font-size: 14px;color: #555;">
												<div style="border-bottom: 1px solid #555; padding-bottom: 5px;">
													<span v-if="item.is_stop == 0">观看直播</span>
													<span v-if="item.is_stop == 1">观看直播回放</span>
												</div>
											</div>
										</div>
										
									</div>
								</div>
								
								
								<div style="display: flex; flex-direction: column;" v-if="product.product_type == 29 && findMyListLength(detailData.offline_list,pmodule.id,product.product_type)>0">
										
										<!-- 线下培训课程 -->
										<div style="font-size: 17px; color: #333; display: flex; align-items: center; margin: 30px 0 5px 0;">
											<div style="background: #69A452; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
											{{product.product_type_value}}（共{{findTotalDay(detailData.offline_list,pmodule.id,product.product_type)}}天）
											<div v-if="findMyList(detailData.offline_list,pmodule.id,product.product_type)[0].is_join == 1" style="background: linear-gradient(to bottom,#c93a2e,#ff9000);color:#fff;font-size: 12px; padding:3px 6px; border-radius: 16px; margin-left: 5px;">
												已参加
											</div>
										</div>
										
										<div style="display: flex; flex-direction: column;padding: 20px 0;  border-bottom: 1px solid #eee;" :class="{'border-bottom-none':index == findMyListLength(detailData.offline_list,pmodule.id,product.product_type)-1}" v-for="(item,index) in findMyList(detailData.offline_list,pmodule.id,product.product_type)">
											<div style="font-size: 17px; font-weight: 600;">{{item.title}}</div>
											<div style="display: flex;line-height: 1;">
												<div style="display: flex;padding: 20px 35px 20px 10px;" v-for="(tem,dex) in item.teacher_list">
													<div style="width: 64px; height: 64px; margin-right: 15px; display: flex; justify-content: center;  align-items: center; border-radius: 50%;background: linear-gradient(to bottom,#ff9000,#c93a2e); box-sizing: border-box;">
														<img :src="tem.img_url" style="width: 58px; height: 58px;  border-radius: 50%; background-color:#CAD6E9 ;" />
													</div>
													<div style="display: flex;flex-direction: column; justify-content: center;">
														<div style="font-size: 14px;font-weight: 600;">{{tem.name}}</div>
														<div v-if="tem.title" style="font-size: 12px; margin-top: 10px; line-height: 1.5;">
															{{tem.title}}
														</div>
														<div v-if="!tem.title && tem.job" style="font-size: 12px; margin-top: 10px; line-height: 1.5;">
															{{tem.job}}
														</div>
														<div v-if="!tem.title && !tem.job && tem.school" style="font-size: 12px; margin-top: 10px; line-height: 1.5;">
															{{tem.school}}
														</div>
											
													</div>
												</div>
											</div>
											<div style="display: flex; flex-direction: column; line-height: 1;font-size: 14px; line-height: 2;">
												<div style="display: flex; ">
													<div style="font-weight: 600;">地点：</div>
													<div>{{item.place}} </div>
												</div>
												<div style="display: flex; ">
													<div style="font-weight: 600;">时间：</div>
													<div>{{item.start_at}}-{{item.stop_at}}</div>
												</div>
												<div style="display: flex; ">
													<div style="font-weight: 600;">日程：</div>
													<div style="flex: 1;" v-html="item.description"></div>
												</div>

											</div>					
										</div>
								</div>
								
								
							</div>
						</div>
					</div>

				</div>
			</div>



			<!-- 学习资源清单 -->
			<div v-if="study_tab_index == 2" style="margin-top: 25px;  display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 36px; margin-bottom: 50px; ;line-height: 1;">
				<!-- 视频课程 -->
				
				<div style="display: flex; flex-direction: column;" v-for="(product,product_index) in detailData.product_types" v-if="resData && product.product_type!=26 && findResList(product.product_type).length>0">
					<div style="font-size: 17px; color: #333; display: flex; align-items: center; margin: 30px 0 5px 0;">
						<div v-if="product.product_type == 4" style="background: #FDD6B5; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
						<div v-if="product.product_type == 28" style="background: #B4C1FC; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
						<div v-if="product.product_type == 29" style="background: #69A452; width: 8px; height: 8px; border-radius: 50%; margin-right: 8px;"></div>
						{{product.product_type_value}}（共{{findResList(product.product_type).length}}门）
					</div>
					
					<div :class="{'border-bottom-none': product_index == detailData.product_types.length-1}" style="display: flex; flex-direction: column; padding: 25px 0; border-bottom: 2px solid #eee; padding-left: 16px; box-sizing: border-box;">
						<div v-for="(item,index) in findResList(product.product_type)" style="display: flex;justify-content: space-between; padding: 15px 0;cursor: pointer;">
							<div>{{index+1}}、{{item.title}}</div>
							<div style="display: flex; align-items: center;" @click="downloadClick(item)">
								<img src="../../assets/images/icon_download.png" style="width: 14px; height: auto;" />
								<div style="display: inline-flex; font-size: 14px; margin-left:5px;color: #999;border-bottom: 1px solid #999; padding-bottom: 3px;margin-top: 3px;">
									{{item.file_name}}
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div v-if="!resData || (resData && resData.length == 0)" style="width: 100%; height: 420px;">
					<el-empty :image-size="200"  description="暂无相关学习资源，敬请期待..."></el-empty>
				</div>

			</div>
		</div>
		
		<!-- 手机端扫码访问 -->
		<div  style="width: 150px; height: 40px; position: fixed; bottom: 40px; right: 20px; z-index: 999;">
			<el-popover
			  placement="bottom"
			  width="200"
			  trigger="click"
			>
			
			<div style="display: flex;  flex-direction: column;justify-content: center; align-items: center;width: 100%; height: 200px;">
				<div ref="qrcode" style="width: 120px; height: 120px;"></div>
				<div style="font-size: 13px; width: 200; margin-top: 15px;display: flex;flex-direction: column; align-items: center;">
					扫码访问手机版学习空间
				</div>
			</div>

			 <el-button slot="reference">手机观看二维码</el-button>
			</el-popover>
		</div>
		
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import download from 'downloadjs';
	import QRCode from "qrcodejs2";

	export default {
		data() {
			return {
				id:null,
				detailData:null,
				resData:null,
				study_tab_index: 1,
				study_tab_index2: 1,
				show_more:false,
				selected:null,
				isLogin:true,
			};
		},
		created() {
			document.title = "机工教师特训营";
			if(!this.isLogin){
				window.top.location.href = "/login";
				return;
			}
			var option = {
				id: this.$route.query.id || "",
			};
			if (option.id) {
				this.id = option.id;
				this.getDetail(()=>{
					this.getResList();
				});
			}
		},
		components: {
			qrcode: QRCode,
		},
		methods: {
			downloadClick(item){
				download(item.file_path,item.file_name);
			},
			findProductType(product_type){
				 return this.detailData.product_types.filter(el => el.product_type == product_type);
			},
			findResList(product_type){
				return this.resData.filter(el => el.product_type == product_type);
			},
			findMyList(list,my_module_id,product_type){
				if(list == null || !list  || (list && list.length == 0)){
					return null;
				}
				if(product_type == 4){
					return this.detailData.course_list.filter(el => el.module_id == my_module_id);
				}else if(product_type == 28){
					return this.detailData.webcast_list.filter(el => el.module_id ==my_module_id);
				}else if(product_type == 29){
					return this.detailData.offline_list.filter(el => el.module_id ==my_module_id);
				}else{
					return null;
				}
			},
			findMyListLength(list,my_module_id,product_type){
				if(list == null || !list  || (list && list.length == 0)){
					return null;
				}
				if(product_type == 4){
					return this.detailData.course_list.filter(el => el.module_id == my_module_id).length;
				}else if(product_type == 28){
					return this.detailData.webcast_list.filter(el => el.module_id ==my_module_id).length;
				}else if(product_type == 29){
					return this.detailData.offline_list.filter(el => el.module_id ==my_module_id).length;
				}else{
					return 0;
				}
			},
			findTotalDay(list,my_module_id,product_type){
				if(list == null || !list  || (list && list.length == 0)){
					return 0;
				}
				if(product_type == 29){
					var _list = this.detailData.offline_list.filter(el => el.module_id ==my_module_id);
					var total_day = 0.0;
					_list.forEach((tem,dex)=>{
						total_day +=parseFloat(tem.total_day);
					})
					return total_day.toFixed(1);
				}else{
					return 0;
				}
			},
			changeTrainingCamp(item){
				this.selected = item;
				this.show_more = false;
			},
			format(percentage) {
				return percentage === 100 ? '完成' : '未完成';
			},
			changeStudyTab(index) {
				this.study_tab_index = index;
			},
			toCampDetail() {
				var url = "/trainingCamp/detail";
				var query = {
					id: this.id
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			toRoomDetail(item) {
				/*
				var url = "/teachRoom/detail";
				var query = { id: item.id };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				*/
				var url = "/teachRoom/detail";
				var query = {
					id: '56bd65acb72f4e48963307e70d6bbba1'
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			toCourseDetail(item){
				if(item.res_length =='0分钟'){
					this.$alert('课程内容正在准备中..', '提示', {
						confirmButtonText: '确定'
					});
				}else{
					var url ='/course/detail';
					var query = {
						id: item.id,
						tt_camp_id:this.id
					};
					let routeUrl = this.$router.resolve({
						path: url,
						query: query,
					});
					window.open(routeUrl.href, "_blank");
				}
			},
			toWebcastDetail(item){
				if(item.has_webcast_url!=1){
					this.$alert('直播间正在创建中..', '提示', {
						confirmButtonText: '确定'
					});
				}else{
					if(item.skip_type ==2){
						window.open(item.webcast_url, "_blank");
					}else{
						var url ='/webcast/detail';
						var query = {
							id: item.id,
							tt_camp_id:this.id
						};
						let routeUrl = this.$router.resolve({
							path: url,
							query: query,
						});
						window.open(routeUrl.href, "_blank");
					}
				}
			},
			changeStudyTab2(index) {
				this.study_tab_index2 = index;
			},
			getDetail(callback) {
				var that = this;
				var url = apiUrl.tt_camp_study_detail_get;
				this.Ajax(url, {id: this.id}, (data) => {
					if(data){
						that.detailData = data;
						document.title = data.camp_title + " - 教师特训营 - 机工教师特训营";
						
						var qrcode_url = 'https://m.cmptt.com/pages/camp/campStudy?id='+this.id;
						that.$nextTick(()=>{
							that.setupQrCode(that.$refs.qrcode, qrcode_url, 120, (code_img) => {
								that.qrcode = code_img;
							});
						})
						
						if(callback) callback();
					}
				});
			},
			getResList() {
				var that = this;
				var url = apiUrl.tt_camp_study_res_list_get;
				this.Ajax(url, {id: this.id}, (data) => {
					if(data){
						that.resData = data;
					}
				});
			},
			//QrCode
			setupQrCode(el, url, width, callback) {
				let code_img = new QRCode(el, {
					width: width,
					height: width, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
				if (callback) callback(code_img);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.border-bottom-none{
			 border-bottom: none !important;
		}
		/deep/.class1 .el-progress__text {
			color: #333 !important;
			font-size: 14px !important;
		}

		/deep/.class2 .el-progress__text {
			color: #333 !important;
			font-size: 14px !important;
		}

		/deep/.class3 .el-progress__text {
			color: #6aa544 !important;
			font-size: 14px !important;
		}

		.more_camp_items {
			display: flex;
			flex-direction: column;
			width: 234px;
			border-radius: 15px;
			margin-top: 10px;
			background-color: #fff;
			position: absolute;
			left: 0;
			top: 20px;
			z-index: 999;
			box-shadow: 2px 4px 8px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
			padding: 5px 15px;
			box-sizing: border-box;

			.more_camp_item {
				color: #999;
				font-size: 14px;
				width: 100%;
				height: 40px;
				line-height: 40px;
				border-bottom: 1px solid #dfdfdf;
				cursor: pointer;
				
				&:hover{
					color: #333;
				}
				
				&.last_item{
					border-bottom: none !important;
				}
			}
		}


		.study_tabs {
			display: flex;
			font-size: 17px;



			.study_tab {
				color: #999;
				margin-right: 50px;
				cursor: pointer;
				display: inline-flex;
				flex-direction: column;
				align-items: center;

				.underline {
					display: none;
					width: 100%;
					height: 5px;
					background: linear-gradient(to right, #ff9000, #c93a2e);
					margin-top: 5px;

					&.white {
						background: #fff !important;
					}
				}

				&.actived {
					color: #c93a2e !important;

					.underline {
						display: block !important;
					}
				}
			}

			&.study_tabs2 {
				.study_tab {
					.underline {
						width: 30px !important;
						background: linear-gradient(to right, #c93a2e, #ff9000) !important;

						&.actived {
							color: #333 !important;
						}
					}
				}
			}
		}

		.course_list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		
			.item {
				width: 260px;
				height: 390px;
				//height: 325px;
				//border: 1px solid #eee;
				display: flex;
				flex-direction: column;
				//box-shadow: 5px 5px 10px #eee;
				//box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
				margin-right: 20px;
				margin-bottom: 20px;
				cursor: pointer;
				overflow: hidden;
		
				&:nth-child(4n+4) {
					margin-right: 0;
				}
		
				&:hover {
					//box-shadow:0 1px 1px 1px 10px #666;
					//box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.1);
				}
		
				.cover {
					width: 285px;
					height: 220px;
					//height: 165px;
				}
				
		
				.mes {
					width: 100%;
					display: flex;
					flex-direction: column;
					line-height: 1;
					padding-left: 18px;
					box-sizing: border-box;
					margin: 0;
					position: relative;
		
					.title {
						margin-top: 15px;
						height: 20px;
						margin-left: -18px;
						font-size: 20px;
						color: #333;
						font-weight: 600;
						border-left: 8px solid #333;
						padding-left: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
		
		
					.anchor {
						font-size: 17px;
						margin-top: 10px;
						color: #333;
						display: flex;
					}
		
					.res_info {
						display: flex;
						justify-content: flex-start;
						font-size: 12px;
						margin-top: 15px;
						position: relative;
		
						.res_count {
							background-color: #333;
							color: #fff;
							padding: 5px 8px;
							display: inline-flex;
							border-radius: 4px 0 0 4px;
						}
		
						.res_length {
							background-color: #3CD0BA;
							color: #01786D;
							padding: 5px 8px;
							display: inline-flex;
							border-radius: 0 4px 4px 0;
						}
					}
		
					.exts {
						display: flex;
						align-items: flex-end;
						margin-top: 25px;
		
						.price {
							font-size: 21px;
							color: #333;
							margin-right: 8px;
							display: inline-flex;
						}
		
						.readers {
							font-size: 12px;
							color: #3CD0BA;
							display: inline-flex;
						}
					}
		
					.icon_play {
						width: 150px;
						height: 150px;
						margin: 0;
						position: absolute;
						z-index: 2;
						right: -24px;
						bottom: -24px;
					}
				}
			}
		}
	}
</style>