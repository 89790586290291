<template>
	<div class="page-main" style="position: relative;" :id="backtop_id">
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="page_index == 1">
			<!-- 报名 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						报名
					</div>
				</div>
			</div>
			
			<div v-if="detailData" style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 35px; margin-bottom: 60px; ;line-height: 1;">
				<div style="display: flex; justify-content: space-between;padding-bottom: 22px; border-bottom: 1px solid #eee;">
					<div style="display: flex; flex-direction: column;font-size: 18px; color: #333;">
						<div style="font-weight: 600;">项目</div>
						<div style="margin-top: 40px;">{{detailData.title}}</div>
					</div>
					<div style="display: flex; flex-direction: column; justify-content: flex-end;">
						<div style="font-size: 12px; color: #ff9000;">总计</div>
						<div style="font-size: 18px; color: #333; margin-top: 24px;">{{price}} x {{gift_count}} = ￥{{total_fee}}</div>
					</div>
				</div>

				<div style="display: flex;flex-direction: column;">
					<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0;">
						个人信息
					</div>
					
					<div style="display: inline-block; width: 640px;">
						<el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="240px" >
						
							<div v-for="(domain, index) in dynamicValidateForm.domains" :key="domain.key">
								<el-divider content-position="left" v-if="index>0">参学人员 +{{index}}</el-divider>
								
								<el-form-item :prop="'domains.' + index + '.name'"  label="姓名" :rules="[{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.name" placeholder="请输入姓名"></el-input>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.sex'" label="性别" :rules="[{ required: true, message: '请选择性别', trigger: 'change' }]">
									<el-radio-group v-model="domain.sex">
									      <el-radio label="男"></el-radio>
									      <el-radio label="女"></el-radio>
									    </el-radio-group>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.mobile'" label="手机" :rules="[{ required: true, message: '请输入手机号码', trigger: ['blur', 'change'] },{ pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }]">
									<el-input @blur="inputMobileBlurEvent" v-model="domain.mobile" placeholder="请输入手机号码"></el-input>
								</el-form-item>
								
								
								<el-form-item :prop="'domains.' + index + '.job_type'" label="身份" :rules="[{ required: true, message: '请选择身份类型', trigger: 'change' }]">
									<el-radio-group v-model="domain.job_type" @input="jobTypeChangeEvent" :disabled="domain.job_type_disabled">
									    <el-radio label="教师"></el-radio>
									    <el-radio label="学生"></el-radio>
										<el-radio label="其他"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="domain.job_type == '教师' || domain.job_type == '学生'" :prop="'domains.' + index + '.school'" label="所在学校" :rules="[ { required: true, message: '请输入学校', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.school" placeholder="请输入学校"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师' || domain.job_type == '学生'" :prop="'domains.' + index + '.college'" label="学院" :rules="[ { required: true, message: '请输入学院', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.college" placeholder="请输入学院"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'" :prop="'domains.' + index + '.title'" label="职称" :rules="[ { required: true, message: '请输入职称', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.title" placeholder="请输入职称"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'" :prop="'domains.' + index + '.duty'" label="职务" :rules="[ { required: true, message: '请输入职务', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.duty" placeholder="请输入职务"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '教师'" :prop="'domains.' + index + '.teaching_length'" label="教龄" :rules="[{ required: true, message: '请输入教龄', trigger: ['blur', 'change'] },{ type: 'number', message: '教龄必须为数字类型', trigger: ['blur', 'change']}]">
									<el-input v-model.number="domain.teaching_length" placeholder="请输入教龄"></el-input>
								</el-form-item>
								
								<el-form-item v-if="domain.job_type == '其他'" :prop="'domains.' + index + '.company'" label="公司(单位)名称" :rules="[ { required: true, message: '请输入公司或单位名称', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.company" placeholder="请输入公司(单位)名称"></el-input>
								</el-form-item>
								<el-form-item v-if="domain.job_type == '学生' || domain.job_type == '其他'" :prop="'domains.' + index + '.education'" label="学历" :rules="[{ required: true, message: '请选择学历', trigger: 'change' }]">
									<el-radio-group v-model="domain.education">
									    <el-radio label="专科"></el-radio>
									    <el-radio label="本科"></el-radio>
										<el-radio label="研究生"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								
								<div style=" padding:5px 0 10px 120px; box-sizing: border-box;">
									<el-divider content-position="left">会议服务选择</el-divider>
								</div>
								
								<el-form-item :prop="'domains.' + index + '.check_in_date'" label="报到日期" :rules="[{ required: true, message: '请选择报到日期', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.check_in_date">
										<el-radio :label="tem" v-for="(tem,dex) in detailData.form_arrival_items"></el-radio>
										<el-radio label="其他"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="domain.check_in_date == '其他'" :prop="'domains.' + index + '.check_in_date_other'" label="其他报到日期" :rules="[ { required: true, message: '请输入其他报道日期', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.check_in_date_other" placeholder="请输入其他报到日期"></el-input>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.diet_type'" label="餐饮" :rules="[{ required: true, message: '请选择餐饮类型', trigger: 'change' }]">
									<el-radio-group v-model="domain.diet_type">
									    <el-radio label="清真"></el-radio>
									    <el-radio label="非清真"></el-radio>
									 </el-radio-group>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.stay_type'" label="住宿" :rules="[{ required: true, message: '请选择住宿类型', trigger: 'change' }]">
									<el-radio-group v-model="domain.stay_type">
									    <el-radio label="自行安排"></el-radio>
									    <el-radio label="代行预订"></el-radio>
									 </el-radio-group>
									 <div style="width: 260px; text-align: right;color: #C93A2E; margin-top: -18px;">
										 具体以当日房价为准
									 </div>
								</el-form-item>
								
								<el-form-item v-if="detailData.form_hotel_items && domain.stay_type=='代行预订'" :prop="'domains.' + index + '.hotel'" label="酒店" :rules="[{ required: true, message: '请选择您入住的酒店', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.hotel">
										<el-radio :label="tem" v-for="(tem,dex) in detailData.form_hotel_items" ></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="domain.hotel" :prop="'domains.' + index + '.room_type'" label="请选择入住方式" :rules="[{ required: true, message: '请选择入住方式', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.room_type">
										<el-radio label="单人入住"></el-radio>
										<el-radio label="与指定人员拼房"></el-radio>
										<el-radio label="会务组随机拼房"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item v-if="domain.room_type == '与指定人员拼房'" :prop="'domains.' + index + '.room_share_with'" label="同住人姓名/电话" :rules="[ { required: true, message: '请输入同住人姓名/电话', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.room_share_with" placeholder="请输入同住人姓名/电话"></el-input>
								</el-form-item>
								
								<el-form-item v-if="domain.hotel" :prop="'domains.' + index + '.stay_days'" label="入住天数" :rules="[{ required: true, message: '请选择入住方式', trigger: ['blur', 'change'] }]">
									<el-radio-group v-model="domain.stay_days">
										<el-radio label="1天"></el-radio>
										<el-radio label="2天"></el-radio>
										<el-radio label="3天"></el-radio>
									</el-radio-group>
								</el-form-item>

								<el-form-item :prop="'domains.' + index + '.is_visit'" :label="detailData.form_visit_label" :rules="[{ required: true, message: '请选择您是否参加参观考察', trigger: ['blur', 'change'] }]">
									<div style="line-height: 1.4;margin: 12px 0; color: #999;" v-html="detailData.form_visit_text"></div>
									<el-radio-group v-model="domain.is_visit">
										<el-radio label="是"></el-radio>
										<el-radio label="否"></el-radio>
									</el-radio-group>
								</el-form-item>
						
						
								<el-button @click.prevent="removeDomain(domain)" v-if="index>0">移除参学人员 +{{index}}</el-button>
							</div>
														
							<div style="margin-top: 30px;" v-if="is_gift_add">
								<el-form-item>
									<!-- <el-button type="primary" @click="submitForm('dynamicValidateForm')">验证填写信息</el-button> -->
									<el-button @click="addDomain">新增参学人员</el-button>
									<!-- <el-button @click="resetForm('dynamicValidateForm')">重置参学人员信息</el-button> -->
								</el-form-item>
								<el-alert
								    title="报名信息填写验证通过"
								    type="success"
									v-if="alert_show1">
								  </el-alert>
							</div>
							<div style=" width: 100%; margin-top: 30px; font-size: 15px; color: #515a6e; padding-left: 160px; box-sizing: border-box;" v-if="!is_gift_add">							  
								  <i class="el-icon-warning-outline" style="margin-right: 5px;"></i>如需添加更多参学人员请联系工作人员
							</div>
							
						</el-form>
						
						<div v-if="detailData.student_price" style="display: flex; flex-direction: column; width: 640px;font-size: 14px; margin-top: 12px; line-height: 1.5;text-align: justify;">
							暂不支持同时添加多种身份的参学者，如有需要请分别使用不同账号提交报名信息或联系工作人员<br>
							温馨提示：学生身份的参学者，参会时请务必携带学生证，会务组会在报到时核验报名信息
						</div>
					</div>
					
					
					<!-- <div style="display: flex; font-size: 24px; margin-top: 58px;">
						<el-checkbox v-model="checked">我要发票</el-checkbox>
					</div> -->
					
					<div style="font-size: 18px;color: #333;font-weight: 600; padding: 40px 0 10px 0;">
						发票信息
					</div>
					
					<div style="display: flex; flex-direction: column; width: 640px;">
						<div style="font-size: 14px; margin: 12px 0 30px 0; line-height: 1.5; text-align: justify;">
							我社统一开具电子普票，会后发送至您预留的邮箱。如需专票，请联系工作人员。<br>
							为避免后续开具的发票无法用于报销，填写发票信息前务必找贵校/贵司财务专员确认最新开票信息。
						</div>

						<el-form :model="dynamicValidateForm2" ref="dynamicValidateForm2" label-width="240px">
							<div v-for="(domain, index) in dynamicValidateForm2.domains" :key="domain.key">
								
								<el-form-item v-if="gift_count>1" :prop="'domains.' + index + '.invoice_type'" label="开具方式" :rules="[{ required: true, message: '请选择发票类目', trigger: 'change' }]">
									<el-radio-group v-model="domain.invoice_type" @input="bindInvoiceData">
										  <el-radio label="统一开具" checked></el-radio>
										  <el-radio label="单独开具"></el-radio>
									</el-radio-group>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.invoice_count'" :border="false" label="发票张数" :rules="[{ required: true, message: '请输入发票张数', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.invoice_count" readonly placeholder="请输入发票张数"></el-input>
								</el-form-item>
								
								<el-form-item :prop="'domains.' + index + '.total_fee'"  label="开票金额" :rules="[{ required: true, message: '请输入开票金额', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.total_fee" readonly placeholder="请输入开票金额"></el-input>
								</el-form-item>
								<el-form-item :prop="'domains.' + index + '.title'"  label="发票抬头" :rules="[{ required: true, message: '请输入发票抬头', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.title" placeholder="请输入发票抬头"></el-input>
								</el-form-item>
								<el-form-item :prop="'domains.' + index + '.tax_id'" label="发票税号" :rules="[{ required: true, message: '请输入发票税号', trigger: ['blur', 'change'] },{ min: 15, max:20,message: '请输入正确的发票税号', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.tax_id" placeholder="请输入发票税号(三证合一后税号为18位)"></el-input>
								</el-form-item>
								
								
								<el-collapse accordion>
								  <el-collapse-item>
										<template slot="title">
										   地址/电话/开户行/账号(非必填，点击可展开填写)  <i class="header-icon el-icon-info"></i> 
										</template>
										
										<div>
											<el-form-item :prop="'domains.' + index + '.registered_address'" label="地址(非必填)">
												<el-input v-model="domain.registered_address" placeholder="请输入开票信息中的单位地址"></el-input>
											</el-form-item>
											<el-form-item :prop="'domains.' + index + '.registered_contact'" label="电话(非必填)">
												<el-input v-model="domain.registered_contact" placeholder="请输入开票信息中的单位电话"></el-input>
											</el-form-item>								
											<el-form-item :prop="'domains.' + index + '.bank'" label="开户行(非必填)">
												<el-input v-model="domain.bank" placeholder="请输入开票信息中的开户行名称"></el-input>
											</el-form-item>
											<el-form-item :prop="'domains.' + index + '.bank_account'" label="账号(非必填)">
												<el-input v-model="domain.bank_account" placeholder="请输入开票信息中的银行账号"></el-input>
											</el-form-item>
										</div>
								  </el-collapse-item>
								</el-collapse>
								
								
								<el-form-item :prop="'domains.' + index + '.category'" label="发票类目" :rules="[{ required: true, message: '请选择发票类目', trigger: 'change' }]">
									<el-radio-group v-model="domain.category">
									      <el-radio label="会议费"></el-radio>
										  <el-radio label="培训费"></el-radio>
										  <!--  
										  <el-radio label="信息服务费"></el-radio>
									      <el-radio label="技术服务费"></el-radio>
										  <el-radio label="其他"></el-radio> 
										  -->
									</el-radio-group>
								</el-form-item>
								<el-form-item v-if="domain.category == '其他'" :prop="'domains.' + index + '.category_other'" label="其他发票类目" :rules="[{ required: true, message: '请输入发票类目', trigger: 'change' }]">
									<el-input v-model="domain.category_other" placeholder="请输入其他发票类目"></el-input>
								</el-form-item>
								<el-form-item :prop="'domains.' + index + '.email'" label="发票接收邮箱" :rules="[{ required: true, message: '请输入邮箱地址', trigger: ['blur', 'change'] },{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.email" placeholder="请输入邮箱地址" ></el-input>
								</el-form-item>
								<el-form-item :prop="'domains.' + index + '.mobile'" label="发票接收人手机" :rules="[{ required: true, message: '请输入手机号码', trigger: ['blur', 'change'] },{ pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: ['blur', 'change'] }]">
									<el-input v-model="domain.mobile" placeholder="请输入手机号码"></el-input>
								</el-form-item>
								
							
							</div>
							
							<div style="margin-top: 30px;">
								<el-alert
								    title="开票信息填写验证通过"
								    type="success"
									v-if="alert_show2">
								</el-alert>
							</div>
						</el-form>
					</div>
				</div>
				
				<div @click="infoConfirm" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">提交报名信息</div>
				</div>
				
			</div>
		</div>
		
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="page_index == 2">
			<!-- 支付 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						支付
					</div>
				</div>
			</div>
			
			<div style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 35px; margin-bottom: 60px;line-height: 1;">
				<div style="font-size: 18px;color: #333; font-weight: 600;">请选择支付方式</div>
				<div class="pay_tabs">
					<div class="pay_tab" :class="{'actived':pay_tab_index == 1}" @click="changePayTab(1)">
						<div style="display: flex;justify-content: center; align-items: center;" v-if="detailData.payment_type == 1">
							<img src="../../assets/images/icon_weixin.png" style="width: 50px;margin-right: 10px;" />
							微信支付
							<div v-if="pay_tab_index == 1" class="tag">
								<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
							</div>
						</div>
						
						<div style="display: flex;justify-content: center;align-items: center;" v-if="detailData.payment_type == 2">
							<img src="../../assets/images/icon_qr_scan.png" style="width: 50px;margin-right: 10px;" />
							扫码支付
							<div v-if="pay_tab_index == 1" class="tag">
								<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
							</div>
						</div>
						
					</div>
					<div class="pay_tab" :class="{'actived':pay_tab_index == 2}" @click="changePayTab(2)">
						<img src="../../assets/images/icon_transfer.png" style="width: 50px; margin-right: 10px;" />
						对公转账
						<div v-if="pay_tab_index == 2" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
					<div class="pay_tab" :class="{'actived':pay_tab_index == 3}" @click="changePayTab(3)">
						<img src="../../assets/images/icon_face_to_face.png" style="width: 50px; margin-right: 10px;" />
						现场缴费
						<div v-if="pay_tab_index == 3" class="tag">
							<i class="el-icon-check" style="color: #fff;font-size:14px;"></i>
						</div>
					</div>
				</div>


				<div v-if="pay_tab_index == 1" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 65px;">
					<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;" v-if="detailData.payment_type ==1">
						<div style="border: 1px solid #eee; background-color: #eee; width: 232px; height: 232px;">
							<div ref="qrcode" style="width: 230px; height: 230px;"></div>
						</div>
						<div style="font-size: 24px; margin-top: 40px; " v-if="orderData && orderData.total_fee">
							总金额：¥{{orderData.total_fee}}
						</div>
						<div style="font-size: 22px;color: #999; margin-top:15px;">
							请使用微信扫码支付
						</div>
					</div>
					
					<div style="display: flex;flex-direction: column; justify-content: center;align-items: center;" v-if="detailData.payment_type ==2">
						<div style="border: 1px solid #eee; background-color: #eee; width: 232px; height: 232px;">
							<img :src="detailData.payment_qrcode_img_url" style="width: 230px; height: 230px;"/>
						</div>
						<div style="font-size: 24px; margin-top: 40px;text-align: center;" v-if="orderData && orderData.total_fee">
							总金额：¥{{orderData.total_fee}}
						</div>
						<div style="font-size: 22px;color: #999; margin-top:15px;">
							请使用微信或支付宝扫码支付
						</div>
					</div>
					
					
				</div>
				
				
				<div v-if="pay_tab_index == 2" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 100px; line-height: 1;">
					<div style="font-size: 18px; line-height: 2;" ref="textDiv">
						<span style="font-weight: 600;" v-if="orderData && orderData.total_fee">打款金额：</span>¥ {{orderData.total_fee}}<br>
						<span style="font-weight: 600;">收款账号：</span>0200 0014 0920 0070 931<br>
						<span style="font-weight: 600;">收款公司名称：</span>机械工业出版社有限公司<br>
						<span style="font-weight: 600;">开户行：</span>中国工商银行北京百万庄支行<br>
						<span style="font-weight: 600;" v-if="orderData && orderData.remark">备注信息：</span>{{orderData.remark}}<br>
					</div>
					<div @click="copyText" style="width: 160px; height: 36px; border-radius: 10px;  background-color: #FCA459;color: #fff; font-size: 18px; display: flex;justify-content: center; align-items: center; margin-top: 35px; margin-bottom: 40px; cursor: pointer;">
						一键复制转账信息
					</div>
				</div>
				<div v-if="pay_tab_index == 3 && detailData" style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 100px; line-height: 1;">
					<div style="font-size: 24px; line-height: 2; width: 500px;">
						温馨提示：<br>如果您选择在参加线下课程时现场缴费，缴费成功后由工作人员更新订单状态，即可解锁线上课程或线上直播回放，但现场缴费将不再享受早鸟价，请各位老师根据需要选择支付方式。
					</div>
				</div>
				<div v-if="pay_tab_index == 1 && detailData.payment_type == 1" @click="finishConfirm" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">已完成支付</div>
				</div>

				
				<div v-if="pay_tab_index != 1 || (pay_tab_index == 1 && detailData.payment_type == 2)" @click="updateOrderInfo(pay_tab_index)" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
					<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">确认支付方式</div>
				</div>
				<div style="font-size: 17px; margin-top: 50px;color: #999; display: flex; flex-direction: column; ;justify-content: center;align-items: center; ">
					<div style="width: 500px; text-align: justify; line-height: 1.5;">报名后，请各位老师添加下方客服微信号，添加好友时请备注：会议名称+姓名+学校，邀请您进本次会议专属群，我们将在群内发布本次会议更多学习信息。</div>
					<div style="margin-top: 30px;">
						<img v-if="!detailData.kf_qrcode_img_url" src="../../assets/images/qrcode_kf.jpg" style="width: 280px;" />
						<img v-if="detailData.kf_qrcode_img_url" :src="detailData.kf_qrcode_img_url" style="width: 280px;" />
					</div>
				</div>
			</div>
		</div>
		
		
		<div class="sign_up_container" style="width: 1200px; display: flex; flex-direction: column;" v-if="page_index == 3">
			<!-- 支付完成 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 70px 0 40px 0;">
					<div style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">
					</div>
					<div v-if="stateData.result == 1" style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						支付完成
					</div>
					<div v-if="stateData.result == 0" style="display: flex;flex-direction: column; line-height: 1;font-size: 28px; color: #333;">
						报名成功
					</div>
				</div>
			</div>
			
			<div style="display: flex;flex-direction: column; background-color: #fff; box-shadow: 1px 2px 1px #eee; border-radius: 15px; padding: 50px; margin-bottom: 50px; ;line-height: 1;">
				<div v-if="stateData.result == 1" style="display: flex;flex-direction: column;justify-content: center; align-items: center; padding: 48px 0 40px 0; box-sizing: border-box;">
					<img src="../../assets/images/icon_success.png" style="width: 120px; height: auto;" />
					<div style=" font-size: 24px; margin-top: 30px; color: #333;">
						已支付成功
					</div>
				</div>
				
				<div v-if="stateData.result == 0" style="display: flex;flex-direction: column;">
					
					<!-- 微信支付 -->
					<div v-if="stateData.pay_method == 1 && detailData.payment_type == 1" style="display: flex;flex-direction: column;justify-content: center; align-items: center; padding: 48px 0 40px 0; box-sizing: border-box; ">
						<i class="el-icon-warning" style="color: #FCA459; font-size: 120px;"></i>
						<div style=" font-size: 24px; margin-top: 30px; color: #333;">
							抱歉，您的订单尚未支付，请支付或对公转账
						</div>
						<div @click="changePayMethod(1)" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
							<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">修改支付方式</div>
						</div>
					</div>
					
					<!-- 扫码支付 -->
					<div v-if="stateData.pay_method == 1 && detailData.payment_type == 2" style="display: flex;flex-direction: column;justify-content: center; align-items: center; padding: 48px 0 40px 0; box-sizing: border-box; ">
						<img src="../../assets/images/icon_success.png" style="width: 120px; height: auto;" />
						<div style=" font-size: 24px; margin-top: 30px; color: #333;">
							报名信息提交成功
						</div>
						<div style=" font-size: 17px; margin-top: 30px; color: #333; line-height: 1.5; text-align: center">
							您选择的支付方式为扫码支付，如果您尚未支付，请使用微信或支付宝扫描下方二维码进行支付
						</div>
						<div style="display: flex;flex-direction: column; justify-content: center;align-items: center;margin-top: 20px;" >
							<div style="border: 1px solid #eee; background-color: #eee; width: 232px; height: 232px;">
								<img :src="detailData.payment_qrcode_img_url" style="width: 230px; height: 230px;"/>
							</div>
							<div style="font-size: 24px; margin-top: 40px;text-align: center;" v-if="orderData && orderData.total_fee">
								总金额：¥{{orderData.total_fee}}
							</div>
						</div>
						<div @click="changePayMethod(2)" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
							<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">修改支付方式</div>
						</div>
					</div>

					
					<!-- 对公转账 -->
					<div v-if="stateData.pay_method == 2 " style="display: flex;flex-direction: column;justify-content: center; align-items: center; padding: 48px 0 40px 0; box-sizing: border-box; ">
						<img src="../../assets/images/icon_success.png" style="width: 120px; height: auto;" />
						<div style=" font-size: 24px; margin-top: 30px; color: #333;">
							报名信息提交成功
						</div>
						<div style=" font-size: 17px; margin-top: 30px; color: #333; line-height: 1.5; text-align: center">
							您选择的支付方式为对公转账，转账信息如下：
						</div>
						<div style="font-size: 18px; line-height: 2; margin-top: 10px;">
							<span style="font-weight: 600;" v-if="orderData && orderData.total_fee">打款金额：</span>¥ {{orderData.total_fee}}<br>
							<span style="font-weight: 600;">收款账号：</span>0200 0014 0920 0070 931<br>
							<span style="font-weight: 600;">收款公司名称：</span>机械工业出版社有限公司<br>
							<span style="font-weight: 600;">开户行：</span>中国工商银行北京百万庄支行<br>
							<span style="font-weight: 600;" v-if="orderData && orderData.remark">备注信息：</span>{{orderData.remark}}<br>
						</div>
						<div @click="copyText" style="width: 160px; height: 36px; border-radius: 10px;  background-color: #FCA459;color: #fff; font-size: 18px; display: flex;justify-content: center; align-items: center; margin-top: 35px; margin-bottom: 40px; cursor: pointer;">
							一键复制转账信息
						</div>
						<div @click="changePayMethod(2)" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
							<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">修改支付方式</div>
						</div>
					</div>
					
					<!-- 现场缴费 -->
					<div v-if="stateData.pay_method == 3" style="display: flex;flex-direction: column;justify-content: center; align-items: center; padding: 48px 0 40px 0; box-sizing: border-box; ">
						<img src="../../assets/images/icon_success.png" style="width: 120px; height: auto;" />
						<div style=" font-size: 24px; margin-top: 30px; color: #333;">
							报名信息提交成功
						</div>
						<div style=" font-size: 17px; margin-top: 30px; color: #333; width: 50%; line-height: 1.5;">
							您选择的支付方式为现场缴费，会议报到当天现场缴费，现场缴费支持刷卡、支付宝、微信支付，现场缴费均不享受早鸟价
						</div>
						<div @click="changePayMethod(3)" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
							<div style="font-size: 17px; background: linear-gradient(to right,#C63E31,#FB9026); color: #fff;  border-radius: 34px; width:150px ; height:34px; display: flex; justify-content: center; align-items: center; cursor: pointer;">修改支付方式</div>
						</div>
					</div>
				</div>
				<div style="font-size: 17px; margin-top: 50px;color: #999; display: flex; flex-direction: column; ;justify-content: center;align-items: center; ">
					<div style="width: 500px; text-align: justify; line-height: 1.5;">报名后，请各位老师添加下方客服微信号，添加好友时请备注：会议名称+姓名+学校，邀请您进本次会议专属群，我们将在群内发布本次会议更多学习信息。</div>
					<div style="padding: 30px 0;">
						<img v-if="!detailData.kf_qrcode_img_url" src="../../assets/images/qrcode_kf.jpg" style="width: 280px;" />
						<img v-if="detailData.kf_qrcode_img_url" :src="detailData.kf_qrcode_img_url" style="width: 280px;" />
					</div>
				</div>
				<div style="display: flex; flex-direction: column; padding:40px 0; box-sizing: border-box;border-top: 1px solid #eee;">
					<div style="display: flex;flex-direction: column;">
						<!-- <div style="font-size: 18px; color: #333;" v-if="stateData.name">{{stateData.name}}（老师）</div> -->
						<div style="font-size: 18px; color: #666;line-height: 1.4; margin-top: 40px;">
							推荐您关注我们的微信公众号，<br>
							获取最新会议信息，<br>
							结识更多优秀教师好友。
						</div>
						<div style="display: flex;  justify-content: space-between; margin-top: 40px;">
							<div style="display: flex;align-items: flex-end; ">
								<div style="width: 132px;height: 132px;border: 1px solid #eee; background-color: #eee; margin-right: 20px;">
									<img src="../../assets/images/qrcode_for_cmptt.jpg" style="width: 130px;" />
								</div>
								<div style="font-size: 18px;">
									<i class="el-icon-caret-left"></i>
									扫码关注公众号
								</div>
							</div>
							<div style="display: flex;align-items: flex-end;">
								<img src="../../assets/images/logo.png" style="width: 180px; height: auto;" />
							</div>
						</div>
					</div>
				</div>
				
				
			</div>
		</div>
		
		
			
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	

	export default {
		data() {
			return {
				dynamicValidateForm: {
					domains: [{
						name: '',
						mobile: '',
						sex: '',
						job_type: '',
						school: '',
						college: '',
						title: '',
						duty: '',
						teaching_length: '',
						company: '',
						education: '',
						diet_type: '',
						check_in_date: '',
						check_in_date_other: '',
						stay_type: '',
						hotel: '',
						room_type_before: '',
						room_type: '',
						room_type_other: '',
						room_share_with: '',
						stay_days: '',
						is_visit: '', 
					}]
				},
				dynamicValidateForm2: {
					domains: [{
						invoice_type: '统一开具',
						invoice_count: '',
						title: '',
						tax_id: '',
						category: '会议费',
						category_other: '',
						bank: '',
						bank_account: '',
						registered_address: '',
						registered_contact: '',
						email: '',
						mobile: '',
						gift_buy_count:'',
						price:'',
						total_fee: '',
					}]
				},
				page_index : 1,
				pay_tab_index: 1,
				id: null,
				detailData: null,
				gift_count: 1,
				is_gift_add: true,
				total_fee:0,
				price:0,
				alert_show1:false,
				alert_show2:false,
				search: {
					id: null,
					case_type: 2,
					gift_buy_count: 1,
					product_type: 31,
				},
				/*
				orderData: {
					total_fee:'1280',
					out_trade_no:'17110695997V5M5W',
					remark:'',
					qrcode_url:'',
				},*/
				orderData:null,
				stateData: null,
				qrcode: null,
				isLogin:true,
				backtop_id:Date.now().toString(10)
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			if(!this.isLogin){
				window.top.location.href = "/login";
				return;
			}
		   var option = {
		   	id: this.$route.query.id || "",
		   };
		   if (option.id) {
			 this.id = option.id;
			 this.search.id = this.id;
		   	 this.getDetail();
		   }
		},
		components: {
			qrcode: QRCode,
		},
		methods: {
			copyText() {
				var text='';
				text +='打款金额：¥ '+this.orderData.total_fee+'\r\n';
				text +='收款账号：0200 0014 0920 0070 931\r\n';
				text +='收款公司名称：机械工业出版社有限公司\r\n';
				text +='开户行：中国工商银行北京百万庄支行\r\n';
				text +='备注信息：'+ this.orderData.remark +'\r\n';
				
			     this.$copyText(text).then((res)=>{
					  //console.log("内容已复制到剪切板")
					  this.$alert('内容已复制到剪切板', '提示');
				 }).catch((err)=>{
					 //console.log("抱歉，复制失败")
					 this.$alert('抱歉，复制失败', '提示');
				 })
			},
			inputMobileBlurEvent(){
				var mobile = this.dynamicValidateForm2.domains[0].mobile;
				if(!mobile){
					this.dynamicValidateForm2.domains[0].mobile = this.dynamicValidateForm.domains[0].mobile;
				}
			},
			bindInvoiceData(){
				var type = this.dynamicValidateForm2.domains[0].invoice_type;
				if(type){
					if(type == "单独开具"){
						this.dynamicValidateForm2.domains[0].invoice_count = this.gift_count;
						this.dynamicValidateForm2.domains[0].total_fee = this.price.toString();
						this.dynamicValidateForm2.domains[0].gift_buy_count = 1;
						this.dynamicValidateForm2.domains[0].price = this.price.toString();
					}else{
						this.dynamicValidateForm2.domains[0].invoice_count = 1;
						this.dynamicValidateForm2.domains[0].total_fee = this.total_fee.toString();
						this.dynamicValidateForm2.domains[0].gift_buy_count = this.gift_count;
						this.dynamicValidateForm2.domains[0].price = this.price.toString();
					}
				}
			},
			scrollTop(){
				this.backtop_id = Date.now().toString();
				this.$nextTick(()=>{
					//window.scrollTop(0,0);
					window.location.hash =this.backtop_id;
				});
			},
			toStudyClick(){
				var url = "/trainingCamp/study";
				var query = { id: this.id };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			
			jobTypeChangeEvent(){
				//有学生价时，身份变更时，定价更新
				if(this.detailData.student_price){
					
					if(!this.dynamicValidateForm.domains[0].job_type){
						var _job_type='';
						this.dynamicValidateForm.domains.forEach((item,index)=>{
							if(item.job_type){
								_job_type = item.job_type;
							}
						})
						this.dynamicValidateForm.domains[0].job_type = _job_type;
					}
					
					this.dynamicValidateForm.domains.forEach((item,index)=>{
						item.job_type = this.dynamicValidateForm.domains[0].job_type;
						if(index!=0){
							item.job_type_disabled = true;
						}
					})

					if(this.dynamicValidateForm.domains[0].job_type == '学生'){
						this.price = parseFloat(this.detailData.student_price);
					}else{
						this.price = parseFloat(this.detailData.price);
					}
					this.total_fee = this.price * this.gift_count * 1.00;
					this.bindInvoiceData();
					
				}
			},
			addDomain() {
				var _job_type='';
				var _job_type_disabled = false;
				var _school='';
				var _college='';
				var _company='';
				if(this.detailData.student_price && this.dynamicValidateForm.domains[0].job_type){
					_job_type = this.dynamicValidateForm.domains[0].job_type;
					_job_type_disabled = true;
				}
				if(this.dynamicValidateForm.domains[0].school){
					_school = this.dynamicValidateForm.domains[0].school;
				}
				if(this.dynamicValidateForm.domains[0].college){
					_college = this.dynamicValidateForm.domains[0].college;
				}
				if(this.dynamicValidateForm.domains[0].company){
					_company = this.dynamicValidateForm.domains[0].company;
				}
				this.dynamicValidateForm.domains.push({
					name: '',
					mobile: '',
					sex: '',
					job_type: _job_type,
					job_type_disabled: _job_type_disabled,
					school: _school,
					college: _college,
					title: '',
					duty: '',
					teaching_length: '',
					company: _company,
					education: '',
					diet_type: '',
					check_in_date: '',
					check_in_date_other: '',
					stay_type: '',
					hotel: '',
					room_type_before: '',
					room_type: '',
					room_type_other: '',
					room_share_with: '',
					stay_days: '',
					is_visit: '',
					key: Date.now()
				});
				this.gift_count ++;
				this.total_fee =this.price*this.gift_count*1.00;
				this.bindInvoiceData();
				
			
				var next_total_fee = this.price * (this.gift_count+1) * 1.00;
				if(next_total_fee > 6000.00){
					this.is_gift_add = false;
				}else{
					this.is_gift_add = true;
				}
			},
			removeDomain(item) {
				var index = this.dynamicValidateForm.domains.indexOf(item)
				if (index !== -1) {
					this.dynamicValidateForm.domains.splice(index, 1)
					this.dynamicValidateForm2.domains.splice(index, 1)
					this.gift_count --;
					this.total_fee =this.price*this.gift_count*1.00;
					this.bindInvoiceData();
					
					
					var next_total_fee = this.price * (this.gift_count+1) * 1.00;
					if(next_total_fee > 6000.00){
						this.is_gift_add = false;
					}else{
						this.is_gift_add = true;
					}
				}
			},
			infoConfirm(){
				var that = this;
				that.$refs['dynamicValidateForm'].validate((valid) => {
					if (valid) {
						that.$refs['dynamicValidateForm2'].validate((_valid) => {
							if (_valid) {
								console.log('可以提交')
								console.log(this.dynamicValidateForm.domains)
								
								console.log('dynamicValidateForm' + JSON.stringify(that.dynamicValidateForm.domains))
								console.log('dynamicValidateForm2' + JSON.stringify(that.dynamicValidateForm2.domains))
								
								that.search.id = this.id;
								that.search.gift_buy_count = this.gift_count;
								that.search.user_list_json = JSON.stringify(that.dynamicValidateForm.domains);
								that.search.invoice_list_json = JSON.stringify(that.dynamicValidateForm2.domains);
								that.goToOrder();
							}
						});
					}
				});
			},
			changePayMethod(index){
				var that = this;
				that.page_index = 2;
				that.detailData.tt_out_trade_no = that.orderData.out_trade_no;
				that.changePayTab(index);
				that.scrollTop();
			},
			goToOrder(){
				var that = this;
				var url = apiUrl.tt_purchase_confirm;
				this.Ajax(url, this.search, (data) => {
					that.page_index = 2;
					that.orderData = data;
					that.changePayTab(1);
					that.scrollTop();
					console.log(JSON.stringify(data))
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(formName == 'dynamicValidateForm'){
							this.alert_show1 = true;
						}else if(formName == 'dynamicValidateForm2'){
							this.alert_show2 = true;
						}
					} else {
						console.log('error submit!!');
						if(formName == 'dynamicValidateForm'){
							this.alert_show1 = false;
						}else if(formName == 'dynamicValidateForm2'){
							this.alert_show2 = false;
						}
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			changePayTab(index) {
				this.pay_tab_index = index;
				var that = this;
				that.$nextTick(() => {
					if(that.orderData && that.orderData.qrcode_url){
						that.setupQrCode(that.$refs.qrcode, that.orderData.qrcode_url, 230, (code_img) => {
							that.qrcode = code_img;
						});
					}
				});
			},
			updateOrderInfo(index){
				var that = this;
				var url = apiUrl.tt_order_info_update;
				this.Ajax(url, {
					out_trade_no: that.orderData.out_trade_no,
					pay_method: index,
					product_type:31,
				}, (data) => {
					//that.$alert('支付方式确认完成');
					that.page_index = 3;
					that.checkOrderState();
				});
			},
			finishConfirm(){
				this.page_index=3;
				this.checkOrderState();
			},
			checkOrderState(){
				var that = this;
				var url = apiUrl.tt_order_state_check;
				this.Ajax(url, { 
					out_trade_no: this.orderData.out_trade_no,
					product_type: 31,
				}, (data) => {
					that.stateData = data;
					that.scrollTop();
				});
			},
			getDetail() {
				var that = this;
				var url = apiUrl.tt_conference_detail_get;
				this.Ajax(url, {
					id: that.id,
					is_sign_up: 1,
				}, (data) => {
					that.detailData = data;
					that.price = parseFloat(that.detailData.price);
					if(that.detailData.is_reg == 0){
						that.page_index = 1;
						that.gift_count = 1;
						that.total_fee =that.price*that.gift_count*1.00;						
						that.bindInvoiceData();

					}else if(that.detailData.order_info){
						that.page_index = 3;
						
						that.stateData = {
							pay_method : that.detailData.order_info.pay_method,
							result: that.detailData.order_info.result
						};
						
						that.orderData = {
							total_fee : that.detailData.order_info.total_fee,
							out_trade_no: that.detailData.order_info.out_trade_no,
							product_id: that.detailData.order_info.product_id,
							product_type: that.detailData.order_info.product_type,
							remark: that.detailData.order_info.remark,
							qrcode_url: that.detailData.order_info.qrcode_url,
						};
					}
				});
			},
			//QrCode
			setupQrCode(el, url, width, callback) {
				let code_img = new QRCode(el, {
					width: width,
					height: width, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
				if (callback) callback(code_img);
			},
			
		},
	};
</script>

<style>
	
	/*单选按钮*/
	/* .el-radio__input.is-checked + .el-radio__label {
	  color: #606266 !important;
	}

	.el-radio__input.is-checked .el-radio__inner {
	  background: #606266 !important;
	  border-color: #606266 !important;
	}
	.el-radio__input:hover{
		border-color: #DCDFE6 !important;
	} */
	
</style>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.pay_tabs {
			display: flex;
			margin-top: 20px;

			.pay_tab {
				font-size: 22px;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 315px;
				height: 90px;
				background-color: #F1F1F1;
				border-radius: 10px;
				position: relative;
				box-sizing: border-box;
				cursor: pointer;
				margin-right: 30px;

				&.actived {
					background-color: #fff;
					border: 2px solid #ff9000;


				}

				.tag {
					background-color: #ff9000;
					width: 30px;
					height: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 10px 0 10px 0;
					position: absolute;
					right: -2px;
					bottom: -2px;
					box-sizing: border-box;
				}
			}
		}
		
		/deep/.sign_up_container{
			/*复选按钮*/
			.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
			    background-color: #FCA459 !important;
			    border-color: #FCA459 !important;
			}
			
			.el-checkbox__input.is-focus .el-checkbox__inner{
				border-color: #FCA459 !important;
			}
			.el-checkbox__label{
				font-size: 15px;
				color: #333 !important;
			}
			.el-checkbox__input.is-checked+.el-checkbox__label{
				color: #FCA459 !important;
			}
			
			.el-radio{
				line-height: 2 !important;
			}
			
			.el-collapse{
				border: none !important;
			}
			.el-collapse-item__wrap{
				border: none !important;
			}
			.el-collapse-item__header{
				padding-left: 156px;
				box-sizing: border-box;
				border: none !important;
			}
			
		}
		
		/*
		.sign_up_container {
			/deep/ .el-radio__label {
				font-size: 18px !important;
			}

			/deep/ .el-radio__inner {
				border-radius: 4px !important;
				width: 20px !important;
				height: 20px !important;
				background-color: #fff !important;
			}

			/deep/ .el-radio__inner::after {
				width: 12px !important;
				height: 12px !important;
				border-radius: 4px !important;
				background-color: #999 !important;
			}

			/deep/ .el-radio__input.is-checked+.el-radio__label {
				color: rgb(153, 153, 153) !important;
			}

			/deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
				color: #666 !important;
			}

			/deep/ .el-checkbox__label {
				font-size: 14px;
			}

			/deep/ .el-checkbox__inner {
				border-radius: 4px !important;
				width: 20px !important;
				height: 20px !important;
				background-color: #fff !important;
			}

			/deep/ .el-checkbox__inner::after {
				width: 12px !important;
				height: 12px !important;
				border-radius: 4px !important;
				background-color: #999 !important;
				box-sizing: content-box;
			}
		}
		*/
	}
</style>