import Vue from 'vue'
import VueRouter from 'vue-router'
import hzInx from '../components/hzIndex.vue'
import Homepage from '../views/Homepage/Homepage.vue' //推荐频道页

//登录/注册
import Login from '../views/Login/Login.vue'   //登录
import Reg from '../views/Login/Reg.vue'   //注册
import Bind from '../views/Login/Bind.vue'   //绑定手机号
import TeacherReg from '../views/Login/TeacherReg.vue'   //教师注册

//教材
import TeachResIndex from '../views/TeachRes/TeachResIndex.vue' //教材主页（分类导航）
import TeachResList from '../views/TeachRes/TeachResList.vue' //教材列表
import TeachResDetail from '../views/TeachRes/TeachResDetail.vue' //教材详情
import TeachResReader from '../views/TeachRes/TeachResReader.vue' //教材详情
import TeachResSeachList from '../views/TeachRes/TeachResSeachList.vue'  //教材搜索结果
import TeachResSeriesList from '../views/TeachRes/TeachResSeriesList.vue'  //丛书列表
import TeachResTagList from '../views/TeachRes/TeachResTagList.vue'  //标签列表

//课程
import CourseList from '../views/Course/CourseList.vue' //课程列表
import CourseDetail from '../views/Course/CourseDetail.vue' //课程详情

//教研室
import TeachRoomList from '../views/TeachRoom/TeachRoomList.vue' //教研室列表
import TeachRoomDetail from '../views/TeachRoom/TeachRoomDetail.vue' //教研室详情
import TeachRoomJoiner from '../views/TeachRoom/TeachRoomJoiner.vue' //教研室参与者

//数字资源汇总
import DigitalResDetail from '../views/DigitalRes/DigitalResDetail.vue' //数字资源汇总页

//特训营
import TrainingCampList from '../views/TrainingCamp/TrainingCampList.vue' //特训营列表
import TrainingCampDetail from '../views/TrainingCamp/TrainingCampDetail.vue' //特训营详情
import TrainingCampSignUp from '../views/TrainingCamp/TrainingCampSignUp.vue' //特训营报名
import TrainingCampStudy from '../views/TrainingCamp/TrainingCampStudy.vue' //特训营学习

//直播
import WebCastDetail from '../views/WebCast/WebCastDetail.vue' //直播回放页面

//名师团
import TeacherList from '../views/Teacher/TeacherList.vue' //名师列表
import TeacherDetail from '../views/Teacher/TeacherDetail.vue' //名师详情

//会议
import ConferenceList from '../views/Conference/ConferenceList.vue' //会议列表
import ConferenceDetail from '../views/Conference/ConferenceDetail.vue' //会议详情
import ConferenceSignUp from '../views/Conference/ConferenceSignUp.vue' //会议报名


//个人中心
import CenterIndex from '../views/Center/Center.vue' //个人中心首页

//搜索
import Searchpage from '../components/Searchpage.vue'  //搜索页


Vue.use(VueRouter)

const routes = [
  {
    path:'/searchpage',
    component:Searchpage
  },
  {
    path:'/teachRes/reader',
    component:TeachResReader
  },
  {
    path: '/',
    redirect:'/homepage',
    component:hzInx,
    children:[
     {
        path:'homepage',
        component:Homepage ,
        meta:{
          // 页面标题title
          title: '首页'
        ,} 
      },
	  {
	    path:'/login',
	    component:Login
	  },
	  {
	    path:'/reg',
	    component:Reg
	  },
	  {
	    path:'/bind',
	    component:Bind
	  },
	  {
	    path:'/teacherReg',
	    component:TeacherReg
	  },
	  {
	    path:'/teachRes/index',
	    component:TeachResIndex
	  },
	  {
	    path:'/teachRes/list',
	    component:TeachResList
	  },
	  {
	    path:'/teachRes/search',
	    component:TeachResSeachList
	  },
	  {
	    path:'/teachRes/detail',
	    component:TeachResDetail
	  },
	  {
		path:'/teachRes/serieslist',
		component:TeachResSeriesList
	  },
	  {
		path:'/teachRes/taglist',
		component:TeachResTagList
	  },
	  {
	    path:'/course/list',
	    component:CourseList
	  },
	  {
	    path:'/course/detail',
	    component:CourseDetail
	  },
	  {
	    path:'/teachRoom/list',
	    component:TeachRoomList
	  },
	  {
	    path:'/teachRoom/detail',
	    component:TeachRoomDetail
	  },
	  {
	    path:'/teachRoom/joiner',
	    component:TeachRoomJoiner
	  },
	  {
	    path:'/trainingCamp/list',
	    component:TrainingCampList
	  },
	  {
	    path:'/trainingCamp/detail',
	    component:TrainingCampDetail
	  },
	  {
	    path:'/trainingCamp/signup',
	    component:TrainingCampSignUp
	  },
	  {
	    path:'/trainingCamp/study',
	    component:TrainingCampStudy
	  },
	  {
	    path:'/teacher/list',
	    component:TeacherList
	  },
	  {
	    path:'/teacher/detail',
	    component:TeacherDetail
	  },
	  {
	    path:'/center/center',
	    component:CenterIndex
	  },
	  {
	    path:'/conference/list',
	    component:ConferenceList
	  },
	  {
	    path:'/conference/detail',
	    component:ConferenceDetail
	  },
	  {
	    path:'/conference/signup',
	    component:ConferenceSignUp
	  },
	  {
	    path:'/webcast/detail',
	    component:WebCastDetail
	  },
	  {
	    path:'/digitalres/detail',
	    component:DigitalResDetail
	  },

    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router