var cmpDomain = "https://cloud.cmptt.com/"
//cmpDomain = 'http://localhost:41481/hzmedia2016_100_190/';
var baseDomain = cmpDomain + "api_k/";
var apiDomain =  cmpDomain + "api/";
var ttDomain =  cmpDomain + "api_tt/";
var rdDomain = "https://cloud.cmpreading.com/api/"

//首页
var homepage_product_list_get = baseDomain + "homepage_product_list_get.ashx"; //首页

//教材教辅
var category_list_get=baseDomain+"category_list_get.ashx";//分类获取
var product_list_get = baseDomain + "product_list_get.ashx"; //根据分类编号获取产品列表
var product_detail_get = baseDomain + "product_detail_get.ashx"; //产品详情
var product_search_list_get = baseDomain + "product_search_list_get.ashx"; //教材搜索
var book_content_get = rdDomain + "book_content_get.ashx"; //电子书目录
var book_chapter_get = rdDomain + "book_chapter_get.ashx"; //电子书章节内容
var course_teach_resources_type_list_get = baseDomain + "course_teach_resources_type_list_get.ashx"; //教辅资源分类
var course_teach_resources_list_get = baseDomain + "course_teach_resources_list_get.ashx"; //教辅资源列表
var product_series_list_get = baseDomain + "product_series_list_get.ashx"; //丛书列表
var product_list_get_by_tag = baseDomain + "product_list_get_by_tag.ashx"; //按标签展示列表

//课程
var course_list_get= baseDomain + "course_list_get.ashx"; //课程列表
var course_detail_get= baseDomain + "course_detail_get.ashx"; //课程详情
var resource_list_get= baseDomain + "resource_list_get.ashx"; //资源列表
var resource_detail_get= baseDomain + "resource_detail_get.ashx"; //资源详情

//思政
var sizheng_week_list_get = apiDomain + "sizheng_week_list_get.ashx";
var sizheng_week_detail_get = apiDomain + "sizheng_week_detail_get.ashx";
var sizheng_point_detail_get = apiDomain + "sizheng_point_detail_get.ashx";

//教研室
var digital_office_list_get = baseDomain + "digital_office_list_get.ashx";//教研室列表
var digital_office_detail_get = baseDomain + "digital_office_detail_get.ashx";//教研室详情
var digital_office_menu_state_get = baseDomain + "digital_office_menu_state_get.ashx";//教研室功能菜单
//最新消息
var digital_office_news_list_get= baseDomain + "digital_office_news_list_get.ashx";//最新消息
//PPT讲义
var digital_office_ppt_list_get  = baseDomain + "digital_office_ppt_list_get.ashx";//PPT讲义ID
var digital_office_ppt_id_get  = baseDomain + "digital_office_ppt_id_get.ashx";//根据章节ID获取PPT讲义
var digital_office_ppt_detail_get = baseDomain + "digital_office_ppt_detail_get.ashx";//PPT详情
//资源章节
var digital_office_chapter_list_get = baseDomain + "digital_office_chapter_list_get.ashx";//章节获取
//课堂实录
var digital_office_record_list_get = baseDomain + "digital_office_record_list_get.ashx";//课堂实录
var digital_office_record_detail_get= baseDomain + "digital_office_record_detail_get.ashx";//课堂实录
//教学设计
var digital_office_design_list_get = baseDomain + "digital_office_design_list_get.ashx";
var digital_office_design_detail_get = baseDomain + "digital_office_design_detail_get.ashx";
var digital_office_design_detail_list_get = baseDomain + "digital_office_design_detail_list_get.ashx";
//教学研讨
var digital_office_discuss_list_get = baseDomain + "digital_office_discuss_list_get.ashx";//教学研讨
var digital_office_discuss_detail_get = baseDomain + "digital_office_discuss_detail_get.ashx";//教学研讨
//教材电子书
var digital_office_book_list_get = baseDomain + "digital_office_book_list_get.ashx";//电子书列表
//参考文献
var digital_office_reference_list_get = baseDomain + "digital_office_reference_list_get.ashx";
//教研室成员
var user_list = baseDomain + "user_list.ashx";
var digital_office_ppt_download = baseDomain + "digital_office_ppt_download.ashx"; //获取PPT下载链接
//教研室兑换
var digital_office_actived = baseDomain + "digital_office_actived.ashx";


//登录
var login_qr_get = ttDomain + "login_qr_get.ashx";//登录二维码
var login =  ttDomain + "login.ashx";//登录验证
var user_sms_send =  ttDomain + "user_sms_send.ashx";//发送手机验证码
var login_mobile_bind =  ttDomain + "login_mobile_bind.ashx";//绑定手机号
var school_list_get = baseDomain + "school_list_get.ashx";//获取省市学校学院
var teacher_reg = baseDomain + "teacher_reg.ashx";//教师注册
var teacher_state_get = baseDomain + "teacher_state_get.ashx";//查询教师认证状态
var teaching_category_list_get_by_level = baseDomain + "teaching_category_list_get_by_level.ashx";//获取授课专业列表
var teaching_category_list_get = baseDomain + "teaching_category_list_get.ashx";//获取课程名称列表
var teaching_field_add = baseDomain + "teaching_field_add.ashx";//添加授课信息
var teaching_field_del = baseDomain + "teaching_field_del.ashx";//删除授课信息
var teaching_field_list_get = baseDomain + "teaching_field_list_get.ashx";//授课信息列表
var teaching_prove_upload = baseDomain + "teaching_prove_upload.ashx"; //教师资质上传

// 题库
var digital_office_question_info_get = baseDomain + "digital_office_question_info_get.ashx";//获取客观题库信息
var q_category_list_get = apiDomain + "q_category_list_get.ashx";//获取题库目录
var q_practise_get = apiDomain + "q_practise_get.ashx";//根据章节ID获取练习题
var q_add_del_favorite = apiDomain + "q_add_del_favorite.ashx";//添加删除收藏
var q_favorite_classify_get = apiDomain + "q_favorite_classify_get.ashx";//获取收藏分类
var q_favorite_detail_list = apiDomain + "q_favorite_detail_list.ashx";//根据章节获取收藏题目
//组卷
var q_exam_list_get = apiDomain + "q_exam_list_get.ashx";//试卷列表
var q_exam_chap_list_get = apiDomain + "q_exam_chap_list_get.ashx";//章节列表
var q_exam_make_paper = apiDomain + "q_exam_make_paper.ashx";//生成试卷
var q_exam_make_paper2 = apiDomain + "q_exam_make_paper2.ashx";//组成试卷
var q_exam_make_paper2_submit = apiDomain + "q_exam_make_paper2_submit.ashx";//生成试卷


//训练营
var tt_camp_detail_get = ttDomain + 'camp_detail_get.ashx';//训练营详情
var tt_camp_list_get= ttDomain + 'camp_list_get.ashx';//训练营详情
var tt_purchase_confirm = ttDomain + 'purchase_confirm.ashx';//训练营报名
var tt_order_state_check = ttDomain + 'order_state_check.ashx';//订单状态确认
var tt_order_info_update = ttDomain + 'order_info_update.ashx';//订单支付方式更新
var tt_camp_study_detail_get = ttDomain + 'camp_study_detail_get.ashx';//学习页面详情
var tt_camp_study_res_list_get = ttDomain + 'camp_study_res_list_get.ashx';//学习页面资源
var tt_category_list_get = ttDomain + 'category_list_get.ashx';//课程、讲师分类
//课程
var tt_course_list_get = ttDomain + 'course_list_get.ashx';//课程列表
var tt_course_detail_get = ttDomain + 'course_detail_get.ashx';//课程列表
//讲师
var tt_teacher_list_get = ttDomain + 'teacher_list_get.ashx';//讲师列表
var tt_teacher_detail_get = ttDomain + 'teacher_detail_get.ashx';//讲师详情
var tt_teacher_camp_list_get =  ttDomain + 'teacher_camp_list_get.ashx';//讲师相关训练营
var tt_teacher_course_list_get=  ttDomain + 'teacher_course_list_get.ashx';//讲师相关课程
var tt_teacher_digital_office_list_get=  ttDomain + 'teacher_digital_office_list_get.ashx';//讲师相关数字教研室
//个人中心
var tt_center_detail_get= ttDomain + 'center_detail_get.ashx';//个人中心用户信息
var tt_purchase_camp_list_get = ttDomain + 'purchase_camp_list_get.ashx';//训练营订阅列表
var tt_purchase_course_list_get = ttDomain + 'purchase_course_list_get.ashx';//课程订阅列表
var tt_purchase_digital_office_list_get = ttDomain + 'purchase_digital_office_list_get.ashx';//教研室订阅列表
var tt_history_list_get = ttDomain + 'history_list_get.ashx';//用户历史记录/足迹
var tt_invoice_list_get = ttDomain + 'invoice_list_get.ashx';//发票信息列表
var tt_invoice_list2_get = ttDomain + 'invoice_list2_get.ashx';//待开票信息列表
var tt_invoice_add = ttDomain + 'invoice_add.ashx';//开票信息添加
//会议
var tt_category_list_get= ttDomain + 'category_list_get.ashx';//学习页面资源
var tt_conference_list_get= ttDomain + 'conference_list_get.ashx';//会议列表
var tt_conference_detail_get = ttDomain + 'conference_detail_get.ashx';//会议详情
//验证token
var tt_token_check = ttDomain + 'token_check.ashx';//数字教研室详情页token参数验证
//直播
var tt_webcast_detail_get = ttDomain + 'webcast_detail_get.ashx';//直播详情
//搜索
var tt_search_count_get  = ttDomain + 'search_count_get.ashx';//搜索产品数量
var tt_search_list_get  = ttDomain + 'search_list_get.ashx';//搜索产品列表
//数字资源列表
var tt_digital_office_chapter_list_get  = ttDomain + 'digital_office_chapter_list_get.ashx';//章节列表
var tt_digital_office_ppt_list_get  = ttDomain + 'digital_office_ppt_list_get.ashx';//PPT列表
var tt_digital_office_detail_get = ttDomain + 'digital_office_detail_get.ashx';//详情
var tt_digital_office_design_list_get = ttDomain + 'digital_office_design_list_get.ashx';//教学设计列表
var tt_digital_office_design_detail_list_get = ttDomain + 'digital_office_design_detail_list_get.ashx';//教学设计详情
var tt_q_category_list_get= ttDomain + 'q_category_list_get.ashx';//题库名称
var tt_q_practise_list_by_category= ttDomain + 'q_practise_list_by_category.ashx';//题库获取
var tt_digital_office_ext_list_get = ttDomain + "digital_office_ext_list_get.ashx";//扩展ID列表
var digital_office_ext_detail_get = ttDomain + "digital_office_ext_detail_get.ashx";//扩展详情

export default {
	homepage_product_list_get : homepage_product_list_get,
	category_list_get : category_list_get,
	product_list_get : product_list_get,
	product_detail_get : product_detail_get,
	product_search_list_get : product_search_list_get,
	product_series_list_get : product_series_list_get,
	book_content_get : book_content_get,
	book_chapter_get : book_chapter_get,
	course_teach_resources_type_list_get : course_teach_resources_type_list_get,
	course_teach_resources_list_get : course_teach_resources_list_get,
	course_list_get : course_list_get,
	course_detail_get : course_detail_get,
	resource_list_get : resource_list_get,
	resource_detail_get : resource_detail_get,
	sizheng_week_list_get: sizheng_week_list_get,
	sizheng_week_detail_get: sizheng_week_detail_get,
	sizheng_point_detail_get: sizheng_point_detail_get,
	digital_office_list_get: digital_office_list_get,
	digital_office_detail_get: digital_office_detail_get,
	digital_office_menu_state_get: digital_office_menu_state_get,
	digital_office_news_list_get: digital_office_news_list_get,
	digital_office_ppt_list_get: digital_office_ppt_list_get,
	digital_office_ppt_id_get: digital_office_ppt_id_get,
	digital_office_ppt_detail_get: digital_office_ppt_detail_get,
	digital_office_chapter_list_get: digital_office_chapter_list_get,
	digital_office_record_list_get: digital_office_record_list_get,
	digital_office_record_detail_get: digital_office_record_detail_get,
	digital_office_design_list_get: digital_office_design_list_get,
	digital_office_design_detail_list_get: digital_office_design_detail_list_get,
	digital_office_design_detail_get: digital_office_design_detail_get,
	digital_office_discuss_list_get: digital_office_discuss_list_get,
	digital_office_discuss_detail_get: digital_office_discuss_detail_get,
	digital_office_reference_list_get: digital_office_reference_list_get,
	digital_office_book_list_get: digital_office_book_list_get,
	digital_office_question_info_get: digital_office_question_info_get,
	user_list: user_list,
	login_qr_get: login_qr_get,
	login: login,
	user_sms_send: user_sms_send,
	login_mobile_bind: login_mobile_bind,
	digital_office_question_info_get: digital_office_question_info_get,
	q_category_list_get: q_category_list_get,
	q_practise_get: q_practise_get,
	q_add_del_favorite: q_add_del_favorite,
	q_favorite_classify_get: q_favorite_classify_get,
	q_favorite_detail_list: q_favorite_detail_list,
	q_exam_list_get: q_exam_list_get,
	q_exam_chap_list_get: q_exam_chap_list_get,
	q_exam_make_paper: q_exam_make_paper,
	q_exam_make_paper2: q_exam_make_paper2,
	q_exam_make_paper2_submit: q_exam_make_paper2_submit,
	school_list_get: school_list_get,
	teacher_reg: teacher_reg,
	teacher_state_get: teacher_state_get,
	product_list_get_by_tag: product_list_get_by_tag,
	teaching_category_list_get_by_level: teaching_category_list_get_by_level,
	teaching_category_list_get: teaching_category_list_get,
	teaching_field_add:teaching_field_add,
	teaching_field_del:teaching_field_del,
	teaching_field_list_get:teaching_field_list_get,
	teaching_prove_upload:teaching_prove_upload,
	digital_office_ppt_download:digital_office_ppt_download,
	digital_office_actived: digital_office_actived,
	tt_camp_detail_get: tt_camp_detail_get,
	tt_camp_list_get: tt_camp_list_get,
	tt_purchase_confirm: tt_purchase_confirm,
	tt_order_state_check: tt_order_state_check,
	tt_order_info_update: tt_order_info_update,
	tt_camp_study_detail_get: tt_camp_study_detail_get,
	tt_camp_study_res_list_get: tt_camp_study_res_list_get,
	tt_category_list_get: tt_category_list_get,
	tt_course_list_get: tt_course_list_get,
	tt_course_detail_get: tt_course_detail_get,
	tt_center_detail_get: tt_center_detail_get,
	tt_purchase_camp_list_get: tt_purchase_camp_list_get,
	tt_purchase_course_list_get: tt_purchase_course_list_get,
	tt_purchase_digital_office_list_get: tt_purchase_digital_office_list_get,
	tt_teacher_list_get: tt_teacher_list_get,
	tt_teacher_detail_get: tt_teacher_detail_get,
	tt_teacher_camp_list_get: tt_teacher_camp_list_get,
	tt_teacher_course_list_get: tt_teacher_course_list_get,
	tt_teacher_digital_office_list_get: tt_teacher_digital_office_list_get,
	tt_history_list_get: tt_history_list_get,
	tt_invoice_list_get: tt_invoice_list_get,
	tt_invoice_list2_get: tt_invoice_list2_get,
	tt_invoice_add: tt_invoice_add,
	tt_token_check: tt_token_check,
	tt_category_list_get: tt_category_list_get,
	tt_conference_list_get: tt_conference_list_get,
	tt_conference_detail_get: tt_conference_detail_get,
	tt_webcast_detail_get: tt_webcast_detail_get,
	tt_search_count_get: tt_search_count_get,
	tt_search_list_get: tt_search_list_get,
	tt_digital_office_chapter_list_get: tt_digital_office_chapter_list_get,
	tt_digital_office_ppt_list_get: tt_digital_office_ppt_list_get,
	tt_digital_office_detail_get: tt_digital_office_detail_get,
	tt_digital_office_design_list_get: tt_digital_office_design_list_get,
	tt_digital_office_design_detail_list_get: tt_digital_office_design_detail_list_get,
	tt_q_category_list_get: tt_q_category_list_get,
	tt_q_practise_list_by_category: tt_q_practise_list_by_category,
	tt_digital_office_ext_list_get: tt_digital_office_ext_list_get,
	digital_office_ext_detail_get: digital_office_ext_detail_get,
}
