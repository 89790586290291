<template>
	<div style="display: flex;flex-direction: column;">
		<div class="" style=" display: flex; justify-content: space-between; align-items: flex-end;font-weight: 600;">
			<div style="font-size: 24px;  border-left: 5px solid #333;color: #333;padding-left: 12px;">PPT展示区</div>
			<div style="font-size: 15px;color: #333; display: inline-block; border-bottom: 1px solid #333; line-height: 1.2; cursor: pointer;"
				@click="pptDownload()">完整版PPT</div>
		</div>


		<div class="" style="width: 100%; margin: 25px 0; display: flex;flex-direction: column;">
			<div class=""
				style=" width: 100%; height: 449px; border: 15px solid #fff; background-color: #fff; position: relative; ">

				<div style="display: inline-block;z-index: 2;">
					<img id="img" style="width: 100%; height: auto; " />
				</div>

				<div v-if="show" class="ppt_chapters_scroll_y_box"
					style="z-index: 9; background-color: #eee; padding: 5px 15px; box-sizing: border-box ;box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);position: absolute;right: -10px; bottom: -15px; width: 316px; height: 360px; display: inline-flex;flex-direction: column; ">
					<div class="ppt_chapters_scroll_y"
						style=" width: 100%;  overflow-y: scroll; padding-right: 15px; box-sizing: border-box;">
						<div class="chapter-item" :class="{'actived':selected && selected.id == item.id}"
							v-for="(item,index) in chapterList" @click="changeChapter(item)"
							v-if="item.resourse_count > 0">
							<div class="title" v-if="item.title && item.title.split(' ')[0]">
								{{item.title.split(' ')[0]}}
							</div>
							<div class="sub_title" v-if="item.title && item.title.split(' ')[1]">
								{{item.title.split(' ')[1]}}
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="" style="width: 100%; height: 50px; background-color: #333;display: flex;">
				<div class="" style="flex: 1; display: flex;align-items: center;">
					<i @click="lastPPT" class="iconfont icon-bg-left"
						style="margin-left: 15px;  margin-right: 20px; ;font-size: 24px;color: #fff; cursor:  pointer; "></i>
					<div style="font-size:20px ; color: #fff;">{{listData.current.index}} / {{listData.count}}</div>
					<i @click="nextPPT" class="iconfont icon-bg-right"
						style="margin-left: 20px;font-size: 24px;color: #fff;cursor:  pointer; "></i>
				</div>
				<div @click="changeShow" class=""
					style="width: 140px; height: 100%; background-color: #CE671B; color: #fff; font-size: 20px; display: flex;justify-content: center;align-items: center;cursor:  pointer;">
					选择章节 <i class="iconfont icon-list" style="margin-left: 8px;font-size: 18px; "></i>

				</div>
			</div>
		</div>

		<div class=""
			style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px; margin-bottom: 25px;">
			PPT文稿区
		</div>

		<div class="" style="font-size: 20px;color: #666; text-align: justify; line-height: 1.75;">
			<div class="" v-if="listData.current.ppt_text" v-html="listData.current.ppt_text"></div>
			<div class="" v-if="!listData.current.ppt_text">暂无</div>
		</div>

		<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%" top="15%">
			<div style="font-size: 15px;color: #333; font-weight: 600; margin-bottom: 25px;">
				<div>PPT讲义仅供个人使用，请勿对外传播，谢谢您的配合。</div>
			</div>
			<div style="font-size: 15px;" v-if="!pptUrl">
				<i class="el-icon-loading" style="font-size: 20px;"></i>
				正在为您准备文件，请稍候...
			</div>
			<div v-if="pptUrl">
				<a :href="pptUrl" download="完整版PPT.pdf">
					<el-button type="primary">预览完整版PPT<i class="el-icon-download el-icon--right"></i></el-button>
				</a>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import watermark from "../../js/watermark.js";

	export default {
		props: {
			myid: String
		},
		data() {
			return {
				id: this.myid,
				search: {
					id: this.myid,
					page_index: 1,
					page_size: 3000,
				},
				listData: {
					total: 0,
					count: 0,
					current: {},
					list: [],
				},
				chapterList: [],
				show: false,
				selected: null,
				dialogVisible: false,
				pptUrl: ''
			};
		},
		created() {
			//document.title = "PPT讲义 - 数字教研室 - 机工教师特训营";
			this.getList();
			this.getChapters();
		},
		// watch: {
		// 	myid(newval) {
		// 		this.id = newval;
		// 	},
		// },
		methods: {
			changeShow() {
				this.show = !this.show;
			},
			changeChapter(item) {
				this.selected = item;
				this.show = false;
				this.getPPTid(item.id);
			},
			getList() {
				var that = this;
				var url = apiUrl.digital_office_ppt_list_get;
				this.Ajax(url, this.search, (data) => {
					if (that.listData.total == 0) {
						that.listData.total = data.page_count;
					}
					if (that.listData.count == 0) {
						that.listData.count = data.total_count;
					}
					that.listData.list = data.list;
					that.getPPT(data.list[0]);
				});
			},
			getChapters() {
				var that = this;
				var url = apiUrl.digital_office_chapter_list_get;
				this.Ajax(url, {
					id: this.id,
					menu_number: 4
				}, (data) => {
					that.chapterList = data;
					console.log(JSON.stringify(that.chapterList))
				});
			},
			getPPTid(chapter_id) {
				var that = this;
				var url = apiUrl.digital_office_ppt_id_get;
				this.Ajax(url, {
					chapter_id: chapter_id
				}, (data) => {
					that.getPPT(data);
				});
			},
			getPPT(id) {
				var that = this;
				var url = apiUrl.digital_office_ppt_detail_get;
				this.Ajax(url, {
					id: id
				}, (data) => {
					that.listData.current = data;
					that.listData.current.ppt_text = that.listData.current.ppt_text.replace(/\n/g, '<br>')
					that.listData.list.forEach((item, index) => {
						if (item == data.id) {
							that.listData.current.index = index + 1;
						}
					})

					var mark_text = "游客 888****8888";
					if (localStorage.getItem("userLogInInfo")) {
						var userInfo = JSON.parse(localStorage.getItem("userLogInInfo"));
						if (userInfo && userInfo.mobile != '') {
							mark_text = "用户 " + userInfo.mobile;
						}
					}
					watermark.getImgSrc(this.listData.current.img_url, mark_text, (img) => {
						document.getElementById('img').src = img.src;
					});
				});
			},
			nextPPT() {
				if (this.listData.current.index >= this.listData.count) {
					this.$message({
						message: '已经是最后一页',
						type: 'warning',
						offset: 300,
						duration: 1500
					});
					return;
				}
				this.getPPT(this.listData.list[this.listData.current.index]);
			},
			lastPPT() {
				if (this.listData.current.index <= 1) {
					this.$message({
						message: '已经是第一页',
						type: 'warning',
						offset: 300,
						duration: 1500
					});
					return;
				}
				this.getPPT(this.listData.list[this.listData.current.index - 2])
			},
			pptDownload() {
				var that = this;
				if (that.id == '9ea73161ee334b79a2ef0575316a088b') {
					that.dialogVisible = true;
					var url = apiUrl.digital_office_ppt_download;
					this.Ajax(url, {
						office_id: that.id
					}, (data) => {
						that.pptUrl = data.replace('images.cmpreading.com', 'images.cmptt.com');
					}, null, null, null, true);
				} else if(that.id == 'ab52f9cd88084cbb81164a882e2d0e6e'){
					this.$alert('请发送邮件至 zhang888@swufe.edu.cn 索取完整版PPT', '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							console.log("点击确认");
						}
					});
				}else{
					this.$alert('请联系编辑老师索取完整版PPT', '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							console.log("点击确认");
						}
					});
				}

			},
			browseFile(url) {
				var newTab = window.open('about:blank');
				newTab.location.href = url;
			}
		},
	};
</script>

<style lang="scss" scoped>
	/deep/.el-button--primary {
		background-color: #CE671B !important;
		border-color: #CE671B !important;
	}

	.ppt_chapters_scroll_y {
		padding-bottom: 100px !important;
	}

	.ppt_chapters_scroll_y::-webkit-scrollbar {
		width: 3px;
	}

	.ppt_chapters_scroll_y::-webkit-scrollbar-thumb {
		height: 50px;
		background-color: #3DD2BC;
		border-radius: 6px;
	}

	.chapter-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		font-size: 15px;
		padding: 10px 0;
		border-bottom: 1px solid #ccc;
		box-sizing: border-box;
		cursor: pointer;

		.title {
			font-weight: 600;
			color: #333;
		}

		.sub_title {
			margin-top: 5px;
			color: #999;
		}

		&:hover,
		&.actived {
			background-color: #E4E4E4;

			.title {
				color: #333;
			}

			.sub_title {
				color: #5d5d5d;
			}
		}

		&:last-child {
			border-bottom: 0;
		}
	}
</style>