<template>
	<div class="container">
		<div class="catalog" v-if="asideShow">
			<div class="box">
				<h3>
					{{bookData.title}}
				</h3>
				<img class="cover" :src="bookData.img_url_compressed" width="130px" alt="" />
				<div class="directory">
					<h2>目录</h2>
					<ul>
						<li v-for="item in directoryData" :key="item.id" @click="targetChapter(item)" style="" :class="{'level2':item.level == 2,'level3':item.level == 3,'actived':item.chapterid == search.chapterid,'close':item.open == 0,}">
							{{item.title}}
							<img class="lock" src="../../assets/image/lock.png" alt="" v-if="item.open == 0" />
						</li>
					</ul>
				</div>
			</div>

		</div>
		<div class="aside_control aside_open" v-if="asideShow" @click="asideShow=!asideShow">
			<i class="open"></i>
		</div>

		<div class="aside_control aside_close" v-if="!asideShow" @click="asideShow=!asideShow">
			<i class="close"></i>
		</div>
		<div ref="content" id="content" class="content">
			<div class="contentP" @keyup.right="next" @contextmenu.prevent @mousedown="clearCopy" @mouseup="clearCopy" v-html="contentData.content"></div>
			<div class="btnbox" v-if="contentData.next_chapterid_open == 1 && contentData.next_chapterid!=''">
				<button class="btn" @click="next">下一章</button>
			</div>
			<div class="btnbox" v-if="contentData.next_chapterid_open == 0 && contentData.next_chapterid!=''">
				<button class="btn">试读结束</button>
			</div>
			<div class="btnbox" v-if="contentData.next_chapterid_open == 0 && contentData.next_chapterid==''">
				<button class="btn">阅读完成</button>
			</div>
		</div>

		<!-- <div class="qrcodebox" v-if="qrShow" @click="qrShow=!qrShow">
			<i class="el-icon-close close_btn"></i>
			<div id="qrcode_id"></div>
			扫码二维码<br>微信读好书
		</div> -->

	</div>
</template>

<script>
	// import QRCode from "qrcodejs2";
	import ApiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	export default {
		data() {
			return {
				directoryShow: false,
				directoryData: [],
				contentData: {},
				search: {
					uuid: '',
					chapterid: '',
				},
				record: {
					product_id: '',
					chapterid: ''
				},
				bookData: {
					img_url: '',
					img_url_compressed: '',
					title: '',
				},
				book_type: 0,
				qrShow: true,
				asideShow: true,
				read_timestamp: Date.parse(new Date()),
				isLogin: false
			};
		},
		components: {
			//qrcode: QRCode,
		},
		created() {
			this.isLogin = localStorage.hasOwnProperty("userLogInInfo");

			var option = {
				uuid: this.$route.query.uuid || "",
				chapterid: this.$route.query.chapterid || "",
			};
			console.log('111');
			if (option && option.uuid && option.chapterid) {
				this.read_timestamp = Date.parse(new Date());
				this.search.uuid = option.uuid;
				this.search.chapterid = option.chapterid;
				this.getChapterText();

			}

			var that = this;
			this.$nextTick(() => {
				
				// 禁用右键
				document.oncontextmenu = new Function("event.returnValue=false");
				// 禁用选择
				document.onselectstart = new Function("event.returnValue=false");
				// 禁用拖动
				document.ondragstart = new Function("event.returnValue=false");
				//键盘快捷方式
				document.onkeydown = function(event) {
					//ctrl组合键无效
					if (event.ctrlKey) {
						//取消默认事件
						event.preventDefault();
						return false;
					}
					//右方向键可翻到下一章
					if (event.keyCode == 39) {
						//取消默认事件
						event.preventDefault();
						that.next();
					} else if (event.keyCode != 40) //向下方向键除外
					{
						//取消默认事件
						event.preventDefault();
						return false;
					}
				}
			});

			this.$notify({
				title: '操作指引',
				dangerouslyUseHTMLString: true,
				message: '鼠标点击中间内容区激活操作<br>键盘方向键 ↓ 可向下滚动页面<br>键盘方向键 → 可翻到下一章节',
				duration: 0,
				position: 'bottom-right'
			});

			// this.$notify({
			//     title: '扫码可在微信上阅读本书',
			//     dangerouslyUseHTMLString: true,
			//     message: '<div id ="qrcode_id"></div>',
			// 	duration: 0,
			// });

		},
		mounted() {
			//添加监听
			window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
			console.log('监听 beforeunload')
		},
		destroyed(){
			//解除监听
			window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
			console.log('解除 beforeunload')
		},
		methods: {
			//页面退出时提交下记录
			beforeunloadHandler(e) {
			   //this.postBookRecord();
			},
			clearCopy() {
				window.getSelection ? window.getSelection().removeAllRanges() : document.selection.empty();
				var range = window.getSelection();
				if (range.length > 0) {}
			},
			setupQRCode(url) {
				let code_img = new QRCode(document.getElementById("qrcode_id"), {
					width: 150,
					height: 150, // 高度
					text: url, // 二维码内容
					colorDark: '#515a6e',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
			},
			getDirectoryData(id, reload) {
				var that = this;
				if ((this.directoryData && this.directoryData.length == 0) || reload) {
					var param = { id: id };
					this.Ajax(ApiUrl.book_content_get, param, function(data, code) {
						if (code == 0 && data) {
							that.directoryData = data;
							console.log("获取目录信息")
						}
					});
				}
			},
			getBookData(data) {
				if (this.bookData && !this.bookData.title && data) {
					this.bookData.title = data.title;
					this.bookData.img_url = data.img_url;
					this.bookData.img_url_compressed = data.img_url_compressed;
					console.log("获取图书信息");
				}
			},
			getChapterText() {
				var that = this;
				if (this.search && this.search.uuid && this.search.chapterid) {
					this.Ajax(ApiUrl.book_chapter_get, this.search, function(data, code) {
						if (code == 0 && data) {
							that.contentData = data;
							if (that.book_type == 0) {
								that.book_type = data.book_type;
								that.loadStyles();
								if (data.qrcode_url != '') {
									//that.setupQRCode(data.qrcode_url);
								}
								document.title = data.title + " - 电子书 - 机工新阅读"
							}
							that.getBookData(data);
							that.getDirectoryData(data.product_id, false);

							that.record.product_id = data.product_id;
							that.record.chapterid = data.chapterid;
						}
					});
				}
			},
			targetChapter(item) {
				//this.postBookRecord();
				if (item && item.uuid && item.chapterid && item.open == 1) {
					this.search.uuid = item.uuid;
					this.search.chapterid = item.chapterid;
					this.getChapterText();
				} else {
					if(this.isLogin){
						
						this.$confirm('本电子样书仅对教师开放，您尚未认证教师权限，是否认证教师权限', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							window.top.location.href = "/teacherReg";
						}).catch(() => {
							// this.$message({
							// 	type: 'info',
							// 	message: '已取消'
							// });
						});
						
						/*
						this.$alert('您暂无权限下载', '提示', {
							confirmButtonText: '确定'
						});
						*/
					   
					}else{
						this.$confirm('您尚未登录, 是否登录?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							window.top.location.href = "/login";
						}).catch(() => {
							// this.$message({
							// 	type: 'info',
							// 	message: '已取消'
							// });
						});
					}
					
					/*
					this.$message({
						message: '暂无权限阅读当前章节',
						type: 'warning',
						offset: 330,
						duration: 2000
					});
					*/
				}
			},
			loadStyles() {
				var csslink = "https://images.cmpreading.com/h5/styles/pc/jg.css";
				if (this.book_type == 2) {
					csslink = "https://images.cmpreading.com/h5/styles/pc/jsj.css";
				}
				var link = document.createElement("link");
				link.setAttribute("rel", "stylesheet");
				link.setAttribute("type", "text/css");
				link.setAttribute("href", csslink);
				document.head.appendChild(link);
			},
			//下一章
			next() {

				if (this.contentData && this.contentData.next_chapterid != "" && this.contentData.next_chapterid_open == 1) {
					//this.postBookRecord();
					
					this.search.uuid = this.contentData.uuid;
					this.search.chapterid = this.contentData.next_chapterid;
					this.getChapterText();
					document.getElementById("content").scrollTop = 0;
				}
			},
			
			/*
			//提交阅读记录
			postBookRecord() {
				var that = this;
				if (this.isLogin) {
					if (this.record && this.record.product_id && this.record.chapterid) {
						var now_timestamp = Date.parse(new Date()); //当前时间戳
						var duration = (now_timestamp - this.read_timestamp) / 1000; //阅读时间，单位秒
						//var scroll_top = document.getElementById("content").scrollTop || 0;//电子书页面阅读位置
						var param = {
							id: this.record.product_id,
							duration: duration,
							chapterid: this.record.chapterid,
							//scroll_top: scroll_top
						}
						try {
							this.Ajax(ApiUrl.book_record_set, param, function(data, code) {
								if (code == 0) {
									that.read_timestamp = Date.parse(new Date()); //更新开始阅读时间戳
								}
							});
						} catch (e) {
							//TODO handle the exception
						}
					}
				}
			},
			*/
		},
	};
</script>

<style lang="scss">
	@media print {

		html,
		body,
		* {
			display: none;
		}
	}

	body {
		background-color: #f7f7f7;
		//background-color: #ffffff;
	}

	html,
	* {
		border: 0;
		outline: 0;
		-webkit-tap-highlight-color: transparent;
		-webkit-appearance: none;
		-webkit-user-select: none;
	}

	html,
	body,
	form,
	fieldset,
	p,
	div,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		-webkit-text-size-adjust: none;
	}

	::-webkit-scrollbar {
		width: 7px;
		height: 7px;
	}

	.content::-webkit-scrollbar {
		display: none;
		/*隐藏滚动条*/
	}

	::-webkit-scrollbar-track,
	::-webkit-scrollbar-thumb {
		border-radius: 999px;
		border: 2px solid transparent;
	}

	::-webkit-scrollbar-track {
		box-shadow: 1px 1px 2px rgba(0, 0, 0, .2) inset;
	}

	::-webkit-scrollbar-thumb {
		min-height: 20px;
		background-clip: content-box;
		box-shadow: 0 0 0 2px rgba(0, 0, 0, .2) inset;
	}

	::-webkit-scrollbar-corner {
		background: transparent;
	}

	:focus {
		outline: none;
		border: none;
	}

	.container {
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		position: relative;
	}

	.content {
		width: 880px;
		height: 100vh;
		overflow-y: auto;
		-webkit-overflow-scrolling:touch;
		word-break: break-all;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
		min-height: 903px;
		box-sizing: border-box;
		padding: 20px 110px 110px 110px;
		background-color: #FFFFFF;

		.contentP {
			width: 100%;
			display: inline-block;
			-moz-user-select: none;
			-webkit-user-select: none;
		}
	}

	.catalog {
		width: 320px;
		height: 100vh;
		overflow-y: auto;
		-webkit-overflow-scrolling:touch;
		position: absolute;
		top: 0;
		left: 0;
		border-right: 1px solid #e6e6e6;
		background-color: #ffffff;
		z-index: 99;

		.box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;

			h3 {
				padding: 15px;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: 500;
				letter-spacing: 1px;
				display: -webkit-box;
				overflow: hidden;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				-webkit-line-clamp: 2;
			}

			.cover {
				width: 130px;
				height: auto;
				box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
				border-radius: 6px;
			}

			.directory {
				padding: 30px 8px 30px 10px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;


				.level2 {
					padding-left: 1em;
				}

				.level3 {
					padding-left: 2em;
				}

				.actived {
					color: #e6554d;
				}

				h2 {
					font-size: 20px;
					font-weight: 700;
					letter-spacing: 1px;
					margin-bottom: 15px;
				}

				ul {
					display: flex;
					flex-direction: column;
					list-style: none;

					li {
						font-size: 15px;
						border-bottom: 1px dashed #e6e6e6;
						line-height: 1.5;
						padding-top: 6px;
						padding-bottom: 6px;
						padding-right: 17px;
						box-sizing: border-box;
						cursor: pointer;
						position: relative;
						display: flex;
						align-items: center;

						.lock {
							width: 15px;
							position: absolute;
							right: 0;
						}
					}

					li:hover {
						background-color: rgb(238, 241, 246);
					}

					.close {
						color: #858c96 !important;
					}
				}
			}


		}




	}



	#content .pic img {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		padding: 15px;
		max-height: 800px;
		box-sizing: border-box;
		;
		background: #fff;
		display: inline-block;
	}

	#content img {
		max-height: 92vh !important;
	}

	#content hr {
		width: 0;
		height: 0;
		border: 0;
	}

	#content pre.ziti1 {
		background-color: rgb(232, 231, 226);
		color: rgb(82, 85, 82);
		font-size: 14px;
		line-height: 1.5;
		white-space: pre-wrap;
		word-wrap: break-word;
		padding: 20px 15px !important;
		font-family: Consolas, "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
	}

	.btnbox {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 50px;

		.btn {
			cursor: pointer;
			display: block;
			width: 440px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			border-radius: 12px;
			text-align: center;
			font-size: 16px;
			background-color: rgb(238, 241, 246);
			color: #1b88ee;
			border: 0;
		}

		.btn:hover {
			background-color: #CCCCCC;
		}
	}

	.qrcodebox {
		width: 200px;
		height: 280px;
		//top: 56px;
		//right: 60px;
		top: 16px;
		right: 16px;
		line-height: 1.5;
		font-size: 16px;
		position: fixed;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #FFFFFF;

		.close_btn {
			position: absolute;
			top: 10px;
			right: 10px;
			color: #909399;
			cursor: pointer;
		}

		#qrcode_id {
			width: 150px;
			height: 150px;
			margin: 10px auto;
		}
	}

	.aside_control {
		position: absolute;
		top: 50%;
		margin-top: -30px;
		width: 30px;
		height: 60px;
		z-index: 98;
		cursor: pointer;

		&.aside_open {
			left: 320px;
		}

		&.aside_close {
			left: 7px;
		}

		.close,
		.open {
			display: block;
			width: 30px;
			height: 60px;
			background-image: url(../../assets/image/skin_ears.png);
			//background-image: url(https://yuedu.163.com/js/reader/images/skin/normal/skin_ears.png);
			background-repeat: no-repeat;

		}

		.close {
			background-position: 0 0;
		}

		.open {
			background-position: 0 -60px;
		}
	}
</style>
