<template>
	<div class="page-main">
		<div style="width: 1200px; display: flex; flex-direction: column;" v-if="detailData">
			<!-- 名师团 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 40px 0 20px 0;">
					<div class=""
						style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);">

					</div>
					<div style="display: flex;flex-direction: column; line-height: 1;">
						<div style="font-size: 28px; color: #333;">名师团</div>
						<div style="font-size: 17px; color: #999; margin-top: 10px;">改变自己，从关注自己身边的榜样开始</div>
					</div>
				</div>
			</div>
			
			<div style=" width: 100%; height: 214px; margin-top: 140px; display: flex; background-color: #E5EAFE; border-radius: 12px; position: relative; box-sizing: border-box;">
				<div style="position: absolute; right: 0; top: 0; font-size: 16px; font-style: italic; color: #fff; background-color: #52A7FB; padding: 8px 16px 8px 16px; border-radius: 0 12px 0 20px; line-height: 1;box-sizing: border-box;">
					{{detailData.category_title}}
				</div>
				<div style="width: 470px; height: 214px;  position:relative; display: flex;align-items: center; justify-content: center;">
					<img :src="detailData.img_url" style="width:360px; position: absolute; bottom: 0;"/>
				</div>
				<div style="flex: 1; height: 214px; display: flex;flex-direction: column; line-height: 1;">
					<div style="font-size: 36px; font-weight: 600; margin-top: 40px;">{{detailData.name}}</div>
					<div style="display: flex;flex-direction: column; font-size: 16px; color: #333;  margin-top: 30px; padding-right: 20px; box-sizing: border-box; line-height: 1.2;">
						<div v-if="detailData.school || detailData.college">
							<span style="display: inline-block;width: 8px; height: 8px; margin-right: 10px; border-radius: 50%; background-color: #FEA14E;"></span>
							<span v-if="detailData.school">{{detailData.school}}</span><span v-if="detailData.college">/{{detailData.college}}</span></div>
						<div v-if="detailData.title" style="margin-top: 6px;padding-left: 18px; text-indent: -18px;">
						<span style="display: inline-block;width: 8px; height: 8px; margin-right: 10px; border-radius: 50%; background-color: #FEA14E;"></span>
						{{detailData.title}}</div>
						<div v-if="detailData.job" style="margin-top: 6px;padding-left: 18px;text-indent: -18px;">
							<span style="display: inline-block;width: 8px; height: 8px; margin-right: 10px; border-radius: 50%; background-color: #FEA14E;"></span>
						{{detailData.job}}
						</div>
						<div v-if="detailData.job2" style="margin-top: 6px;padding-left: 18px;text-indent: -18px;">
						<span style="display: inline-block;width: 8px; height: 8px; margin-right: 10px; border-radius: 50%; background-color: #FEA14E;"></span>
						{{detailData.job2}}</div>
						<div v-if="detailData.job3" style="margin-top: 6px; padding-left: 18px;text-indent: -18px;">
						<span style="display: inline-block;width: 8px; height: 8px; margin-right: 10px; border-radius: 50%; background-color: #FEA14E; "></span>
						{{detailData.job3}}
						</div>
					</div>
					<!-- <div style="display: flex; margin-top: 15px;">
						<div v-for="(item,index) in tagData" style=" display:inline-block; font-size: 14px;line-height: 1; padding: 4px 8px; border-radius: 3px; border: 1px solid #FCA258; color:#FCA258; margin-right: 12px; margin-top: 10px;">{{item}}</div>
					</div> -->
				</div>

			</div>
			
			<div class="teach_tabs" style="width: 100%; margin: 35px 0;">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="讲师介绍" name="first" v-if="detailData.description">
						<div style="font-size: 17px; line-height: 1.5; text-align: justify; padding: 10px 0 100px 0;  box-sizing: border-box;" v-html="detailData.description">
							
						</div>
					</el-tab-pane>
				    <el-tab-pane label="特训营" name="second" v-if="detailData.camp_total>0 && productData.list.length>0">
						<div style="width: 100%; display: flex; flex-direction: column;margin-bottom: 32px;  cursor: pointer; " v-for="(item,index) of productData.list" @click="toDetail(item)">
							<div style="display: flex;">
								<img  :src="item.img_url_compressed" style="width: 300px;margin-right: 32px;" />
								<div style="flex: 1; display: flex; flex-direction: column;">
									<div style="color: #333;font-size: 16px;">{{item.title}}</div>
									<div style="color: #999;font-size: 14px;margin-top: 12px;">{{item.start_at}}</div>
								</div>
							</div>
						</div>
					</el-tab-pane>
				    <el-tab-pane label="课程" name="third" v-if="detailData.course_total>0 && productData2.list.length>0">
						<div class="course_list" style="margin-top: 20px;">
							<div class="item" style="display: flex; flex-direction: column;border-radius: 0 0 20px 20px;" v-for="(item,index) in productData2.list" @click="toDetail(item)">
								<img style="width: 100%;" src="../../assets/images/cover.png" v-if="item.img_url_tt==''"/>
								<img style="width: 100%;" :src="item.img_url_tt"  v-if="item.img_url_tt!=''"/>
								<div class="" style="flex: 1; background-color: #F1F1F1; display: flex;flex-direction: column;">
									<div style="flex: 1; display: flex;flex-direction: column; padding: 15px 10px 10px 15px;">
										<div style="font-size: 20px; font-weight: 600; line-height: 1.2; ">{{item.title}}</div>
										<div style="font-size: 18px;line-height: 1; margin-top: 8px;">主讲人：{{item.author}}</div>
									</div>
									<div style="height: 40px;  border-radius: 0 0 20px 20px; border-top: 1px solid #E8E8E8; display: flex; justify-content: space-between;  ; align-items: center; padding:0 17px 0 20px;">
										<div style="display: flex;font-size: 13px; align-items: center;">
											 <div style="background-color: #c93a2e;color: #fff; padding:2px 8px; border-radius:20px;">{{item.res_count}}小节</div>
											<div style=" margin-left: 8px;"><i class="el-icon-pie-chart" style="margin-right: 2px;"></i>{{item.res_length}}</div>
										</div>
										
										<div style="font-weight: 600; font-size: 17px; color: #c93a2e;" v-if="item.price!='0.00'">¥{{item.price}}</div>
										<div style="font-weight: 600; font-size: 17px; color: #c93a2e;" v-if="item.price=='0.00'">免费</div>
									</div>
								</div>
							</div>	
						</div>
						
					</el-tab-pane>
				    <el-tab-pane label="教研室" name="four" v-if="detailData.digital_office_total>0 && productData3.list.length>0">
						
						<div class="room_list">
							<div class="room_item" v-for="(item,index) in productData3.list"  @mouseover="mouseoverHandler(item)" @mouseout="mouseoutHandler()" @click="toDetail(item)">
								<div class="hover_box" v-if="selected && selected.id == item.id"></div>
								<div class="default_box">
									<div class="mes_box" style="display: flex;flex-direction: column; justify-content: space-between;">
										<div style="display: flex;flex-direction: column;">
											<div class="name">{{item.title}}</div>
											<div class="title">
												<div class="title_1" v-if="item.teacher_title && item.teacher_title.split('；')[0]">{{item.teacher_name}} {{item.teacher_title.split('；')[0]}}</div>
												<div class="title_2" v-if="item.teacher_title && item.teacher_title.split('；')[1]">{{item.teacher_title.split('；')[1]}}</div>
											</div>
										</div>
										
										<div class="exts" style="margin-top: 35px;">
											<div class="ext">
												<div class="ext_hd">资源</div>
												<div class="ext_bd">
													<div>{{item.resourse_count}}</div>
												</div>
											</div>
											<div class="ext ext2">
												<div class="ext_hd">成员</div>
												<div class="ext_bd">
													<div>{{item.student_count}}</div>
													<div class="unit">人</div>
												</div>
											</div>
										</div>
										<div class="triangle_gray"></div>
										<div class="triangle_white"></div>
										<div class="triangle_black"></div>
									</div>
								</div>
							</div>
						</div>
						
						
					</el-tab-pane>
				 </el-tabs>
			</div>
			
			  
		</div>
		
		
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				search: {
					teacher_id:'',
					page_index: 1,
					page_size: 12,
					order_by: 'created_at:desc'
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				},
				search2: {
					teacher_id:'',
					page_index: 1,
					page_size: 12,
					order_by: 'created_at:desc'
				},
				productData2: {
					total: 0,
					count: 0,
					list: [],
				},
				search3: {
					teacher_id:'',
					page_index: 1,
					page_size: 12,
					order_by: 'created_at:desc'
				},
				productData3: {
					total: 0,
					count: 0,
					list: [],
				},
				
				tagData: ["AIGC", "数字化教育", "科技", "Chat GPT", "法律"],
				activeName: 'first',
				selected:null,
				detailData:null,
			};
		},
		created() {
			document.title = "名师团 - 机工教师特训营";

			var option = {
				id: this.$route.query.id || "",
			};
			if (option.id) {
				this.id = option.id;
				this.search.teacher_id = this.id;
				this.search2.teacher_id = this.id;
				this.search3.teacher_id = this.id;
				this.getDetail();
			}
		},
		methods: {
			toDetail(item){
				this.GoToDetail(item.product_type,item.id);
			},
			getDetail() {
				var that = this;
				var url = apiUrl.tt_teacher_detail_get;
				this.Ajax(url, {
					id: that.id
				}, (data) => {
					that.detailData = data;
					document.title = data.name + " - 名师团 - 机工教师特训营";
					if(data.camp_total>0){
						that.getProductList();
					}
					if(data.course_total>0){
						that.getProductList2();
					}
					if(data.digital_office_total>0){
						that.getProductList3();
					}
					if(data.description){
						that.activeName = 'first';
					}else if(data.camp_total>0){
						that.activeName = 'second';
					}else if(data.course_total>0){
						that.activeName = 'third';
					}else if(data.digital_office_total>0){
						that.activeName = 'four';
					}else{
						that.activeName ='';
					}
				});
			},
			mouseoverHandler(item) {
				this.selected = item;
			},
			mouseoutHandler(item) {
				this.selected =  null;
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_teacher_camp_list_get;
				this.Ajax(url, this.search, (data) => {
					if (that.productData.total == 0) {
						that.productData.total = data.total;
					}
					if (that.productData.count == 0) {
						that.productData.count = data.count;
					}
					that.productData.list = data.list;
			
					//console.log('productData' + JSON.stringify(that.productData))
				});
			},
			getProductList2() {
				var that = this;
				var url = apiUrl.tt_teacher_course_list_get;
				this.Ajax(url, this.search2, (data) => {
					if (that.productData2.total == 0) {
						that.productData2.total = data.total;
					}
					if (that.productData2.count == 0) {
						that.productData2.count = data.count;
					}
					that.productData2.list = data.list;
			
					//console.log('productData2' + JSON.stringify(that.productData2))
				});
			},
			getProductList3() {
				var that = this;
				var url = apiUrl.tt_teacher_digital_office_list_get;
				this.Ajax(url, this.search3, (data) => {
					if (that.productData3.total == 0) {
						that.productData3.total = data.total;
					}
					if (that.productData3.count == 0) {
						that.productData3.count = data.count;
					}
					that.productData3.list = data.list;
					that.productData3.list.forEach((item,index)=>{
						if(item.id == '9ea73161ee334b79a2ef0575316a088b' || item.id == '56bd65acb72f4e48963307e70d6bbba1'){
							item.show = true;
						}else{
							item.show = false;
						}
					})
					//console.log('productData3' + JSON.stringify(that.listData))
				});
			},
		},
	};
</script>

<style>
.teach_tabs .el-tabs__item{
	height: 48px !important;
	line-height: 48px !important;
	font-size: 26px !important;
	color: #999 !important;
	padding: 0 43px;
}

.teach_tabs .el-tabs__active-bar{
	background-color: transparent !important;
	background: linear-gradient(to right,#ff9000,#c93a2e) !important;
	height: 3px !important;
	border-radius: 3px !important;
}

.teach_tabs .el-tabs__item:hover{
	color: #333 !important;
}
.teach_tabs .el-tabs__item.is-active{
	color: #333 !important;
}
</style>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		
		.course_list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		
			.item {
				width: 280px;
				height: 376px;
				//height: 325px;
				//border: 1px solid #eee;
				display: flex;
				flex-direction: column;
				//box-shadow: 5px 5px 10px #eee;
				//box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
				margin-right: 20px;
				margin-bottom: 40px;
				cursor: pointer;
				overflow: hidden;
		
				&:nth-child(4n+4) {
					margin-right: 0;
				}
		
				&:hover {
					//box-shadow:0 1px 1px 1px 10px #666;
					//box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.1);
				}
		
				.cover {
					width: 285px;
					height: 220px;
					//height: 165px;
				}
				
		
				.mes {
					width: 100%;
					display: flex;
					flex-direction: column;
					line-height: 1;
					padding-left: 18px;
					box-sizing: border-box;
					margin: 0;
					position: relative;
		
					.title {
						margin-top: 15px;
						height: 20px;
						margin-left: -18px;
						font-size: 20px;
						color: #333;
						font-weight: 600;
						border-left: 8px solid #333;
						padding-left: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
		
		
					.anchor {
						font-size: 17px;
						margin-top: 10px;
						color: #333;
						display: flex;
					}
		
					.res_info {
						display: flex;
						justify-content: flex-start;
						font-size: 12px;
						margin-top: 15px;
						position: relative;
		
						.res_count {
							background-color: #333;
							color: #fff;
							padding: 5px 8px;
							display: inline-flex;
							border-radius: 4px 0 0 4px;
						}
		
						.res_length {
							background-color: #3CD0BA;
							color: #01786D;
							padding: 5px 8px;
							display: inline-flex;
							border-radius: 0 4px 4px 0;
						}
					}
		
					.exts {
						display: flex;
						width: 60%;
						align-items: flex-end;
						margin-top: 30px;;

						.price {
							font-size: 21px;
							color: #333;
							margin-right: 8px;
							display: inline-flex;
						}
		
						.readers {
							font-size: 12px;
							color: #3CD0BA;
							display: inline-flex;
						}
					}
		
					.icon_play {
						width: 150px;
						height: 150px;
						margin: 0;
						position: absolute;
						z-index: 2;
						right: -24px;
						bottom: -24px;
					}
				}
			}
		}
		
		.room_list {
			width: 100%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-bottom: 40px;
		
			.room_item {
				width: 565px;
				height: 200px;
				display: flex;
				flex-direction: column;
				margin-bottom: 40px;
				margin-right: 40px;
				background-color: #fff;
				border: 1px solid #ddd;
				position: relative;
				cursor: pointer;
		
				&:nth-of-type(2n+2) {
					margin-right: 0;
				}
		
				.hover_box {
					position: absolute;
					width: 100%;
					height: 100%;
					background: #F1F1F1;
					opacity: 0.5;
					z-index: 1;
				}
				
				.default_box{
					z-index: 9;
					.mes_box {
						flex: 1;
						width: 100%;
						padding: 10px 20px;
						box-sizing: border-box;
						display: flex;
						flex-direction: column;
						
						.triangle_gray {
							position: absolute;
							width: 0;
							right: 0;
							bottom: 0;
							border-top: 23px solid transparent;
							border-right: 23px solid #ddd;
							border-bottom: 23px solid #ddd;
							border-left: 23px solid transparent;
						}
						
						.triangle_white {
							position: absolute;
							width: 0;
							right: -1px;
							bottom: -1px;
							border-top: 23px solid transparent;
							border-right: 23px solid #fff;
							border-bottom: 23px solid #fff;
							border-left: 23px solid transparent;
						}
					
						.triangle_black {
							position: absolute;
							width: 0;
							right: -1px;
							bottom: -1px;
							border-top: 18px solid transparent;
							border-right: 18px solid #f49845;
							border-bottom: 18px solid #f49845;
							border-left: 18px solid transparent;
						}
					
						.name {
							font-size: 20px;
							font-weight: 600;
							color: #333;
						}
					
						.title {
							font-size: 14px;
							color: #999;
							margin-top: 10px;
					
							.title_2 {
								margin-top: 5px;
							}
						}
					
						.exts {
							display: flex;
							flex-direction: row;
							margin-top: 15px;
					
							.ext {
								display: flex;
								flex-direction: column;
								font-weight: 600;
					
								&.ext2 {
									padding: 0 20px;
									margin: 0 20px;
									border-left: 1px solid #ccc;
								}
					
								.ext_hd {
									font-size: 14px;
									color: #333;
								}
					
								.ext_bd {
									font-size: 18px;
									color: #f49845;
									margin-top: 8px;
									display: flex;
									align-items: baseline;
					
									.unit {
										font-size: 12px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>