import watermark from 'watermarkjs';

var text="";
const options = {
	init(img) {
		img.crossOrigin = 'anonymous'
	}
};

var rotate = function(target) {
	var context = target.getContext('2d');
	var metrics = context.measureText(text);
	var x = (target.width / 2) - (metrics.width + 24);
	var y = (target.height / 2) + 48 * 2;

	context.translate(x, y);
	context.globalAlpha = 0.5;
	context.fillStyle = '#e5e5e5';
	context.font = '24px Josefin Slab';
	context.rotate(-45 * Math.PI / 180);


	/*中间水印*/
	context.fillText(text, -384, -384);
	context.fillText(text, -192, -192);
	context.fillText(text, 0, 0);
	context.fillText(text, 192, 192);
	context.fillText(text, 384, 384);
	/*底部水印*/
	context.fillText(text, -528, -192);
	context.fillText(text, -336, 0);
	context.fillText(text, -144, 192);
	context.fillText(text, 48, 384);
	context.fillText(text, 240, 576);
	/*头部水印*/
	context.fillText(text, 624, 192);
	context.fillText(text, 432, 0);
	context.fillText(text, 240, -192);
	context.fillText(text, 48, -384);
	context.fillText(text, -144, -576);

	return target;
};

function getImgSrc(url, watermark_text, callback) {
	var arr = new Array();
	arr.push(url);
	text = watermark_text;
	watermark(arr, options)
		.image(rotate)
		.then((img) => {
		if(callback) callback(img);
	});
}

export default {
	getImgSrc: getImgSrc
}