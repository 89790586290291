<template>
	<div style=" width: 100%; display: inline-flex;flex-direction: column; margin-bottom: 20px;">
		<div class="" style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px; ">
			客观题库
		</div>
		<div class="" style="width: 640px;height: 150px; display:flex;flex-direction: row; margin-top: 30px; background-color: #E4E4E4;">
			<div class="" style=" width: 150px; height: 150px;  background:#d7d7d7; display: flex; justify-content: center; align-items: center;  color: #333;">
				<div style="display: flex;width: 84px; height: 110px;flex-direction: column; justify-content: space-between; align-items: center;">
					<div id="qrcode_id" v-if="has_auth == 1" style="display: none; margin-left: -99999px;"></div>
					<div v-if="has_auth == 1" style="width: 84px; height: 84px;display: flex;justify-content: center;align-items: center;">
						 <img :src="qrcodeimg" alt="" width="84" height="84" v-show="qrcodeimg">
					</div>
					
					
					<div v-if="has_auth == 0" style="width: 84px; height: 84px;font-size: 12px; background-color: #333;color: #ccc; display: flex;justify-content: center;align-items: center;">
						暂无权查看
					</div>
					<div style="font-size:14px">扫码参与答题</div>
				</div>

			</div>
			<div class="" style=" flex: 1; display: flex;flex-direction: row; justify-content: center; align-items: center;">

				<div class="" style="display: flex;flex-direction: column; width: 156px; border-right: 1px solid #ccc; padding-left: 32px;  box-sizing: border-box;">
					<i class="el-icon-s-operation" style="font-size: 35px;color: #333;"></i>
					<div class="" style="font-size: 22px;margin-top: 10px;color: #333;font-weight: 600;">
						章节数：
					</div>
					<div class="" style=" display:flex;flex-direction: row;font-size: 18px;margin-top: 18px;">
						<div class="">
							共 <span style="font-size:28px;color: #333;font-weight: 600;">{{cate_count}}</span> 个章节
						</div>
					</div>
				</div>
				<div class="" style=" flex:1; display: flex;flex-direction: column;padding-left: 32px;  box-sizing: border-box;">
					<i class="el-icon-s-operation" style="font-size: 35px;color: #333;"></i>
					<div class="" style="font-size: 22px;margin-top: 10px;color: #333;font-weight: 600;">
						题数：
					</div>
					<div class="" style=" display:flex;flex-direction: row;font-size: 18px;margin-top: 18px;">
						<div class="">
							判断 <span style="font-size:28px;color: #333;font-weight: 600;">{{panduan_count}}</span> 题
						</div>
						<div class="" style="margin-left: 30px;">
							选择 <span style="font-size:28px;color: #333;font-weight: 600;">{{xuanze_count}}</span> 题
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import QRCode from "qrcodejs2";

	export default {
		props: {
			myid: String,
			myauth: Number
		},
		data() {
			return {
				id: this.myid,
				has_auth: this.myauth,
				cate_count: 0,
				panduan_count: 0,
				xuanze_count: 0,
				qrcodeimg: '',
			};
		},
		components: {
			qrcode: QRCode,
		},
		created() {
			//document.title = "客观题库 - 数字教研室 - 机工教师特训营";
			this.getInfo();
		},
		methods: {
			setupQRCode(url) {
				let code_img = new QRCode(document.getElementById("qrcode_id"), {
					width: 84,
					height: 84, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
				
				this.$nextTick(()=>{
					// 生成二维码之后，获取canvas元素
					let qrcodeEle = document.getElementById("qrcode_id")
					let cvs = qrcodeEle.querySelector('canvas')
					this.qrcodeimg = cvs.toDataURL('image/png')
				})
			
			},
			getInfo() {
				var that = this;
				var url = apiUrl.digital_office_question_info_get;
				this.Ajax(url, { office_id: this.id }, (data) => {
					that.cate_count = data.cate_count;
					that.panduan_count = data.panduan_count;
					that.xuanze_count = data.xuanze_count;
					if (that.has_auth == 1 && data.qrcode_url) {
						that.setupQRCode(data.qrcode_url);
					}
				});
			},
		},
	};
</script>

<style>

</style>