<template>
  
  <div class="" style=" width: 100%; display: flex; flex-direction: column;  align-items: center;height: 260px; position: relative;">
	  <img  src="../assets/images/footer_bg.png" style="min-width: 100%; overflow: hidden;"/>
	  <div style="width: 1200px; position: absolute; top: 0; padding: 35px 0; box-sizing: border-box;  display: flex;flex-direction: column;  justify-content: space-between; color: #fff;">
		  	<div class="" style="display: flex; justify-content: space-between;">
				<div style="display: flex;flex-direction: column;">
					<img src="../assets/images/footer_logo.png" style="width: 200px; height: auto; margin: 0;"/>
					<p style="font-size: 14px; margin: 0; margin-top: 18px; letter-spacing: 5px;">机械工业出版社旗下产品</p>
				</div>
		  		
				<div style="display: flex;flex-direction: column; line-height: 1;">
					<p style="font-size: 17px;">联系我们</p>
					<div style="font-size: 14px;margin-top: 25px;">
						<p>联系电话：010-88373033、010-88378723</p>
						<p style="margin-top: 14px;">联系邮箱：service@cmptt.com</p>
						<p style="margin-top: 14px;">联系地址：北京市西城区百万庄大街22号</p>
					</div>
				</div>
				
				<div class="" style=" display: flex; flex-direction:column; line-height: 1;">
					<p style="font-size: 17px;">关注我们</p>
					<div style="display: flex; align-items: center; margin-top: 20px;">
						<img src="../assets/images/qrcode_for_cmptt.jpg"  style="width: 100px; height: auto; margin-right: 20px; "/>
						<p style="font-size: 14px; line-height: 1.3;">扫码关注<br/>机工教师公众号</p>
					</div>
				</div>
		  	</div>
		    <div style=" display: flex; font-size:14px; margin-top: 30px;">
			   <div>Copyright©2024&nbsp;机工教师特训营&nbsp;</div>
			   <div style="margin-left:10px;"><a style="color: #fff !important;" href="https://beian.miit.gov.cn" target="_blank">京ICP备14043556号-12</a></div>
			   <div style="margin-left:10px; display: flex; "><img src="../assets/images/icon_beian.png"  style="width: 16px; height: 16px; margin-right:5px; "/><a style="color: #fff !important;" href="https://beian.mps.gov.cn/#/query/webSearch?code=11010202010928" rel="noreferrer" target="_blank">京公网安备11010202010928号</a></div>
			</div>
	  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isshow: false,
    };
  },
  methods: {
    handelpop() {
      this.isshow = false;
    },
    popblock() {
      this.isshow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url(../assets/css/footer.css);

.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999999;
  cursor: default;
  .erwem {
    width: 281px;
    height: 344px;
    background: #fff;
    margin: 200px auto;
    border: 1px solid #fff;
    position: relative;
    .mers {
      width: 189px;
      height: 198px;

      margin: 0 auto;
      margin-top: 52px;
      // border: 1px solid red;
      margin-bottom: 12px;
    }
    p {
      font-size: 16px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: center;
    }
    .imgas {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}
</style>
