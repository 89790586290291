<template>
	<div style="width: 100%; display: flex;flex-direction: column; background-color: #fff;">
		<div style="display: flex; align-items: flex-end; margin-bottom:20px ;">
			<div style="font-size: 17px; margin-right: 15px;">特训营</div>
			<div style="font-size: 12px; color: #FBA559; margin-bottom: 3px;">已购</div>
		</div>
		<div @click="toDetail(item)" style="width: 100%; display: flex; flex-direction: column;cursor: pointer; margin-bottom: 40px; " v-for="(item,index) in productData.list" v-if="productData.list && productData.list.length>0">
			<div style="display: flex;">
				<img  :src="item.img_url_compressed" style="width: 300px;margin-right: 32px;" />
				<div style="flex: 1; display: flex; flex-direction: column; line-height: 1.3; justify-content: space-between; ">
					<div style="display: flex;flex-direction: column;">
						<div style="color: #333;font-size: 14px;">{{item.title}}</div>
						<div style="color: #999;font-size: 13px; margin-top: 5px;">{{item.start_at}}</div>
					</div>
					<div style="margin-top: 13px; display: flex; justify-content: space-between; align-items: flex-end; width: 100%;" @click.stop="downloadClick(item.cert_info)">
						<div style="color: #999;font-size: 13px;">有效期：{{item.created_at}} ~ {{item.expire_at}}</div>
						<el-button size="mini" v-if="item.cert_info && item.cert_info.cert_url" >下载培训证书<i class="el-icon-download el-icon--right"></i></el-button>
					</div>
				</div>
			</div>
		</div>
		
		<el-empty v-if="!productData.list">
			<el-button @click="toList()">去看看有哪些特训营</el-button>
		</el-empty>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import download from 'downloadjs';
	
	export default {
		data() {
			return {
				search: {
					page_index: 1,
					page_size: 8,
					order_by: 'created_at:desc'
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				},	
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.getProductList();
		},
		methods: {
			downloadClick(item){
				download(item.cert_url,item.title);
			},
			toList(){
				this.GoToChangeTab(27);
			},
			toDetail(item) {
				if(item.is_open_study!=1){
					this.$alert('您已报名成功，线上内容暂未开放，敬请期待······', '温馨提示', {
					          confirmButtonText: '确定'
					  });
				}else{
					var url = "/trainingCamp/study";
					var query = { id: item.id };
					let routeUrl = this.$router.resolve({
						path: url,
						query: query,
					});
					window.open(routeUrl.href, "_blank");
				}
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_purchase_camp_list_get;
				this.Ajax(url, this.search, (data,code,msg) => {
					if (that.productData.total == 0) {
						that.productData.total = data.total;
					}
					if (that.productData.count == 0) {
						that.productData.count = data.count;
					}
					that.productData.list = data.list;
					if(code == 40004 && that.search.page_index == 1){
						that.productData.list = null;
					}
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.getProductList();
			}
		},
	};
</script>

<style>
</style>