<template>
	<div class="page-main" >
		<div class="body-main">
			<!-- 
			<div style="width: 100%; height: 120px; overflow: hidden;">
				<img src="../../assets/images/bg_room_01.png" style="width: 100%; height: auto;" alt="" />
			</div>
			-->
			<!-- 数字教研室 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 40px 0 20px 0;">
					<div class="" style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);"></div>
					<div style="display: flex;flex-direction: column; line-height: 1;">
						<div style="font-size: 28px; color: #333;">数字教研室</div>
						<div style="font-size: 17px; color: #999; margin-top: 10px;">提升教学实践系统能力的成长圈子</div>
					</div>
				</div>
			</div>
			
			<div class="mainc">
				<div class="room_list">
					<div class="room_item" v-for="(item,index) in listData.list" @mouseover="mouseoverHandler(item)" @mouseout="mouseoutHandler()" @click="toDetail(item)">
						<div class="hover_box" v-if="selected && selected.id == item.id">
							<img class="book_cover" :src="item.book_img" />
							<div class="book_title">{{item.title.replace('数字教研室','')}}</div>
							<div class="room_title">数字教研室</div>
							<div class="btn_more" v-if="item.show">
								了解更多
							</div>
							<div class="btn_more" v-if="!item.show">
								准备中...
							</div>
						</div>
						<div class="default_box"  v-if="!selected || (selected && selected.id != item.id)">
							<div style="width: 100%; height: 278px; overflow-y: hidden;">
								<img :src="item.teacher_img" style="width: 100%;" />
							</div>
							<div class="mes_box">
								<div class="name">{{item.teacher_name}}</div>
								<div class="title">
									<div class="title_1" v-if="item.teacher_title && item.teacher_title.split('；')[0]">{{item.teacher_title.split('；')[0]}}</div>
									<div class="title_2" v-if="item.teacher_title && item.teacher_title.split('；')[1]">{{item.teacher_title.split('；')[1]}}</div>
								</div>
								<div class="exts">
									<div class="ext">
										<div class="ext_hd">资源</div>
										<div class="ext_bd">
											<div>{{item.resourse_count}}</div>
										</div>
									</div>
									<div class="ext ext2">
										<div class="ext_hd">成员</div>
										<div class="ext_bd">
											<div>{{item.student_count}}</div>
											<div class="unit">人</div>
										</div>
									</div>
									<div class="ext">
										<div class="ext_hd">教龄</div>
										<div class="ext_bd">
											<div>{{item.teaching_length}}</div>
											<div class="unit">年</div>
										</div>
									</div>
								</div>
								<div class="triangle_white"></div>
								<div class="triangle_black"></div>
							</div>
						</div>
					</div>
				
				</div>


				<!-- <div class="book_pager">
							<el-pagination
								@current-change="currentPage"
								:current-page.sync="search.page_index"
								:page-size="search.page_size"
								:pager-count="5"
								layout="prev, pager, next"
								:total="productData.total">
							</el-pagination>
							<div class="page_count">
								共 {{productData.count}} 页
							</div>
						</div> -->

			</div>


		</div>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				search: {
					page_index: 1,
					page_size: 8,
				},
				listData: {
					total: 0,
					count: 0,
					list: [],
				},
				selected:null,
			};
		},
		created() {
			document.title = "数字教研室 - 机工教师特训营";
			this.getList();
		},
		methods: {
			mouseoverHandler(item) {
				this.selected = item;
				//console.log('mouseover：'+JSON.stringify(this.selected))
			},
			mouseoutHandler(item) {
				this.selected =  null;
				//console.log('mouseout：'+JSON.stringify(this.selected))
			},
			toDetail(item) {
				if(!item.show){
					this.$alert('该数字教研室正在准备中，敬请期待...', '提示', {
						confirmButtonText: '确定'
					});
					return;
				}
				this.GoToDetail(item.product_type,item.id);
			},
			getList() {
				var that = this;
				var url = apiUrl.digital_office_list_get;
				this.Ajax(url, this.search, (data) => {
					if (that.listData.total == 0) {
						that.listData.total = data.total;
					}
					if (that.listData.count == 0) {
						that.listData.count = data.count;
					}
					that.listData.list = data.list;
					that.listData.list.forEach((item,index)=>{
						if(item.id == '9ea73161ee334b79a2ef0575316a088b' 
						|| item.id == '56bd65acb72f4e48963307e70d6bbba1'
						|| item.id == 'f15b3eb3b76840c8bbb8d8672347eaaf'
						|| item.id == 'ab52f9cd88084cbb81164a882e2d0e6e'){
							item.show = true;
						}else{
							item.show = false;
						}
					})
					//console.log('listData' + JSON.stringify(that.listData))
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.getProductList();
			},
			

		},
	};
</script>

<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;

		.body-main {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #fff;
			//padding: 10px 0;
			box-sizing: border-box;

			.book_pager {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 40px;

				.page_count {
					display: inline-flex;
					height: 40px;
					justify-content: center;
					align-items: center;
					font-size: 18px;
					color: #00786c;
				}

				.el-pagination {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.el-pagination .el-icon {
					font-size: 22px !important;
					cursor: pointer;
				}

				.el-pager li {
					font-size: 16px !important;
					display: inline-flex;
					width: 40px !important;
					min-width: 40px !important;
					height: 40px !important;
					align-items: center;
					justify-content: center;
					background-color: #fff;
					border-radius: 50%;
					font-weight: 400;
					color: #959595;
					margin: 5px;
					cursor: pointer;
				}

				.el-pager li.active {
					background-color: #F3641E !important;
					color: #fff !important;
				}
			}

			.mainc {
				display: flex;
				width: 1025px;
				line-height: 1;

				.room_list {
					width: 100%;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					margin-bottom: 40px;

					.room_item {
						width: 315px;
						height: 420px;
						display: flex;
						flex-direction: column;
						margin-top: 40px;
						margin-right: 40px;
						background-color: #ECECEC;
						position: relative;
						cursor: pointer;

						&:nth-of-type(3n+3) {
							margin-right: 0;
						}

						.hover_box {
							position: absolute;
							width: 100%;
							height: 100%;
							background: linear-gradient(to right, #fece8e, #f49845);
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;

							.book_cover {
								width: 120px;
								height: 170px;
								box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.3);
							}

							.book_title {
								font-size: 24px;
								font-weight: 600;
								color: #333;
								margin-top: 30px;
							}

							.room_title {
								font-size: 22px;
								color: #333;
								margin-top: 8px;
							}

							.btn_more {
								margin-top: 30px;
								padding: 10px 30px;
								border-radius: 32px;
								background-color: #fff;
								font-size: 20px;
								font-weight: 600;
								color: #333;
							}

						}
						
						.default_box{
							.mes_box {
								flex: 1;
								width: 100%;
								padding: 10px 20px;
								box-sizing: border-box;
								display: flex;
								flex-direction: column;
								
								.triangle_white {
									position: absolute;
									width: 0;
									right: 0;
									bottom: 0;
									border-top: 23px solid transparent;
									border-right: 23px solid #fff;
									border-bottom: 23px solid #fff;
									border-left: 23px solid transparent;
								}
							
								.triangle_black {
									position: absolute;
									width: 0;
									right: 0;
									bottom: 0;
									border-top: 18px solid transparent;
									border-right: 18px solid #f49845;
									border-bottom: 18px solid #f49845;
									border-left: 18px solid transparent;
								}
							
								.name {
									font-size: 28px;
									font-weight: 600;
									color: #333;
								}
							
								.title {
									font-size: 12px;
									color: #999;
									margin-top: 10px;
							
									.title_2 {
										margin-top: 5px;
									}
								}
							
								.exts {
									display: flex;
									flex-direction: row;
									margin-top: 15px;
							
									.ext {
										display: flex;
										flex-direction: column;
										font-weight: 600;
							
										&.ext2 {
											padding: 0 20px;
											margin: 0 20px;
											border-left: 1px solid #999;
											border-right: 1px solid #999;
										}
							
										.ext_hd {
											font-size: 13px;
											color: #333;
										}
							
										.ext_bd {
											font-size: 20px;
											color: #f49845;
											margin-top: 8px;
											display: flex;
											align-items: baseline;
							
											.unit {
												font-size: 12px;
											}
										}
									}
								}
							
							}
							
						}
					}
				}
			}


			.mianb {
				width: 100%;
				height: 75px;
				margin: 0 auto;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				font-size: 18px;
				font-family: PingFang-SC-Medium, PingFang-SC;
				font-weight: 500;
				color: #363636;


				.el-breadcrumb {
					font-size: 16px;
				}

				.el-breadcrumb__inner a,
				.el-breadcrumb__inner.is-link,
				.el-breadcrumb__separator {
					color: #00786c !important;
				}

				.el-breadcrumb__inner.is-link:hover {
					border-bottom: 1px solid #00786c;
				}

				.el-breadcrumb__item:last-child .el-breadcrumb__inner {
					color: #00786c !important;
				}
			}

		}
	}
</style>