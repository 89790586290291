<template>
	<div class="">
		<div style=" width: 100%; display: inline-flex;flex-direction: column; margin-bottom: 20px;">
			<div class="" style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px; ">
				章节选择
			</div>
		</div>
		<div class="reference-content">
			<el-collapse v-model="activeName" accordion>
				<el-collapse-item :name="item.title" v-for="(item,index) in chapterList" v-if="item.resourse_count>0">
					<template slot="title">
						<div class="title-body">
							<div class="tit_text">{{item.title}}</div>
							<div class="tit_msg">共 {{item.resourse_count}} 条参考文献</div>
						</div>
						<i :class="{'el-icon-caret-bottom':selected.id != item.id,'el-icon-caret-top':selected.id == item.id}"></i>
					</template>
					<div class="reference-item" v-for="(tem,dex) in listData.list">
						<div class="num">{{dex+1}}.</div>
						<div class="mes">
							<div class="tit">{{tem.title}}</div>
							<div class="link_box"><a class="url_link" target="_blank" :href="tem.url">{{tem.url}}</a></div>
						</div>
					</div>
				</el-collapse-item>
			</el-collapse>
		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		props: {
			myid: String
		},
		data() {
			return {
				id: this.myid,
				search: {
					chapter_id: '',
					page_index: 1,
					page_size: 3000,
				},
				listData: {
					current: {},
					list: [],
				},
				chapterList: [],
				activeName: null,
				selected: null,
				
			};
		},
		created() {
			//document.title = "参考文献 - 数字教研室 - 机工教师特训营";
			this.getChapters();
		},
		watch: {
			activeName(newval) {
				var item = this.chapterList.find(tem => tem.title == newval);
				this.changeChapter(item);
			}
		},
		methods: {
			changeChapter(item) {
				this.selected = item;
				this.search.chapter_id = item.id;
				this.getList();
			},
			getChapters() {
				var that = this;
				var url = apiUrl.digital_office_chapter_list_get;
				this.Ajax(url, { id: this.id ,menu_number:10 }, (data) => {
					that.chapterList = data;
					that.changeChapter(data[0]);
				});
			},
			getList() {
				var that = this;
				var url = apiUrl.digital_office_reference_list_get;
				this.Ajax(url, this.search, (data) => {
					that.listData.list = data;
				});
			},
		},
	};
</script>

<style lang="scss" >
	.reference-content {
		
		.el-collapse-item {
	
			.reference-item {
				display: flex;
				flex-direction: row;
				line-height: 1;
				width: 100%;
				padding-top: 15px;
				box-sizing: border-box;
				cursor: pointer;
	
	
	
				.num {
					display: inline-flex;
					margin-right: 5px;
				}
	
				.mes {
					flex: 1;
					display: flex;
					flex-direction: column;
					border-bottom: 1px solid #ddd;
					padding-bottom: 10px;
	
	
					.tit {
						font-size: 16px;
						line-height: 1.2;
					}
	
					.link_box {
						margin-top: 5px;
	
						.url_link {
							display: inline-block;
							font-size: 13px;
							color: #333;
							border-bottom: 1px solid #333;
							line-height: 1;
						}
					}
	
				}
	
				&:nth-last-of-type(1) {
					.mes {
						border-bottom: none;
					}
				}
			}
	
			.el-collapse-item__header,
			.el-collapse-item__wrap {
				background-color: #eee;
				border-bottom: 1px solid #eee;
				padding: 0 15px;
			}
	
			.el-collapse-item__header {
				display: inline-flex;
				width: 100%;
				justify-content: space-between;
				line-height: 1;
				height: 60px;
	
	
				.title-body {
					display: flex;
					flex-direction: column;
					line-height: 1;
	
					.tit_text {
						font-size: 18px;
						color: #333;
						font-weight: 600;
					}
	
					.tit_msg {
						font-size: 12px;
						color: #5D5D5D;
						margin-top: 5px;
					}
				}
	
				.el-icon-caret-bottom,
				.el-icon-caret-top {
					color: #333;
					font-size: 19px;
				}
			}
	
	
			.el-collapse-item__arrow {
				display: none;
			}
	
			&:hover {
				.el-collapse-item__header {
					background-color: #E4E4E4;
				}
			}
	
			&.is-active {
	
				.el-collapse-item__header,
				.el-collapse-item__wrap {
					background-color: #E4E4E4;
					border-bottom: 1px solid #E4E4E4;
				}
	
				.el-collapse-item__header {
	
					.title-body {
						display: flex;
						flex-direction: column;
						line-height: 1;
	
						.tit_text {
							color: #333;
	
						}
	
						.tit_msg {
							display: none;
						}
					}
	
					.el-icon-caret-bottom,
					.el-icon-caret-top {
						color: #333;
					}
				}
			}
		}
	}
</style>