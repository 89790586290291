<template>
	<div style="width: 100%; display: flex;flex-direction: column; background-color: #fff;">
		<div style="display: flex; align-items: flex-end;">
			<div style="font-size: 17px; margin-right: 15px;">课程</div>
			<div style="font-size: 12px; color: #FBA559; margin-bottom: 3px;">已购</div>
		</div>
		
		<div class="course_list" style="margin-top: 20px;" v-if="productData.list && productData.list.length>0">
			<div class="item" style="display: flex; flex-direction: column;border-radius: 0 0 20px 20px;" v-for="(item,index) in productData.list" @click="toDetail(item)">
				<img style="width: 100%;" src="../../assets/images/cover.png" v-if="item.img_url_tt==''"/>
				<img style="width: 100%;" :src="item.img_url_tt"  v-if="item.img_url_tt!=''"/>
				<div class="" style="flex: 1; background-color: #F1F1F1; display: flex;flex-direction: column;">
					<div style="flex: 1; display: flex;flex-direction: column; padding: 15px 10px 10px 15px;">
						<div style="font-size: 20px; font-weight: 600; line-height: 1.2; ">{{item.title}}</div>
						<div style="font-size: 18px;line-height: 1; margin-top: 8px;">主讲人：{{item.author}}</div>
					</div>
					<div style="height: 40px;  border-radius: 0 0 20px 20px; border-top: 1px solid #E8E8E8; display: flex; justify-content: space-between;  ; align-items: center; padding:0 17px 0 20px;">
						<div style="display: flex;font-size: 13px; align-items: center;">
							 <div style="background-color: #c93a2e;color: #fff; padding:2px 8px; border-radius:20px;">{{item.res_count}}小节</div>
							<div style=" margin-left: 8px;"><i class="el-icon-pie-chart" style="margin-right: 2px;"></i>{{item.res_length}}</div>
						</div>
						
						<div style="font-weight: 600; font-size: 17px; color: #c93a2e;" v-if="item.price!='0.00'">¥{{item.price}}</div>
						<div style="font-weight: 600; font-size: 17px; color: #c93a2e;" v-if="item.price=='0.00'">免费</div>
					</div>
				</div>
			</div>	
		</div>
		
		<div class="book_pager" v-if="productData.total != 0">
			<el-pagination @current-change="currentPage" :current-page.sync="search.page_index"
				:page-size="search.page_size" :pager-count="5" layout="prev, pager, next"
				:total="productData.total">
			</el-pagination>
			<div class="page_count">
				共 {{productData.count}} 页
			</div>
		</div>
		
		<el-empty v-if="!productData.list">
			<el-button @click="toList()">去看看有哪些课程</el-button>
		</el-empty>
		
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				search: {
					page_index: 1,
					page_size: 6,
					order_by: 'created_at:desc'
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				}
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.getProductList();
		},
		methods: {
			toList(){
				this.GoToChangeTab(4);
			},
			toDetail(item){
				this.GoToDetail(item.product_type,item.id);
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_purchase_course_list_get;
				this.Ajax(url, this.search, (data,code,msg) => {
					
					if (that.productData.total == 0) {
						that.productData.total = data.total;
					}
					if (that.productData.count == 0) {
						that.productData.count = data.count;
					}
					that.productData.list = data.list;
					//console.log('productData' + JSON.stringify(that.productData))
					if(code == 40004 && that.search.page_index == 1){
						that.productData.list = null;
					}
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.getProductList();
			}

		},
	};
</script>

<style lang="scss" scoped>
	.book_pager {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 40px;
	
		.page_count {
			display: inline-flex;
			height: 40px;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			color: #959595;
		}
	
		.el-pagination {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	
		.el-pagination .el-icon {
			font-size: 22px !important;
			cursor: pointer;
		}
	
	
		.el-pager li {
			font-size: 16px !important;
			display: inline-flex;
			width: 40px !important;
			min-width: 40px !important;
			height: 40px !important;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			border-radius: 50%;
			font-weight: 400;
			color: #959595;
			margin: 5px;
			cursor: pointer;
		}
	
	
	
		.el-pager li.active {
			background-color: #F3641E !important;
			color: #fff !important;
	
		}
	
	}
	
	.course_list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	
		.item {
			width: 280px;
			height: 376px;
			//height: 325px;
			//border: 1px solid #eee;
			display: flex;
			flex-direction: column;
			//box-shadow: 5px 5px 10px #eee;
			//box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			margin-right: 20px;
			margin-bottom: 40px;
			cursor: pointer;
			overflow: hidden;
	
			&:nth-child(3n+3) {
				margin-right: 0;
			}
	
			&:hover {
				//box-shadow:0 1px 1px 1px 10px #666;
				//box-shadow: -1px 2px 5px 0 rgba(0, 0, 0, 0.1);
			}
	
			.cover {
				width: 285px;
				height: 220px;
				//height: 165px;
			}
			
	
			.mes {
				width: 100%;
				display: flex;
				flex-direction: column;
				line-height: 1;
				padding-left: 18px;
				box-sizing: border-box;
				margin: 0;
				position: relative;
	
				.title {
					margin-top: 15px;
					height: 20px;
					margin-left: -18px;
					font-size: 20px;
					color: #333;
					font-weight: 600;
					border-left: 8px solid #333;
					padding-left: 10px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
	
	
				.anchor {
					font-size: 17px;
					margin-top: 10px;
					color: #333;
					display: flex;
				}
	
				.res_info {
					display: flex;
					justify-content: flex-start;
					font-size: 12px;
					margin-top: 15px;
					position: relative;
	
					.res_count {
						background-color: #333;
						color: #fff;
						padding: 5px 8px;
						display: inline-flex;
						border-radius: 4px 0 0 4px;
					}
	
					.res_length {
						background-color: #3CD0BA;
						color: #01786D;
						padding: 5px 8px;
						display: inline-flex;
						border-radius: 0 4px 4px 0;
					}
				}
	
				.exts {
					display: flex;
					width: 60%;
					align-items: flex-end;
					margin-top: 30px;;
	
					.price {
						font-size: 21px;
						color: #333;
						margin-right: 8px;
						display: inline-flex;
					}
	
					.readers {
						font-size: 12px;
						color: #3CD0BA;
						display: inline-flex;
					}
				}
	
				.icon_play {
					width: 150px;
					height: 150px;
					margin: 0;
					position: absolute;
					z-index: 2;
					right: -24px;
					bottom: -24px;
				}
			}
		}
	}
</style>