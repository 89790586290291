<template>
	<div style="width: 100%; display: flex;flex-direction: column; background-color: #fff;">
		<div style="display: flex; align-items: flex-end;">
			<div style="font-size: 17px; margin-right: 15px;">教研室</div>
			<div style="font-size: 12px; color: #FBA559; margin-bottom: 3px;">已购</div>
		</div>
		
		<div class="room_list" v-if="productData.list && productData.list.length>0">
			<div class="room_item" v-if="productData.list" v-for="(item,index) in productData.list" @click="toDetail(item)"  @mouseover="mouseoverHandler(item)" @mouseout="mouseoutHandler()" >
				<div class="hover_box" v-if="selected && selected.id == item.id"></div>
				<div class="default_box">
					<div style="width: 100%; height: 320px; overflow-y: hidden;">
						<img :src="item.teacher_img" style="width: 100%;" />
					</div>
					<div class="mes_box" style="display: flex;flex-direction: column; justify-content: space-between;">
						<div style="display: flex;flex-direction: column;">
							<div class="name">{{item.teacher_name}}</div>
							<div class="title">
								<div class="title_1" v-if="item.teacher_title && item.teacher_title.split('；')[0]">{{item.teacher_title.split('；')[0]}}</div>
								<div class="title_2" v-if="item.teacher_title && item.teacher_title.split('；')[1]">{{item.teacher_title.split('；')[1]}}</div>
							</div>
						</div>
						
						<div class="exts">
							<div class="ext">
								<div class="ext_hd">资源</div>
								<div class="ext_bd">
									<div>{{item.resourse_count}}</div>
								</div>
							</div>
							<div class="ext ext2">
								<div class="ext_hd">成员</div>
								<div class="ext_bd">
									<div>{{item.student_count}}</div>
									<div class="unit">人</div>
								</div>
							</div>
							<div class="ext">
								<div class="ext_hd">教龄</div>
								<div class="ext_bd">
									<div>{{item.teaching_length}}</div>
									<div class="unit">年</div>
								</div>
							</div>
						</div>
						<div class="triangle_gray"></div>
						<div class="triangle_white"></div>
						<div class="triangle_black"></div>
					</div>
				</div>
			</div>
		</div>
		
		<el-empty v-if="!productData.list">
			<el-button @click="toList()">去看看有哪些数字教研室</el-button>
		</el-empty>
		
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				search: {
					page_index: 1,
					page_size: 6,
					order_by: 'created_at:desc'
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				},
				selected:null,
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.getProductList();
		},
		methods: {
			mouseoverHandler(item) {
				this.selected = item;
				//console.log('mouseover：'+JSON.stringify(this.selected))
			},
			mouseoutHandler(item) {
				this.selected =  null;
				//console.log('mouseout：'+JSON.stringify(this.selected))
			},
			toList(){
				this.GoToChangeTab(26);
			},
			toDetail(item) {
				if(!item.show){
					this.$alert('该数字教研室正在准备中，敬请期待...', '提示', {
						confirmButtonText: '确定'
					});
					return;
				}
				this.GoToDetail(item.product_type,item.id);
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_purchase_digital_office_list_get;
				this.Ajax(url, this.search, (data,code,msg) => {
					if (that.productData.total == 0) {
						that.productData.total = data.total;
					}
					if (that.productData.count == 0) {
						that.productData.count = data.count;
					}
					that.productData.list = data.list;
					that.productData.list.forEach((item,index)=>{
						if(item.id == '9ea73161ee334b79a2ef0575316a088b' || item.id == '56bd65acb72f4e48963307e70d6bbba1'){
							item.show = true;
						}else{
							item.show = false;
						}
					})
					if(code == 40004 && that.search.page_index == 1){
						that.productData.list = null;
					}
				});
			},

		},
	};
</script>

<style lang="scss" scoped>
	
	
	.room_list {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 40px;
	
		.room_item {
			width: 368px;
			height: 485px;
			display: flex;
			flex-direction: column;
			margin-top: 40px;
			margin-right: 40px;
			background-color: #fff;
			border: 1px solid #ddd;
			position: relative;
			cursor: pointer;
	
			&:nth-of-type(2n+2) {
				margin-right: 0;
			}
	
			.hover_box {
				position: absolute;
				width: 100%;
				height: 100%;
				background: #F1F1F1;
				opacity: 0.5;
				z-index: 1;
			}
			
			.default_box{
				z-index: 9;
				
				.mes_box {
					flex: 1;
					width: 100%;
					padding: 10px 20px;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;
					
					.triangle_gray {
						position: absolute;
						width: 0;
						right: 0;
						bottom: 0;
						border-top: 23px solid transparent;
						border-right: 23px solid #ddd;
						border-bottom: 23px solid #ddd;
						border-left: 23px solid transparent;
					}
					
					.triangle_white {
						position: absolute;
						width: 0;
						right: -1px;
						bottom: -1px;
						border-top: 23px solid transparent;
						border-right: 23px solid #fff;
						border-bottom: 23px solid #fff;
						border-left: 23px solid transparent;
					}
				
					.triangle_black {
						position: absolute;
						width: 0;
						right: -1px;
						bottom: -1px;
						border-top: 18px solid transparent;
						border-right: 18px solid #f49845;
						border-bottom: 18px solid #f49845;
						border-left: 18px solid transparent;
					}
				
					.name {
						font-size: 20px;
						font-weight: 600;
						color: #333;
					}
				
					.title {
						font-size: 14px;
						color: #999;
						margin-top: 10px;
						line-height: 1;
				
						.title_2 {
							margin-top: 2px;
						}
					}
				
					.exts {
						display: flex;
						flex-direction: row;
						margin-top: 10px;
						line-height: 1;
				
						.ext {
							display: flex;
							flex-direction: column;
							font-weight: 600;
				
							&.ext2 {
								padding: 0 20px;
								margin: 0 20px;
								border-left: 1px solid #ccc;
							}
				
							.ext_hd {
								font-size: 14px;
								color: #333;
							}
				
							.ext_bd {
								font-size: 20px;
								color: #f49845;
								margin-top: 8px;
								display: flex;
								align-items: baseline;
				
								.unit {
									font-size: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
</style>