<template>
	<div id="container"  style=" width: 100%; display: inline-flex;flex-direction: column; margin-bottom: 20px;">
		<!-- 标题 -->
		<div class="" style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px; ">
			主观题库
		</div>
		
		<!-- 章节选择区域 -->
		<div class="chapter_select_box">
			<div id="selectC" class="chapter_select_input" :class="{'selecting': chapter_select_items_show,'selected':selected_chapter_select && !chapter_select_items_show}" @click="changeChapterSelectItemsShow()">
				<div v-if="chapter_select_items_show || (!chapter_select_items_show && !selected_chapter_select)">选择章节</div>
				<div class="chapter_select_input_lf" v-if="selected_chapter_select && !chapter_select_items_show">
					<div class="chapter_tit">
						{{selected_chapter_select.title.split(" ")[0]}}
					</div>
					<div class="chapter_sub_tit">{{selected_chapter_select.title.split(" ")[1]}}</div>
				</div>
		
				<i class="el-icon-caret-bottom"></i>
			</div>
			<div class="chapter-select-items-box" v-if="chapter_select_items_show">
				<div class="chapter-select-items">
					<div class="chapter-select-item" v-for="(item,index) in cateList">
						<div class="chapter-select-item_lf" @click="selectChapterClick(item)">
							<div class="chapter_tit">{{item.title.split(" ")[0]}}</div>
							<div class="chapter_sub_tit">{{item.title.split(" ")[1]}}（{{item.question_count}}题）</div>
						</div>
						<div class="chapter-select-item_rt" v-if="favorite_cate_list.includes(item.id+'')" @click="favClick(item)">
							<i class="el-icon-star-on"></i> 查看收藏
						</div>
		
						<div class="chapter-select-item_rt gray" v-if="!favorite_cate_list.includes(item.id+'')">
							<i class="el-icon-star-off"></i> 查看收藏
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 题目和答案显示区域 -->
		<div v-show="question_answer_show_flag" class="" style="display: flex;flex-direction: column; margin-top: 25px;">
			<div class="" style="font-size: 18px; color: #999;font-weight: 600;">
				{{practise_num}}/{{practise_list==null?0:practise_list.length}}
			</div>
			<div style="font-size: 18px; color: #333; margin-top: 15px; text-align: justify; line-height: 1.5;">
				<div class="questionContent" v-if="practise_list" v-html="practise_list[practise_num-1].title"></div>
			</div>
			<div style="display: flex;flex-direction: row;justify-content: space-between; margin-top: 25px; padding: 15px 0; align-items: center; border-bottom:1px solid #ccc ;">
				<div v-if="practise_list && practise_list[practise_num-1].is_favorite==0" style="display:flex;flex-direction: row; font-size: 15px;cursor: pointer;" @click="addDelFavorite(1)">
					<i class="el-icon-star-off" style="margin-right: 5px;"></i>
					收藏
				</div>
				<div v-if="practise_list && practise_list[practise_num-1].is_favorite==1" style="display:flex;flex-direction: row; font-size: 15px; color: #00786c;cursor: pointer;" @click="addDelFavorite(0)">
					<i class="el-icon-star-on" style="margin-right: 5px;"></i>
					取消收藏
				</div>
				<div style=" width: 150px; display: flex;flex-direction: row; justify-content: space-between;  font-size: 12px; font-weight: 600; line-height: 1; ">
					<div style="display: inline-block; padding: 8px 16px; background-color: #3DD2BC; color: #00786c; border-radius: 32px; cursor: pointer;" @click="gotoPage(-1)">上一题</div>
					<div style="display: inline-block; padding: 8px 16px; background-color: #3DD2BC; color: #00786c; border-radius: 32px; cursor: pointer;" @click="gotoPage(1)">下一题</div>
				</div>
		
			</div>
			<div style=" margin-top: 15px;font-size: 15px; font-weight: 600; color: #00786c; cursor: pointer;" @click="clickShowAnswer()">
				查看答案 <i class="el-icon-caret-bottom" style="margin-right: 5px;"></i>
			</div>
			<div v-show="is_show_answer" style="margin-top: 15px;font-size: 18px;color: #333; line-height: 1.5;">
				<div class="questionContent" v-if="practise_list" v-html="practise_list[practise_num-1].answer"></div>
				
				
				<div style="margin-top: 20px;font-size: 15px; font-weight: 600; color: #00786c;" v-if="practise_list && practise_list[practise_num-1] && practise_list[practise_num-1].answer_key">答案解析</div>
				<div style="margin-top: 15px;font-size: 18px;color: #333; line-height: 1.5;" v-if="practise_list && practise_list[practise_num-1] && practise_list[practise_num-1].answer_key" v-html="practise_list[practise_num-1].answer_key"></div>
			</div>
		</div>
		
	
	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import fooTer from "../../components/footer";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import sizheng from "../TeachRoom/TeachRoomSiZheng.vue";
	import ppt from "../TeachRoom/TeachRoomPPT.vue";
	import live from "../TeachRoom/TeachRoomLive.vue";
	import quesob  from "../TeachRoom/TeachRoomQues.vue";
	import quessub  from "../TeachRoom/TeachRoomQues2.vue";
	

	export default {
		props:{
			myid:String,
			myauth: Number
		},
		data() {
			return {
				id: this.myid,						// 教研室ID
				has_auth: this.myauth,
				parent_category_id: 0,				// 教研室对应的习题主ID
				chapter_select_items_show: false,	// 章节选择框是否显示
				selected_chapter_select: null,		// 被选中的章节item
				cateList:null,						// 章节列表
				question_answer_show_flag:false,
				practise_num:0,
				practise_list:null,					// 所有习题列表
				is_show_answer:false,
				favorite_cate_list:[]				// 收藏习题对应的章节ID列表
			};
		},
		
		created() {
			//document.title = "主观题库 - 数字教研室 - 机工教师特训营";
			if (this.has_auth == 1) {
				this.getCateList();
				this.commonVariable.Header();
			}
		},
		watch: {
			practise_num: {
				handler(newVal, oldVal) {
					this.$nextTick(function() {
						if (!this.commonVariable.isMathjaxConfig) {
							this.commonVariable.initMathjaxConfig();
						}
						this.commonVariable.MathQueue('container');
					});
				}
			}
		},
		methods: {
			changeChapterSelectItemsShow() {
				this.chapter_select_items_show = !this.chapter_select_items_show;
			},
			selectChapterClick(item) {
				this.selected_chapter_select = item;
				this.chapter_select_items_show = false;
				this.question_answer_show_flag = true;
				this.getPractiseList(item.id);
			},
			getCateList(){
				var that = this;
				var url = apiUrl.digital_office_question_info_get;
				this.Ajax(url, { office_id: this.id}, (data) => {
					that.parent_category_id = data.practise_category_id;
					// 获取章节列表
					var url1 = apiUrl.q_category_list_get;
					this.Ajax(url1, { parent_category_id: data.practise_category_id, question_type:2}, (data_cate) => {
						that.cateList = data_cate.child_list;
					});
					
					// 获取收藏状态
					var url2 = apiUrl.q_favorite_classify_get;
					this.Ajax(url2, { parent_category_id: data.practise_category_id, question_type:2}, (data_fav) => {
						data_fav.favorite_category_list.forEach((item, index) => {
							that.favorite_cate_list.push(item.id);
						});
					});
				});
			},
			getPractiseList(cate_id){
				var that = this;
				var url = apiUrl.q_practise_get;
				this.Ajax(url, { category_id: cate_id, page_index:1, page_size:1000, question_type:2}, (data) => {
					that.practise_num=1;
					that.practise_list=data.list;
				});
			},
			gotoPage(step){
				if (step==-1 && this.practise_num == 1){
					this.practise_num = 1;
				}else if(step==1 && this.practise_num == this.practise_list.length){
					this.practise_num = this.practise_list.length;
				}else{
					this.practise_num = this.practise_num  + step;
					this.is_show_answer = false;
				}
			},
			clickShowAnswer(){
				this.is_show_answer = !this.is_show_answer;
			},
			addDelFavorite(toState){
				var that = this;
				var url = apiUrl.q_add_del_favorite;
				this.Ajax(url, { question_id:that.practise_list[that.practise_num-1].question_id, state:toState}, (data) => {
					// 更新本题目的收藏状态
					that.practise_list[that.practise_num-1].is_favorite = toState;
					
					// 重新获取章节目录的收藏状态
					var url2 = apiUrl.q_favorite_classify_get;
					that.favorite_cate_list = [];
					this.Ajax(url2, { parent_category_id: that.parent_category_id, question_type:2}, (data_fav) => {
						data_fav.favorite_category_list.forEach((item, index) => {
							that.favorite_cate_list.push(item.id);
						});
					});
				});
				
				
			},
			favClick(item){
				this.selected_chapter_select = item;
				this.chapter_select_items_show = false;
				this.question_answer_show_flag = true;
				this.getFavList(item.id);
			},
			getFavList(cate_id){
				var that = this;
				var url = apiUrl.q_favorite_detail_list;
				this.Ajax(url, {parent_category_id: that.parent_category_id, category_id: cate_id, page_index:1, page_size:1000, question_type:2}, (data) => {
					that.practise_num=1;
					that.practise_list=data.list;
				});
			}
		},
	};
</script>
<style>
	.questionContent table {
		border: 1px solid black;
		max-width: 900px !important;
	}
	.questionContent tr td {
		border: 1px solid black;
	}
	
	.questionContent img{
		max-width: 640px !important;
	}
	
	.MathJax {
		outline: 0 !important;
	}
	.MJXc-display {
		max-width: 774px !important;
		overflow-x:scroll !important;
		overflow-y:hidden !important;
	}
</style>
<style lang="scss" scoped>
	.chapter_select_box {
		display: flex;
		flex-direction: column;
		position: relative;
		margin-top: 30px;
	
		.chapter_select_input {
			width: 512px;
			height: 45px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			font-size: 20px;
			font-weight: 600;
			color: #333;
			background-color: #E4E4E4;
			padding: 0 15px;
			box-sizing: border-box;
			cursor: pointer;
	
			&.selecting {
				background-color: #999;
				color: #fff;
			}
	
			&.selected {
				color: #333;
				background-color: #E4E4E4;
	
				.chapter_select_input_lf {
					display: flex;
					flex-direction: column;
					font-size: 15px;
	
					.chapter_tit {
						color: #333;
						font-weight: 600;
					}
	
					.chapter_sub_tit {
						color: #666;
						font-weight: normal;
						margin-top: 5px;
					}
				}
			}
		}
	
		.chapter-select-items-box {
			background-color: #eee;
			width: 512px;
			height: 356px;
			position: absolute;
			left: 0;
			top: 45px;
			display: flex;
			flex-direction: column;
			box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
			box-sizing: border-box;
			cursor: pointer;
	
			.chapter-select-items {
				width: 490px;
				height: 356px;
				overflow-y: scroll;
				overflow-x: hidden;
				padding: 0 20px 0 10px;
				box-sizing: border-box;
	
				&::-webkit-scrollbar {
					width: 5px;
				}
	
				&::-webkit-scrollbar-thumb {
					height: 50px;
					background-color: #333;
					border-radius: 8px;
				}
	
				.chapter-select-item {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding: 12px 0;
					line-height: 1;
					font-size: 15px;
					border-bottom: 1px solid #ddd;
	
					.chapter-select-item_lf {
						flex: 1;
						display: flex;
						flex-direction: column;
	
						.chapter_tit {
							color: #333;
							font-weight: 600;
						}
	
						.chapter_sub_tit {
							color: #666;
							margin-top: 5px;
						}
					}
	
					.chapter-select-item_rt {
						display: flex;
						flex-direction: row;
						color: #333;
						justify-content: center;
						align-items: center;
	
						&.gray {
							color: #666;
						}
	
						.el-icon-star-on {
							margin-right: 5px;
						}
					}
				}
			}
		}
	
	}

</style>