<template>
	<div class="page-main">
		<div style="width: 1200px; display: flex; flex-direction: column;">
			<!-- 名师团 -->
			<div style="display: inline-flex; flex-direction: column; width: 1200px;  z-index: 3;">
				<div style="display: flex; margin: 40px 0 20px 0;">
					<div class="" style="margin-right: 10px;width: 5px;background: linear-gradient(to bottom,#ff9000,#c93a2e);"></div>
					<div style="display: flex;flex-direction: column; line-height: 1;">
						<div style="font-size: 28px; color: #333;">名师团</div>
						<div style="font-size: 17px; color: #999; margin-top: 10px;">改变自己，从关注自己身边的榜样开始</div>
					</div>
				</div>
			</div>

			<!-- 名师类型 -->
			<div class="type_list">
				<div class="type_lable">类型</div>
				<div class="type_items">
					<div @click="typeChangeClick(item)" class="type_item" :class="{'disabled': item.product_count == 0 , 'actived': type && item.id == type.id && item.product_count>0}" v-for="(item,index) in categoryData">
						{{item.title}}
					</div>
				</div>
			</div>



			<div style="display: flex; flex-wrap: wrap;margin-top: 120px;  justify-content: space-between;  position: relative; ">
				<div v-for="(item,index) of productData.list" @click="toDetail(item)"
					style="display: flex; flex-direction: column;width: 382px;height: 196px;  margin-bottom: 120px;  background: #fff; border-radius: 8px; padding:85px 10px 20px 30px; box-sizing: border-box; position: relative; cursor: pointer;">
						
							<div style="font-size: 28px;font-weight: 600; line-height: 1;  border-left: 5px solid #52A7FB; padding-left: 9px; color: #333;">
								{{item.name}}
							</div>
							<div style="font-size: 16px;margin-top: 10px; color: #666; padding-left: 12px; line-height: 1.4;">
								<div v-if="item.school || item.college"><span v-if="item.school">{{item.school}}</span><span v-if="item.college">/{{item.college}}</span></div>
								<div v-if="item.title">{{item.title}}</div>
								<div v-if="!item.title && item.job">{{item.job}}</div>
								<div v-if="!item.title && !item.job && item.job2">{{item.job2}}</div>
								<div v-if="!item.school && !item.college && !item.title && !item.job && item.job3">{{item.job3}}</div>
							</div>
					<img :src="item.img_url_compressed" style="width: 136px; height: 136px; background-color: #D3D7F2;  border-radius: 50%; position: absolute; left: 30px; top: -68px;" />
					<div style="position: absolute; right: 0; top: 0; font-size: 22px; font-style: italic; color: #52A7FB; background-color: #E5E5E5; padding: 10px 30px; border-radius: 0 8px 0 30px; line-height: 1;">
						{{item.category_title}}
					</div>
					
				</div>
				<div v-if="productData.list.length%3!=0" v-for="(item,index) of (3 - (productData.list.length % 3))"
					style="display: flex; flex-direction: column;width: 376px; height: 189px; margin-bottom: 40px; justify-content: space-between; background: transparent; ">
				</div>
			</div>
			


			<div class="tecaher_list_book_pager" v-if="productData.count >1">
				<el-pagination @current-change="currentPage" :current-page.sync="search.page_index"
					:page-size="search.page_size" :pager-count="5" layout="prev, pager, next"
					:total="productData.total">
				</el-pagination>
				<div class="page_count">
					共 {{productData.count}} 页
				</div>
			</div>



		</div>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				listCount: 7,
				categoryData: null,
				type:null,
				search: {
					page_index: 1,
					page_size: 12,
					order_by: 'created_at:asc'
				},
				productData: {
					total: 0,
					count: 0,
					list: [],
				},
			};
		},
		created() {
			document.title = "名师团 - 机工教师特训营";
			this.getCategoryList();
			this.getProductList();
		},
		methods: {
			toDetail(item){
				this.GoToDetail(item.product_type,item.id);
			},
			typeChangeClick(item) {
				if(item.product_count == 0){
					return;
				}
				this.type = item;
				this.searchClick();
			},
			searchClick() {
				if (this.type) {
					if(this.type.id == 0){
						this.search.type = '';	
					}else{
					  	this.search.type = this.type.id;	
					}
				}
				this.productData.total = 0;
				this.productData.count = 0;
				this.productData.list = null;
				this.currentPage(1);
			},
			getCategoryList() {
				var that = this;
				var url = apiUrl.tt_category_list_get;
				this.Ajax(url, { product_type :30}, (data) => {
					that.categoryData = data;
					that.categoryData.unshift({
						id: 0,
						title: '全部',
						type: 3,
						product_count: 1000
					})
				});
			},
			getProductList() {
				var that = this;
				var url = apiUrl.tt_teacher_list_get;
				this.Ajax(url, this.search, (data) => {
					if (that.productData.total == 0) {
						that.productData.total = data.total;
					}
					if (that.productData.count == 0) {
						that.productData.count = data.count;
					}
					that.productData.list = data.list;
					//console.log('productData' + JSON.stringify(that.productData))
				});
			},
			currentPage(index) {
				this.search.page_index = parseInt(index);
				this.getProductList();
			}
		},
	};
</script>


<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #F1F1F1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		
		.triangle {
			position: absolute;
			right: 0;
			bottom: 0;
			font-size: 20px;
			font-style: italic;
			color: #fff;
			padding: 12px 15px 8px 20px;
			border-radius: 32px 0 12px 0;
			line-height: 1;
			
			&.blue{
				background-color: #52A7FB;
			}
			
			&.green{
				background-color: #33D0B3;
			}
			
			&.orange{
				background-color: #FAA053;
			}
			
			&.red{
				background-color: #E56C80;
			}
		}
		
		/deep/.tecaher_list_book_pager {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 40px;
		
			.page_count {
				display: inline-flex;
				height: 40px;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				color: #959595;
			}
		
			.el-pagination {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		
			.el-pagination .el-icon {
				font-size: 22px !important;
				cursor: pointer;
			}
			
			.el-pagination button{
				background-color: transparent !important; 
			}

			.el-pager li {
				font-size: 16px !important;
				display: inline-flex;
				width: 40px !important;
				min-width: 40px !important;
				height: 40px !important;
				align-items: center;
				justify-content: center;
				background-color: #F1F1F1 !important;
				border-radius: 50%;
				font-weight: 400;
				color: #959595;
				margin: 5px;
				cursor: pointer;
			}
		

			.el-pager li.active {
				background-color: #F3641E !important;
				color: #fff !important;
		
			}
		
		}

		

		.type_list {
			display: inline-flex;
			width: 1200px;
			height: 52px;
			z-index: 3;
			background-color: #fff;
			border-radius: 6px;

			.type_lable {
				width: 90px;
				font-weight: 500;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
			}

			.type_items {
				flex: 1;
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: center;
				font-size: 14px;

				.type_item {

					&.disabled{
						color: rgba(0, 0, 0, 0.3) !important;
					}

					&:not(.disabled):hover,
					&.actived {
						cursor: pointer;
						color: #C73E32;
					}
				}
			}
		}
	}
</style>