<template>
	<div
		style=" width: 100%; display: flex; flex-direction: column;align-items: center; border-bottom: 1px solid #eaeaea;">
		<div
			style=" width: 100%; height: 65px; background-color: #fff; display: flex; justify-content: space-between; ">
			<div style="display: inline-flex;display: flex;">
				<div style="display: inline-flex; width: 271px; height: 65px; justify-content: center; align-items: center; cursor: pointer;"
					@click="GoToIndex(0)">
					<img src="../../assets/images/logo.png" style=" width: 175px; height: auto;" />
				</div>
				<div class="navs">
					<div class="nav" :class="{'actived':nav_index == 0}" @click="GoToIndex(0)">首页</div>
					<div class="nav" :class="{'actived':nav_index == 27}" @click="GoToIndex(27)">教师特训营</div>
					<div class="nav" :class="{'actived':nav_index == 4}" @click="GoToIndex(4)">课程</div>
					<div class="nav" :class="{'actived':nav_index == 26}" @click="GoToIndex(26)">数字教研室</div>
					<div class="nav" :class="{'actived':nav_index == 30}" @click="GoToIndex(30)">名师团</div>
				</div>
			</div>


			<div style="display: flex; height: 100%; align-items: center;padding-right: 32px; box-sizing: border-box;">
				<div style="display: flex; align-items: center;">
					<div class="inputs"
						style="width: 278px !important; background: #f7f7f7ff;display: flex; align-items: center;">
						<img src="../../assets/image/search.png" alt="" />
						<input ref="inputRef" class="ipus"
							style="color: #999999;outline: none;background: rgb(247, 247, 247);border: none;width: 170px;height: 34px;font-size: 16px;"
							confirm-type="search" placeholder-class="text" placeholder="关键字" @focus="input_focusIpt"
							v-on:keyup.enter="input_search(input_content)" v-model="input_content" />
						<div style="width: 88px;height: 34px;
					        line-height: 34px;
					        font-size: 16px;
					        background: #e6564eff;
					        color: white;
					        border-radius: 19px;
					        cursor: pointer;" @click="input_search()">搜索</div>
					</div>
				</div>

				<!-- 未登录状态 -->
				<div v-if="!isUserLogin" @click="toLogin()" class="login_box">
					<img src="../../assets/images/icon_user.png" class="login_icon" />
					<div class="login_text">登录</div>
				</div>

				<!-- 登录状态非折叠效果 -->
				<div v-if="isUserLogin" class="loginer_box">
					<img class="icon_user" src="../../assets/images/icon_user.png" />
					<div class="loginer">{{loginUser}}</div>
					<div class="btn_logout" @click="toLogout()">退出</div>
					<div class="btn_center" @click="toCenter()">个人中心</div>
					<div class="panel_date">
						<i class="iconfont icon-rili"></i>
						{{myDate.date}}
					</div>
					<div style="display: flex;"></div>
				</div>


				<!-- 登录状态折叠效果 -->
				<div v-if="isUserLogin" class="popover_container">
					<el-popover placement="bottom-end" width="160" trigger="hover" :offset="20">
						<div class="slot">
							<div class="cell_loginer">
								<img class="icon_user" src="../../assets/images/icon_user.png" />
								<div class="loginer">{{loginUser}}</div>
							</div>
							<div class="cell_items">
								<div class="item" @click="toCenter()">
									<i class="el-icon-user item_icon"></i>
									个人中心
								</div>
								<div class="item" @click="toLogout()">
									<i class="el-icon-switch-button item_icon"></i>
									退出
								</div>
							</div>
						</div>

						<div slot="reference" class="reference">
							<img class="icon_user" src="../../assets/images/icon_user.png" />
							<i class="el-icon-arrow-down"></i>
						</div>
					</el-popover>
				</div>



			</div>


		</div>
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";

	export default {
		props: ["isShowNav", "isInputing", "isShowScrollBtn"],
		data() {
			return {
				myDate: {
					date: null,
					week: null,
				},
				num: 0,
				tabList: [],
				productTypeData: {},
				busy: false,
				input_content: "",
				hotKeywords: [], //热门搜索
				isInputing2: this.isInputing,
				backTopRight: 200,
				isShowScrollBtn2: false,
				isGoIndex: false,
				// isShowNav: false,
				loginUser: '137****0188',
				isUserLogin: true,
				isRegDisplay: true,
				corpTitle: '',
				nav_index: 0,
				show_wechat: false,
			};
		},
		//   components: {
		//     Homepage,
		//   },

		created() {
			this.GetMyDate();

			var that = this;
			that.isUserLogin = localStorage.getItem("userLogInInfo") ? true : false;
			// that.isRegDisplay = that.isUserLogin && JSON.parse(localStorage.getItem("userLogInInfo")).is_reg_finished ==
			// 	0 ? true : false;
			if (that.isUserLogin) {
				var userInfo = JSON.parse(localStorage.getItem("userLogInInfo"));
				if (userInfo && userInfo.title != '') {
					that.loginUser = userInfo.mobile;
				}
			}

			// if(that.isLogin){
			// 	var userInfo = JSON.parse(localStorage.getItem("userLogInInfo"));
			// 	if (userInfo && userInfo.title != '') {
			// 	    that.corpTitle = userInfo.title;
			// 	}
			// }

			//    var toPath = localStorage.getItem("toPagePath");
			//    if (toPath && toPath != "") {
			//      this.setupCurrentIndex(toPath);
			//    }
			//    this.setupList();
			//    this.hotKeywordsGet();

			//    window.addEventListener("resize", function() {
			//      that.css_caculateBackTopRight();
			//    });
			//    this.css_caculateBackTopRight();
		},
		// watch: {
		// 	$route(to, from) {
		// 		var toPath = to.path.toLowerCase();
		// 		localStorage.setItem("toPagePath", toPath);
		// 		if (toPath && toPath != "") {
		// 			this.setupCurrentIndex(toPath);
		// 		}
		// 	},
		// },
		methods: {
			GetMyDate() {
				let mydate = new Date();
				let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
				this.myDate.date = (mydate.getMonth() + 1) + '月' + mydate.getDate() + '日'
				this.myDate.week = weeks[mydate.getDay()];
			},
			GoToIndex(index) {
				this.GoToChangeTab(index)
				this.nav_index = index;
			},
			css_caculateBackTopRight() {
				var screenWidth = document.body.offsetWidth;
				if (screenWidth < 1200) {
					this.isShowScrollBtn2 = false;
				} else {
					this.isShowScrollBtn2 = true;
				}
				var right = (screenWidth - 1157) / 2 - 30;
				this.backTopRight = right || 200;
				// console.log(screenWidth);
			},
			scrollToTop() {
				this.$emit("scrollToTop");
			},
			hotKeywordsGet() {
				var that = this;
				this.Ajax(apiUrl.search_keywords_get, {}, function(data) {
					if (data && data.length > 0) {
						that.hotKeywords = data;
					}
				});
			},
			input_search(title) {
				if (title && title != "") this.input_content = title;
				this.$refs.inputRef.blur();
				this.isInputing2 = false;
				var url = "/SearchPage";
				var query = {
					inputStr: this.input_content
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				this.input_content = "";

			},
			toLogin() {
				window.top.location.href = "/login";
			},
			toReg() {
				window.top.location.href = "/reg";
			},

			toLogout() {
				localStorage.removeItem("userLogInInfo");
				//this.isLogin = false;
				this.isUserLogin = false;
				this.$router.go(0);
			},
			toCenter() {
				window.top.location.href = "/center/center";
			},
			toTeacherReg() {
				window.top.location.href = "/teacherReg";
			},
			input_focusIpt() {
				setTimeout(() => {
					this.isInputing2 = true;
					this.$refs.inputRef.focus();
					this.$forceUpdate();
				}, 200);
			},
			input_blurIpt() {
				this.isInputing2 = false;
				this.$refs.inputRef.blur();
				// setTimeout(() => {
				// this.isInputing2 = true;
				//   this.$forceUpdate();
				// }, 500);
			},
			handleScroll(e, item) {
				let scrollY = e.target.scrollTop;
				console.log(e.target.scrollTop);
				var height = 90;
				if (scrollY >= height) {
					this.isShowNav = true;
				} else {
					this.isShowNav = false;
				}
				this.$forceUpdate();
			},
			setupCurrentIndex(toPath) {
				toPath = toPath.toLowerCase();
				toPath = toPath.replace("/", "");
				if (toPath == "ebook" || toPath == "ebookdtl") {
					this.num = 1;
				} else if (toPath == "aubook" || toPath == "audtl") {
					this.num = 2;
				} else if (toPath == "course" || toPath == "couraudio") {
					this.num = 3;
				} else if (toPath == "livebook" || toPath == "livedtl") {
					this.num = 4;
				} else if (
					toPath == "teachers" ||
					toPath == "guestcollection" ||
					toPath == "guestdetail" ||
					toPath == "guestlist" ||
					toPath == "invite" ||
					toPath == "inviteresult" ||
					toPath == "teachersearchpage"
				) {
					this.num = 5;
				} else if (toPath == "subject" || toPath == "subjectdetail") {
					this.num = 6;
				}
			},
			loadMore() {},
			setupList() {
				var that = this;
				this.tabList = [];
				var url = apiUrl.shuyuan_navbar_get;
				var param = {
					client_type: 2
				};
				this.Ajax(url, param, (data) => {
					data.forEach((item) => {
						if (item.product_type == 11) {
							item.title = "直播";
						} else if (item.product_type == 13) {
							item.title = "师资";
						}
						if (item.product_type != 11) {
							that.tabList.push(item);
						}
					});
					that.tabList.push({
						title: '院校服务',
						img_url: '',
						product_type: 15,
						product_count: 0
					})
					that.productTypeData = data;
				});
			},
			handellet() {
				if (this.$refs.hoves.style.display == "none") {
					this.$refs.hoves.style.display = "block";
				} else if ((this.$refs.hoves.style.display = "block")) {
					this.$refs.hoves.style.display = "none";
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	@media screen and (max-width:1500px) {
		.loginer_box{
			display: none !important;
		}
		.popover_container{
			display: block !important;
		}
	}

	.login_box {
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
		margin-left: 20px;

		.login_icon {
			width: 25px;
			height: auto;
			margin-right: 8px;
		}

		.login_text {
			font-size: 16px;
			color: #c83a2e;
			cursor: pointer;
		}
	}

	.loginer_box {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-weight: 500;
		margin-left: 20px;

		.icon_user {
			width: 24px;
			height: auto;
			margin-right: 8px;
		}

		.loginer {
			font-size: 16px;
			color: #c83a2e;
		}

		.btn_logout {
			margin-left: 8px;
			cursor: pointer;
			font-size: 16px;
			color: #666666;
			border-bottom: 1px solid #666;
		}

		.btn_center {
			background: linear-gradient(to right, #c93a2e, #ff9000);
			color: #fff;
			font-size: 14px;
			padding: 2px 10px;
			margin: 0 20px 0 15px;
			border-radius: 12px;
			cursor: pointer;
		}

		.panel_date {
			line-height: 1;
			padding: 3px 18px 3px 15px;
			background-color: #E5E5E5;
			border-radius: 30px;
			font-size: 14px;
			display: inline-flex;
			align-items: center;

			.icon-rili {
				margin-right: 5px;
				font-size: 22px !important;
			}
		}
	}

	.popover_container {
		display: none;
		margin-left: 20px;

		.reference {
			display: flex;
			flex-direction: row;
			align-items: center;

			.icon_user {
				width: 24px;
				height: auto;
				margin-right: 8px;
			}
		}
	}

	.el-popover {
		.slot {
			display: flex;
			flex-direction: column;
			width: 100%;

			.cell_loginer {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 48px;
				border-bottom: 1px solid rgb(235, 235, 235);

				.icon_user {
					width: 24px;
					height: auto;
					margin-right: 8px;
				}

				.loginer {
					font-size: 16px;
					color: #c83a2e;
				}
			}

			.cell_items {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 10px 0;

				.item {
					color: #333;
					font-size: 15px;
					height: 40px;
					width: 100%;
					display: flex;
					align-items: center;
					padding-left: 10px;
					box-sizing: border-box;
					cursor: pointer;

					.item_icon {
						margin-right: 8px;
						color: #999999;
					}

					&:hover {
						color: #c83a2e;

						.item_icon {
							color: #c83a2e;
						}
					}
				}
			}
		}
	}

	* {
		margin: 0;
		padding: 0;
	}

	.navs {
		display: inline-flex;
		flex-direction: row;
		flex-wrap: nowrap;
		box-sizing: border-box;
	}

	.navs>.nav {
		height: 65px;
		background-color: #fff;
		display: inline-flex;
		align-items: center;
		color: #333;
		font-size: 16px;
		padding: 0 20px;
		cursor: pointer;
		box-sizing: border-box;

		&.actived {
			color: #c83a2e !important;
		}
	}


	.nav:hover {
		background: linear-gradient(to bottom, transparent, #fff, #FEDADE) !important;
	}

	.inputs {
		width: 140px;
		height: 34px;
		background: #f7f7f7;
		border-radius: 25px;
		margin-left: 30px;
		text-align: center;
		font-size: 20px;
		line-height: 50px;
		display: flex;
		align-items: center;

		img {
			width: 17px;
			height: 17px;
			margin-left: 12px;
			margin-right: 12px;
		}

		span {
			font-size: 16px;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #c9c9c9;
		}
	}
</style>