<template>
	<div class="page-main">
		<div style="width: 100%;display: flex; flex-direction: column; align-items: center;background: #fff;">
			<div style="display: flex; flex-direction: column;max-width: 1200px;padding: 30px 15px; box-sizing: border-box;">
				<img src="../../assets/images/banner_edu_01.jpg" style="width: 100%; height: auto;" alt="" />
				<div class="category_items">
					<div class="item" :class="{'actived':selected_category && selected_category.num == item.num}" @click="selectCategory(item)" v-for="(item,index) in category_list">
						<div class="mask" v-if="selected_category && selected_category.num ==  item.num"></div>
						<img :src='"../../assets/images/pic_category_0"+(index+1)+"_w768.png"' alt="" />

						<div v-if="!selected_category" style="position: absolute; bottom: 30px; left: 30px; display: inline-flex; flex-direction: column; font-weight: 600; color: #fff;">
							<div style="display: block; width: 190px;">
								<div style="font-size: 40px;">{{item.name}}</div>
								<div style="font-size: 16px;text-transform:uppercase">{{item.en_name}}</div>
							</div>
						</div>

						<div v-if="selected_category && selected_category.num !=item.num" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; font-weight: 600; color: #fff; justify-content: center; align-items: center;">
							<div style="display: block; text-align: center; width: 190px; ">
								<div style="font-size: 40px;">{{item.name}}</div>
								<div style="font-size: 16px;text-transform:uppercase">{{item.en_name}}</div>
							</div>
						</div>

						<div v-if="selected_category && selected_category.num == item.num" style="display: flex;flex-direction: column; color: #fff; position: absolute;left: 0; top: 0; padding: 40px; width: 100%; height: 100%; box-sizing: border-box;">
							<div>
								<div style="font-size: 28px;text-transform:uppercase">{{item.en_name}}</div>
								<div style="font-size: 40px;">{{item.name}}</div>
							</div>
							<div class="lines">
								<div class="line_white"></div>
								<div class="line_white"></div>
							</div>
							<div class="category_level2_list">
								<div class="category_level2_item" :class="{'actived_level2':selected_category_level2 && selected_category_level2.num == tem.num}" v-for="(tem,dex) in item.list" v-if="item.list" @click.stop="selectCategory(tem)">
									<div class="category_level2_item_text">{{tem.name}}</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div style="display: flex;flex-direction: column; align-items: center; min-height: 50vh;" v-if="category_level3_list">
					<div style="display: flex;flex-direction: row;width: 1050px; flex-wrap: wrap;  justify-content: flex-start;">
						<div class="x_item" v-for="(item,index) in category_level3_list" @click="goProductList(item)">
							<div class="title">{{item.name}}</div>
							<div class="count">
								<div class="text">{{item.book_count}}</div>本
							</div>
							<div class="triangle_white"></div>
							<div class="triangle_black"></div>
						</div>
					</div>
				</div>
	


			</div>
		</div>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	export default {
		data() {
			return {
				selected_category: null,
				selected_category_level2: null,
				category_search: {
					parent_num: 1,
					page_index: 1,
					page_size: 100
				},
				category_list: null,
				category_level3_list: null,
			};
		},
		created() {
			document.title = "机工教师特训营";
			this.getCategoryList();
		},
		methods: {
			goProductList(item){
				var url = "/teachRes/list";
				var query = { num: item.num };
				let routeUrl = this.$router.resolve({
				  path: url,
				  query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			selectCategory(item) {
				if (!this.category_search.parent_num || this.category_search.parent_num != item.num) {
					if (item.level == 1) {
						this.selected_category = item;
						this.category_level3_list = null;
					} else if (item.level == 2) {
						this.selected_category_level2 = item;
					}
					this.category_search.parent_num = item.num;
					this.getCategoryList();
					
				}
			},
			getCategoryList() {
				var that = this;
				var url = apiUrl.category_list_get;
				this.Ajax(url, this.category_search, (data) => {
					var level = data.list[0].level;
					if (level == 1) {
						that.category_list = data.list;
						console.log(JSON.stringify(that.category_list))
					} else if (level == 2) {
						var arr = new Array();
						that.category_list.forEach((item, index) => {
							if (item.num == this.category_search.parent_num) {
								arr.push({
									num: item.num,
									name: item.name,
									en_name: item.en_name,
									level: item.level,
									book_count: item.book_count,
									list: data.list
								})
							} else {
								arr.push({
									num: item.num,
									name: item.name,
									en_name: item.en_name,
									level: item.level,
									book_count: item.book_count,
									list: null
								})
							}
						});
						that.category_list = arr;
						console.log(JSON.stringify(that.category_list))
					} else if (level == 3) {
						that.category_level3_list = data.list;
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;

		.lines {
			display: flex;
			margin: 20px 0;

			.line_white {
				flex: 1;
				height: 2px;
				background-color: #fff;
			}

			.line_white+.line_white {
				margin-left: 30px;
			}
		}

		.category_level2_list {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			writing-mode: vertical-lr;
			height: 240px;

			.category_level2_item {
				font-size: 24px;
				height: 40px;
				line-height: 40px;
				writing-mode: horizontal-tb;
				width: 50%;
				box-sizing: border-box;
				cursor: pointer;

				.category_level2_item_text {
					display: inline-block;
				}

				//竖排第6项及以后
				&:nth-child(6)~div {
					margin-left: 10px;
				}

				&.actived_level2,
				&:hover {
					font-weight: 600;
					color: #2BDABB;

					.category_level2_item_text {
						border-bottom: 1px solid #2BDABB;
					}
				}
			}
		}
		

		.x_item {
			position: relative;
			width: 150px;
			height: 150px;
			background-color: #E9E9E9;
			padding: 15px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			margin:10px;
			justify-content: space-between;
			cursor: pointer;

			.title {
				font-size: 20px;
				font-weight: 600;
			}

			.count {
				font-size: 15px;
				color: #333;
				font-weight: 600;
				display: flex;
				flex-direction: row;
				align-items: flex-end;

				.text {
					font-size: 36px;
					color: #00746B;
					margin-bottom: -10px;
					margin-right: 5px;
				}
			}
			
			.triangle_white {
				position: absolute;
				width: 0;
				right: 0;
				border-top: 25px solid transparent;
				border-right: 25px solid #fff;
				border-bottom: 25px solid #fff;
				border-left: 25px solid transparent;
				
				right: 0;
				bottom: 0;
			}
			
			.triangle_black {
				display: none;
				position: absolute;
				width: 0;
				right: 0;
				border-top: 18px solid transparent;
				border-right: 18px solid #333;
				border-bottom: 18px solid #333;
				border-left: 18px solid transparent;
				
				right: 0;
				bottom: 0;
			}
			
			&:hover{
				background-color: #2BDABB;
				
				.triangle_black {
					display:block;
				}
			}
		}

		

		.category_items {
			width: 1050px;
			height: 485px;
			display: flex;
			margin: 20px auto;
			box-sizing: border-box;
		

			.item+.item {
				margin-left: 15px;
			}

			.item {
				flex: 1;
				overflow: hidden;
				transition: .5s;
				position: relative;
				cursor: pointer;

				.mask {
					width: 340px;
					height: 485px;
					background: rgba(0, 43, 38, 0.6);
					position: absolute;
					left: 0;
					top: 0;
					box-sizing: border-box;
					overflow: hidden;
				}


				img {
					width: 340px;
					height: 485px;
					object-fit: cover;
					object-position: -250px 0;
					transition: .5s;
					box-sizing: border-box;
				}

				&:nth-child(1) {
					img {
						object-position: -90px 0 !important;
					}
				}



				&.actived {
					flex-basis: 32%;

					.mask {
						width: 100%;
					}

					img {
						width: 100%;
						height: 100%;
						object-position: 0 0;
					}
				}
			}


		}
	}
</style>