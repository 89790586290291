<template>
	<div style="display: flex;flex-direction: column; ">
		
		<div class="" style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px;">
			教材电子书
		</div>
		
		<div style=" margin-top: 30px; width: 100%; display: flex; flex-wrap: wrap; flex-direction: row; ">
			<div v-for="(item,index) in list" @click="readBook(item)" style="width: 170px; height: 285px; margin:0 35px 35px 0; background-color: #ECECEC; border-radius: 0 0 50px 0; display: flex; flex-direction: column; justify-content: flex-start;align-items: center; cursor: pointer;">
				<img style="width: 170px; height: 227px; box-shadow: 3px 5px 3px rgba(0,0,0,0.3);border: 1px solid #eee;  box-sizing: border-box;" :src="item.image" />
				<div style="width: 170px; display: flex; flex-direction: column; ">
					<div style="font-size: 17px; line-height: 1.2; font-weight: 600; margin-top: 15px;  overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-align: center;">{{item.title}}</div>
				</div>
			</div>
		</div>
		

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";

	export default {
		props: {
			myid: String
		},
		data() {
			return {
				id: this.myid,
				list: null,
			};
		},
		created() {
			//document.title = "电子书 - 数字教研室 - 机工教师特训营";
			this.getBookList();
		},
		methods: {
			readBook(item) {
				var url = "/teachRes/reader";
				var query = { uuid: item.uuid, chapterid: item.chapterid };
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
			},
			getBookList() {
				var that = this;
				var param = { office_id: this.id };
				this.Ajax(apiUrl.digital_office_book_list_get, param, function(data, code) {
					if (code == 0 && data) {
						that.list = data;
						console.log(JSON.stringify(that.list))
					}
				});
			},
			
		},
	};
</script>

<style lang="scss" scoped>
	
	
</style>