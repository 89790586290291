<template>
	<div style=" width: 100%; display: inline-flex;flex-direction: column; margin-bottom: 20px;">

		<div  v-if="!paper_selected"  style="display: flex; flex-direction: column;">
			
			<!-- 标题 -->
			<div class="" style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px; ">
				策略组卷
			</div>
			
			
			<!-- 组卷次数 -->
			<div style="display: flex;flex-direction: column;">
				<div style="font-size: 22px; color: #333; margin: 30px 0 15px 0; display: flex;">
					<div style="color: #333; margin-right: 5px;">●</div>
					<div>组卷人</div>
				</div>
				<div style="display: flex; align-items: center;margin-bottom: 45px;">
					<div style="display: flex; align-items: center; margin-right: 20px;">
						<img src="../../assets/images/icon_user.png" style=" width: 30px; height: auto; margin-right: 10px; " />
						<div style="font-size: 20px; color: #333;">{{mobile}}</div>
					</div>
					<div style="display: flex;align-items: center;font-size: 16px;">
						<div>剩余次数：</div>
						<div style="display: inline-flex; justify-content: center; align-items: center; font-weight: 400; width: 20px; height: 20px;  color:#333; background-color: #e4e4e4;">
							{{exam_residue_count}}
						</div>
					</div>
				</div>
			</div>
			
			
			<!-- 试卷列表 -->
			<div style="display: flex; flex-wrap: wrap; ">
				<div v-if="exam_residue_count>0" style="display: flex;flex-direction: column;margin-right: 35px;width: 220px; cursor: pointer; " @click="createNewExam()">
					<div style="width: 100%; overflow-x: hidden;margin-bottom: -20px; z-index: 1;border-radius: 10px 0 0 0">
						<div style="margin-left:-10px;  width: 120px; height: 50px;background-color: #E4E4E4;border-radius: 10px 10px 0 0;transform: skew(45deg);"></div>
					</div>
			
					<div style=" z-index: 2; color: #333; display: flex;flex-direction: column; justify-content: center; align-items: center; width: 220px; height: 222px; background-color: #E4E4E4; padding: 20px;box-sizing: border-box; border-radius: 0 10px 10px 10px;">
						<i class="el-icon-plus" style="font-size: 30px; margin-top: -25px;"></i>
						<div style="font-size: 20px; margin-top: 10px;">创建新试卷</div>
					</div>
				</div>
			
				<div v-for="(item,index) in exam_list" style="display: flex;flex-direction: column;margin-right: 35px;width: 220px;cursor: pointer; margin-bottom: 35px; " @click="downloadExamPdf(item.download_url)">
					<div style="width: 100%; overflow-x: hidden;margin-bottom: -20px; z-index: 1; border-radius: 10px 0 0 0;box-sizing: border-box;">
						<div style="margin-left:-10px;  width: 120px; height: 50px;  background-color: #e4e4e4; color: #333; border-radius: 10px 10px 0 0;transform: skew(45deg);">
							<div style="transform: skew(-45deg); display: flex; justify-content: center;align-items: center; padding-top: 8px; font-size: 18px;">试卷{{index+1}}</div>
						</div>
					</div>
			
					<div style="z-index: 2; display: flex;flex-direction: column; justify-content: space-between; width: 220px; height: 222px; background-color: #fff; padding:20px;box-shadow: 6px 6px 6px  rgba(0, 0, 0,0.2); box-sizing: border-box;border-radius: 10px;">
						<div style="display: flex;flex-direction: column;">
							<div style="font-size: 20px; color: #333; line-height: 1.2;">{{item.title}}</div>
							<div style="font-size: 18px; color: #999; margin-top:10px ;">共{{item.question_count}}题</div>
						</div>
			
						<div style="font-size: 15px; color: #999;">{{item.created_at}}</div>
					</div>
				</div>
			</div>

		</div>
		

		
		<div v-show="paper_selected" style="display: flex; flex-direction: column;">
			
			<!-- 切换卡 -->
			
			<div style="display: flex;justify-content: space-between; align-items: center;">
				
				<div class="tabs">
					<div class="tab" :class="{'actived':tab_index == 1}" @click="tabClick(1)">按题型分布随机组卷</div>
					<div class="spliter"></div>
					<div class="tab" :class="{'actived':tab_index == 2}" @click="tabClick(2)">按章节内容随机组卷</div>
				</div>
				
				<div style=" display: flex; width: 100px; align-items: center; cursor: pointer;  z-index: 2;" @click="clearSelected()">
					<i class="iconfont icon-back" style="margin-right: 5px; margin-bottom: -3px;font-size: 32px;"></i>
					<div style="font-size: 22px;">返回</div>
				</div>
				
			</div>
			
			
			
			
			
			
			<!-- 策略一内容 -->
			<div style="display: flex;flex-direction: column;"  v-if="tab_index == 1">
				
				
				<!-- 组卷次数 -->
				<div style="display: flex;flex-direction: column;">
					<div style="font-size: 22px; color: #333; margin: 30px 0 15px 0; display: flex;">
						<div style="color: #333; margin-right: 5px;">●</div>
						<div>组卷人</div>
					</div>
					<div style="display: flex; align-items: center;margin-bottom: 45px;">
						<div style="display: flex; align-items: center; margin-right: 20px;">
							<img src="../../assets/images/icon_user.png" style=" width: 30px; height: auto; margin-right: 10px; " />
							<div style="font-size: 20px; color: #333;">{{mobile}}</div>
						</div>
						<div style="display: flex;align-items: center;font-size: 16px;">
							<div>剩余次数：</div>
							<div style="display: inline-flex; justify-content: center; align-items: center; font-weight: 400; width: 20px; height: 20px; color:#333; background-color: #d7d7d7;">
								{{exam_residue_count}}
							</div>
						</div>
					</div>
				</div>
				
				<!-- 组卷界面 -->
				<div style="display: flex;flex-direction: column; position: relative;">
					<div style="width: 100%; overflow-x: hidden;margin-bottom: -20px; z-index: 1;border-radius: 10px 0 0 0">
						<div style="margin-left:-10px;  width: 200px; height: 50px;background-color: #E4E4E4;border-radius: 10px 10px 0 0;transform: skew(45deg);">
							<div style="transform: skew(-45deg); display: flex; justify-content: center;align-items: center; padding-top: 10px; color: #333;">
								<div style="font-size: 18px;">试卷</div>
								<div style="font-size: 12px;">（创建中......）</div>
							</div>
						</div>
					</div>
				
					<div style=" z-index: 2; display: flex;flex-direction: column; width: 100%; min-height: 100vh; background-color: #E4E4E4; padding:35px 30px;box-sizing: border-box; border-radius: 0 10px 10px 10px;">
						
				
						<div class="" style="display: flex;flex-direction: column; padding: 25px 0; border-bottom: 1px dashed #ccc;">
							<div style="display: flex;flex-direction: column;font-size: 18px;">
								<div style="display: flex;">
									<div>创建日期：</div>
									<div style="color: #333;">{{myDate}}</div>
								</div>
								<div style="display: flex; align-items: center; margin-top: 15px;">
									<div>试卷名称：</div>
									<div style="flex: 1;">
										<el-input type="text" v-model="form.title" placeholder="请输入试卷名称" style="width: 100%;"></el-input>
									</div>
								</div>
							</div>
						</div>
				
						<div style="display: flex;flex-direction: column; padding: 20px 0;">
							<div class="" style=" color: #333;font-size: 18px; font-weight: 600;">
								题型说明：
							</div>
				
							<div style="display: flex; flex-direction: column; margin-top: 25px;">
								<div style="display: flex; padding: 17px 0; border-bottom: 1px dashed #ccc;">
									<div style="width: 10px; height: 10px;background-color: #333; margin-top: 2px;"></div>
									<div style="flex: 1;display: flex; flex-direction: column;font-size: 17px; margin-left: 12px;">
										<div style="display: flex;">
											<div>题型1 —— </div>
											<div style="font-weight: 600; margin-left: 5px;">判断题</div>
											<div style="font-size: 13px; margin-left: 10px; padding:4px 8px;background-color:#d7d7d7; border-radius: 4px;">共10分</div>
										</div>
										<div style="font-size: 15px;color: #999;margin-top: 12px;">
											说明：每题<span style="font-weight: 600;color: #333;">1分</span>，章节随机，每一章随机抽选一道题。
										</div>
									</div>
								</div>
								
								<div style="display: flex; padding: 17px 0; border-bottom: 1px dashed #ccc;">
									<div style="width: 10px; height: 10px;background-color: #333; margin-top: 2px;"></div>
									<div style="flex: 1;display: flex; flex-direction: column;font-size: 17px; margin-left: 12px;">
										<div style="display: flex;">
											<div>题型2 —— </div>
											<div style="font-weight: 600; margin-left: 5px;">选择题</div>
											<div style="font-size: 13px; margin-left: 10px; padding:4px 8px;background-color:#d7d7d7; border-radius: 4px;">共20分</div>
										</div>
										<div style="font-size: 15px;color: #999;margin-top: 12px;">
											说明：每题<span style="font-weight: 600;color: #333;">2分</span>，章节随机，每一章随机抽选一道题。
										</div>
									</div>
								</div>
								
								
								<div style="display: flex; padding: 17px 0; border-bottom: 1px dashed #ccc;">
									<div style="width: 10px; height: 10px;background-color: #333; margin-top: 2px;"></div>
									<div style="flex: 1;display: flex; flex-direction: column;font-size: 17px; margin-left: 12px;">
										<div style="display: flex;">
											<div>题型3 —— </div>
											<div style="font-weight: 600; margin-left: 5px;">计算题</div>
											<div style="font-size: 13px; margin-left: 10px; padding:4px 8px;background-color:#d7d7d7; border-radius: 4px;">共40分</div>
										</div>
										<div style="display: flex;margin-top: 12px;" >
											<div :class="{'border':question_count ==5}" style="display: flex;flex-direction: column; padding: 15px;font-size: 16px;background-color: #fff;color: #333; align-items: center;cursor: pointer;  position: relative;" @click="setQuestionCount(5)">
												<div v-if="question_count ==5" style="position: absolute; right: 0; top: 0; background-color: #d7d7d7; width: 18px; height: 18px; display: flex; align-items: center;justify-content: center;color: #fff;">
													<i class="el-icon-check"  style="font-size: 12px; "></i>
												</div>
												<div style=" border-bottom: 1px solid #333; padding-bottom: 5px; margin-bottom: 5px;">每题<span style="font-size: 26px; font-weight: 600; padding: 0 2px;">8</span>分</div>
												<div>共计5题</div>
											</div>
											
											<div  :class="{'border':question_count ==4}" style="display: flex;flex-direction: column; padding: 15px;font-size: 16px;background-color: #fff;color: #333; align-items: center;cursor: pointer; margin-left: 30px;position: relative; " @click="setQuestionCount(4)">
												<div v-if="question_count ==4" style="position: absolute; right: 0; top: 0; background-color: #d7d7d7; width: 18px; height: 18px; display: flex; align-items: center;justify-content: center;color: #fff;">
													<i class="el-icon-check" style="font-size: 12px; "></i>
												</div>
												<div style=" border-bottom: 1px solid #333; padding-bottom: 5px; margin-bottom: 5px;">每题<span style="font-size: 26px; font-weight: 600; padding: 0 2px;">10</span>分</div>
												<div>共计4题</div>
											</div>
										</div>
										<!-- <div style="display: flex;margin-top: 12px;" v-if="question_count==4">
											<div style="display: flex;flex-direction: column; padding: 15px;font-size: 16px;background-color: #fff;color: #36A091; align-items: center;cursor: pointer; " @click="setQuestionCount(5)">
												<div style=" border-bottom: 1px solid #36A091; padding-bottom: 5px; margin-bottom: 5px;">每题<span style="font-size: 26px; font-weight: 600; padding: 0 2px;">8</span>分</div>
												<div>共计5题</div>
											</div>
											
											<div style="display: flex;flex-direction: column; padding: 15px;font-size: 16px;background-color: #fff;color: #36A091; align-items: center;cursor: pointer; border: 1px solid #36A091; margin-left: 30px; " @click="setQuestionCount(4)">
												<div style=" border-bottom: 1px solid #36A091; padding-bottom: 5px; margin-bottom: 5px;">每题<span style="font-size: 26px; font-weight: 600; padding: 0 2px;">10</span>分</div>
												<div>共计4题</div>
											</div>
										</div> -->
										<div style="font-size: 15px;color: #999;margin-top: 12px;">
											说明:题量有<span style="font-weight: 600;color: #333;">5道</span>和<span style="font-weight: 600;color: #333;">4道</span>两种选择,总分均为40分,章节随机,每一章节随机抽选一道题。
										</div>
									</div>
								</div>
								
								<div style="display: flex; padding: 17px 0; border-bottom: 1px dashed #ccc;">
									<div style="width: 10px; height: 10px;background-color: #333; margin-top: 2px;"></div>
									<div style="flex: 1;display: flex; flex-direction: column;font-size: 17px; margin-left: 12px;">
										<div style="display: flex;">
											<div>题型4 —— </div>
											<div style="font-weight: 600; margin-left: 5px;">填空、连线、画图题</div>
											<div style="font-size: 13px; margin-left: 10px; padding:4px 8px;background-color:#d7d7d7; border-radius: 4px;">共15分</div>
										</div>
										<div style="font-size: 15px;color: #999;margin-top: 12px;">
											说明:每题<span style="font-weight: 600;color: #333;">5分</span>,章节随机,每一章节随机抽选一道题。且最小可能与题型3所选章节重合。
										</div>
									</div>
								</div>
								
								<div style="display: flex; padding: 17px 0; border-bottom: 1px dashed #ccc;">
									<div style="width: 10px; height: 10px;background-color: #333; margin-top: 2px;"></div>
									<div style="flex: 1;display: flex; flex-direction: column;font-size: 17px; margin-left: 12px;">
										<div style="display: flex;">
											<div>题型5 —— </div>
											<div style="font-weight: 600; margin-left: 5px;">简述及（材料）分析题</div>
											<div style="font-size: 13px; margin-left: 10px; padding:4px 8px;background-color:#d7d7d7; border-radius: 4px;">共15分</div>
										</div>
										<div style="font-size: 15px;color: #999;margin-top: 12px;">
											说明:每题<span style="font-weight: 600;color: #333;">5分</span>,章节随机,每一章节随机抽选一道题,且最小可能与题型3及题型4所选章节重合。
										</div>
									</div>
								</div>
								
							</div>
							
							<div style="display: flex; justify-content: center; margin: 32px 0;">
								<div style="width: 196px; height: 38px; border-radius: 38px; display: flex; justify-content: center;  align-items: center; background-color: #d7d7d7;font-size: 18px;color: #333; cursor: pointer;" @click="submitExam2()"><i class="el-icon-help" style="font-size: 20px; margin-right: 5px;"></i>试题分布推演</div>
							</div>
							<div style="width: 100%; height: 850px; background-color: #fff; border-radius: 20px; padding: 20px; box-sizing: border-box;">
								<div style="display: flex; font-size: 18px; color: #999;">
									<div style="border-right: 1px solid #999; padding-right: 12px; margin-right: 12px;">试卷总分：<span style="color: #333;font-weight: 600;">100分</span></div>
									<div>试卷总数：<span style="color: #00786c;font-weight: 600;" v-if="question_count==5">31道</span><span style="color: #333;font-weight: 600;" v-if="question_count==4">30道</span></div>
								</div>
								<div style="display: flex; width: 100%; height: 714px;background-color: #E4E4E4;margin-top: 15px;">
									<div style="width: 100%;">
										<div style="height: 714px;" ref="examChart" id="examChart"></div>
									</div>
								</div>
								
								<div style=" width: 100%; display: flex;margin-top: 20px; justify-content: center;">
									<div style="width: 196px; height: 38px;border-radius: 38px; background-color: #515151; color: #fff; display: flex; justify-content: center; align-items: center; margin-right: 48px; cursor: pointer;" @click="submitExam2()"><i class="el-icon-circle-check" style="font-size: 20px; margin-right: 5px;"></i>不满意，重新推演</div>
									<div style="width: 196px; height: 38px; border-radius: 38px; background-color: #CE671B; color: #fff; display: flex; justify-content: center; align-items: center;cursor: pointer;"@click="submitExam3()"><i class="el-icon-circle-close" style="font-size: 20px; margin-right: 5px;"></i>满意，生成试卷</div>
								</div>
							</div>
						</div>
				
					</div>
				</div>
				
			</div>
			
			
			<!-- 策略二内容 -->
			<div style="display: flex;flex-direction: column;" v-if="tab_index == 2">
				
				<!-- 组卷次数 -->
				<div style="display: flex;flex-direction: column;">
					<div style="font-size: 22px; color: #333; margin: 30px 0 15px 0; display: flex;">
						<div style="color: #333; margin-right: 5px;">●</div>
						<div>组卷人</div>
					</div>
					<div style="display: flex; align-items: center;margin-bottom: 45px;">
						<div style="display: flex; align-items: center; margin-right: 20px;">
							<img src="../../assets/images/icon_user.png" style=" width: 30px; height: auto; margin-right: 10px; " />
							<div style="font-size: 20px; color: #333;">{{mobile}}</div>
						</div>
						<div style="display: flex;align-items: center;font-size: 16px;">
							<div>剩余次数：</div>
							<div style="display: inline-flex; justify-content: center; align-items: center; font-weight: 400; width: 20px; height: 20px; color:#333; background-color: #e4e4e4;">
								{{exam_residue_count}}
							</div>
						</div>
					</div>
				</div>
				
				<!-- 组卷界面 -->
				<div style="display: flex;flex-direction: column; position: relative;">
					<div style="width: 100%; overflow-x: hidden;margin-bottom: -20px; z-index: 1;border-radius: 10px 0 0 0">
						<div style="margin-left:-10px;  width: 200px; height: 50px;background-color: #E4E4E4;border-radius: 10px 10px 0 0;transform: skew(45deg);">
							<div style="transform: skew(-45deg); display: flex; justify-content: center;align-items: center; padding-top: 10px; color: #333;">
								<div style="font-size: 18px;">试卷</div>
								<div style="font-size: 12px;">（创建中......）</div>
							</div>
						</div>
					</div>
				
					<div style=" z-index: 2; display: flex;flex-direction: column; width: 100%; min-height: 100vh; background-color: #E4E4E4; padding:35px 30px;box-sizing: border-box; border-radius: 0 10px 10px 10px;">
						<div style="width: 100%; background-color: #fff; border-radius: 15px; display: flex;flex-direction: column; padding: 20px; box-sizing: border-box;">
							<div style="font-size: 20px;color: #333; ">试题分布：</div>
							<div style="display: flex; color: #999; margin-top: 15px;font-size: 16px;">
								<div style="display: flex;">
									<div>题目总数：</div>
									<div style="color: #333;">{{total_count}}</div>
								</div>
								<div style="margin: 0 8px;">|</div>
								<div style="display: flex;">
									<div>客观题目：</div>
									<div style="color: #333;">{{objectivity_count}}</div>
								</div>
								<div style="margin: 0 8px;">|</div>
								<div style="display: flex;">
									<div>主观题目：</div>
									<div style="color: #333;">{{subjectivity_count}}</div>
								</div>
							</div>
							<div style="display: flex;color: #999; margin-top: 10px;font-size: 16px;">
								<div>覆盖章节：</div>
								<div style="color: #333; width: 550px;">{{checked_chapter}}</div>
							</div>
							<div style="display: flex; width: 100%; height: 200px;background-color: #E4E4E4;margin-top: 15px;">
								<div style="width: 50%;">
									<div style="height: 200px;" ref="typeChart" id="typeChart"></div>
								</div>
								<div style="width: 50%;">
									<div style="height: 200px;" ref="chapChart" id="chapChart"></div>
								</div>
							</div>
							<div style="display: flex;margin-top: 15px;color: #999;font-size: 16px; line-height: 1.5; text-align: justify;">
								提示：每份试卷总题目不超过40道；至少选择6个章节；每个章节题目总数不超过5道，其中主观题不超过2道。
							</div>
						</div>
				
						<div class="" style="display: flex;flex-direction: column; margin-top: 10px;padding: 25px 0; border-bottom: 1px solid #ccc;">
							<div style="display: flex;flex-direction: column;font-size: 18px;">
								<div style="display: flex;">
									<div>创建日期：</div>
									<div style="color: #333;">{{myDate}}</div>
								</div>
								<div style="display: flex; align-items: center; margin-top: 15px;">
									<div>试卷名称：</div>
									<div style="flex: 1;">
										<el-input type="text" v-model="form.title" placeholder="请输入试卷名称" style="width: 100%;"></el-input>
									</div>
								</div>
							</div>
						</div>
				
						<div style="display: flex;flex-direction: column; padding: 20px 0;">
							<div class="" style="font-size: 17px; color: #333; font-weight: 600; font-size: 18px;">
								选择章节：
							</div>
				
							<div style="display: flex;flex-direction: column;margin-top: 25px;">
								<div style="display: flex; flex-direction: column; border-bottom: 1px dashed #ccc;" v-for="(item,index) of chapter_list">
									<div class="" style="display: flex; justify-content: space-between; height: 44px; align-items: center;">
										<div style="display: flex; align-items: flex-end;">
											<div style=" display: flex; align-items: center;">
												<el-checkbox v-model="item.checked" @change="checkedChange($event,item)"></el-checkbox>
												<div style=" color: #333;font-size: 18px; margin-left: 10px;">{{item.title}}</div>
											</div>
											<div style=" color: #999;font-size: 14px; margin-left: 13px;">
												客观题库：{{item.keguan_max}} / 主观题库：{{item.zhuguan_max}}
											</div>
										</div>
										<div @click="taggleClick(item)" style="display: flex;justify-content: flex-end;align-items: center;width: 80px; height: 44px; cursor: pointer;">
											<i class="el-icon-arrow-down" style="font-size: 18px; color: #999;" v-if="!item.open"></i>
											<i class="el-icon-arrow-up" style="font-size: 18px; color: #333;" v-if="item.open"></i>
										</div>
									</div>
									<div style="display: flex; flex-direction: column; padding:15px 20px; box-sizing: border-box;" v-if="item.open">
										<div style="display: flex; align-items: center;">
											<div style="color: #333; display: flex;flex-direction: column; width: 96px; height: 96px; background-color: #fff;justify-content: center;align-items: center;">
												<div style="cursor: pointer;">
													<input type="number" @input="keguanChange($event,item)" v-model="item.keguan_count" min="0" :max="item.keguan_max" style="width: 56px;color:#666;font-size: 40px; font-weight: 600; text-align: center; margin-right: -14px;" />
												</div>
												<div style="width:56px; height: 0; border-bottom: 2px solid #333; margin-bottom: 5px;"></div>
												<div style="font-size: 18px; ">客观题</div>
											</div>
											<div style="font-size: 24px; color: #999;display: flex;flex-direction: column; width: 44px; height: 96px;justify-content: center;align-items: center;">
												+
											</div>
											<div style="color: #333; display: flex;flex-direction: column; width: 96px; height: 96px; background-color: #fff;justify-content: center;align-items: center;">
												<div style="cursor: pointer;">
													<input type="number" @input="zhuguanChange($event,item)" v-model="item.zhuguan_count" min="0" max="item.zhuguan_max" style="width: 56px;color:#666;font-size: 40px; font-weight: 600; text-align: center; margin-right: -14px;" />
												</div>
												<div style="width:56px; height: 0; border-bottom: 2px solid #333; margin-bottom: 5px;"></div>
												<div style="font-size: 18px; ">主观题</div>
											</div>
											<div style="font-size: 24px; color: #999;display: flex;flex-direction: column; width: 44px; height: 96px;justify-content: center;align-items: center;">
												=
											</div>
											<div style="color: #333;display: flex;flex-direction: column; width: 96px; height: 96px; background-color: #fff;justify-content: center;align-items: center;">
												<div style="font-size: 40px; font-weight: 600;">{{item.total_count}}</div>
												<div style="width:56px; height: 0; border-bottom: 2px solid transparent; margin-bottom: 5px;"></div>
												<div style="font-size: 18px; ">总题目</div>
											</div>
										</div>
										<div style="font-size: 12px;color: #D71311; margin-top: 12px;">
											{{item.msg}}
										</div>
									</div>
								</div>
							</div>
						</div>
				
				
						<!-- 按钮区域 -->
						<div style="display: flex; margin-top: 35px; justify-content: center; align-items: center;">
							<div style="width: 104px; height: 40px; display: flex; justify-content: center; align-items: center; background-color: #CE671B; color: #fff; border-radius: 40px; margin-right: 25px; cursor: pointer;" @click="submitExam()">保存</div>
							<div style="width: 104px; height: 40px; display: flex; justify-content: center; align-items: center;background-color: #666; color: #fff;border-radius: 40px;cursor: pointer;" @click="clearExam()">清空</div>
						</div>
				
					</div>
				</div>
			</div>
			
		</div>

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import * as echarts from "echarts";


	export default {
		props: {
			myid: String,
			myauth: Number
		},
		data() {
			return {
				id: this.myid,
				has_auth: this.myauth,
				mobile: "",
				exam_residue_count: 0,
				exam_list: [],
				form: {
					title: '',
				},
				paper_selected: null,
				chapter_list: [],
				exam_info: [],
				exam_id: 0,
				typeChart: null,
				chapChart: null,
				tab_index: 1,
				question_count:5,
				examChart: null,
				myDate:null,
			};
		},
		created() {
			//document.title = "策略组卷 - 数字教研室 - 机工教师特训营";
			if (this.has_auth == 1) {
				this.getExamInfo();
				this.GetMyDate();
			}
		},
		computed: {
			total_count: {
				get() {
					var count = 0;
					this.chapter_list.forEach((item, index) => {
						if (item.checked) {
							count = count + parseInt(item.total_count);
						}
					})
					return parseInt(count);
				},
				set() {}
			},
			objectivity_count: {
				get() {
					var o_count = 0;
					this.chapter_list.forEach((item, index) => {
						if (item.checked) {
							o_count = o_count + parseInt(item.keguan_count);
						}
					})
					return parseInt(o_count);
				},
				set() {}
			},
			subjectivity_count: {
				get() {
					var s_count = 0;
					this.chapter_list.forEach((item, index) => {
						if (item.checked) {
							s_count = s_count + parseInt(item.zhuguan_count);
						}
					})
					return parseInt(s_count);
				},
				set() {}
			},
			checked_chapter: {
				get() {
					var chap_list = [];
					this.chapter_list.forEach((item, index) => {
						if (item.checked) {
							chap_list.push(item.title);
						}
					})
					return chap_list.join("、");
				},
				set() {}
			},
			typeOption: {
				get() {
					var keguanti = 0;
					var zhuguanti = 0;

					this.chapter_list.forEach((item, index) => {
						if (item.checked) {
							keguanti = keguanti + parseInt(item.keguan_count);
							zhuguanti = zhuguanti + parseInt(item.zhuguan_count);
						}
					})
					return {
						series: [{
							type: "pie", // type设置为饼图
							// label: {
							// 	show: true,
							// 	formatter: "{b} : {c} ({d}%)" // b代表名称，c代表对应值，d代表百分比
							// },
							data: [{
									value: keguanti,
									name: "客观题"
								},
								{
									value: zhuguanti,
									name: "主观题"
								}
							]
						}]
					}
				},
				set() {}
			},
			chapOption: {
				get() {
					var datas = [];
					this.chapter_list.forEach((item, index) => {
						if (item.checked) {
							datas.push({
								value: 1,
								name: item.title
							})
						}
					})
					return {
						series: [{
							type: "pie", // type设置为饼图
							data: datas
						}]
					}
				},
				set() {}
			},
			examOption:{
				get(){
					var datas = [];
					this.exam_info.forEach((item, index)=>{
						datas.push({
								value: item.question_count,
								name: item.c_title,
								desc: item.desc,
								point: item.point
							  });
					})
					return {
							series: [
							  {
								type: "pie", // type设置为饼图
								data: datas,
								label: {
									show: true ,
									formatter: function (arg) {
										return arg.data.name + '（共' + arg.data.value + '题，' + arg.data.point + '分）\n' + arg.data.desc;
									}
								},
								radius: '30%'
							  }
							]
						  }
				},
				set(){}
			}
		},
		methods: {
			GetMyDate() {
				let mydate = new Date();
				this.myDate = mydate.getFullYear() + '年'+ (mydate.getMonth() + 1) + '月' + mydate.getDate() + '日'
			},
			tabClick(index){
				this.tab_index = index;
			},
			clearSelected() {
				this.paper_selected = null;
			},
			taggleClick(item) {
				this.chapter_list.forEach((tem, dex) => {
					if (tem.id == item.id) {
						tem.open = !item.open;
						//tem.checked= !item.open;
					}
				})
			},
			checkedChange(e, item) {
				this.chapter_list.forEach((tem, dex) => {
					if (tem.id == item.id) {
						tem.open = e;
					}
				});
				if(this.typeChart == null){
					this.typeChart = echarts.init(this.$refs.typeChart); // 图标初始化
				}
				if(this.chapChart == null){
					this.chapChart = echarts.init(this.$refs.chapChart); // 图标初始化
				}
				
				this.initTypeEcharts();
				this.initChapEcharts();
			},
			keguanChange(e, item) {
				console.log(JSON.stringify(e.target.value));
				console.log(JSON.stringify(item))
				this.chapter_list.forEach((tem, dex) => {
					if (tem.id == item.id) {
						if (parseInt(e.target.value) > 5) {
							tem.keguan_count = 5;
						}
						if (parseInt(tem.keguan_count) + parseInt(tem.zhuguan_count) > 5) {
							tem.zhuguan_count = 5 - parseInt(tem.keguan_count);
						}

						tem.total_count = parseInt(tem.zhuguan_count) + parseInt(tem.keguan_count);
						if (tem.total_count > 5) {
							tem.msg = "超出每章上限5";
						} else {
							tem.msg = "";
						}
					}
				});
				this.initTypeEcharts();
			},
			zhuguanChange(e, item) {
				console.log(JSON.stringify(e.target.value));
				console.log(JSON.stringify(item))
				this.chapter_list.forEach((tem, dex) => {
					if (tem.id == item.id) {
						if (parseInt(e.target.value) > 2) {
							tem.zhuguan_count = 2;
						}
						if (parseInt(tem.keguan_count) + parseInt(tem.zhuguan_count) > 5) {
							tem.keguan_count = 5 - parseInt(tem.zhuguan_count);
						}

						tem.total_count = parseInt(tem.zhuguan_count) + parseInt(tem.keguan_count);
						if (tem.total_count > 5) {
							tem.msg = "超出每章上限5";
						} else if (parseInt(tem.zhuguan_count) > 2) {
							tem.msg = "主观题每章上限2";
						} else {
							tem.msg = "";
						}
					}
				});
				this.initTypeEcharts();
			},
			getExamInfo() {
				var that = this;
				var url = apiUrl.q_exam_list_get;
				this.Ajax(url, {
					office_id: that.id
				}, (data) => {
					that.exam_residue_count = data.exam_residue_count;
					that.mobile = data.mobile;
					that.exam_list = data.list;
				});
			},
			createNewExam() {
				this.paper_selected = {
					id: 0
				};
				var that = this;
				var url = apiUrl.q_exam_chap_list_get;
				this.Ajax(url, {
					office_id: that.id
				}, (data) => {
					that.chapter_list = [];
					data.forEach((item, index) => {
						that.chapter_list.push({
							checked: false,
							open: false,
							title: item.title,
							keguan_count: 3,
							keguan_max: item.objectivity,
							zhuguan_count: 2,
							zhuguan_max: item.subjectivity,
							total_count: 5,
							msg: '',
							id: item.chap_id
						});
					});
				});
			},
			submitExam() {
				var parm_list = [];
				if (this.total_count > 40) {
					alert("题目总数不能大于40。");
					return;
				}

				var checked_chap_count = 0;
				var at_least_one = false;
				this.chapter_list.forEach((item, index) => {
					if (item.checked) {
						checked_chap_count = checked_chap_count + 1;
						if (parseInt(item.keguan_count) == 0 && parseInt(item.zhuguan_count) == 0) {
							at_least_one = true;
						}
						parm_list.push({
							chap_id: item.id,
							objectivity: item.keguan_count,
							subjectivity: item.zhuguan_count
						});
					}
				})

				if (at_least_one) {
					alert("选中的章节中，至少需要1道题。");
					return;
				}

				if (checked_chap_count < 6) {
					alert("至少选择6个章节");
					return;
				}

				var that = this;
				var url = apiUrl.q_exam_make_paper;
				this.Ajax(url, {
					office_id: that.id,
					exam_title: that.form.title,
					parm: JSON.stringify(parm_list)
				}, (data) => {
					that.paper_selected = null;
					that.getExamInfo();
					that.chapter_list = [];
				});
			},
			submitExam2(){
				var that = this;
				if(that.form.title == ""){
					alert("试卷标题不能为空。");
					return;
				}
				if(this.examChart ==null){
					this.examChart = echarts.init(this.$refs.examChart);
				}
				
				var url = apiUrl.q_exam_make_paper2;
				this.Ajax(url, {office_id: that.id, exam_title: that.form.title, question_count: that.question_count}, (data) => {
					console.log(data.list);
					that.exam_info = data.list;
					that.exam_id = data.exam_id;
					this.initExamEcharts();
				});
			},
			submitExam3(){
				if (this.exam_id == 0){
					alert("请先组卷。");
					return;
				}
				var that = this;
				var url = apiUrl.q_exam_make_paper2_submit;
				this.Ajax(url, {exam_id: that.exam_id}, (data) => {
					that.paper_selected = null;
					that.getExamInfo();
					that.chapter_list = [];
					that.tab_index = 1;
					that.exam_info = [];
					that.exam_id = 0;
					that.form.title = "";
				});
				
			},
			clearExam() {
				this.chapter_list.forEach((item, index) => {
					item.checked = false;
					item.open = false;
				})
				this.initTypeEcharts();
				this.initChapEcharts();
			},
			initTypeEcharts() {
				this.typeChart.setOption(this.typeOption); // 渲染页面
			},
			initChapEcharts() {
				this.chapChart.setOption(this.chapOption); // 渲染页面
			},
			initExamEcharts() {
				this.examChart.setOption(this.examOption);// 渲染页面
			},
			downloadExamPdf(url) {
				let link = document.createElement('a');
				link.style.display = 'none';
				link.href = url.replace('images.cmpreading.com','images.cmptt.com');
				// 生成时间戳
				// let timestamp = new Date().getTime()
				// link.download = timestamp + '.txt'
				document.body.appendChild(link)
				link.click()
				link.remove();
			},
			setQuestionCount(c){
				if(this.question_count != c){
					this.question_count = c;
					console.log(this.question_count)
					this.exam_info = [];
					this.exam_id = 0;
					if(this.examChart ==null){
						this.examChart = echarts.init(this.$refs.examChart);
					}
					this.initExamEcharts();
				}
			},
		},
	};
</script>

<style>
	.el-input__inner {
		height: 36px !important;
		line-height: 36px !important;
	}

	.el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #333 !important;
		border-color: #333 !important;
	}

	.el-checkbox__input :hover {
		border-color: #333 !important;
	}
	
	.border{
		border: 1px solid #333;
	}

	.tabs {
		display: flex;
		flex-direction: row;
		font-size: 24px;
		font-weight: 600;

		.tab {
			cursor: pointer;
			color: #CACACA;
		}

		.tab.actived,
		.tab:hover {
			color: #333;
		}

		.spliter {
			height: 24px;
			width: 1px;
			background-color: #ccc;
			margin: 0 15px;
		}
	}
</style>