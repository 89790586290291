<template>
	<div class="page-main" v-if="detailData">
		<div style="width: 100%;display: flex; flex-direction: column; align-items: center;">
			<!-- <div style="width: 100%;height: 400px;;display: flex; flex-direction: column; align-items: center;background: #FEECDA;"> -->
			<!-- <div style="width: 100%;height: 400px;;display: flex; flex-direction: column; align-items: center;"> -->
			<!-- <div style="display: flex; flex-direction: column;width: 1200px; max-width: 1200px; box-sizing: border-box;">
				<div class="mianb">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">视频课</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/course/list' }">课程列表</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/' }">课程介绍</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div> -->
			
			<div id="top" style="display: flex; flex-direction: column;width: 1030px; height: 256px; margin-top: 150px;border-radius: 30px 30px 0 0;" :style="{'background':colorSelected ? colorSelected.bgColor :'#7c8cd4','color': colorSelected && colorSelected.fontColor =='#fff' ? colorSelected.fontColor :'#333'}">
				<!-- <img style="width: 100%;" src="../../assets/images/banner_course_detail.png" /> -->
				<img ref="myImg" id="myImg" :src="detailData.img_url_tt" crossorigin="anonymous" alt="" style="display: none;" />
				<div style="width: 100%; height: 211px;display: flex; flex-direction: row; position: relative; padding: 30px 40px 30px 440px; box-sizing: border-box;">
					<img v-if="detailData.teacher_list && detailData.teacher_list.length>0" :src="detailData.teacher_list[0].img_url_compressed" style="width: 360px; position: absolute; bottom: 0; left: 90px;"/>
					<div style="display: flex;flex-direction: column; justify-content: space-between; line-height: 1;">
						<div style="display: flex;flex-direction: column;">
							<div style="font-size: 20px; margin-bottom: 20px;">课程：</div>
							<div style="font-size: 30px;font-weight: 600; line-height: 1.2;">{{detailData.title}}</div>	
						</div>
						<div style="display: flex; flex-direction: column;" v-if="detailData.teacher_list && detailData.teacher_list.length>0">
							<!-- <div  style="font-size: 25px; " :style="{'color': colorSelected  ? colorSelected.fontColor :'#333'}">{{detailData.teacher_list[0].name}}</div> -->
							<div  style="font-size: 25px;#333;margin-top: 5px;">{{detailData.teacher_list[0].name}}</div>
							<div style="margin-top: 10px; color: #333;  font-size: 17px; ">
								<span v-if="detailData.teacher_list[0].school">{{detailData.teacher_list[0].school}}</span>
								<span v-if="detailData.teacher_list[0].title">{{detailData.teacher_list[0].title}}</span>
							</div>
						</div>
						
						
					</div>			
				</div>
			</div>

			<div id="top"
				style=" margin-top: -100px; display: flex; flex-direction: column;width: 830px; padding-top: 50px; ">
				<!-- <div style="width: 100%; height: 100%; display: flex;flex-direction: row;margin-bottom: 30px; ">
					<img style="width: 300px; height: 232px; box-shadow: 3px 5px 3px rgba(0,0,0,0.3);border: 1px solid #eee; margin-right: 64px;" :src="detailData.img_url_tt" />
					<div style="flex: 1;height: 232px; display:flex;flex-direction: column;  line-height: 1.2; padding-top: 15px; ;box-sizing: border-box;">
						<div style="font-size: 40px; color: #333; font-weight: 600;" v-html="detailData.title"></div>
						<div style="font-size: 30px; color: #333; margin-top: 35px;font-weight: 600;" v-html="detailData.author"></div>
						<div style="font-size: 18px; color: #333; margin-top: 12px;"></div>
					</div>
				</div> -->
				<div
					style=" width: 100%; height: 90px; display: flex; flex-direction: row; margin-bottom: -40px; z-index: 9;box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04); line-height: 1;">
					<div style="flex: 1; height: 90px;background-color: #fff; display: flex;flex-direction:column; ">
						<div
							style="width: 100%; height: 90px; overflow: hidden; display: flex; justify-content: center; align-items: center;">
							<img src="../../assets/images/bg_course_01.png" style="width: 70%;" />
						</div>
						<div
							style=" width: 100%; height: 90px; margin-top: -90px;  padding:20px 30px 10px 30px; box-sizing: border-box; display: flex;flex-direction:column; justify-content: space-between; ">
							
							<div class="" style="display: flex; align-items:baseline;  color: #c83a2e;" v-if="detailData.price !='0.00'">
								<div style="color: #c83a2e; display: flex; align-items: flex-end;  margin-right: 10px;">
									<div style="font-size: 14px; font-weight: 600; margin-right: 5px;" v-if="detailData.line_price !=''">特惠价</div>
									<div style="font-size: 14px;">￥</div>
									<div style="font-size: 30px;margin-bottom: -3px; margin-left: -3px;">{{detailData.price}}</div>
								</div>
								<div v-if="detailData.line_price !=''" style="display: flex;font-size: 14px;color: #999; align-items: center; text-decoration: line-through;">
									￥{{detailData.line_price}}
								</div>
							</div>

							<div class="" style="display: flex; align-items: flex-end; line-height: 1;" v-if="detailData.price =='0.00' && detailData.line_price !=''">
								<div style="color: #999; display: flex; align-items: flex-end;  text-decoration: line-through; margin-right: 10px;">
									<div style="font-size: 14px;">￥</div>
									<div style="font-size: 30px;margin-bottom: -3px;">{{detailData.line_price}}</div>
								</div>
								<div style="color: #c83a2e;font-size: 14px; font-weight: 600;">限免</div>
							</div>
							
							<div class="" style="display: flex; line-height: 1;color: #c83a2e;font-size: 30px; font-weight: 600;" v-if="detailData.price =='0.00' && detailData.line_price ==''">
								免费
							</div>

							<div class="" style="font-size:12px;color: #333;display: flex; flex-direction: row;">
								<div><i class="el-icon-time"></i> {{detailData.res_length}}</div>
								<div
									style="padding: 0 12px; margin:0 12px; border-left:1px solid #333; border-right:1px solid #333; ">
									{{detailData.res_count}}小节
								</div>
								<div>{{detailData.readers_count}}人正在学习</div>
							</div>
						</div>

					</div>
					<div @click="showQrClick" v-if="detailData.price !='0.00' && priceShow"
						style="width: 200px;height: 90px;display:flex;background: linear-gradient(to right,#c83a2e,#FB9328) ; color:#fff ; font-weight: 600; justify-content: center;align-items: center; font-size: 22px;cursor: pointer">
						立即购买
					</div>
				</div>
			</div>
		</div>
		<div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
			<div style="display: flex; flex-direction: column;width: 1030px;max-width: 1030px;margin: 90px 0;">
				<div style=" width: 100%; ;display: flex; flex-direction: row; justify-content: center;">
					<div style="width:805px;">
						<div v-if="playerShow" id="mse" style="width:100%; height: 460px; border: 1px solid #f1f1f1; border-top: none; background-color: #f1f1f1; box-sizing:border-box;">
							<!-- 视频播放区域 -->
						</div>

						<div v-if="!playerShow"
							style="width:100%; height: 460px;background:linear-gradient(to right top,#90ECA4,#5E80FC) ; font-size: 28px; color: #fff; display: flex;  justify-content: center;align-items: center; cursor: pointer;">
							<div v-if="isLogin" @click="showQrClick">
								您暂无权限播放本视频，请<span
									style="border-bottom: 1px solid #C43E32;color: #C43E32; padding: 0 0 2px 5px;">订阅</span>
							</div>
							<div v-if="!isLogin" @click="toLogin">
								<p v-if="detailData.price !='0.00'">您暂无权限播放本视频，请<span
										style="border-bottom: 1px solid #C43E32;color: #C43E32; padding: 0 5px 2px 5px;">登录</span>订阅
								</p>
								<p v-else>您暂无权限播放本视频，请<span
										style="border-bottom: 1px solid #C43E32; color: #C43E32; padding: 0 0 2px 5px;">登录</span>
								</p>
							</div>
						</div>
					</div>


					<div style="width:225px; display: flex;flex-direction:column;" v-if="detailData">
						<div
							style="width: 100%; height: 50px; font-size: 20px;font-weight: 600;background: #333; display: flex; justify-content: center;align-items: center; color: #fff;">
							<i class="iconfont icon-guanlian"
								style="margin-right: 5px; margin-bottom: -3px; font-size: 22px;"></i>
							手机端观看
						</div>
						<div
							style="width: 100%; height: 256px; background-color: #ECECEC; border-radius: 0 0 50px 0; display: flex; flex-direction: column; justify-content: center;align-items: center; cursor: pointer;">
							<div ref="qrcode" style="width: 110px; height: 110px;"></div>
							<div style="font-size: 15px; font-weight: 600; color: #333; margin-top: 12px;">扫码观看课程</div>
						</div>

					</div>

					<!-- <div style="width:225px; display: flex;flex-direction:column;" v-if="detailData && detailData.connection_book">
						<div style="width: 100%; height: 50px; font-size: 20px;font-weight: 600;background: #333; display: flex; justify-content: center;align-items: center; color: #fff;">
							<i class="iconfont icon-guanlian" style="margin-right: 5px; margin-bottom: -3px; font-size: 22px;"></i>
							相关图书
						</div>
						<div @click="toDetail(detailData.connection_book)" style="width: 100%; height: 256px; background-color: #ECECEC; border-radius: 0 0 50px 0; display: flex; flex-direction: column; justify-content: center;align-items: center; cursor: pointer;">
							<img style="width: 114px; height: 161px; box-shadow: 3px 5px 3px rgba(0,0,0,0.3);border: 1px solid #eee;" :src="detailData.connection_book.img_url" />
							<div style="width: 150px; display: flex; flex-direction: column; ">
								<div style="font-size: 15px; font-weight: 600; margin-top: 12px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;text-align: center;">{{detailData.connection_book.title}}</div>
								<div style="font-size: 12px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;text-align: center;">{{detailData.connection_book.author}}</div>
							</div>
							
						</div>
					
					</div> -->
				</div>

				<div
					style="width: 100%; display: flex; flex-direction: row;margin-top: 40px; line-height: 1;">

					<div class="course_tab_main" style=" width: 790px; margin-right: 35px; "
						v-if="!detailData.connection_book">
						<div class="course_tab_items">
							<!-- <i class="el-icon-caret-right"></i> -->
							<div class="course_tab_item">章节选择</div>
						</div>
						<div class="course_tab_conts">
							<div class="course_tab_cont">
								<div class="res_item" @click="getVideo(item,true)"
									:class="{'res_selected': listData && listData.current && listData.current.resource_id == item.resource_id}"
									v-for="(item,index) in listData.list">
									<div class="icon_box">
										<i class="iconfont icon-24gf-playCircle"></i>
									</div>
									<div class="mes_box">
										<div class="title_box">
											<div class="title">{{item.title}}</div>
											<div class="free_tag" v-if="item.is_try == 1">
												免费试看
											</div>
											<i class="el-icon-lock" style="margin-left: 10px;"
												v-if="item.is_try == 0 && item.is_available == 0"></i>
										</div>
										<div class="res_length">时长 {{ formatLength(item.video_length)}}</div>
									</div>
								</div>
							</div>

						</div>

					</div>

					<div class="course_tab_main" style=" width: 790px; margin-right: 35px; "
						v-if="detailData.connection_book">
						<div class="course_tab_items">
							<!-- <i class="el-icon-caret-right"></i> -->
							<div class="course_tab_item" @click="changeTab(1)" :class="{'actived':tab_index == 1}">章节选择
							</div>
							<div class="course_tab_item" @click="changeTab(2)" :class="{'actived':tab_index == 2}">
								相关图书
							</div>
						</div>
						<div class="course_tab_conts">
							<div v-if="tab_index == 1" class="course_tab_cont">
								<div class="res_item" @click="getVideo(item,true)"
									:class="{'res_selected': listData && listData.current && listData.current.resource_id == item.resource_id}"
									v-for="(item,index) in listData.list">
									<div class="icon_box">
										<i class="iconfont icon-24gf-playCircle"></i>
									</div>
									<div class="mes_box">
										<div class="title_box">
											<div class="title">{{item.title}}</div>
											<div class="free_tag" v-if="item.is_try == 1">
												免费试看
											</div>
											<i class="el-icon-lock" style="margin-left: 10px;"
												v-if="item.is_try == 0 && item.is_available == 0"></i>
										</div>
										<div class="res_length">时长 {{ formatLength(item.video_length)}}</div>
									</div>
								</div>
							</div>

							<div v-if="tab_index == 2" class="course_tab_cont">
								<div style=" width: 100%; padding: 30px 0; box-sizing: border-box;">
									<div @click="toDetail(detailData.connection_book)"
										style="width: 100%; width: 150px; height: 220px; display: flex; flex-direction: column; justify-content: center;align-items: center; cursor: pointer;">
										<img style="width: 114px; height: 161px; box-shadow: 3px 5px 3px rgba(0,0,0,0.3);border: 1px solid #eee;"
											:src="detailData.connection_book.img_url" />
										<div style="width: 150px; display: flex; flex-direction: column; ">
											<div
												style="font-size: 15px; font-weight: 600; margin-top: 12px; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;text-align: center;">
												{{detailData.connection_book.title}}</div>
											<div
												style="font-size: 12px; margin-top: 5px; ;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;text-align: center;">
												{{detailData.connection_book.author}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					<!-- <div style="flex: 1;">
						<div style="width: 100%;color: #666; border-bottom: 1px solid #666;height: 50px; line-height: 50px; font-size: 20px;font-weight: 600;">
							手机端观看
						</div>
						<div style="width: 100%; height: 180px; display: flex; flex-direction: column;justify-content: center;align-items: center; cursor: pointer;">
							<div ref="qrcode" style="width: 110px; height: 110px;"></div>
							<div style="font-size: 12px;color: #999; margin-top: 12px;">课程二维码</div>
						</div>

					</div> -->
				</div>


				<div style="width: 100%; display: flex; flex-direction: row;margin-top: 20px; line-height: 1;">
					<div class="" style=" width: 790px; margin-right: 35px; ">
						<div style="color: #666; border-bottom: 1px solid #666;height: 50px; line-height: 50px; font-size: 20px; font-weight: 600;">
							<i class="el-icon-caret-right"></i>
							课程简介
						</div>
						<div v-html="detailData.desc" style="width:100%;  display: flex;flex-direction: column; line-height: 1; padding: 20px 0;"></div>
					</div>
				</div>

			</div>
		</div>

		<!-- 购买提示 -->
		<el-dialog title="订阅课程" width="420px" top="28vh" :visible.sync="dialogQrVisible" :show-close="true">
			<div
				style=" width: 100%; height: 150px; display: flex; flex-direction: column;justify-content: center;align-items: center;">
				<div ref="qrcode2" style="width: 150px; height: 150px;"></div>
				<div style="margin-top: 10px;">
					请扫码二维码订阅本课程
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogQrVisible = false">取消支付</el-button>
				<el-button type="primary" @click="finishedClick">支付完成</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import QRCode from "qrcodejs2";
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import Player from 'xgplayer';
	import 'xgplayer/dist/index.min.css';
	//import { json } from "express";
	import ColorThief from 'colorthief';


	export default {
		data() {
			return {
				id: null,
				tt_camp_id:null,
				detailData: null,
				search: {
					product_id: '',
					page_index: 1,
					page_size: 80,
					client_type: 10	,	
					order_by: 'created_at:asc'
				},
				listData: {
					total: 1,
					count: 1,
					current: null,
					list: [],
				},
				isLogin: false,
				player: null,
				playerShow: false,
				dialogQrVisible: false,
				qrcode: null,
				qrcode2: null,
				priceShow: true,
				tab_index: 1,
				colorList:[
					{'bgColor':'#dae5cf','fontColor':'#90BC71'},
					{'bgColor':'#d1cfeb','fontColor':'#5861AB'},
					{'bgColor':'#f6e2d3','fontColor':'#CE8F58'},
					{'bgColor':'#f5dbdf','fontColor':'#FB758B'},
				],
				colorSelected:null,
			};
		},
		components: {
			qrcode: QRCode,
		},
		created() {
			document.title = "课程 - 机工教师特训营";
			this.isLogin = localStorage.getItem("userLogInInfo") ? true : false;
			console.log(this.isLogin);

			var option = {
				id: this.$route.query.id || "",
				tt_camp_id: this.$route.query.tt_camp_id || "",
			};
			if (option.id) {
				this.id = option.id;
				this.tt_camp_id = option.tt_camp_id;
				this.search.product_id = option.id;
				
				this.getDetail();
			}
		},
		methods: {
			ImgColor() {
			
				let domImg = document.querySelector('#myImg'); // 获取图片dom节点
				console.log(domImg)
				let colorthief = new ColorThief();
				domImg.addEventListener('load', () => { // 图片加载
					console.log('主色调'+ this.rgbaToHex(colorthief.getColor(domImg))); //图片主色调，第二个参数可选(1~10)
					var _color = this.rgbaToHex(colorthief.getColor(domImg));
					this.colorList.forEach((item,index)=>{
						if(item.bgColor == _color){
							this.colorSelected = item;
						}
					})
					if(!this.colorSelected){
						if(_color!=''){
							switch(_color){
								case '#454051':
								case '#3d3c42':
								case '#313439':
								case '#1a1a1e':
								case '#1f1d21':
								case '#282b3c':
									this.colorSelected = this.colorList[0];
									break;
								
								case '#2d3643':
								case '#2b3041':
								case '#2e3a55':
								case '#1b1a1e':
								case '#1d232f':
								case '#342f44':
									this.colorSelected = this.colorList[1];
									break;
									
								case '#f8ded9':
								case '#8b6151':
								case '#1f1725':
									this.colorSelected = this.colorList[2];
									break;
									
								case '#2e3356':
								case '#44454b':
									this.colorSelected = this.colorList[3];
									break;
									
								default :
									this.colorSelected = {'bgColor':_color,'fontColor':'#333'};
									break;
							}
						}else{
							this.colorSelected = this.colorList[0];
						}
						
					}
				})
			},
			rgbaToHex(rgba) { // rgba转16进制
				let hex = '#';
				for (const i of rgba) {
					hex += i.toString(16).padStart(2, '0');
				}
				return hex;
			},
			changeTab(index) {
				this.tab_index = index;
			},
			toLogin() {
				window.top.location.href = "/login";
			},
			showQrClick() {
				if (this.isLogin) {
					this.dialogQrVisible = true;
					var that = this;
					if (!that.qrcode2) {
						that.$nextTick(() => {
							that.setupQrCode(that.$refs.qrcode2, that.detailData.qrcode_url, 150, (code_img) => {
								that.qrcode2 = code_img;
							});
						});
					}
				} else {
					var msg_title = "您需要登录后才可以订阅，是否登录?";
					this.$confirm(msg_title, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						window.top.location.href = "/login";
					}).catch(() => {
						// this.$message({
						// 	type: 'info',
						// 	message: '已取消'
						// });
					});
				}
			},
			finishedClick() {
				this.dialogQrVisible = false;
				this.getResList();
			},
			play() {
				var that = this;

				if (!that.player) {
					var player = new Player({
						"id": 'mse',
						"url": that.listData.current.video_url,
						"poster":'https://images.cmpreading.com/h5/cmptt/course/player_poster.png',
						'fluid': true,
						'volume': 0.3,
						'videoInit': true,
						'playsinline': true,
						'playbackRate': [0.5, 0.75, 1, 1.5, 2],
						'defaultPlaybackRate': 1,
						'x5-video-player-type': 'h5',
						'x5-video-orientation': 'portraint',
						"lang": 'zh-cn',
						'commonStyle': {
							'playedColor': '#C43D31',
							'volumeColor': '#C43D31'
						}
					});
					that.player = player;
					console.log(that.player)
				} else {
					that.player.src = that.listData.current.video_url;
					that.player.autoplay = true;
				}
			},
			toDetail(item) {
				return;
				/*
				var url = "/teachRes/detail";
				var query = {
					id: item.id
				};
				let routeUrl = this.$router.resolve({
					path: url,
					query: query,
				});
				window.open(routeUrl.href, "_blank");
				*/
			},
			formatLength(length) {
				var result = '';
				var values = length.split(':');
				switch (values.length) {
					case 1:
						result = values[0] + '秒';
						break;
					case 2:
						result = values[0] + '分' + values[1] + '秒';
						break;
					case 3:
						result = values[0] + '时' + values[1] + '分' + values[2] + '秒';
						break;

					default:
						result = '';
						break;
				}
				return result;
			},
			playClick(item) {
				if (this.selected && this.selected.resource_id == item.resource_id) {
					return;
				}
				console.log(JSON.stringify(item))
				this.selected = item;
			},
			getDetail() {
				var that = this;
				var url = apiUrl.tt_course_detail_get;
				this.Ajax(url, {
					id: this.id,
					tt_camp_id: this.tt_camp_id,
				}, (data) => {
					that.detailData = data;
					that.detailData.qrcode_url ='http://m.cmptt.com/pages/curriculum/curriculumDetails?id='+this.id
					document.title = data.title + " - 课程 - 机工教师特训营";
					that.$nextTick(() => {
						that.ImgColor();
					});
					
					
					if (data.qrcode_url && data.qrcode_url != "" && !that.qrcode) {
						that.$nextTick(() => {
							that.setupQrCode(this.$refs.qrcode, data.qrcode_url, 110, (code_img) => {
								that.qrcode = code_img;
							});
						});
					}
					
					that.getResList();
				});
			},
			getResList() {
				var that = this;
				var url = apiUrl.resource_list_get;
				this.Ajax(url, this.search, (data) => {
					if (that.listData.total == 0) {
						that.listData.total = data.total;
					}
					if (that.listData.count == 0) {
						that.listData.count = data.count;
					}
					that.listData.list = that.listData.list.concat(data.list);
					if (that.search.page_index == 1) {
						that.getVideo(that.listData.list[0]);
					}
				});
			},
			getVideo(item, alert) {
				if (this.listData && this.listData.current && this.listData.current.video_url != '' && this.listData
					.current.resource_id == item.resource_id) {
					return;
				}
				this.playerShow = true;
				if (item.is_try == 0 && item.is_available == 0) {
					this.playerShow = false;
					if (alert) {
						if (this.isLogin) {
							this.$alert('您暂无权限播放本视频, 请订阅', '提示', {
								confirmButtonText: '确定'
							});
						} else {
							var msg_title = "您暂无权限播放本视频, 是否登录订阅?";
							if (this.detailData.price == '0.00') {
								msg_title = "您暂无权限播放本视频，登录后即可播放，是否登录?";
							}
							this.$confirm(msg_title, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								window.top.location.href = "/Login";
							}).catch(() => {
								// this.$message({
								// 	type: 'info',
								// 	message: '已取消'
								// });
							});
						}
						return;
					}
				}


				var that = this;
				var url = apiUrl.resource_detail_get;
				this.Ajax(url, {
					product_id: item.product_id,
					resource_id: item.resource_id,
					tt_camp_id: this.tt_camp_id,
					client_type: 10	,			
				}, (data) => {
					that.listData.current = data;
					that.listData.list.forEach((item, index) => {
						if (item.resource_id == data.resource_id) {
							that.listData.current.index = index + 1;
						}
						if (index == 0 && item.is_available == 1) {
							that.priceShow = false;
							console.log(that.priceShow)
						}
					})
					that.play();
				});
			},
			//QrCode
			setupQrCode(el, url, width, callback) {
				let code_img = new QRCode(el, {
					width: width,
					height: width, // 高度
					text: url, // 二维码内容
					colorDark: '#333333',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.L,
				});
				if (callback) callback(code_img);
			},

		},
	};
</script>


<style lang="scss">
	.xgplayer .xg-options-list li:hover,
	.xgplayer .xg-options-list li.selected {
		color: #C43D31;
		opacity: 1;
	}

	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;

		.course_tab_main {
			.course_tab_items {
				color: #666;
				border-bottom: 1px solid #666;
				height: 50px;
				line-height: 50px;
				font-size: 20px;
				font-weight: 600;
				display: flex;

				.course_tab_item {
					display: inline-flex;
					height: 50px;
					line-height: 50px;
					box-sizing: border-box;
					cursor: pointer;

					&:hover {
						color: #FF7A39;
					}

					&.actived {
						border-bottom: 3px solid #FF7A39;
					}
				}

				.course_tab_item+.course_tab_item {
					margin-left: 30px;
				}

				.course_tab_conts {
					display: flex;
					flex-direction: column;

					.course_tab_cont {
						width: 100%;
						display: flex;
						flex-direction: column;
						line-height: 1;
						padding: 10px 0;
					}
				}


			}
		}

		.res_item {
			margin-top: 10px;
			width: 100%;
			height: 60px;
			padding: 12px 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			cursor: pointer;

			.icon_box {
				color: #ccc;
				margin-right: 15px;
			}

			.mes_box {
				color: #ccc;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.title_box {
					width: 100%;
					display: flex;
					flex-direction: row;

					.title {
						font-size: 18px;
						font-weight: 500;
					}

					.free_tag {
						background-color: #FF7A39;
						color: #fff;
						font-size: 10px;
						padding: 4px 8px;
						border-radius: 10px 10px 10px 0;
						position: relative;
						margin-left: 10px;

						&::before {
							content: '';
							position: absolute;
							bottom: 0;
							left: -5px;
							border-top: 5px solid transparent;
							border-right: 5px solid #FF7A39;
							border-bottom: 5px solid #FF7A39;
							border-left: 5px solid transparent;
						}
					}
				}

				.res_length {
					font-size: 10px;
				}
			}

			&.res_selected,
			&:hover {
				background-color: #FDF4F3 !important;

				.icon_box {
					color: #C43E32 !important;
				}

				.mes_box {
					color: #999 !important;
				}
			}
		}



		.mianb {
			width: 100%;
			height: 75px;
			margin: 0 auto;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: 18px;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #C43E32;


			.el-breadcrumb {
				font-size: 16px;
			}

			.el-breadcrumb__inner a,
			.el-breadcrumb__inner.is-link,
			.el-breadcrumb__separator {
				color: #C43E32 !important;

			}

			.el-breadcrumb__inner.is-link:hover {
				border-bottom: 1px solid #C43E32;
			}

			.el-breadcrumb__item:last-child .el-breadcrumb__inner {
				color: #C43E32 !important;
			}

		}
	}
</style>